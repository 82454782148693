import {ErrorMessage} from "formik"
import {toAbsoluteUrl, toProfileImgUrl} from "../../helpers"
import toast from "react-hot-toast"
import {getLabel} from "../../language-provider/LanProvider"

const FormikImage = ({
  label,
  labelClassName,
  name,
  profileImg,
  setProfileImg,
  editFlag,
  editPath,
  formik,
  ...rest
}) => {
  const onImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      if (!event.target.files[0].type.includes("image/") && event.target.files[0].size > 5100000) {
        toast.error(getLabel("ONLY_IMG_UNDER_5MB"))
      } else if (!event.target.files[0].name.match(/\.(jpg|jpeg|png|bmp)$/)) {
        toast.error(getLabel("ONLY_IMG"))
      } else if (event.target.files[0].size > 5100000) {
        toast.error(getLabel("IMG_UNDER_5MB"))
      } else if (
        event.target.files[0].type.includes("image/") &&
        event.target.files[0].size <= 5100000
      ) {
        setProfileImg(URL.createObjectURL(event.target.files[0]))
        formik.setFieldTouched(name, false)
        return true
      }
    }
  }
  return (
    <>
      <label htmlFor="imgId" name="Profile_Picture">
        <label className={labelClassName}>{label}</label>
        <br />
        <span className="form-control form-control-solid mb-lg-0">
          <input
            hidden
            id="imgId"
            type="file"
            accept="image/*"
            className="form-control form-control-solid mb-lg-0"
            onClick={() => formik.setFieldTouched(name, true)}
            // onChange={(e) => {
            //   formik.setFieldValue("Profile_Picture", e.target.files[0])
            //   var binaryData = []
            //   binaryData.push(e.target.files[0])
            //   setProfileImg(window.URL.createObjectURL(new Blob(binaryData)))
            //   // setProfileImg(URL.createObjectURL(e?.target?.files[0]))
            // }}
            onChange={(event) => {
              if (onImageChange(event)) {
                formik.setFieldValue(name, event.target.files[0])
              }
            }}
          />
          <img
            style={{maxWidth: "100%", height: "auto", width: "auto"}}
            src={
              profileImg
                ? profileImg
                : editFlag && editPath
                ? toProfileImgUrl(editPath)
                : toAbsoluteUrl("/media/avatars/blank.png")
            }
            alt=""
            {...rest}
          />
        </span>
      </label>
      <br />
      <ErrorMessage name={name} component="span" className="invalid-input" style={{color: "red"}} />
    </>
  )
}

export default FormikImage
