const Videoon = () => {
  return (
    <svg width={27} height={27} viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15.1033 6.9144H7.0512L17.4238 17.287C17.4779 17.024 17.5089 16.6682 17.5089 16.3433V9.36638C17.5089 7.81165 16.658 6.9144 15.1033 6.9144ZM18.5608 15.477L21.3764 17.9135C21.6393 18.1456 21.9487 18.2925 22.2272 18.2925C22.8305 18.2925 23.225 17.8516 23.225 17.2174V9.17301C23.225 8.53874 22.8305 8.09785 22.2272 8.09785C21.9487 8.09785 21.6393 8.24481 21.3764 8.47686L18.5608 10.9056V15.477ZM6.7186 19.476H15.3431L4.38264 8.51554C4.29755 8.70891 4.24341 9.06472 4.24341 9.39732V17.0163C4.24341 18.5633 5.16387 19.476 6.7186 19.476Z"
        fill="white"
      />
      <rect x={4} y={7} width={14} height={13} rx={2} fill="white" />
    </svg>
  )
}

export default Videoon
