import React, {createContext, useContext, useState, useEffect} from "react"
import {DefaultLayoutConfig} from "./DefaultLayoutConfig"
import {
  getEmptyCssClasses,
  getEmptyCSSVariables,
  getEmptyHTMLAttributes,
  LayoutSetup,
} from "./LayoutSetup"
import {useSelector} from "react-redux";
import {checkBasicPermission} from "../../../app/services/permission.service";
const LayoutContext = createContext({
  config: DefaultLayoutConfig,
  classes: getEmptyCssClasses(),
  attributes: getEmptyHTMLAttributes(),
  cssVariables: getEmptyCSSVariables(),
  setLayout: (config) => {},
})
export const enableSplashScreen = () => {
  const splashScreen = document.getElementById("splash-screen")
  if (splashScreen) {
    splashScreen.style.setProperty ("display", "flex")
  }
}
export const disableSplashScreen = () => {
  const splashScreen = document.getElementById("splash-screen")
  if (splashScreen) {
    splashScreen.style.setProperty ("display", "none")
  }
}
const LayoutProvider = ({children}) =>
{
    const [config, setConfig] = useState(LayoutSetup.config)
    const [classes, setClasses] = useState(LayoutSetup.classes)
    const [attributes, setAttributes] = useState(LayoutSetup.attributes)
    const [cssVariables, setCSSVariables] = useState(LayoutSetup.cssVariables)
	
	const email = useSelector ((state) => state?.AuthReducer?.email)
	const [isShowNotificatonPermission, setIsShowNotificatonPermission] = useState(false)
	
	useEffect(() =>
	{
		if (email)
		{
			const checkPermission = async () =>
			{
				const permission = await checkBasicPermission ();
				if (permission[0]?.permission == "denied" || permission[1]?.permission == "denied")
				{
					setIsShowNotificatonPermission (true);
				}
			}
			checkPermission().then()
		}
	}, [email]);
	
	const setLayout = (_themeConfig) =>
    {
    enableSplashScreen()
    const bodyClasses = Array.from(document.body.classList)
    bodyClasses.forEach((cl) => document.body.classList.remove(cl))
    LayoutSetup.updatePartialConfig(_themeConfig)
    setConfig(Object.assign({}, LayoutSetup.config))
    setClasses(LayoutSetup.classes)
    setAttributes(LayoutSetup.attributes)
    setCSSVariables(LayoutSetup.cssVariables)
    setTimeout(() => {
      disableSplashScreen()
    }, 500)
  }
    const value =
	{
	    config,
	    classes,
	    attributes,
	    cssVariables,
	    setLayout,
		isShowNotificatonPermission
    }
    
	useEffect(() =>
	{
        disableSplashScreen()
    }, [])
    return <LayoutContext.Provider value={value}>{children}</LayoutContext.Provider>
}
export {LayoutContext, LayoutProvider}
export function useLayout() {
  return useContext(LayoutContext)
}
