/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react"
import {Languages} from "./Languages"
import {useSelector} from "react-redux"
import {toProfileImgUrl} from "../../helpers"
import {getLabel} from "../../language-provider/LanProvider"
import {logOut} from "../../helpers/reuseable-fun/FunReuse"
import {Link} from "react-router-dom"
import AxiosMethod from "../../Api/AxiosMethod"
import {database} from "../../../app/services/firebase.service";
import {child, get, ref, remove} from "firebase/database";

const HeaderUserMenu = () => {
  const loggedInUser = useSelector((state) => state?.AuthReducer?.userData);
  const user_id = useSelector((state) => state?.AuthReducer?.user_id);
  
  const logOutClick = async () =>
  {
		if (loggedInUser !== null)
		{
		    await AxiosMethod (`users/logout`, `post`, {email: loggedInUser.email, platform: "web", user_id: loggedInUser?.user_id,});
		    const loggedinDeviceRef = ref ( database, `loggedindevice/${user_id}`);
			
			// CHECK IF THE USER IS LOGGED IN ON THIS DEVICE
			const snapshot = await get (loggedinDeviceRef);
			if (snapshot.exists())
			{
				const deviceIds = Object.keys (snapshot.val ());
				console.log ("deviceIdsdeviceIdsdeviceIds", deviceIds);
				
				// REMOVE THE EXISTING DEVICE ID
				const removePromises = deviceIds.map (id => remove (child(loggedinDeviceRef,id)));
				await Promise.all (removePromises);
			}
			logOut();
			
		}
  }

  return (
    <div
      className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px"
      data-kt-menu="true"
    >
      <div className="menu-item px-3">
        <div className="menu-content d-flex align-items-center px-3">
          <div className="symbol symbol-50px me-5">
            {/* <img alt="Logo" src={toAbsoluteUrl("/media/avatars/300-1.jpg")} /> */}
            {loggedInUser?.profile_pic ? (
              <img src={toProfileImgUrl(loggedInUser?.profile_pic)} alt="Logo" />
            ) : (
              <h2 style={{height: "40%", width: "30%"}} className="symbol text-primary">
                {loggedInUser?.full_name
                  .match(/\b(\w)/g)
                  .join("")
                  .toUpperCase()}
              </h2>
            )}
          </div>

          <div className="d-flex flex-column maxw-175">
            <div className="fw-bolder d-flex align-items-center fs-5">
              {loggedInUser?.full_name}
            </div>
            <a
              className="fw-bold text-muted text-hover-primary fs-7"
              style={{wordBreak: "break-word"}}
            >
              {loggedInUser?.email}
            </a>
            {loggedInUser.role_id !== 1 && (
              <span className="badge badge-light-success fw-bolder fs-8 px-2 mt-2 white-space py-1 text-capitalize">
                {(loggedInUser && loggedInUser["plan.name"]) || ""}
              </span>
            )}
          </div>
        </div>
      </div>

      {/* {loggedInUser?.role_id == 2 && (
        <>
          <div className="separator my-2"></div>
          <div className="menu-item px-5">
            <Link to={"/crafted/pages/profile"} className="menu-link px-5">
              My Profile
            </Link>
          </div>
        </>
      )} */}

      {/* <div className="menu-item px-5">
        <a href="#" className="menu-link px-5">
          <span className="menu-text">My Projects</span>
          <span className="menu-badge">
            <span className="badge badge-light-danger badge-circle fw-bolder fs-7">3</span>
          </span>
        </a>
      </div> */}

      {/* <div
        className="menu-item px-5"
        data-kt-menu-trigger="hover"
        data-kt-menu-placement="left-start"
        data-kt-menu-flip="bottom"
      >
        <a href="#" className="menu-link px-5">
          <span className="menu-title">My Subscription</span>
          <span className="menu-arrow"></span>
        </a>

        <div className="menu-sub menu-sub-dropdown w-175px py-4">
          <div className="menu-item px-3">
            <a href="#" className="menu-link px-5">
              Referrals
            </a>
          </div>

          <div className="menu-item px-3">
            <a href="#" className="menu-link px-5">
              Billing
            </a>
          </div>

          <div className="menu-item px-3">
            <a href="#" className="menu-link px-5">
              Payments
            </a>
          </div>

          <div className="menu-item px-3">
            <a href="#" className="menu-link d-flex flex-stack px-5">
              Statements
              <i
                className="fas fa-exclamation-circle ms-2 fs-7"
                data-bs-toggle="tooltip"
                title="View your statements"
              ></i>
            </a>
          </div>

          <div className="separator my-2"></div>

          <div className="menu-item px-3">
            <div className="menu-content px-3">
              <label className="form-check form-switch form-check-custom form-check-solid">
                <input
                  className="form-check-input w-30px h-20px"
                  type="checkbox"
                  value="1"
                  defaultChecked={true}
                  name="notifications"
                />
                <span className="form-check-label text-muted fs-7">Notifications</span>
              </label>
            </div>
          </div>
        </div>
      </div> */}

      {/* <div className="menu-item px-5">
        <a href="#" className="menu-link px-5">
          My Statements
        </a>
      </div> */}

      <div className="separator my-2"></div>
      <div className="menu-item px-5">
        <Link to="update-profile" className="menu-link px-5">
          {getLabel("UPDATE_PROFILE")}
        </Link>
      </div>

      <div className="menu-item px-5">
        <Link to="/change-password" className="menu-link px-5">
          {getLabel("CHANGE_PASSWORD")}
        </Link>
      </div>
      <Languages />
      <div className="menu-item px-5">
        <a onClick={logOutClick} className="menu-link px-5">
          {getLabel("SIGN_OUT")}
        </a>
      </div>
    </div>
  )
}
export {HeaderUserMenu}
