/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect, useState} from "react"
import {useDispatch, useSelector} from "react-redux"
import toast from "react-hot-toast"

import AxiosMethod from "../../../_metronic/Api/AxiosMethod"
import Pagination from "../../../_metronic/helpers/pagination-helper/Pagination"
import SortingHelper from "../../../_metronic/helpers/sorting-helper/SortingHelper"
import TableHeaderToolbar from "../../../_metronic/helpers/table-header-helper/TableHeaderToolbar"
import {getLabel} from "../../../_metronic/language-provider/LanProvider"
import {PageTitle} from "../../../_metronic/layout/core"
import {SetLoader} from "../../../_metronic/redux/actions/LoaderAction"
import {setOrgManagerParams} from "../../../_metronic/redux/actions/QueryParamsAction"
import AdminBuildingFilter from "../qr-admin-management/AdminBuildingFilter"
import OrgManagerRows from "./OrgManagerRows"

const OrgManagerList = () => {
  const dispatch = useDispatch()
  const [users, setUsers] = useState()
  const currentParams = useSelector((state) => state?.QueryParamsReducer?.orgManagerParams)
  const [params, setparams] = useState(currentParams)

  const [notFound, setNotFound] = useState("")

  // get org role=2
  const getClient = async (search, limit, page, sortBy, sort, filterKey, filterValue) => {
    dispatch(SetLoader(true))
    let getUsersRes = await AxiosMethod(
      `users?search=${search}&limit=${limit}&page=${page}&sortwith=${sortBy}&sortby=${sort}&role=5&filterKey=${
        String(filterKey) || ""
      }&filterValue=${String(filterValue) || ""}`,
      `get`
    )
    let values = {
      search: search,
      limit: limit,
      page: page,
      sortBy: sortBy,
      sort: sort,
      filterKey: filterKey,
      filterValue: filterValue,
    }
    setparams(values)
    dispatch(setOrgManagerParams(values))
    if (getUsersRes && getUsersRes.status === 200) {
      dispatch(SetLoader(false))
      setNotFound(false)
      setUsers(getUsersRes.data.data)
    }
    if (JSON.parse(getUsersRes.request.response).status === "error") {
      dispatch(SetLoader(false))
      // toast.error(JSON.parse(getUsersRes.request.response).message)
      setNotFound(getLabel("ORG_MANAGER_NOT_FOUND"))
    }
  }

  const deleteClient = async (userId) => {
    let deleteUsersRes = await AxiosMethod(`users/${userId}`, `delete`)
    if (deleteUsersRes.status === 200) {
      toast.success(getLabel("ORG_MANAGER_DELETED"))
      getClient(
        params.search,
        params.limit,
        users?.rows?.length <= 1 && params.page > 1 ? params.page - 1 : params.page,
        params.sortBy,
        params.sort,
        params.filterKey || "",
        params.filterValue || ""
      )
    } else {
      toast.error(JSON.parse(deleteUsersRes.request.response).message)
    }
  }

  useEffect(() => {
    getClient(
      params.search,
      params.limit,
      users?.rows?.length <= 1 && params.page > 1 ? params.page - 1 : params.page,
      params.sortBy,
      params.sort,
      params.filterKey || "",
      params.filterValue || ""
    )
  }, [])

  return (
    <>
      <PageTitle>{getLabel("ORG_MANAGER_LIST")}</PageTitle>

      <div className="card">
        <TableHeaderToolbar
          params={params}
          setparams={setparams}
          action={getClient}
          filterBody={<AdminBuildingFilter params={params} action={getClient} filterOptions={["country", "organization"]} />}
          showSearch={true}
          showExportBtn={false}
          // handleCsv={handleCsv}
          // csvData={csvData}
          // filename="roomtypes"
          showAddButton={true}
          addButton={`${getLabel("INVITE")} ${getLabel("NEW")} ${getLabel("ORG_MANAGER")}`}
          path="/org/manager/invite"
          inviteType="user"
        />
        <div className="card-body py-4">
          <div id="kt_table_users_wrapper" className="dataTables_wrapper dt-bootstrap4 no-footer">
            <div className="table-responsive">
              <table
                className="table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer"
                id="kt_table_users"
              >
                <thead>
                  <tr className="text-start text-muted fw-bolder fs-7 text-uppercase gs-0">
                    <th
                      className="min-w-125px sorting"
                      tabIndex={0}
                      aria-controls="kt_table_users"
                      rowSpan={1}
                      colSpan={1}
                      aria-label="User: activate to sort column ascending"
                      style={{width: "288.678px", cursor: "pointer"}}
                      onClick={() =>
                        getClient(
                          params.search,
                          params.limit,
                          params.page,
                          "full_name",
                          params.sort === 0 ? 1 : 0,
                          params.filterKey || "",
                          params.filterValue || ""
                        )
                      }
                    >
                      {getLabel("ORG_MANAGER")}
                      {SortingHelper(params.sort, params.sortBy, "full_name")}
                    </th>
                    <th
                      className="min-w-125px sorting"
                      tabIndex={0}
                      aria-controls="kt_table_users"
                      rowSpan={1}
                      colSpan={1}
                      aria-label="Role: activate to sort column ascending"
                      style={{width: "288.678px", cursor: "pointer"}}
                      onClick={() =>
                        getClient(
                          params.search,
                          params.limit,
                          params.page,
                          "country_id",
                          params.sort === 0 ? 1 : 0,
                          params.filterKey || "",
                          params.filterValue || ""
                        )
                      }
                    >
                      {getLabel("COUNTRY")}
                      {SortingHelper(params.sort, params.sortBy, "country_id")}
                    </th>
                    <th
                      className="min-w-125px sorting"
                      tabIndex={0}
                      aria-controls="kt_table_users"
                      rowSpan={1}
                      colSpan={1}
                      aria-label="Role: activate to sort column ascending"
                    >
                      {getLabel("ALREADY_INVITED_ORG")}
                    </th>
                    <th
                      className="text-end min-w-100px sorting_disabled"
                      rowSpan={1}
                      colSpan={1}
                      aria-label="Actions"
                      style={{width: "128.946px"}}
                    >
                      {getLabel("ACTIONS")}
                    </th>
                  </tr>
                </thead>
                <tbody className="text-gray-600 fw-bold">
                  {notFound ? (
                    <tr>
                      <td colSpan={9}>
                        <div className="d-flex text-center w-100 align-content-center justify-content-center text-uppercase">
                          {notFound}
                        </div>
                      </td>
                    </tr>
                  ) : (
                    users?.rows?.map((user, i) => {
                      return <OrgManagerRows key={i} user={user} deleteClient={deleteClient} />
                    })
                  )}
                </tbody>
              </table>
            </div>

            {!notFound && (
              <Pagination
                params={params}
                setparams={setparams}
                count={users?.count}
                pageData={users?.rows?.length}
                action={getClient}
              />
            )}
          </div>
        </div>
      </div>
    </>
  )
}

export default OrgManagerList
