/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState } from "react"
import ConfirmModel from "../../../_metronic/helpers/model/ConfirmModel"
import { DeleteSvg } from "../../../_metronic/helpers/svg-helper/DeleteSvg"
import ViewSvg from "../../../_metronic/helpers/svg-helper/ViewSvg"
import { getLabel } from "../../../_metronic/language-provider/LanProvider"

const StaffMapRows = ({ user, deleteStaff }) => {
  const [delId, setdelId] = useState("")

  return (
    <>
      <tr key={user.user_id} className="odd">
        {/* Begin User */}
        {/* Begin Organization */}
        <td>{user?.["organization.full_name"]}</td>
        <td>{user?.["department.name"]}</td>
        <td>{user?.["staff.full_name"]}</td>
        {/*begin::Action=*/}
        <td>
          <div className="d-flex justify-content-end flex-shrink-0">
            {/* view Button */}
            <ViewSvg path="/org/staff-mapping/view-org/staff-mapping" editObj={user} />
            {/* edit button */}
            {/* <EditSvg path="/org/staff-mapping/manage-org/staff-mapping" editObj={user} /> */}
            {/* Delete Button */}
            <DeleteSvg onClick={() => setdelId(user.user_group_organization_id)} />

            <ConfirmModel
              delMsz={`${getLabel("STAFF")} ${getLabel("MAPPING")}`}
              show={delId}
              onHide={setdelId}
              onConfirmDel={deleteStaff}
            />
          </div>
        </td>

        {/*end::Action=*/}
      </tr>
    </>
  )
}

export default StaffMapRows
