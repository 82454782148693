/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState } from "react"
import * as Yup from "yup"
import { Link } from "react-router-dom"
import { useDispatch } from "react-redux"
import toast from "react-hot-toast"
import { Formik } from "formik"
import { Form } from "formik"
import { AuthAction } from "../../../../_metronic/redux/actions/AuthAction"
import FormikController from "../../../../_metronic/Shared-Component-formik/FormikController"
import AxiosMethod from "../../../../_metronic/Api/AxiosMethod"
import { getLabel } from "../../../../_metronic/language-provider/LanProvider"
import { ValSchemaList } from "../../../../_metronic/Shared-Component-formik/schema/ValSchemaList"
import { PlanAction } from "../../../../_metronic/redux/actions/LoaderAction"
import { useNavigate } from "react-router-dom"
import {generateDeviceId, getStorage, setStorage} from "../../../services/helper.service";
import AlertModal from "../../../services/alert.service";
import {child, get, ref, set, update} from "firebase/database";
import {database} from "../../../services/firebase.service";
import moment from "moment";
import {httpRequest} from "../../../services/network.service";

export function Login(values)
{
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [loading, setLoading] = useState(false)
    const [otherDevice, setOtherDeicve] = useState()
    const [loginDetails, setLoginDetails] = useState()
	const [showLogoutPrompt, setShowLogoutPrompt] = useState( false);
	const [user, setUser] = useState (null); // STORE THE USER OBJECT AFTER LOGIN.
	const [loginResponse, setLoginResponse] = useState (null); // STORE THE USER OBJECT AFTER LOGIN.
	const [isLoadingLogout, setIsLoadingLogout] = useState (false);
	const [userEnteredEmail, setUserEnteredEmail] = useState("");
	
	const initialValues =
	{
        Email: process.env.NODE_ENV === "development" ? 'admin@yopmail.com' : "",
        Password: process.env.NODE_ENV === "development" ? 'Test@123' : "",
    }
    let validationSchema = Yup.object({
        Email: ValSchemaList(1, "EMAIL"),
        Password: ValSchemaList(8, "PASSWORD"),
    })
	
	// FUNCTION TO HANDLE USER LOGIN
	const onLogin = async (values, { setSubmitting, setStatus }) =>
    {
	    
	    try
	    {
		    setLoading (true);
		    
		    let currentDeviceId = await generateDeviceId ();
		    
		    // PROCEED WITH LOGIN
		    // CREATE SESSION FOR THE USER AND STORE SESSION TOKEN ALONG WITH DEVICE ID
		    const loginData =
		    {
			    email: values.Email,
			    password: values.Password,
			    request_from: "web",
			    device_id: currentDeviceId
		    }
		    
		    // SEND THE HTTP REQUEST TO THE API USING THE LOGIN DATA OBJECT.
		    const response = await httpRequest ("users/login", "POST", loginData);
		    console.log ("response======", response);
		    
		    if (response && typeof response.data !== "undefined")
		    {
			    // IF THE RESPONSE GIVE THE RESULT
			    if (response.status === 'success')
			    {
				    try
				    {
					    setUserEnteredEmail (values.Email);
					    let isUserAlreadyLoggedIn = false; // TRUE IF THE USER IS ALREADY LOGGED IN FROM ANY OTHER DEVICE.
					    setLoginResponse (response);
					    setUser (response.data); // STORE THE USER OBJECT AFTER LOGIN.
					    
					    // GET THE LIST OF LOGGED IN DEVICES TO SEE IF USER HAS ALREADY LOGGED IN FROM ANY DEVICE.
					    const loggedinDeviceRef = ref (database, `loggedindevice/${response.data.user_id}`);
					    
					    // CHECK IF THE USER IS LOGGED IN ON THIS DEVICE
					    const snapshot = await get (loggedinDeviceRef);
					    
					    // IF THE USER HAS ALREADY LOGGED IN FROM ANY DEVICE THEN SHOW THE PROMPT TO LOGOUT FROM OTHER DEVICE.
					    if (snapshot.exists ())
					    {
						    const deviceIds = Object.keys (snapshot.val ()); // GET THE LIST OF DEVICE IDS.
						    
						    // IF USER'S DEVICES ARE FOUND THEN CHECK IF USER IS LOGGED INTO ANY OTHER DEVICE OR NOT?
						    if (deviceIds && deviceIds.length > 1)
						    {
							    deviceIds.forEach ((id) =>
							    {
								    // IF USER IS LOGGED INTO ANY OTHER DEVICE THEN SHOW THE PROMPT TO LOGOUT FROM OTHER DEVICE.
								    if (id !== currentDeviceId && snapshot.val ()[id].isLoggedIn)
								    {
									    isUserAlreadyLoggedIn = true;
									    setShowLogoutPrompt (true);
									    setLoading (false);
								    }
							    })
						    }
					    }
					    
					    // IF USER IS NOT LOGGED INTO ANY OTHER DEVICE THEN LOG THE USER IN.
					    if (!isUserAlreadyLoggedIn)
					    {
						    await createLoginSession (response);
					    }
				    } catch (e)
				    {
					    console.log ("error in login with password", e);
					    toast.error  (getLabel ("INTERNAL_SERVER_ERROR"));
				    }
			    } else
			    {
				    setLoading (false);
				    setSubmitting (false);
				    // toast.error(JSON.parse(getLoginRes.request.response).message)
				    setStatus (JSON.parse (response).message)
			    }
			    
			    if (response?.status === 402)
			    {
				    navigate ("/select-plan",
					    {
						    state: {userData: response.data},
					    })
				    toast.error (response.data?.message)
			    }
		    }
	    }
		catch (error)
		{
			toast.success  (getLabel ("INTERNAL_SERVER_ERROR"));
			console.log ({error});
		}
    }
	
	// FUNCTION TO HANDLE LOGOUT FROM OTHER DEVICES.
	const handleConfirmLogout = async () =>
	{
		setIsLoadingLogout (true);
		
		// CALL YOUR BACKEND API TO HANDLE THE LOGOUT PROCESS
		await httpRequest (`users/logout/othersession`, `POST`, {user_id: user.user_id});
		
		// PERFORM LOGOUT FROM OTHER DEVICES
		const loggedInDeviceRef = ref ( database, `loggedindevice/${user.user_id}`);
		
		// CHECK IF THE USER IS LOGGED IN ON THIS DEVICE
		const snapshot = await get (loggedInDeviceRef);
		
		// IF THE USER IS LOGGED IN ON ANY DEVICE THEN LOGOUT FROM ALL DEVICES.
		if (snapshot.exists())
		{
			const deviceIds = Object.keys (snapshot.val ());
			
			// IF THE VALUE OF FIELD isLoggedIn IS TRUE THEN LOGOUT FROM THAT DEVICE.
			for (const id of deviceIds)
			{
				if (snapshot.val()[id].isLoggedIn)
				{
					await update (child (loggedInDeviceRef, id), {"isLoggedIn": false})
				}
			}
		}
		await createLoginSession (loginResponse);
		setIsLoadingLogout (false);
		
		// ONCE LOGOUT IS SUCCESSFUL, CLOSE THE PROMPT
		setShowLogoutPrompt (false);
	};
	
	// CREATE A LOGIN SESSION. THIS A COMMON FUNCTION THAT WILL CALL AFTER FETCHING LOGIN INFORMATION FROM SERVER
	// AND CHECKING IF THE USER IS NOT ALREADY LOGGED INTO ANY OTHER DEVICE.
	// THIS IS THE FINAL STEP OF THE LOGIN PROCESS.
	const createLoginSession = async (response) =>
	{
		try
		{
			let userData = response.data;
			
			// 1. FIRST STEP IS TO CREATE A LOGGED IN DEVICE ID IN THE DATABASE.
			let device_id = await generateDeviceId();
			let loggedInDeviceData =
			{
				"isLoggedIn": true,
				"lastupdated": moment().format(),
				"platform": "web"
			}
			
			const loggedInDeviceRef = ref (database, `loggedindevice/${userData.user_id}/${device_id}`);
			const snapshot =  await get (loggedInDeviceRef);
			
			// IF A RECORD IS FOUND THEN UPDATE THE RECORD. OTHERWISE, CREATE A NEW RECORD IN REALTIME DATABASE.
			if (snapshot.exists())
			{
				await update (loggedInDeviceRef, loggedInDeviceData);
			}
			else // CREATE A NEW RECORD IN REALTIME DATABASE.
			{
				await set (loggedInDeviceRef, loggedInDeviceData);
			}
			
			// 2. ON SECOND STEP, SEND FCM TOKEN TO THE SERVER.
			let token = await getStorage ("qr_fcm_token");
			
			// MAKE SURE A VALID TOKEN IS FOUND.
			if (token)
			{
				let requestData =
					{
						platform: "web",
						token: token,
						user_id: userData.user_id,
					};
				
				// NO NEED TO WAIT FOR THE RESPONSE FROM SERVER. JUST SEND THE TOKEN TO THE SERVER.
				httpRequest ("users/fcmtoken/update", "POST", requestData).then();
			}
			else // NO TOKEN FOUND. THIS USER WILL NOT RECEIVE ANY CALLS.
			{
				console.error ("no token found ", token);
			}
			
			// 3. GET THE PLANS LIST FROM DATABASE
			let getPlanRes = await httpRequest (`plans`, `get`);
			console.log ("no getPlanRes getPlanRes ", getPlanRes);
			
			// DISPATCHED THE PLAN DATA.
			dispatch (PlanAction (getPlanRes?.data?.rows));
			await setStorage ("qr_userdata", userData)
			setLoading (false); // STOP THE LOADER.
			
			// 4. SHOW A SUCCESSFUL LOGIN MESSAGE TO USER AND REDIRECT THEM TO HOME SCREEN.
			toast.success (getLabel ("LOGIN_SUCCESS"))
			dispatch (
				AuthAction ({
					email: userData.email,
					token: response.token,
					userData: userData,
					user_id: userData.user_id,
				})
			)
		}
		catch (e) // ERROR IN CREATING A LOGIN INSTANCE.
		{
			console.log ("error in creating a login instance", e);
			toast.success  (getLabel ("INTERNAL_SERVER_ERROR"));
		}
	}
	
	return (
    <>
      <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onLogin}>
        {(formik) => {
          return (
            <Form onSubmit={formik.handleSubmit} className="form w-100">
              <div className="text-center mb-10">
                <h1 className="text-dark mb-3">{getLabel("LOGIN_TITLE")}</h1>
              </div>

              {formik.status && (
                <div className="mb-lg-15 alert alert-danger">
                  <div className="alert-text font-weight-bold">{formik.status}</div>
                </div>
              )}

              <div className="fv-row mb-10">
                <FormikController
                  control="input"
                  type="email"
                  label={getLabel("EMAIL")}
                  labelClassName="required form-label fs-6 fw-bolder text-dark"
                  name="Email"
                  placeholder={`${getLabel("ENTER")} ${getLabel("EMAIL")}`}
                  className="form-control form-control-lg form-control-solid"
                  autoComplete="off"
                  formik={formik}
                  value={formik.values.Email}
                  onChange={formik.handleChange}
                  error={formik.errors.Email}
                  touched={formik.errors.Email}
                />
              </div>
              <div className="fv-row mb-10">
                <div className="d-flex justify-content-between mt-n5">
                  <div className="d-flex flex-stack mb-2">
                    <label className="required form-label fw-bolder text-dark fs-6 mb-0">
                      {getLabel("PASSWORD")}
                    </label>
                    <Link
                      to="/auth/forgot-password"
                      className="link-primary fs-6 fw-bolder"
                      style={{ marginLeft: "5px" }}
                    >
                      {getLabel("FORGOT_PASSWORD")}
                    </Link>
                  </div>
                </div>
                <FormikController
                  control="input"
                  type="password"
                  name="Password"
                  placeholder={`${getLabel("ENTER")} ${getLabel("PASSWORD")}`}
                  className="form-control form-control-lg form-control-solid pe-13"
                  autoComplete="on"
                  maxLength="30"
                  formik={formik}
                  value={formik.values.Password}
                  onChange={formik.handleChange}
                  error={formik.errors.Password}
                  touched={formik.errors.Password}
                />
              </div>
              <div className="text-center">
                <button
                  type="submit"
                  id="kt_sign_in_submit"
                  className="btn btn-lg btn-primary w-100 mb-5"
                  disabled={formik.isSubmitting || !formik.isValid}
                >
                  {loading ? (
                    <span className="indicator-progress" style={{ display: "block" }}>
                      {getLabel("PLEASE_WAIT")}
                      <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                    </span>
                  ) : (
                    <span className="indicator-label">{getLabel("CONTINUE")}</span>
                  )}
                </button>
              </div>

              <div className="text-center">
                <Link
                  to="/select-plan"
                  className="link-primary fs-6 fw-bolder"
                  style={{ marginLeft: "5px" }}
                >
                  {getLabel("REG_ORG_USER")}
                </Link>
              </div>
            </Form>
          )
        }}
      </Formik>
	    
	    {showLogoutPrompt &&
		    <AlertModal
			    description={"You are already logged in on another device. Do you want to logout from other device and continue?"}
			    title="Logout from Other Device"
			    visible={showLogoutPrompt}
			    onRequestClose={()=> setShowLogoutPrompt(false)}
			    type={"danger"}
			    buttonObject={[
				    {
					    buttonTitle: "Yes",
				        loader: isLoadingLogout,
				        onButtonPress: ()=> handleConfirmLogout()
				    },
				    {
					    buttonTitle: "No",
					    onButtonPress: ()=> setShowLogoutPrompt(false),
				    },
			    ]}
		    />
	    }
    </>
  )
}
