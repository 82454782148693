/* eslint-disable react-hooks/exhaustive-deps */
import * as Yup from "yup"
import toast from "react-hot-toast"

import {Formik, Form} from "formik"
import {useNavigate, useSearchParams} from "react-router-dom"
import {useEffect, useState} from "react"
import AxiosMethod from "../../../_metronic/Api/AxiosMethod"
import FormikController from "../../../_metronic/Shared-Component-formik/FormikController"
import {getTempCountries, getTempState} from "../../../_metronic/helpers/reuseable-fun/FunReuse"
import {TakeEditedVal} from "../../../_metronic/helpers/only-edit-value/TakeEditedVal"
import {getLabel} from "../../../_metronic/language-provider/LanProvider"
import {ValSchemaList} from "../../../_metronic/Shared-Component-formik/schema/ValSchemaList"
import jwt_decode from "jwt-decode"

const InviteOrg = () => {
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()
  const [stateList, setStateList] = useState([])
  const [countryList, setCountryList] = useState([])

  const [searchParams] = useSearchParams()
  const token_decoded = jwt_decode(searchParams.get("token"))

  const [countryIdForState, setCountryIdForState] = useState(null)
  const [profileImg, setProfileImg] = useState("")

  const initialValues = {
    full_name: "",
    email: token_decoded?.email,
    contact_no: "",
    address: "",
    city: "",
    state_id: "",
    country_id: "",
    password: "",
  }

  let validationSchema = Yup.object({
    full_name: ValSchemaList(0, "FULL_NAME"),
    // email: ValSchemaList(1, "EMAIL"),
    // contact_no: ValSchemaList(4, "CONTACT_NO"),
    password: ValSchemaList(0, "PASSWORD"),
  })

  const getCountries = async () => {
    setCountryList(await getTempCountries())
  }
  const getState = async () => {
    setStateList(await getTempState(countryIdForState))
  }
  const onAddEditUser = async (values) => {
    setLoading(true)
    let temp = TakeEditedVal(values, initialValues)
    var bodyFormData = new FormData()
    for (var key in temp) {
      bodyFormData.append(key, temp[key])
    }
    bodyFormData.append("role_id", token_decoded?.type == "user" ? 5 : 3)
    bodyFormData.append("organization_id", token_decoded?.organizationId)
    bodyFormData.append("email", token_decoded?.email)

    let createUsersRes = await AxiosMethod(`users/registerClient`, `post`, bodyFormData)
    if (createUsersRes && createUsersRes.status === 200) {
      let generateQrRes = await AxiosMethod(`qrcodes/generate`, `post`, {
        email: values.email,
      })
      if (generateQrRes && generateQrRes.status === 200) {
        setLoading(true)
        toast.success(getLabel("USER_CREATED_SUCCESS"))
        // toast.success("QRCode generated successfully")
      }
      if (JSON.parse(generateQrRes.request.response).status === "error") {
        setLoading(true)
        toast.error(JSON.parse(generateQrRes.request.response).message)
      }
      navigate("/")
    }
    if (JSON.parse(createUsersRes.request.response).status === "error") {
      setLoading(true)
      toast.error(JSON.parse(createUsersRes.request.response).message)
    }
  }
  useEffect(() => {
    getCountries()
  }, [])
  useEffect(() => {
    getState()
  }, [countryIdForState])

  return (
    <>
      {/*begin::Content*/}
      <div className="d-flex flex-column flex-column-fluid" style={{height: "100vh"}}>
        {/*begin::Wrapper*/}
        <div className="mb-10">
          <div className="card" id="kt_pricing">
            {/*begin::Card body*/}
            <div className="card-body p-lg-17">
              {/*begin::Plans*/}
              <div className="d-flex flex-column">
                {/*begin::Row*/}
                <div
                  className="card card-custom"
                  style={{boxShadow: "0px 0px 30px 10px rgb(157 157 157)"}}
                >
                  <div className="card-body py-4">
                    <div className="d-flex row me-n7 pe-7">
                      <Formik
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        onSubmit={onAddEditUser}
                      >
                        {(formik) => {
                          formik.values.country_id && setCountryIdForState(formik.values.country_id)
                          return (
                            <Form onSubmit={formik.handleSubmit} className="form">
                              {/*begin::Scroll*/}
                              <h1 className="pt-15 text-center">
                                {`Register as a ${
                                  token_decoded?.type == "user" ? "Organization Manager" : "Staff"
                                }`}
                              </h1>
                              <br />
                              <div className="card-body py-4">
                                <div
                                  className="d-flex row me-n7 pe-7"
                                  // className="d-flex row scroll-y me-n7 pe-7"
                                  id="kt_modal_add_user_scroll"
                                  data-kt-scroll="true"
                                  data-kt-scroll-activate="{default: false, lg: true}"
                                  // data-kt-scroll-max-height="auto"
                                  data-kt-scroll-dependencies="#kt_modal_add_user_header"
                                  data-kt-scroll-wrappers="#kt_modal_add_user_scroll"
                                  // data-kt-scroll-offset="300px"
                                >
                                  {/* Full Name */}
                                  <div className="col-6 mb-7">
                                    <FormikController
                                      control="input"
                                      type="text"
                                      label={getLabel("FULL_NAME")}
                                      placeholder={`${getLabel("ENTER")} ${getLabel("FULL_NAME")}`}
                                      labelClassName="required fw-bold fs-6 mb-2"
                                      name="full_name"
                                      className="form-control form-control-solid mb-lg-0"
                                      maxLength="25"
                                      formik={formik}
                                      value={formik.values.full_name}
                                      onChange={formik.handleChange}
                                      error={formik.errors.full_name}
                                    />
                                  </div>
                                  {/* email */}
                                  <div className="col-6 mb-7">
                                    <FormikController
                                      control="input"
                                      type="email"
                                      label={getLabel("EMAIL")}
                                      placeholder={`${getLabel("ENTER")} ${getLabel("EMAIL")}`}
                                      labelClassName="required fw-bold fs-6 mb-2"
                                      name="email"
                                      className="form-control form-control-solid mb-lg-0"
                                      maxLength="50"
                                      disabled={true}
                                      formik={formik}
                                      value={formik.values.email}
                                      onChange={formik.handleChange}
                                      error={formik.errors.email}
                                    />
                                  </div>
                                  {/* Contact Number */}
                                  <div className="col-6 mb-7">
                                    <FormikController
                                      control="input"
                                      type="text"
                                      label={getLabel("CONTACT_NO")}
                                      placeholder={`${getLabel("ENTER")} ${getLabel("CONTACT_NO")}`}
                                      labelClassName="fw-bold fs-6 mb-2"
                                      name="contact_no"
                                      className="form-control form-control-solid mb-lg-0"
                                      onKeyPress={(event) => {
                                        if (!/[0-9]/.test(event.key)) {
                                          event.preventDefault()
                                        }
                                      }}
                                      maxLength="20"
                                      formik={formik}
                                      value={formik.values.contact_no}
                                      onChange={formik.handleChange}
                                      error={formik.errors.contact_no}
                                    />
                                  </div>
                                  {/* address */}
                                  <div className="col-6 mb-7">
                                    <FormikController
                                      control="input"
                                      type="text"
                                      label={getLabel("ADDRESS")}
                                      placeholder={`${getLabel("ENTER")} ${getLabel("ADDRESS")}`}
                                      labelClassName="fw-bold fs-6 mb-2"
                                      name="address"
                                      className="form-control form-control-solid mb-lg-0"
                                      maxLength="100"
                                      formik={formik}
                                      value={formik.values.address}
                                      onChange={formik.handleChange}
                                      error={formik.errors.address}
                                    />
                                  </div>
                                  {/* city */}
                                  <div className="col-6 mb-7">
                                    <FormikController
                                      control="input"
                                      type="text"
                                      label={getLabel("CITY")}
                                      placeholder={`${getLabel("ENTER")} ${getLabel("CITY")}`}
                                      labelClassName="fw-bold fs-6 mb-2"
                                      name="city"
                                      className="form-control form-control-solid mb-lg-0"
                                      maxLength="25"
                                      formik={formik}
                                      value={formik.values.city}
                                      onChange={formik.handleChange}
                                      error={formik.errors.city}
                                    />
                                  </div>
                                  {/* simple react country */}
                                  <div className="col-6 mb-7">
                                    <FormikController
                                      control="select"
                                      label={getLabel("COUNTRY")}
                                      labelClassName="fw-bold fs-6 mb-2"
                                      name="country_id"
                                      className="form-select form-select-solid"
                                      options={countryList}
                                      value={formik.values.country_id}
                                      onChange={(e) => {
                                        formik.handleChange(e)
                                        getState(e.target.value)
                                      }}
                                      error={formik.errors.country_id}
                                    />
                                  </div>
                                  {/* simple react state_id */}
                                  <div className="col-6 mb-7">
                                    <FormikController
                                      control="select"
                                      label={getLabel("STATE")}
                                      labelClassName="fw-bold fs-6 mb-2"
                                      name="state_id"
                                      className="form-select form-select-solid"
                                      options={stateList}
                                      value={formik.values.state_id}
                                      onChange={formik.handleChange}
                                      error={formik.errors.state_id}
                                    />
                                  </div>
                                  {/* password filed id adding user */}
                                  <div className="col-6 mb-7">
                                    <FormikController
                                      control="input"
                                      type="password"
                                      autoComplete="on"
                                      label={getLabel("PASSWORD")}
                                      placeholder={`${getLabel("ENTER")} ${getLabel("PASSWORD")}`}
                                      labelClassName="required fw-bold fs-6 mb-2"
                                      name="password"
                                      className="form-control form-control-solid mb-lg-0 pe-13"
                                      maxLength="30"
                                      formik={formik}
                                      value={formik.values.password}
                                      onChange={formik.handleChange}
                                      error={formik.errors.password}
                                    />
                                  </div>{" "}
                                  {/*Profile ImageShared */}
                                  <div className="col-6 mb-7">
                                    <FormikController
                                      control="image"
                                      label={getLabel("PROFILE_IMG")}
                                      labelClassName="fw-bold fs-6 mb-2"
                                      name="profile_pic"
                                      // className="form-control form-control-solid mb-lg-0"
                                      profileImg={profileImg}
                                      setProfileImg={setProfileImg}
                                      // editFlag={location?.state?.edit}
                                      // editPath={location?.state?.editObj?.profile_pic}
                                      formik={formik}
                                      value={formik.values.profile_pic}
                                      onChange={formik.handleChange}
                                      error={formik.errors.profile_pic}
                                    />
                                  </div>
                                </div>
                              </div>
                              {/*end::Scroll*/}
                              {/*begin::Actions*/}
                              <div className="card-footer d-flex justify-content-center">
                                <button
                                  className="btn btn-light-primary font-weight-bold"
                                  style={{marginRight: "1rem"}}
                                  type="button"
                                  onClick={() => {
                                    navigate("/org/staff-management")
                                  }}
                                >
                                  <span className="indicator-label">{getLabel("CANCEL")}</span>
                                </button>
                                <button
                                  type="submit"
                                  id="kt_password_reset_submit"
                                  className="btn btn-lg btn-primary fw-bolder me-4"
                                  disabled={formik.isSubmitting || !formik.isValid}
                                >
                                  {loading ? (
                                    <span className="indicator-progress" style={{display: "block"}}>
                                      {getLabel("PLEASE_WAIT")}
                                      <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                    </span>
                                  ) : (
                                    <span className="indicator-label">{getLabel("SUBMIT")}</span>
                                  )}
                                </button>
                              </div>
                              {/*end::Actions*/}
                            </Form>
                          )
                        }}
                      </Formik>
                    </div>
                  </div>
                </div>
                {/*end::Row*/}
              </div>
              {/*end::Plans*/}
            </div>
            {/*end::Card body*/}
          </div>
        </div>
        {/*end::Wrapper*/}
      </div>
      {/*end::Content*/}
    </>
  )
}

export default InviteOrg
