/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState} from "react"
import {toProfileImgUrl} from "../../../_metronic/helpers"
import ConfirmModel from "../../../_metronic/helpers/model/ConfirmModel"
import {handleNull} from "../../../_metronic/helpers/reuseable-fun/FunReuse"
import {DeleteSvg} from "../../../_metronic/helpers/svg-helper/DeleteSvg"
import EditSvg from "../../../_metronic/helpers/svg-helper/EditSvg"
import ViewSvg from "../../../_metronic/helpers/svg-helper/ViewSvg"
import {getLabel} from "../../../_metronic/language-provider/LanProvider"

const AdminRows = ({user, deleteAdmin}) => {
  const [delId, setdelId] = useState("")
  return (
    <tr key={user.user_id} className="odd">
      {/* Begin User */}
      <td className="d-flex align-items-center">
        {/*begin:: Avatar */}
        <div className="symbol symbol-circle symbol-50px overflow-hidden me-3">
          <a>
            <div className="symbol-label fs-3 bg-light-primary text-primary">
              {user.profile_pic ? (
                <img src={toProfileImgUrl(user.profile_pic)} alt="" className="w-100" />
              ) : (
                user.full_name
                  .match(/\b(\w)/g)
                  .join("")
                  .toUpperCase()
              )}
            </div>
          </a>
        </div>
        {/*begin::User details*/}
        <div className="d-flex flex-column">
          <a className="text-gray-800 text-hover-primary mb-1">
            {user.full_name}
            <br />
            {user.contact_no}
          </a>
          <span>{user.email}</span>
        </div>
        {/*begin::User details*/}
      </td>
      {/* Begin Plan */}
      {/* <td>{user["plan.details"]}</td> */}
      {/* Begin Country */}
      <td>{handleNull(user["country.name"])}</td>
      {/*begin::Joined*/}
      {/* <td data-order="2022-03-10T11:05:00+05:30">{convert(new Date(user.doj).toString())}</td> */}
      {/*begin::Action=*/}
      <td>
        <div className="d-flex justify-content-end flex-shrink-0">
          {/* view Button */}
          <ViewSvg path="/admin-management/view-admin" editObj={user} />
          {/* edit button */}
          <EditSvg path="/admin-management/manage-admin" editObj={user} />
          {/* Delete Button */}
          <DeleteSvg onClick={() => setdelId(user.user_id)} />

          <ConfirmModel
            delMsz={getLabel("ADMIN")}
            show={delId}
            onHide={setdelId}
            onConfirmDel={deleteAdmin}
          />
        </div>
      </td>

      {/*end::Action=*/}
    </tr>
  )
}

export default AdminRows
