
export const checkBasicPermission = async (permissionList?: any) =>
{
	// BASIC PERMISSION LIST.
	let basicPermissionList = ["notifications"];
	
	// HOLD THE VALUE.
	let values: any = [];
	
	// CHECK ONE BY ONE PERMISSION.
	for (let list of basicPermissionList)
	{
		const element: any = list
		let response: any = await navigator.permissions.query ({ name: element });
		if (values.length === 0)
		{
			let value =
			{
				name: response.name,
				permission: response.state,
			}
			values.push (value);
		}
		else
		{
			let filter = values.findIndex ((value1:any) =>
			{
				return value1.name === response.name
			})
			if (filter === -1)
			{
				let value =
				{
					name: response.name,
					permission: response.state,
				}
				values.push (value)
			}
			else
			{
				values[filter].permission = response.permission
			}
		}
	}
	return values
}
