import {useEffect, useState} from "react"
import moment from "moment"
import AxiosMethod from "../../../_metronic/Api/AxiosMethod"
import toast from "react-hot-toast"
import {PageTitle} from "../../../_metronic/layout/core"
import Pagination from "../../../_metronic/helpers/pagination-helper/Pagination"
import TableHeaderToolbar from "../../../_metronic/helpers/table-header-helper/TableHeaderToolbar"
import {useDispatch, useSelector} from "react-redux"
import {setDeptParams} from "../../../_metronic/redux/actions/QueryParamsAction"
import SortingHelper from "../../../_metronic/helpers/sorting-helper/SortingHelper"
import EditSvg from "../../../_metronic/helpers/svg-helper/EditSvg"
import {DeleteSvg} from "../../../_metronic/helpers/svg-helper/DeleteSvg"
import ViewSvg from "../../../_metronic/helpers/svg-helper/ViewSvg"
import {getLabel} from "../../../_metronic/language-provider/LanProvider"
import DepartmentBuildingFilter from "./DepartmentBuildingFilter"
import {SetLoader} from "../../../_metronic/redux/actions/LoaderAction"
import ConfirmModel from "../../../_metronic/helpers/model/ConfirmModel"
import {handleNull, userUtcOffset} from "../../../_metronic/helpers/reuseable-fun/FunReuse"

export const weekView = (dept) =>
{
  let arr = []
  JSON.parse(dept?.weekdays)?.map((e) =>
    e == 0
      ? arr.push("Sunday")
      : e == 1
      ? arr.push("Monday")
      : e == 2
      ? arr.push("Tuesday")
      : e == 3
      ? arr.push("Wednesday")
      : e == 4
      ? arr.push("Thursday")
      : e == 5
      ? arr.push("Friday")
      : arr.push("Saturday")
  )

  return arr.map((e) =>
  {
    return (
      <span style={{display: "block", whiteSpace: "nowrap"}} key={e}>
        {e}
      </span>
    )
  })
}

const DepartmentList = () =>
{
  const dispatch = useDispatch()
  const [dept, setDept] = useState()
  const currentParams = useSelector((state) => state?.QueryParamsReducer?.deptParams)
  const [params, setparams] = useState(currentParams)
  const [delId, setdelId] = useState("")

  const [notFound, setNotFound] = useState("")

  const getDept = async (search, limit, page, sortBy, sort, filterKey, filterValue) => {
    dispatch(SetLoader(true))

    // GET Group
    let getDeptRes = await AxiosMethod(
      `departments?search=${search}&limit=${limit}&page=${page}&sortwith=${sortBy}&sortby=${sort}&filterKey=${
        String(filterKey) || ""
      }&filterValue=${String(filterValue) || ""}`,
      `get`
    )
    let values = {
      search: search,
      limit: limit,
      page: page,
      sortBy: sortBy,
      sort: sort,
      filterKey: filterKey,
      filterValue: filterValue,
    }
    setparams(values)
    dispatch(setDeptParams(values))
    if (getDeptRes && getDeptRes.status === 200) {
      dispatch(SetLoader(false))
      setNotFound(false)
      setDept(getDeptRes?.data?.data)
    }
    if (JSON.parse(getDeptRes.request.response).status === "error") {
      dispatch(SetLoader(false))
      setNotFound(JSON.parse(getDeptRes.request.response).message)
      // toast.error(JSON.parse(getDeptRes.request.response).message)
    }
  }
  // Delete Language
  const deleteDepartment = async (id) => {
    let deleteValidationRes = await AxiosMethod(`departments/${id}`, `delete`)
    if (deleteValidationRes && deleteValidationRes.status === 200) {
      toast.success(deleteValidationRes.data.message)
      await getDept(
        params.search,
        params.limit,
        dept?.rows.length <= 1 && params.page > 1 ? params.page - 1 : params.page,
        params.sortBy,
        params.sort,
        params.filterKey || "",
        params.filterValue || ""
      )
    }
    if (JSON.parse(deleteValidationRes.request.response).status === "error") {
      toast.error(getLabel("DEPT_CANT_DEL"))
      // toast.error(JSON.parse(deleteValidationRes.request.response).message)
    }
  }

  useEffect(() => {
    getDept(
      params.search,
      params.limit,
      dept?.rows?.length <= 1 && params.page > 1 ? params.page - 1 : params.page,
      params.sortBy,
      params.sort,
      params.filterKey || "",
      params.filterValue || ""
    )
  }, [])
  return (
    <>
      <PageTitle>{getLabel("SCHEDULE_LIST")}</PageTitle>

      <div className="card">
        {/* Begin:: Card Header */}
        <TableHeaderToolbar
          params={params}
          setparams={setparams}
          action={getDept}
          filterBody={<DepartmentBuildingFilter params={params} action={getDept} />}
          showSearch={true}
          showExportBtn={false}
          // handleCsv={handleCsv}
          // csvData={csvData}
          // filename="roomtypes"
          showAddButton={true}
          addButton={`${getLabel("ADD")} ${getLabel("SCHEDULE")}`}
          path="/org/schedule-management/manage-schedule"
        />
        {/* End:: Card Header */}
        {/*begin::Card body*/}
        <div className="card-body py-4">
          {/*begin::Table*/}
          <div id="kt_table_users_wrapper" className="dataTables_wrapper dt-bootstrap4 no-footer">
            <div className="table-responsive">
              <table
                className="table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer"
                id="kt_table_users"
              >
                {/*begin::Table head*/}
                <thead>
                  {/*begin::Table row*/}
                  <tr className="text-start text-muted fw-bolder fs-7 text-uppercase gs-0">
                    <th
                      className="min-w-125px sorting"
                      tabIndex={0}
                      aria-controls="kt_table_users"
                      rowSpan={1}
                      colSpan={1}
                      aria-label="Joined Date: activate to sort column ascending"
                      style={{width: "208.13px", cursor: "pointer"}}
                      onClick={() =>
                        getDept(
                          params.search,
                          params.limit,
                          params.page,
                          "user.full_name",
                          params.sort === 0 ? 1 : 0,
                          params.filterKey || "",
                          params.filterValue || ""
                        )
                      }
                    >
                      {getLabel("ORG")}
                      {SortingHelper(params.sort, params.sortBy, "user.full_name")}
                    </th>
                    <th
                      className="min-w-125px sorting"
                      tabIndex={0}
                      aria-controls="kt_table_users"
                      rowSpan={1}
                      colSpan={1}
                      aria-label="Joined Date: activate to sort column ascending"
                      style={{width: "208.13px", cursor: "pointer"}}
                      onClick={() =>
                        getDept(
                          params.search,
                          params.limit,
                          params.page,
                          "name",
                          params.sort === 0 ? 1 : 0,
                          params.filterKey || "",
                          params.filterValue || ""
                        )
                      }
                    >
                      {getLabel("NAME")}
                      {SortingHelper(params.sort, params.sortBy, "name")}
                    </th>
                    <th
                      className="min-w-125px sorting"
                      tabIndex={0}
                      aria-controls="kt_table_users"
                      rowSpan={1}
                      colSpan={1}
                      aria-label="Joined Date: activate to sort column ascending"
                      style={{width: "208.13px", cursor: "pointer"}}
                      onClick={() =>
                        getDept(
                          params.search,
                          params.limit,
                          params.page,
                          "description",
                          params.sort === 0 ? 1 : 0,
                          params.filterKey || "",
                          params.filterValue || ""
                        )
                      }
                    >
                      {getLabel("DESC")}
                      {SortingHelper(params.sort, params.sortBy, "description")}
                    </th>
                    <th
                      className="min-w-125px sorting"
                      tabIndex={0}
                      aria-controls="kt_table_users"
                      rowSpan={1}
                      colSpan={1}
                      aria-label="Joined Date: activate to sort column ascending"
                      style={{width: "208.13px", cursor: "pointer"}}
                      onClick={() =>
                        getDept(
                          params.search,
                          params.limit,
                          params.page,
                          "start_shift",
                          params.sort === 0 ? 1 : 0,
                          params.filterKey || "",
                          params.filterValue || ""
                        )
                      }
                    >
                      {getLabel("START_HOUR")}
                      {SortingHelper(params.sort, params.sortBy, "start_shift")}
                    </th>
                    <th
                      className="min-w-125px sorting"
                      tabIndex={0}
                      aria-controls="kt_table_users"
                      rowSpan={1}
                      colSpan={1}
                      aria-label="Joined Date: activate to sort column ascending"
                      style={{width: "208.13px", cursor: "pointer"}}
                      onClick={() =>
                        getDept(
                          params.search,
                          params.limit,
                          params.page,
                          "end_shift",
                          params.sort === 0 ? 1 : 0,
                          params.filterKey || "",
                          params.filterValue || ""
                        )
                      }
                    >
                      {getLabel("END_HOUR")}
                      {SortingHelper(params.sort, params.sortBy, "end_shift")}
                    </th>
                    <th
                      className="min-w-125px sorting"
                      tabIndex={0}
                      aria-controls="kt_table_users"
                      rowSpan={1}
                      colSpan={1}
                      aria-label="Joined Date: activate to sort column ascending"
                      style={{width: "208.13px", cursor: "pointer"}}
                      onClick={() =>
                        getDept(
                          params.search,
                          params.limit,
                          params.page,
                          "weekdays",
                          params.sort === 0 ? 1 : 0,
                          params.filterKey || "",
                          params.filterValue || ""
                        )
                      }
                    >
                      {getLabel("WEEKDAYS")}
                      {SortingHelper(params.sort, params.sortBy, "weekdays")}
                    </th>
                    <th
                      className="text-end min-w-100px sorting_disabled"
                      rowSpan={1}
                      colSpan={1}
                      aria-label="Actions"
                      style={{width: "128.946px"}}
                    >
                      {getLabel("ACTIONS")}
                    </th>
                  </tr>
                  {/*end::Table row*/}
                </thead>
                {/*end::Table head*/}
                {/*begin::Table body*/}
                <tbody className="text-gray-600 fw-bold">
                  {notFound ? (
                    <tr>
                      <td colSpan={8}>
                        <div className="d-flex text-center w-100 align-content-center justify-content-center text-uppercase">
                          {notFound}
                        </div>
                      </td>
                    </tr>
                  ) : (
                    dept?.rows &&
                    dept?.rows.map((dept, i) => {
                      return (
                        <tr key={i} className="odd">
                          <td>{handleNull(dept["user.full_name"])}</td>
                          <td>{handleNull(dept.name)}</td>
                          <td>{handleNull(dept.description)}</td>
                          <td>
                            {handleNull(
                              moment(dept.start_shift).utcOffset(userUtcOffset).format("HH:mm")
                            )}
                          </td>
                          <td>
                            {handleNull(
                              moment(dept.end_shift).utcOffset(userUtcOffset).format("HH:mm")
                            )}
                          </td>
                          <td>{dept.weekdays ? weekView(dept) : "N/A"}</td>
                          {/*begin::Action=*/}
                          <td>
                            <div className="d-flex justify-content-end flex-shrink-0">
                              {/* view Button */}
                              <ViewSvg
                                path="/org/schedule-management/view-schedule"
                                editObj={dept}
                              />
                              {/* edit button */}
                              <EditSvg
                                path="/org/schedule-management/manage-schedule"
                                editObj={dept}
                              />
                              {/* Delete Button */}
                              <DeleteSvg onClick={() => setdelId(dept.department_id)} />
                            </div>
                          </td>

                          {/*end::Action=*/}
                        </tr>
                      )
                    })
                  )}
                </tbody>
                {/*end::Table body*/}
              </table>
            </div>

            {!notFound && (
              <Pagination
                params={params}
                setparams={setparams}
                count={dept?.count}
                pageData={dept?.rows?.length}
                action={getDept}
              />
            )}
          </div>
          {/*end::Table*/}
        </div>
        {/*end::Card body*/}
      </div>

      <ConfirmModel
        delMsz={getLabel("SCHEDULE")}
        show={delId}
        onHide={setdelId}
        onConfirmDel={deleteDepartment}
      />
    </>
  )
}

export default DepartmentList
