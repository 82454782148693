import {useLocation, useNavigate} from "react-router-dom"
import {getLabel} from "../../../_metronic/language-provider/LanProvider"
import {PageTitle} from "../../../_metronic/layout/core"

const ViewUserInfo = () => {
  const usersBreadcrumbs = [
    {
      title: `${getLabel("FIELD")} ${getLabel("LIST")}`,
      path: "/config/fields-customization",
      isSeparator: false,
      isActive: false,
    },
    {
      title: "",
      path: "",
      isSeparator: true,
      isActive: false,
    },
  ]
  const navigate = useNavigate()
  const location = useLocation()
  return (
    <>
      <PageTitle breadcrumbs={usersBreadcrumbs}>
        {`${getLabel("VIEW")} ${getLabel("FIELD")}`}
      </PageTitle>

      <div className="card py-2 py-sm-4">
        <div className="card-body">
          <div className="row">
            <div className="col-12">
              <div className="row">
                <div className="col-4 mb-5 mt-3">
                  <label className="fs-5 fw-bolder form-label mb-2">
                    <span> {getLabel("NAME")}</span>
                  </label>
                  <div className="text-capitalize">{location?.state?.editObj.field_name}</div>
                </div>
                <div className="col-4 mb-5 mt-3">
                  <label className="fs-5 fw-bolder form-label mb-2">
                    <span> {getLabel("TYPE")}</span>
                  </label>
                  <div className="text-capitalize">{location?.state?.editObj.type}</div>
                </div>
                {/* <div className="col-4 mb-5 mt-3">
                  <label className="fs-5 fw-bolder form-label mb-2">
                    <span> {getLabel("CREATED_BY")}</span>
                  </label>
                  <div className="text-capitalize">{location?.state?.editObj.created_by}</div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
        <div className="text-center mb-5">
          <button
            type="reset"
            style={{color: "black"}}
            className="btn btn-light me-3"
            onClick={() => navigate(-1)}
          >
            {getLabel("DISCARD")}
          </button>
        </div>
      </div>
    </>
  )
}

export default ViewUserInfo
