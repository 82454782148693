import clsx from "clsx"
import React from "react"
import { useSelector } from "react-redux"
import { toProfileImgUrl } from "../../../helpers"
import { HeaderUserMenu } from "../../../partials/header-menus/HeaderUserMenu"
const toolbarButtonMarginClass = "ms-1 ms-lg-3",
  toolbarUserAvatarHeightClass = "symbol-30px symbol-md-40px"

const Topbar = () => {
  const loggedInUser = useSelector((state) => state?.AuthReducer?.userData)

  return (
    <div className="d-flex align-items-stretch flex-shrink-0">
      {/* IMP begin::User */}
      <div
        className={clsx("d-flex align-items-center", toolbarButtonMarginClass)}
        id="kt_header_user_menu_toggle"
      >
        {/* begin::Toggle */}
        <div
          className={clsx("cursor-pointer symbol", toolbarUserAvatarHeightClass)}
          data-kt-menu-trigger="click"
          data-kt-menu-attach="parent"
          data-kt-menu-placement="bottom-end"
          data-kt-menu-flip="bottom"
        >
          {/* <img src={toAbsoluteUrl("/media/avatars/300-1.jpg")} alt="metronic" /> */}
          {loggedInUser?.profile_pic ? (
            <img src={toProfileImgUrl(loggedInUser?.profile_pic)} alt="metronic" />
          ) : (
            <div className="symbol-label fs-3 bg-light-primary text-primary cursor-pointer">
              {loggedInUser?.full_name
                .match(/\b(\w)/g)
                .join("")
                .toUpperCase()}
            </div>
          )}
        </div>
        <HeaderUserMenu />
        {/* end::Toggle */}
      </div>
      {/* end::User */}

      {/* begin::Aside Toggler */}
      {/* {config.header.left === "menu" && (
        <div className="d-flex align-items-center d-lg-none ms-2 me-n3" title="Show header menu">
          <div
            className="btn btn-icon btn-active-light-primary w-30px h-30px w-md-40px h-md-40px"
            id="kt_header_menu_mobile_toggle"
          >
            <KTSVG path="/media/icons/duotune/text/txt001.svg" className="svg-icon-1" />
          </div>
        </div>
      )} */}
    </div>
  )
}
export { Topbar }
