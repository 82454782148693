import {useLocation, useNavigate} from "react-router-dom"
import {useEffect, useState} from "react"
import {PageTitle} from "../../../_metronic/layout/core"
import {handleNull, userUtcOffset} from "../../../_metronic/helpers/reuseable-fun/FunReuse"
import {firestore} from "../../services/firebase.service";
import { collection, query, where, orderBy, limit, getDocs } from 'firebase/firestore';
import {getLabel} from "../../../_metronic/language-provider/LanProvider"
import {SetLoader} from "../../../_metronic/redux/actions/LoaderAction"
import {useDispatch} from "react-redux";
import moment from "moment"
import { useParams } from 'react-router-dom';
import { doc, getDoc } from 'firebase/firestore'; // IMPORT FIRESTORE FUNCTIONS
import React from 'react';

// VIEW CALL DETAILS COMPONENT.
const ViewCallDetails = () =>
{
	const { id } = useParams(); // DESTRUCTURE ID FROM USEPARAMS
	
	// BREADCRUMBS FOR VIEW CALL DETAILS.
    const usersBreadcrumbs =
	[
	    {
	      title: `${getLabel("CALL_DETAILS")} ${getLabel("LIST")}`,
	      path: "/call-details",
	      isSeparator: false,
	      isActive: false,
	    },
	    {
	      title: "",
	      path: "",
	      isSeparator: true,
	      isActive: false,
	    }
	]

	// DECLARE THE VARIABLES AND STATES AND PROPS.
	const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
	const [callData, setCallData] = useState(); // CALL DETAILS STATE.
	const [duration, setDuration] = useState(""); // CALL DURATION STATE.
	const [notFound, setNotFound] = useState("")

	// HOOK TO FETCH CALL DETAILS.
	useEffect(() =>
	{
		// CALL THE FUNCTION TO GET CALL DETAILS.
		getCallDetail().then();
	}, [id]); // CALL THE FUNCTION WHEN ID CHANGES.

	// GET CALL DETAIL FUNCTION.
	const getCallDetail = async () =>
	{
		// SET THE LOADER.
		dispatch (SetLoader(true));

		try
		{
			// FETCH THE CALL DOCUMENT FROM FIRESTORE
			const docRef = doc (firestore, 'call', id);
			const docSnap = await getDoc(docRef);
			
			// CHECK IF THE DOCUMENT EXISTS
			if (docSnap.exists())
			{
				const data = docSnap.data();
				console.log ('Document data call data:', data);
				setCallData (data); // SET THE CALL DETAILS IN STATE
				
				let formattedDuration = '00:00:00';
				if (data?.endtime && data?.starttime)
				{
					const durationInMilliseconds = moment
					.duration(moment(data.endtime).diff(moment(data.starttime)))
					.asMilliseconds();
					formattedDuration = moment.utc(durationInMilliseconds).format('HH:mm:ss');
				}
				setDuration (formattedDuration); // SET THE FORMATTED DURATION
				
			} else
			{
				console.log ('No such document!');
			}
		} catch (error)
		{
			console.log ('Error fetching call list:', error);
		}

		// SET THE LOADER.
		dispatch (SetLoader(false));
	}
    return (
    <>
		<PageTitle breadcrumbs={usersBreadcrumbs}>
			{`${getLabel("VIEW")} ${getLabel("CALL_DETAILS")}`}
		</PageTitle>

        <div className="card py-2 py-sm-4">
            <div className="card-body">
	            {
					callData &&
		            <div className="row">
                        <div className="col-12">
                            <div className="row">
                            <div className="col-4 mb-5 mt-3">
								<label className="fs-5 fw-bolder form-label mb-2">
									<span>{`${getLabel("CALLER")}`}</span>
								</label>
								<div className="text-capitalize">
									{callData?.name & (<><br /> {callData?.name}</>)}
									{
										callData?.userinfo && Object.entries (callData?.userinfo).map ((info, i) => {
											const excluded = ["name", "booking_id", "defaultstaff", "qrOrgName", "qrcodeName", "defaultstaffdetail"];
											if (!excluded.includes(info[0]))
											{
												return (
													<React.Fragment key={i}>
														{info[1]? info[1]: ""}
														<br />
													</React.Fragment>
												);
											} else
											{
												return null; // It's better to return null instead of an empty string
											}
										})
									}
								</div>
							</div>
							<div className="col-4 mb-5 mt-3">
								<label className="fs-5 fw-bolder form-label mb-2">
									<span>{getLabel("BOOKING_ID")}</span>
								</label>
								<div className="text-capitalize">
									{handleNull(callData?.userinfo?.booking_id)}
								</div>
							</div>
			                <div className="col-4 mb-5 mt-3">
								<label className="fs-5 fw-bolder form-label mb-2">
									<span>{`${getLabel("ORG")}/ ${getLabel("QR_CODE")}  ${getLabel("NAME")}/ Default ${getLabel("STAFF")}`}</span>
								</label>
								<div className="text-capitalize">
									{handleNull(callData?.userinfo?.qrOrgName)}
									<br/>
									{handleNull(callData?.userinfo?.qrcodeName)}
									<br/>
									{handleNull(callData?.userinfo?.defaultstaff)}
									<br />
									{callData?.useremail}
								</div>
			                </div>
                            <div className="col-4 mb-5 mt-3">
								<label className="fs-5 fw-bolder form-label mb-2">
								<span>{`Attended By/ ${getLabel("START_TIME")}/ ${getLabel("DURATION")}`}</span>
								</label>
								<div className="text-capitalize">
				                  {handleNull(callData?.answaredby_fullname)}
				                  <br/>
			                    {callData.starttime &&
			                    callData.starttime !== "Invalid date"
			                      ? moment(callData.starttime)
			                          .utcOffset(userUtcOffset)
			                          .format("DD/MM/yyyy HH:mm")
			                      : "N/A"}
				                  <br />
				                  {
					                  duration
									  // moment.utc(moment.duration( location?.state?.editObj.duration).asMilliseconds()).utcOffset(userUtcOffset).format("HH:mm:ss")
						          }
			                  </div>
                            </div>
                            <div className="col-4 mb-5 mt-3">
			                    <label className="fs-5 fw-bolder form-label mb-2">
			                        <span>Call Status</span>
			                    </label>
			                    <div className="text-capitalize">
				                    {
					                    callData?.isCallConnected && callData.isCallConnected == 1
						                    ?
						                    "Answered"
						                    :
						                    "Missed"
				                    }
			                    </div>
                            </div>
						</div>
                        </div>
                    </div>
	            }
            </div>
            <div className="text-center mb-5">
          <button
            type="reset"
            style={{color: "black"}}
            className="btn btn-light me-3"
            onClick={() => navigate(-1)}
          >
            {getLabel("DISCARD")}
          </button>
        </div>
      </div>
    </>
  )
}

export default ViewCallDetails
