import toast from "react-hot-toast"
import clsx from "clsx"
import * as Yup from "yup"
import { Formik, Form } from "formik"
import AxiosMethod from "../../../_metronic/Api/AxiosMethod"
import FormikController from "../../../_metronic/Shared-Component-formik/FormikController"
import { ValSchemaList } from "../../../_metronic/Shared-Component-formik/schema/ValSchemaList"
import { useState } from "react"
import { useNavigate } from "react-router-dom"
import { PageTitle } from "../../../_metronic/layout/core"
import { getLabel } from "../../../_metronic/language-provider/LanProvider"
import { logOut } from "../../../_metronic/helpers/reuseable-fun/FunReuse"

const ChangePassword = () => {
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)

  const initialValues = {
    currentPassword: '',
    newPassword: '',
    confirmPassword: '',
  }
  let validationSchema = Yup.object({
    currentPassword: ValSchemaList(8, 'CURRENT_PASS'),
    newPassword: ValSchemaList(8, 'NEW_PASS'),
    confirmPassword: ValSchemaList(3, 'CONFIRM_PASS', 'newPassword'),
  })
  const onChangePass = async (values) => {
    setLoading(true)
    const apiRes = await AxiosMethod(`users/changePassword`, `POST`, values)
    if (apiRes?.data?.status === "success") {
      setLoading(false)
      toast.success(apiRes.data.message)
      setTimeout(() => {
        logOut()
      }, 1000)
    } else {
      setLoading(false)
      toast.error(JSON.parse(apiRes.request.response).message)
    }
  }

  return (
    <>
      <PageTitle>
        {getLabel("CHANGE_PASSWORD")}
      </PageTitle>

      <div className='card mb-5 mb-xl-10'>
        <div
          className='card-header border-0 cursor-pointer'
          role='button'
          data-bs-toggle='collapse'
          data-bs-target='#kt_account_profile_details'
          aria-expanded='true'
          aria-controls='kt_account_profile_details'
        >
          <div className='card-title m-0'>
            {getLabel("CHANGE_PASSWORD")}
          </div>
        </div>

        <div id='kt_account_profile_details' className='collapse show'>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onChangePass}
          >
            {(formik) => {
              return (
                <Form onSubmit={formik.handleSubmit} className='form' autoComplete='false'>
                  <div className='card-body py-4'>
                    <div
                      className='d-flex row me-n7 pe-7'
                      id='kt_modal_add_user_scroll'
                      data-kt-scroll='true'
                      data-kt-scroll-activate='{default: false, lg: true}'
                      data-kt-scroll-dependencies='#kt_modal_add_user_header'
                      data-kt-scroll-wrappers='#kt_modal_add_user_scroll'
                    >
                      {/* pass */}
                      <div className='col-4 mb-7 password-wrap'>
                        <FormikController
                          control='input'
                          {...formik.getFieldProps('currentPassword')}
                          type='password'
                          name='currentPassword'
                          label={getLabel("CURRENT_PASS")}
                          placeholder={`${getLabel("ENTER")} ${getLabel("CURRENT_PASS")}`}
                          labelClassName='required form-label fs-6 fw-bolder text-dark'
                          className={clsx('form-control bg-transparent', {
                            'is-invalid':
                              formik.touched.currentPassword && formik.errors.currentPassword,
                          })}
                          autoComplete='on'
                          maxLength='30'
                          formik={formik}
                          value={formik.values.currentPassword}
                          onChange={formik.handleChange}
                          error={formik.errors.currentPassword}
                          touched={formik.errors.currentPassword}
                        />
                      </div>
                      <div className='col-4 mb-7 password-wrap'>
                        <FormikController
                          control='input'
                          {...formik.getFieldProps('newPassword')}
                          type='password'
                          name='newPassword'
                          label={getLabel("NEW_PASS")}
                          placeholder={`${getLabel("ENTER")} ${getLabel("NEW_PASS")}`}
                          labelClassName='required form-label fs-6 fw-bolder text-dark'
                          className={clsx('form-control bg-transparent', {
                            'is-invalid': formik.touched.newPassword && formik.errors.newPassword,
                          })}
                          autoComplete='on'
                          maxLength='30'
                          formik={formik}
                          value={formik.values.newPassword}
                          onChange={formik.handleChange}
                          error={formik.errors.newPassword}
                          touched={formik.errors.newPassword}
                        />
                      </div>
                      <div className='col-4 mb-7 password-wrap'>
                        <FormikController
                          control='input'
                          {...formik.getFieldProps('confirmPassword')}
                          type='password'
                          label={getLabel("CONFIRM_PASS")}
                          placeholder={`${getLabel("ENTER")} ${getLabel("CONFIRM_PASS")}`}
                          name='confirmPassword'
                          labelClassName='required form-label fs-6 fw-bolder text-dark'
                          className={clsx('form-control bg-transparent', {
                            'is-invalid':
                              formik.touched.confirmPassword && formik.errors.confirmPassword,
                          })}
                          autoComplete='on'
                          maxLength='30'
                          formik={formik}
                          value={formik.values.confirmPassword}
                          onChange={formik.handleChange}
                          error={formik.errors.confirmPassword}
                          touched={formik.errors.confirmPassword}
                        />
                      </div>
                    </div>
                  </div>
                  <div className='card-footer d-flex justify-content-center'>
                    <button
                      className='btn btn-light-primary font-weight-bold'
                      style={{ marginRight: '1rem' }}
                      type='button'
                      onClick={() => {
                        navigate(-1)
                      }}
                    >
                      <span className='indicator-label'>CANCEL</span>
                    </button>
                    <button
                      type='submit'
                      id='kt_password_reset_submit'
                      className='btn btn-lg btn-primary fw-bolder me-4'
                      disabled={formik.isSubmitting || !formik.isValid}
                    >
                      {loading ? (
                        <span className='indicator-progress' style={{ display: 'block' }}>
                          PLEASE_WAIT
                          <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                        </span>
                      ) : (
                        <span className='indicator-label'>SUBMIT</span>
                      )}
                    </button>
                  </div>
                </Form>
              )
            }}
          </Formik>
        </div>
      </div>
    </>
  )
}

export default ChangePassword
