import {ErrorMessage, Field} from "formik"
import {useState} from "react"

function Input(props) {
  const {
    type,
    name,
    label,
    labelChildren,
    labelChildrenSpace,
    labelClassName,
    children,
    value,
    formik,
    ...rest
  } = props
  const [showPass, setShowPass] = useState(false)

  return (
    <>
      {labelChildren ? (
        <span>
          {labelChildrenSpace === "before" && labelChildren}
          {label && (
            <label className={labelClassName} htmlFor={name}>
              {label}
            </label>
          )}
          {labelChildrenSpace === "after" && labelChildren}
        </span>
      ) : (
        label && (
          <label className={labelClassName} htmlFor={name}>
            {label}
          </label>
        )
      )}
      {children}
      <div style={{position: "relative"}}>
        <Field
          type={showPass ? "text" : type}
          name={name}
          value={value}
          onBlur={() => formik?.setFieldTouched(name, true)}
          {...rest}
        />
        {type === "password" && showPass ? (
          <span
            className="btn btn-sm btn-icon position-absolute translate-middle top-50 end-0 me-n2"
            onClick={() => setShowPass(false)}
          >
            {/* <i className="bi bi-eye-fill"></i> */}
            <i className="bi bi-eye fs-2"></i>
          </span>
        ) : type === "password" && !showPass ? (
          <span
            className="btn btn-sm btn-icon position-absolute translate-middle top-50 end-0 me-n2"
            onClick={() => setShowPass(true)}
          >
            <i className="bi bi-eye-slash fs-2"></i>
          </span>
        ) : null}
      </div>
      <ErrorMessage name={name} component="span" className="invalid-input" style={{color: "red"}} />
    </>
  )
}
export default Input
