/* eslint-disable array-callback-return */
import { useEffect, useRef, useState } from "react"
import { useNavigate } from "react-router-dom"
import { Modal } from "react-bootstrap"
import { CSVLink } from "react-csv"
import CloseSvg from "../svg-helper/CloseSvg"
import ExportSvg from "../svg-helper/ExportSvg"
import SearchSvg from "../svg-helper/SearchSvg"
import SelectAllCheckbox from "../../Shared-Component-formik/checkboxes/SelectAllCheckbox"
import AxiosMethod from "../../Api/AxiosMethod"
import AddSvg from "../svg-helper/AddSvg"
import { getLabel } from "../../language-provider/LanProvider"

const TableHeaderToolbar = (
	{
		params,
		setparams,
		showSearch,
		action,
		filterBody,
		showExportBtn,
		csvTableName,
		csvNameLabel,
		filename,
		exportData,
		exportType,
		showAddButton,
		showPrintBtn,
		addButton,
		path,
		inviteType,
	}) =>
{
	// DECLARE THE VARIABLES AND STATES AND PROPS AND NAVIGATION.
	const navigate = useNavigate()
	const download = useRef (null)
	const [csvLoading, setcsvLoading] = useState (false)
	const [csvData, setCsvData] = useState()
	const [search, setSearch] = useState (params?.search || '');

	// SET STATE STORE THE EXPORT FILE CHECKBOX FIELD DATA
	const [data, setData] = useState([]);

	// SET THE STATE TO STORE THE CSV FILE HEADER COLUMNS SELECTED BY USER.
	const [csvColumns, setCsvColumns] = useState([]);
	const [columnHeader, setColumnHeader] = useState([]);

	// STATE FOR MODAL BOX STORE THE SHOW AND HIDE STATE
	const [showModal, setShowModal] = useState(false);

	// CANCEL THE SEARCH FIELD SET .
    const cancelSearchField = () =>
    {
		// SET NULL VALUE IN FIELD AND PARAMS
        setSearch ('');
        setparams ({ ...params, search: '' });
        action ('', params?.limit, 1, params?.sortBy, params?.sort, params?.filterKey || '', params?.filterValue || '');
    };

    useEffect(() =>
    {
		// SET THE TIME-OUT.
        const timer = setTimeout(() =>
        {
            if (search)
			{
				action (search, params?.limit, 1, params?.sortBy, params?.sort, params?.filterKey || '', params?.filterValue || '');
            }
        }, 500);

		// CLEAR TIME-OUT.
		return () => clearTimeout(timer);
    }, [search]);

	// HANDLE CHANGE THE VALYE OF SEARCH-BOX.
	const handleChangeValue = (value) =>
	{
		// IF VALUE IS NULL OR EMPTY THEN CALL CANCEL SEARCH FIELD .
		if (!value)
		{
			cancelSearchField();
		}
		else
		{
			// SET THE SEARCH FIELD.
		    setSearch (value.replace (/^\+/, ''));
		}
	};

    // CLOSE THE MODAL BOX.
    const handleModalClose = () =>
    {
		setShowModal (false)
    }

	// SHOW THE MODAL BOX.
    const handleShowModal = () =>
    {
	    setShowModal (true);
    }

	// HANDLE PRINT BUTTON PRESS PRINT SCREEN.
	const handlePrintScreen = () =>
	{
		window.print();
	}

	// CHECK THE SELECTED FILE HEADER COLUMNS TO EXPORT.
    const makeCSVColumns = () =>
    {
	    let columns = [];
		let header = [];
	    data.map((e) =>
	    {
			if (e?.isChecked)
			{
				columns.push (e?.name);
				header.push ({
					label: e?.label,
					key: e?.name
				});
			}
            setCsvColumns (columns);
			setColumnHeader (header);
	    })
	}

	// HANDLE DOWNLOAD BUTTON GENERATE THE CSV FILE.
	const handleDownloadButton = async () =>
	{
		// SET THE LOADER.
		setcsvLoading (true);

		// GENERATE THE CSV FILE.
		let response = await generateCSVFile();
		if (response)
		{
			// TRIGGER THE DOWNLOAD PROGRAMMATICALLY
			if (download.current) {
				download.current.link.click();
			}
		}

		// STOP THE LOADER.
		setcsvLoading (false);
	}

	// HANDLE GENERATE THE CSV FILE FROM SERVER AND LOCAL EXPORT DATA.
	const generateCSVFile = async () =>
	{
		// CHECK THE EXPORT TYPE IS 'L' = FROM EXPORT DATA GET FILE.
		if (exportType === "L")
		{
			console.log (csvColumns);
			console.log (columnHeader);

			let fileData = [];
			exportData.length > 0 &&
			exportData.map ((row)=>
			{
				let list = {};
				csvColumns.map ((col) =>
				{
					list[col] = row[col] || "N/A";
				})
				fileData.push (list);
			})
			console.log("fileDatafileData", fileData);
			setCsvData (fileData);
			return true;
		}
		else // EXPORT TYPE IS 'S' = FROM SERVER GET FILE.
		{
			let obj =
			{
				model: csvTableName, // DATABASE TABLE NAME.
				columns: csvColumns, // TABLE COLUMNS.
			}
			let query =  `?search=${params.search}&filterKey=${String(params.filterKey) || "" }&filterValue=${String(params.filterValue) || ""}`;

			// GET DATA FROM SERVER.
			let response = await AxiosMethod (`exportData${query}`, `post`, obj);

			// CHECK THE RESPONSE STATUS IF SUCEESS THEN SET THE STATE.
			if (response.status === 200)
			{
				console.log(response.data )
				setCsvData (response.data);
				setcsvLoading (false);
				return true;
			}
		}
	}

	// MOUNT THE INITIALIZED THE FUNCTION.
	useEffect(() =>
	{
		makeCSVColumns();
	}, [data]);

	// RENDER THE CODE.
	return (
    <>
		<div className="card-header border-0 d-print-none" >

	        {/* SEARCH */}
	        <div className="card-title">
		        {
					showSearch && (
	                <div className="d-flex align-items-center position-relative my-1">
						<span className="svg-icon svg-icon-1 position-absolute ms-6">
							<SearchSvg />
						</span>
						{
							params?.search && (
							<span
							    className="svg-icon svg-icon-1 position-absolute end-0 me-2 cursor-pointer"
							    onClick={() => {cancelSearchField()}}
							>
								<CloseSvg />
							</span>
						)}
						<input
							className="form-control form-control-solid w-250px ps-14 pe-10"
							placeholder={getLabel("SEARCH")}
							maxLength={20}
							value={search}
							onChange={(e) => handleChangeValue(e.target.value)}
							onKeyPress={(e) =>
							{
								if (!/^[a-z\d\-_ ]+$/i.test(e.key))
								{
							        e.preventDefault()
							    }
							}}
						/>
	                </div>
	            )}
	        </div>

	        {/* EXPORT/ADD */}
	        <div className="card-toolbar">

				{/* FILTER */}
				{filterBody}

		        <div className="d-flex justify-content-end">

			        {/* EXPORT BUTTON */}
			        {
						showExportBtn && (
		                <button
			                type="button"
			                className="btn btn-light-primary me-3 d-flex"
			                data-kt-menu-trigger="click"
			                data-kt-menu-placement="bottom-end"
			                onClick={() => handleShowModal()}
		                >
			                {
								!csvLoading
								?
									<>
										<span className="svg-icon svg-icon-2">
									        <ExportSvg />
										</span>
										Export
									</>
			                    :
									<span className="indicator-progress" style={{ display: "block" }}>
									  Exporting...
									  <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
									</span>
			                }
                        </button>
                    )}

			        {/* PRINT SCREEN BUTTON */}
			        {
						showPrintBtn && (
					    <button
						    type="button"
						    className="btn btn-light-primary me-3 d-flex"
						    data-kt-menu-trigger="click"
						    data-kt-menu-placement="bottom-end"
						    onClick={() => handlePrintScreen()}
					    >
					      Print
					    </button>
					)}

		            {/* CSV EXPORT  */}
		            {
						csvData && (
						<CSVLink data={csvData} headers={columnHeader} filename={filename} ref={download}>
							<span ref={download}></span>
						</CSVLink>
		            )}

		            {/* ADD BUTTON */}
		            {
						showAddButton && (
						<button
							className="btn btn-primary"
							onClick={() =>
							{
								navigate (path, { state: { add: true, inviteType: inviteType, params: params } })
							}}
						>
		                    <AddSvg /> {addButton}
		                </button>
		            )}
				</div>
            </div>
        </div>

		{/* EXPORT COLUMN MODEL */}
		<Modal show={showModal} onHide={handleModalClose}>
			<div className="card card-custom">
                <div className="card-header">
		            <div className="card-title">
		              <h2>Export columns to CSV</h2>
		            </div>
				</div>
                <div className="card-body py-4">
		            <div className="d-flex row me-n7 pe-7">
		                <SelectAllCheckbox userData={csvNameLabel} data={data} setData={setData} />
		            </div>
                </div>
                <div className="card-footer d-flex justify-content-center">
		            <button
			              className="btn btn-light-primary font-weight-bold"
			              style={{ marginRight: "1rem" }}
			              type="button"
			              onClick={handleModalClose}
		            >
			            <span className="indicator-label">{getLabel("CANCEL")}</span>
		            </button>
		            <button type="button" className="btn btn-primary" onClick={handleDownloadButton}>
		                <span className="indicator-label">Download .CSV File</span>
		            </button>
                </div>
            </div>
      </Modal>
    </>
    )
}
export default TableHeaderToolbar
