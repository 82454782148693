import { ErrorMessage, Field } from "formik"

function TextArea(props) {
  const { type, name, label, labelClassName, value, formik, ...rest } = props

  return (
    <>
      {label && (
        <label htmlFor={name} className={labelClassName}>
          {label}
        </label>
      )}
      <div style={{ position: "relative" }}>
        <Field
          as="textarea"
          heigth="20" 
          name={name}
          value={value}
          onBlur={() => formik?.setFieldTouched(name, true)}
          {...rest}
        />
      </div>
      <ErrorMessage name={name} component="span" className="invalid-input" style={{ color: "red" }} />
    </>
  )
}

export default TextArea
