import { useEffect, useState } from "react"
import AxiosMethod from "../../../_metronic/Api/AxiosMethod"
import { getLabel } from "../../../_metronic/language-provider/LanProvider"
import FilterSvg from "./../../../_metronic/helpers/svg-helper/FilterSvg"
import {getTempOrg} from "../../../_metronic/helpers/reuseable-fun/FunReuse";

const CallDetailsFilter = ({ params, action }) =>
{
	
	const [usersList, setUsersList] = useState([]);
	const [organizationList, setOrganizationList] = useState([]);
	const [keyVal, setkeyVal] = useState({
		filterKeys: [params.filterKey],
		filterValues: [params.filterValue],
	})
	console.log ("paramsparamsparams", keyVal)
	
	// GET THE USERS LIST
	const getUsers = async (search, limit, page, sortBy, sort, filterKey, filterValue) =>
	{
		let tempArray = [];

		let getUsersRole4 = await AxiosMethod(
			`users?search=${search}&limit=${limit}&page=${page}&sortwith=${sortBy}&sortby=${sort}&role=4&filterKey=${String(filterKey) || ""
			}&filterValue=${String(filterValue) || ""}`,
			`get`
		);
		console.log ("userRole 4 ", getUsersRole4);
		if (getUsersRole4?.data?.data?.status === "success")
		{
			let userRows =  getUsersRole4?.data?.data?.rows;
			userRows.length && userRows?.map ((user) =>
			{
				tempArray.push ({key: user.user_id, label: user.full_name, value: user.user_id, user_id: user.user_id});
			})
		}
		
		let getUsersRole3 = await AxiosMethod(
			`users?search=${search}&limit=${limit}&page=${page}&sortwith=${sortBy}&sortby=${sort}&role=3&filterKey=${String(filterKey) || ""
			}&filterValue=${String(filterValue) || ""}`,
			`get`)
		
		let userRows =  getUsersRole3?.data?.data?.rows;
		
		console.log ("userRole 3 ",userRows);
		if (userRows)
		{
			userRows?.map ((user) =>
			{
				tempArray.push ({key: user.user_id, label: user.full_name, value: user.user_id, user_id: user.user_id});
			})
		}
		console.log ("tempArray", tempArray);
		if (tempArray.length === 0)
		{
			// RESET THE USER_ID
			//handleChangeFilter ("user_id", "");
		}
		setUsersList (tempArray);
	}
	
	// GET THE ALL ORGANIZATION LIST
	const getOrganizationList = async () =>
	{
		let listData  =  await getTempOrg();
		setOrganizationList (listData);
	}
	
	// CHECKING RESET THE FILTER AND SHOW RESET OPTION BUTTON
	const checkReset = () =>
	{
		if (
			JSON.stringify(keyVal.filterKeys) !== JSON.stringify(params.filterKey) ||
			JSON.stringify(keyVal.filterValues) !== JSON.stringify(params.filterValue)
		) {
			return true
		} else {
			return false
		}
	}

	// FUNCTION USING FOR RESETTING FILTER
	const reset = (flag) => {
		if (flag) {
			setkeyVal({
				filterKeys: [],
				filterValues: [],
			})
			action(params.search, params.limit, 1, params.sortBy, params.sort, [], [])
		} else {
			setkeyVal({
				filterKeys: params.filterKey,
				filterValues: params.filterValue,
			})
		}
	}

	// APPLY FILTER
	const applyFilter = () => {
		action(
			params.search,
			params.limit,
			1,
			params.sortBy,
			params.sort,
			keyVal.filterKeys,
			keyVal.filterValues
		)
	}

	// GET THE INACTIVE USER FILTER
	const getInactiveUsers = async (isDeleted) =>
	{
		setUsersList([]);

		getUsers (
			"",
			1000,
			usersList?.rows?.length <= 1 && params.page > 1 ? params.page - 1 : params.page,
			"full_name",
			1,
			(isDeleted ? [] : ["is_deleted"]),
			(isDeleted ? [] : [false])
		)
	}

	// MOUNT THE INITIALIZATION FUNCTIONS.
	// GET USER LIST
	useEffect(() =>
	{
		// if (usersList.length === 0)
		// {
		// 	getUsers(
		// 		params.search,
		// 		1000,
		// 		1,
		// 		"full_name",
		// 		1,
		// 		params.filterKey,// ["is_deleted"],
		// 		params.filterKey,//[false]
		// 	)
		// }
		if (keyVal.filterKeys.indexOf("organization_id") > -1)
		{
			const keyIndex = keyVal.filterKeys.indexOf ("organization_id");
			const updatedValues = [...keyVal.filterValues];
			
			getUsers (
				params.search,
				1000,
				1,
				"full_name",
				1,
				["is_deleted", "organization_id"],
				[false, updatedValues [keyIndex]]
			)
		}
	}, [])
	
	// MOUNT THE INITIALIZATION FUNCTIONS.
	useEffect(() =>
	{
		getOrganizationList();
	}, [])
	
	// MOUNT THE INITIALIZATION FUNCTIONS.
	// useEffect(() =>
	// {
	// 	keyVal.filterValues[0] && getUsers(keyVal.filterValues[0])
	// }, [keyVal.filterValues])
	
	// GET VALUE IN FILTER OBJECT BY INDEX.
	const getValueFilter = (name) =>
	{
		let value = ""; // HOLD THE VALUE OF FILTER KEY
		
		// GET INDEX NUMBER IN FILTER OBJECT
		let index = keyVal.filterKeys.indexOf (name);
		if (index > -1)
		{
			value = keyVal.filterValues[index];
		}
		return value;
	}
	
	// ON CHANGE VALUES OF FILTER OBJECT
	const handleChangeFilter = (key, value) =>
	{
		// CHECK THE IF SELECTED KEY IS ALREADY IN FILTERKEYS IN KEYVAL OBJECT.
		const keyIndex = keyVal.filterKeys.indexOf (key);
		if (keyIndex !== -1)
		{
			// IF THE KEY IS ALREADY IN FILTER-KEY, THEN UPDATE ITS CORRESPONDING VALUE.
			const updatedKeys = [...keyVal.filterKeys];
			const updatedValues = [...keyVal.filterValues];
			
			if (value)
			{
				updatedValues [keyIndex] = value;
				setkeyVal({
					...keyVal,
					filterValues: updatedValues,
				});
			} else
			{
				// IF VALUE IS NULL, REMOVE THE KEY AND VALUE FROM THE ARRAYS.
				updatedKeys.splice (keyIndex, 1);
				updatedValues.splice (keyIndex, 1);
				
				setkeyVal({
					...keyVal,
					filterKeys: updatedKeys,
					filterValues: updatedValues,
				});
				
				console.log ("value null",key,  value)
			}
		}
		else // IF THE KEY IS NOT IN FILER-KEYS IN KEYVAL, THEN ADD BOTH THE KEY AND VALUE IN KEYVAL OBJECT.
		{
			setkeyVal(
				{
					...keyVal,
					filterKeys: [...keyVal.filterKeys, key],
					filterValues: [...keyVal.filterValues, value],
				}
			);
		}
	}
	
	// RENDER THE COMPONENT.
	return (
		<>
			<button
				type="button"
				className="btn btn-light-primary me-3 d-flex"
				onClick={() => reset()}
				data-kt-menu-trigger="click"
				data-kt-menu-placement="bottom-end"
			>
				<span className="svg-icon svg-icon-2">
					<FilterSvg />
				</span>
				{getLabel("FILTER")}
				
				{
					(params.filterKey?.length > 0) &&
					<div className="badge badge-pill badge-danger"  style={{marginLeft:10}}>
						{ params.filterKey?.length}
					</div>
				}
			</button>

			<div
				className="menu menu-sub menu-sub-dropdown w-300px w-md-325px"
				data-kt-menu="true"
				style={{}}
			>
				<div className="px-7 py-5">
					<div className="fs-5 text-dark fw-bolder">{getLabel("FILTER_OPTIONS")}</div>
				</div>
				<div className="separator border-gray-200"></div>
				<div className="px-7 py-5" data-kt-user-table-filter="form">
					
					{/* ORGANIZATION FILTER */}
					{
						organizationList?.length > 0 && (
							<div className="mb-10">
								<label className="form-label fs-6 fw-bold">{getLabel("ORG")} :</label>
								<select
									className="form-select form-select-solid fw-bolder cursor-pointer"
									value={getValueFilter ("organization_id")}
									onChange={(e) =>
									{
										handleChangeFilter ("organization_id", e.target?.value);
										getUsers (
											params.search,
											1000,
											1,
											"full_name",
											1,
											["is_deleted", "organization_id"],
											[false, e.target?.value]
										)
									}}
								>
									<option value="">{getLabel("ALL")}</option>
									{
										organizationList.map((option, index) =>
										{
											return (
												<option key={index} value={option.value}>
													{option?.label}
												</option>
											)
										})
									}
								</select>
							</div>
						)
					}
					
					{/* USERS FILTER OPTION */}
						{
							keyVal.filterValues[0] && usersList?.length > 0 && (
								
								<div className="mb-10">
									<label className="form-label fs-6 fw-bold">{getLabel ("staff")} :</label>
									<select
										className="form-select form-select-solid fw-bolder cursor-pointer"
										value={getValueFilter ("user_id")}
										onChange={(e) =>
										{
											handleChangeFilter ("user_id", e.target?.value);
										}}
									>
										<option value="">{getLabel("ALL")}</option>
										{
											usersList.map ((option, index) =>
											{
												return (
													<option key={index} value={option.value}>
														{option.label}
													</option>
												)
											})
										}
									</select>
								</div>
							)
						}
					
					{/* INACTIVE USER OPTION*/}
					<div className="mb-10 form-check form-check-solid">
						<input
							className='form-check-input'
							type='checkbox' id='check-inactive'
							onChange={(e) =>
							{
								getInactiveUsers (e.target.checked) }
							}
						/>
						<label className='form-check-label ps-2' htmlFor='check-inactive'>
							{getLabel ("inactive")}
						</label>
					</div>

					{/* BUTTON SECTION HERE RESET AND APPLY BUTTON */}
					<div className="d-flex justify-content-end">
						{(checkReset() || (keyVal.filterKeys.length > 0 && keyVal.filterKeys[0] !== "")) && (
							<button
								type="button"
								className="btn btn-light btn-active-light-primary fw-bold me-2 px-6"
								data-kt-menu-dismiss="true"
								data-kt-user-table-filter="reset"
								onClick={() => reset(1)}
							>
								{getLabel("RESET")}
							</button>
						)}
						<button
							type="button"
							className="btn btn-primary fw-bold px-6"
							data-kt-menu-dismiss="true"
							data-kt-user-table-filter="filter"
							disabled={!checkReset()}
							onClick={() => applyFilter()}
						>
							{getLabel("APPLY")}
						</button>
					</div>
				</div>
			</div>
		</>
	)
}

export default CallDetailsFilter
