import {useEffect} from "react"
import {getLabel} from "../../language-provider/LanProvider";
import SortingHelper from "../../helpers/sorting-helper/SortingHelper";

const SelectAllCheckbox = ({userData, data, setData}) =>
{
  const handleChange = (e) =>
  {
        const {name, checked} = e.target
	    if (name === "allSelect")
		{
	      let tempUser = data.map((elem) =>
	      {
	            return {...elem, isChecked: checked}
	      })
	      setData(tempUser)
	    } else {
	      let tempUser = data.map((elem) =>
	        elem?.name === name ? {...elem, isChecked: checked} : elem
	      )
	      setData(tempUser)
	    }
  }
  useEffect(() => {
	  // Set the initial state when the component is loaded
	  setData(userData.map(item => ({ ...item, isChecked: true })));
    //setData(userData)
  }, [])
	return (
	<>
		<div className="col-12 mb-7">
			<div className="table-responsive">
				<table
					className="table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer"
					id="kt_table_users"
				>
					<thead>
					<tr className="text-start text-muted fw-bolder fs-7 text-uppercase gs-0">
						<th
							className="min-w-25px"
							tabIndex={0}
							aria-controls="kt_table_users"
							rowSpan={1}
							colSpan={1}
							aria-label="Select All"
							style={{cursor: "pointer"}}
						>
							<input
								type="checkbox"
								className="form-check-input"
								name="allSelect"
								checked={!data.some((elem) => elem?.isChecked !== true)}
								onChange={handleChange}
							/>
						</th>
						<th
							className="min-w-125px sorting"
							tabIndex={0}
							aria-controls="kt_table_users"
							rowSpan={1}
							colSpan={1}
							aria-label="Connection Date: activate to sort column ascending"
							style={{cursor: "pointer"}}
						>
							Column Name
						</th>
					</tr>
					</thead>
					<tbody>
					{
						data.map((elem, index) => (
						<tr key={index}>
							<td>
								<input
									type="checkbox"
									className="form-check-input"
									name={elem?.name}
									checked={elem?.isChecked || false}
									onChange={handleChange}
								/>
							</td>
							<td>
								<label className="form-check-label ms-2">{elem?.label}</label>
							</td>
						</tr>
					))}
					</tbody>
				</table>
			</div>
		</div>
    </>
  )
}

export default SelectAllCheckbox
