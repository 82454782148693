import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useLocation, useNavigate } from "react-router-dom"
import AxiosMethod from "../../../_metronic/Api/AxiosMethod"
import { getLabel } from "../../../_metronic/language-provider/LanProvider"
import { PageTitle } from "../../../_metronic/layout/core"
import { SetLoader } from "../../../_metronic/redux/actions/LoaderAction"
import { setConnectionsParams } from "../../../_metronic/redux/actions/QueryParamsAction"
import SortingHelper from "../../../_metronic/helpers/sorting-helper/SortingHelper"
import moment from "moment"
import { handleNull, userUtcOffset } from "../../../_metronic/helpers/reuseable-fun/FunReuse"

const ViewConnection = () => {
  const dispatch = useDispatch()
  const currentParams = useSelector((state) => state?.QueryParamsReducer?.staffParams)
  const [params, setparams] = useState(currentParams)
  const connectionsBreadcrumbs = [
    {
      title: `${getLabel("CONNECTIONS")} ${getLabel("LIST")}`,
      path: "/connections",
      isSeparator: false,
      isActive: false,
    },
    {
      title: "",
      path: "",
      isSeparator: true,
      isActive: false,
    },
  ]
  const navigate = useNavigate()
  const location = useLocation()
  const [connections, setConnections] = useState(null)
  const [notFound, setNotFound] = useState("")

  const getConnections = async () => {
    let getConnectionsRes = await AxiosMethod(
      `connections/details/connections/${location?.state?.editObj.connection_details_id}`,
      `get`
    )

    if (getConnectionsRes && getConnectionsRes.status === 200) {
      dispatch(SetLoader(false))
      setNotFound(false)
      setConnections(getConnectionsRes.data.data)
    }
    if (JSON.parse(getConnectionsRes.request.response).status === "error") {
      dispatch(SetLoader(false))
      setNotFound(JSON.parse(getConnectionsRes.request.response).message)
    }
  }

  useEffect(() => {
    getConnections();
  }, []);

  return (
    <>
      <PageTitle breadcrumbs={connectionsBreadcrumbs}>
        {`${getLabel("VIEW")} ${getLabel("CONNECTIONS")}`}
      </PageTitle>

      <div className="card py-2 py-sm-4">
        <div className="card-body">
          <div className="row">
            <div className="col-4 mb-5 mt-3">
              <label className="fs-5 fw-bolder form-label mb-2">
                <span>{getLabel("USER")}</span>
              </label>
              <div className="text-capitalize">
                {handleNull(location?.state?.editObj.user_name)}
              </div>
            </div>
            <div className="col-4 mb-5 mt-3">
              <label className="fs-5 fw-bolder form-label mb-2">
                <span>{getLabel("DATE")}</span>
              </label>
              <div className="text-capitalize">
                {location?.state?.editObj.date &&
                  location?.state?.editObj.date !== "Invalid date"
                  ? moment(location?.state?.editObj.date)
                    .utcOffset(userUtcOffset)
                    .format("DD/MM/yyyy")
                  : "N/A"}
              </div>
            </div>
            <div className="col-4 mb-5 mt-3">
              <label className="fs-5 fw-bolder form-label mb-2">
                <span>{getLabel("CONNECTION_LOGIN_TIME")}</span>
              </label>
              <div className="text-capitalize">
                {location?.state?.editObj.login_connection_date &&
                  location?.state?.editObj.login_connection_date !== "Invalid date"
                  ? moment(location?.state?.editObj.login_connection_date)
                    .utcOffset(userUtcOffset)
                    .format("DD/MM/yyyy HH:mm")
                  : "N/A"}
              </div>
            </div>
            <div className="col-4 mb-5 mt-3">
              <label className="fs-5 fw-bolder form-label mb-2">
                <span>{getLabel("CONNECTION_LOGOUT_TIME")}</span>
              </label>
              <div className="text-capitalize">
                {location?.state?.editObj.logout_connection_date &&
                  location?.state?.editObj.logout_connection_date !== "Invalid date"
                  ? moment(location?.state?.editObj.logout_connection_date)
                    .utcOffset(userUtcOffset)
                    .format("DD/MM/yyyy HH:mm")
                  : "N/A"}
              </div>
            </div>

            <div className="my-5">
              <div id="kt_table_users_wrapper" className="dataTables_wrapper dt-bootstrap4 no-footer">
                <div className="table-responsive">
                  <table
                    className="table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer"
                    id="kt_table_users"
                  >
                    {/*begin::Table head*/}
                    <thead>
                      {/*begin::Table row*/}
                      <tr className="text-start text-muted fw-bolder fs-7 text-uppercase gs-0">
                        <th
                          className="min-w-125px sorting"
                          tabIndex={0}
                          aria-controls="kt_table_users"
                          rowSpan={1}
                          colSpan={1}
                          aria-label="Connection Type: activate to sort column ascending"
                          style={{ width: "288.678px", cursor: "pointer" }}
                          onClick={() =>
                            getConnections(
                              params.search,
                              params.limit,
                              params.page,
                              "connection_type_id",
                              params.sort === 0 ? 1 : 0,
                              params?.filterKey || "",
                              params?.filterValue || ""
                            )
                          }
                        >
                          {getLabel("CONNECTION_TYPE")}
                          {SortingHelper(params.sort, params.sortBy, "connection_type_id")}
                        </th>
                        <th
                          className="min-w-125px sorting"
                          tabIndex={0}
                          aria-controls="kt_table_users"
                          rowSpan={1}
                          colSpan={1}
                          aria-label="Connection Time: activate to sort column ascending"
                          style={{ width: "288.678px", cursor: "pointer" }}
                          onClick={() =>
                            getConnections(
                              params.search,
                              params.limit,
                              params.page,
                              "date",
                              params.sort === 0 ? 1 : 0,
                              params?.filterKey || "",
                              params?.filterValue || ""
                            )
                          }
                        >
                          {getLabel("CONNECTION_DATE")}
                          {SortingHelper(params.sort, params.sortBy, "date")}
                        </th>
                        <th
                          className="min-w-125px sorting"
                          tabIndex={0}
                          aria-controls="kt_table_users"
                          rowSpan={1}
                          colSpan={1}
                          aria-label="Connection From: activate to sort column ascending"
                          style={{ width: "288.678px", cursor: "pointer" }}
                          onClick={() =>
                            getConnections(
                              params.search,
                              params.limit,
                              params.page,
                              "from",
                              params.sort === 0 ? 1 : 0,
                              params?.filterKey || "",
                              params?.filterValue || ""
                            )
                          }
                        >
                          {getLabel("CONNECTION_FROM")}
                          {SortingHelper(params.sort, params.sortBy, "from")}
                        </th>
                        <th
                          className="min-w-125px sorting"
                          tabIndex={0}
                          aria-controls="kt_table_users"
                          rowSpan={1}
                          colSpan={1}
                          aria-label="Operating system: activate to sort column ascending"
                          style={{ width: "288.678px", cursor: "pointer" }}
                          onClick={() =>
                            getConnections(
                              params.search,
                              params.limit,
                              params.page,
                              "device_os",
                              params.sort === 0 ? 1 : 0,
                              params?.filterKey || "",
                              params?.filterValue || ""
                            )
                          }
                        >
                          {getLabel("CONNECTION_DEVICE_OS")}
                          {SortingHelper(params.sort, params.sortBy, "device_os")}
                        </th>
                      </tr>
                      {/*end::Table row*/}
                    </thead>
                    <tbody>
                      {notFound ? (
                        <tr>
                          <td colSpan={8}>
                            <div className="d-flex text-center w-100 align-content-center justify-content-center text-uppercase">
                              {notFound}
                            </div>
                          </td>
                        </tr>
                      ) : (
                        connections?.rows &&
                        connections?.rows?.map((connection) => (
                          <tr key={connection.connection_id} className="odd text-left">
                            <td className="text-capitalize">{handleNull(connection["connection_type_description"])}</td>
                            <td>
                              {connection.date && connection.date !== "Invalid date"
                                ? moment(connection.date)
                                  .utcOffset(userUtcOffset)
                                  .format("DD/MM/yyyy HH:mm")
                                : "N/A"}
                            </td>
                            <td className="text-capitalize">{handleNull(connection["from"])}</td>
                            <td className="text-capitalize">{handleNull(connection["device_os"])}</td>
                          </tr>
                        ))
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div className="text-center my-5">
              <button
                type="reset"
                style={{ color: "black" }}
                className="btn btn-light me-3"
                onClick={() => navigate(-1)}
              >
                {getLabel("DISCARD")}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ViewConnection