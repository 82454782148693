import {ActionTypes} from "../constant/ActionType"

const initialState = {
  loading: false,
  plan: [],
  turnDetails: [],
	
}

// { type, payload } = action
const LoaderReducer = (state = initialState, {type, payload}) => {
  switch (type) {
    case ActionTypes.SET_LOADER:
      return {
        ...state,
        loading: payload,
      }
    case ActionTypes.SET_PLAN:
      return {
        ...state,
        plan: payload,
      }
    case ActionTypes.SET_TURN_SERVER:
      return {
        ...state,
        turnDetails: payload,
      }
    default:
      return state
  }
}

export default LoaderReducer
