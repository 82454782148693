import {ActionTypes} from "../constant/ActionType"

const initialState =
{
	email: "",
	token: "",
	user_id: "",
	userData: null,
	enabledRingtone: true, // DEFAULT ENABLE RINGTONE FOR INCOMING CALLS IF USER NOT INTERACTED WITH 15 SECOND THEN
	// UPDATE FLASE SHOW HEADER BUTTON "ENABLE RIGNTONE".
	screenIncrement: 0, // INCREMENT THE SCREEN NUMBER TO FORCE USEEFFECT IN APP.TSX TO NAVIGATE TO NEW SCREEN.
}

// { type, payload } = action
const AuthReducer = (state = initialState, {type, payload}) =>
{
    switch (type)
    {
	    case ActionTypes.AUTH:
			return (state =
			{
			    ...state,
			    email: payload.email,
			    token: payload.token,
			    user_id:  payload.user_id,
			    userData: payload.userData,
			})
	    
	    // UPDATE THE USER INTERACTION WITH THE DOCUMENT STATE.
	    case "UPDATE_USERINTERACTION_SHOWBUTTON":
		    return { ...state, enabledRingtone: payload.enabledRingtone };
	    
	    default:
	      return state
    }
}

export default AuthReducer
