/* eslint-disable react-hooks/exhaustive-deps */
import * as Yup from "yup"
import toast from "react-hot-toast"

import { Formik, Form } from "formik"
import { useLocation, useNavigate } from "react-router-dom"
import { useEffect, useState } from "react"
import { PageTitle } from "../../../_metronic/layout/core"
import AxiosMethod from "../../../_metronic/Api/AxiosMethod"
import FormikController from "../../../_metronic/Shared-Component-formik/FormikController"
import {
  generateEmail,
  getTempClient,
  getTempCountries,
  getTempState,
} from "../../../_metronic/helpers/reuseable-fun/FunReuse"
import { TakeEditedVal } from "../../../_metronic/helpers/only-edit-value/TakeEditedVal"
import { getLabel } from "../../../_metronic/language-provider/LanProvider"
import { ValSchemaList } from "../../../_metronic/Shared-Component-formik/schema/ValSchemaList"
import { useSelector } from "react-redux"

const CreateEditOrganization = () => {
  const [loading, setLoading] = useState(false)
  const usersBreadcrumbs = [
    {
      title: `${getLabel("ORG_LIST")}`,
      path: "/org/organization-management",
      isSeparator: false,
      isActive: false,
    },
    {
      title: "",
      path: "",
      isSeparator: true,
      isActive: false,
    },
  ]
  
  const loggedInUser = useSelector((state) => state?.AuthReducer?.userData)
  const [plan, setPlan] = useState([])

  const permission = plan[plan?.findIndex((obj) => obj?.plan_id === loggedInUser?.plan_id)]?.modules
  const getPlan = async () => {
    let getPlanRes = await AxiosMethod(`plans`, `get`)
    setPlan(getPlanRes?.data?.data?.rows)
  }
  useEffect(() => {
    getPlan()
  }, [])
  const navigate = useNavigate()
  const location = useLocation()
  const [stateList, setStateList] = useState([])
  const [countryList, setCountryList] = useState([])
  const [countryIdForState, setCountryIdForState] = useState(
    location?.state?.editObj?.country_id ? location?.state?.editObj?.country_id : null
  )
  const [profileImg, setProfileImg] = useState("")
  const [clientList, setClientList] = useState([])
	const [activeUser, setActiveUser] = useState(
	location?.state?.editObj && location?.state?.editObj?.status
	)
  const initialValues = {
    client_id: location?.state?.editObj ? location?.state?.editObj.client_id : "",
    tin_no: location?.state?.editObj ? location?.state?.editObj.tin_no : "",
    full_name: location?.state?.editObj ? location?.state?.editObj.full_name : "",
    email: generateEmail(),
    contact_no: location?.state?.editObj ? location?.state?.editObj.contact_no : "",
    address: location?.state?.editObj ? location?.state?.editObj.address : "",
    city: location?.state?.editObj ? location?.state?.editObj.city : "",
    state_id: location?.state?.editObj ? location?.state?.editObj.state_id : "",
    country_id: location?.state?.editObj ? location?.state?.editObj.country_id : "",
  }
  let validationSchema = Yup.object({
    ...(loggedInUser["role.role_id"] == 1 &&
      permission?.includes(5) && {
      client_id: ValSchemaList(0, "USER"),
    }),
    // tin_no: ValSchemaList(0, "TIN_NO"),
    full_name: ValSchemaList(0, "NAME"),
    // email: ValSchemaList(1, "EMAIL"),
    // contact_no: ValSchemaList(4, "CONTACT_NO"),
  })

  const getCountries = async () => {
    setCountryList(await getTempCountries())
  }
  const getClients = async () => {
    setClientList(await getTempClient())
  }
  const getState = async () => {
    setStateList(await getTempState(countryIdForState))
  }
  const onAddEditUser = async (values) => {
    setLoading(true)
    let temp = TakeEditedVal(values, initialValues)
    var bodyFormData = new FormData()
    for (var key in temp) {
      bodyFormData.append(key, temp[key])
    }
    !location?.state?.add &&
      values.state_id &&
      !temp.country_id &&
      bodyFormData.append("country_id", location?.state?.editObj?.country_id)
    location?.state?.add && bodyFormData.append("role_id", 2)
    location?.state?.add && bodyFormData.append("password", "Org@123")
	!location?.state?.add && bodyFormData.append("status", activeUser)
	!location?.state?.add && bodyFormData.append("login_status", activeUser)

    let createUsersRes = await AxiosMethod(
      location?.state?.add ? `users/create` : `users/${location?.state?.editObj.user_id}`,
      location?.state?.add ? `post` : `patch`,
      bodyFormData
    )
    if (createUsersRes && createUsersRes.status === 200) {
      setLoading(false)
      toast.success(
        location?.state?.add
          ? getLabel("ORG_CREATED")
          : getLabel("ORG_UPDATED")
      )
      navigate("/org/organization-management")
    }
    if (JSON.parse(createUsersRes.request.response).status === "error") {
      setLoading(false)
      toast.error(JSON.parse(createUsersRes.request.response).message)
    }
  }
  useEffect(() => {
    getCountries()
    getClients()
  }, [])
  useEffect(() => {
    getState()
  }, [countryIdForState])

  return (
    <>
      <PageTitle breadcrumbs={usersBreadcrumbs}>
        {`${getLabel(location?.state?.add ? "ADD" : "EDIT")} ${getLabel("ORG")}`}
      </PageTitle>

      <div className="card card-custom">
        <div className="card-header">
          <div className="card-title">
            <h3 className="card-label">
              {`${getLabel(location?.state?.add ? "ADD" : "EDIT")} ${getLabel("ORG")}`}
            </h3>
          </div>
        </div>

        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onAddEditUser}
        >
          {(formik) => {
            formik.values.country_id && setCountryIdForState(formik.values.country_id)
            return (
              <Form onSubmit={formik.handleSubmit} className="form">
                {/*begin::Scroll*/}
                <div className="card-body py-4">
                  <div
                    className="d-flex row me-n7 pe-7"
                    // className="d-flex row scroll-y me-n7 pe-7"
                    id="kt_modal_add_user_scroll"
                    data-kt-scroll="true"
                    data-kt-scroll-activate="{default: false, lg: true}"
                    // data-kt-scroll-max-height="auto"
                    data-kt-scroll-dependencies="#kt_modal_add_user_header"
                    data-kt-scroll-wrappers="#kt_modal_add_user_scroll"
                  // data-kt-scroll-offset="300px"
                  >
                    {/* client */}
                    {loggedInUser["role.role_id"] == 1 && permission?.includes(5) && (
                      <div className="col-6 mb-7">
                        <FormikController
                          control="react_select"
                          label={getLabel("USER")}
                          labelClassName="required fw-bold fs-6 mb-2"
                          name="client_id"
                          className="form-control-solid mb-lg-0"
                          formik={formik}
                          options={clientList}
                          value={formik.values.client_id}
                          onChange={formik.handleChange}
                          error={formik.errors.client_id}
                          isDisabled={!location?.state?.add}
                        />
                      </div>
                    )}

                    {/* Tin No. */}
                    <div className="col-6 mb-7">
                      <FormikController
                        control="input"
                        type="text"
                        label={getLabel("TIN_NO")}
                        placeholder={`${getLabel("ENTER")} ${getLabel("TIN_NO")}`}
                        labelClassName="fw-bold fs-6 mb-2"
                        name="tin_no"
                        className="form-control form-control-solid mb-lg-0"
                        maxLength="25"
                        formik={formik}
                        value={formik.values.tin_no}
                        onChange={formik.handleChange}
                        error={formik.errors.tin_no}
                      />
                    </div>
                    {/* Full Name */}
                    <div className="col-6 mb-7">
                      <FormikController
                        control="input"
                        type="text"
                        label={getLabel("NAME")}
                        placeholder={`${getLabel("ENTER")} ${getLabel("NAME")}`}
                        labelClassName="required fw-bold fs-6 mb-2"
                        name="full_name"
                        className="form-control form-control-solid mb-lg-0"
                        maxLength="25"
                        formik={formik}
                        value={formik.values.full_name}
                        onChange={formik.handleChange}
                        error={formik.errors.full_name}
                      />
                    </div>
                    {/* email */}
                    {/* <div className="col-6 mb-7">
                      <FormikController
                        control="input"
                        type="email"
                        disabled={!location?.state?.add}
                     style={location?.state?.edit && {color: "hsl(0, 0%, 60%)"}}
                        label={getLabel("EMAIL")}
                        placeholder={`${getLabel("ENTER")} ${getLabel("EMAIL")}`}
                        labelClassName="required fw-bold fs-6 mb-2"
                        name="email"
                        className="form-control form-control-solid mb-lg-0"
                        maxLength="50"
                        autoComplete="false"
                        formik={formik}
                        value={formik.values.email}
                        onChange={formik.handleChange}
                        error={formik.errors.email}
                      />
                    </div> */}
                    {/* Contact Number */}
                    <div className="col-6 mb-7">
                      <FormikController
                        control="input"
                        type="text"
                        label={getLabel("CONTACT_NO")}
                        placeholder={`${getLabel("ENTER")} ${getLabel("CONTACT_NO")}`}
                        labelClassName="fw-bold fs-6 mb-2"
                        name="contact_no"
                        className="form-control form-control-solid mb-lg-0"
                        onKeyPress={(event) => {
                          if (!/[0-9]/.test(event.key)) {
                            event.preventDefault()
                          }
                        }}
                        maxLength="20"
                        formik={formik}
                        value={formik.values.contact_no}
                        onChange={formik.handleChange}
                        error={formik.errors.contact_no}
                      />
                    </div>
                    {/* address */}
                    <div className="col-6 mb-7">
                      <FormikController
                        control="input"
                        type="text"
                        label={getLabel("ADDRESS")}
                        placeholder={`${getLabel("ENTER")} ${getLabel("ADDRESS")}`}
                        labelClassName="fw-bold fs-6 mb-2"
                        name="address"
                        className="form-control form-control-solid mb-lg-0"
                        maxLength="100"
                        formik={formik}
                        value={formik.values.address}
                        onChange={formik.handleChange}
                        error={formik.errors.address}
                      />
                    </div>
                    {/* city */}
                    <div className="col-6 mb-7">
                      <FormikController
                        control="input"
                        type="text"
                        label={getLabel("CITY")}
                        placeholder={`${getLabel("ENTER")} ${getLabel("CITY")}`}
                        labelClassName="fw-bold fs-6 mb-2"
                        name="city"
                        className="form-control form-control-solid mb-lg-0"
                        maxLength="25"
                        formik={formik}
                        value={formik.values.city}
                        onChange={formik.handleChange}
                        error={formik.errors.city}
                      />
                    </div>
                    {/* simple react country */}
                    <div className="col-6 mb-7">
                      <FormikController
                        control="select"
                        label={getLabel("COUNTRY")}
                        labelClassName="fw-bold fs-6 mb-2"
                        name="country_id"
                        className="form-select form-select-solid"
                        options={countryList}
                        value={formik.values.country_id}
                        onChange={(e) => {
                          formik.handleChange(e)
                          getState(e.target.value)
                        }}
                        error={formik.errors.country_id}
                      />
                    </div>
                    {/* simple react state_id */}
                    <div className="col-6 mb-7">
                      <FormikController
                        control="select"
                        label={getLabel("STATE")}
                        labelClassName="fw-bold fs-6 mb-2"
                        name="state_id"
                        className="form-select form-select-solid"
                        options={stateList}
                        value={formik.values.state_id}
                        onChange={formik.handleChange}
                        error={formik.errors.state_id}
                      />
                    </div>
                    {/* Organization */}
                    {/* <div className="col-6 mb-7">
                      <FormikController
                        control="input"
                        type="text"
                        label={getLabel("ORG")}
                        labelClassName="required fw-bold fs-6 mb-2"
                        name="Organization"
                        className="form-control form-control-solid mb-lg-0"
                        maxLength="30"
                        formik={formik}
                        value={formik.values.Organization}
                        onChange={formik.handleChange}
                        error={formik.errors.Organization}
                      />
                    </div> */}
                    {/*Profile ImageShared */}
                    <div className="col-6 mb-7">
                      <FormikController
                        control="image"
                        label={getLabel("LOGO")}
                        labelClassName="fw-bold fs-6 mb-2"
                        name="profile_pic"
                        // className="form-control form-control-solid mb-lg-0"
                        profileImg={profileImg}
                        setProfileImg={setProfileImg}
                        editFlag={location?.state?.edit}
                        editPath={location?.state?.editObj?.profile_pic}
                        formik={formik}
                        value={formik.values.profile_pic}
                        onChange={formik.handleChange}
                        error={formik.errors.profile_pic}
                      />
                    </div>
	                  
	                  {/* is default checkbox */}
	                  <div className="col-6 mb-7">
		                  <label className="fw-bold fs-6 mb-2" htmlFor="active">
			                  Active Organization
		                  </label>
		                  <label className="form-check form-switch form-check-custom form-check-solid">
			                  <input
				                  className="form-check-input"
				                  type="checkbox"
				                  name="active"
				                  style={{ width: "5rem" }}
				                  value={activeUser}
				                  checked={activeUser == 1 ? true : false}
				                  onChange={() => setActiveUser (activeUser == 1 ? 0 : 1)}
			                  />
		                  </label>
	                  </div>
                  </div>
                </div>
                {/*end::Scroll*/}
                {/*begin::Actions*/}
                <div className="card-footer d-flex justify-content-center">
                  <button
                    className="btn btn-light-primary font-weight-bold"
                    style={{ marginRight: "1rem" }}
                    type="button"
                    onClick={() => {
                      navigate("/org/organization-management")
                    }}
                  >
                    <span className="indicator-label">{getLabel("CANCEL")}</span>
                  </button>
                  <button
                    type="submit"
                    id="kt_password_reset_submit"
                    className="btn btn-lg btn-primary fw-bolder me-4"
                    disabled={formik.isSubmitting || !formik.isValid}
                  >
                    {loading ? (
                      <span className="indicator-progress" style={{ display: "block" }}>
                        {getLabel("PLEASE_WAIT")}
                        <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                      </span>
                    ) : (
                      <span className="indicator-label">{getLabel("SUBMIT")}</span>
                    )}
                  </button>
                </div>
                {/*end::Actions*/}
              </Form>
            )
          }}
        </Formik>
      </div>
    </>
  )
}

export default CreateEditOrganization
