/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect} from "react"
import {Outlet, Route, Routes} from "react-router-dom"
import {ForgotPassword} from "./components/ForgotPassword"
import {Login} from "./components/Login"
import {ResetPassword} from "./components/ResetPassword"
import {Navigate} from "react-router-dom"
import TwoStep from "./components/TwoStep"
import Menu from "./components/Menu"
import PaymentProcessing from "./components/PaymentProcessing"
import {Row, Col} from "react-bootstrap"

const AuthLayout = () => {
  useEffect(() => {
    document.body.classList.add("bg-white")
    return () => {
      document.body.classList.remove("bg-white")
    }
  }, [])
  return (
    <div
      className="d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed"
      // style={{
      //   backgroundImage: `url(${toAbsoluteUrl("/media/illustrations/sketchy-1/14.png")})`,
      // }}
    >
      <div className="d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20">
        {/* <a href="#" className="mb-12">
          <img alt="Logo" src={toAbsoluteUrl("/media/logos/logo-1.svg")} className="h-45px" />
        </a> */}
        {/* <a href="#">
          <img
            alt="Logo"
            src={toAbsoluteUrl("/media/logos/qr-logo-login.png")}
            style={{height: "25vh"}}
          /> */}
        {/* <span style={{marginLeft: "1rem", fontSize: "2rem"}}>QR-phone</span> */}
        {/* </a> */}
        {/* end::Logo */}
        {/* begin::Wrapper */}
        <div id="auth-width" className="w-lg-500px bg-white rounded shadow-sm p-10 p-lg-15 mx-auto">
          <Outlet />
        </div>
      </div>
      {/* end::Content */}
      {/* begin::Footer */}
      {/* <div className="d-flex flex-center flex-column-auto p-10">
        <div className="d-flex align-items-center fw-bold fs-6">
          <a href="#" className="text-muted text-hover-primary px-2">
            {getLabel("ABOUT")}
          </a>

          <a href="#" className="text-muted text-hover-primary px-2">
            {getLabel("CONTACT")}
          </a>

          <a href="#" className="text-muted text-hover-primary px-2">
            {getLabel("CONTACT_US")}
          </a>
        </div>
      </div> */}
      {/* end::Footer */}
    </div>
  )
}
const AuthPage = () => (
  <>
    <Menu />
    <div className="d-flex flex-column flex-column-fluid" style={{height: "100vh"}}>
      <Row id="row-login">
        <Col className="pt-5" md={12} lg={6}>
          <Routes>
            <Route element={<AuthLayout />}>
              <Route path="login" element={<Login />} />
              <Route path="forgot-password" element={<ForgotPassword />} />
              <Route path="two-step-verification" element={<TwoStep />} />
              <Route path="reset-password" element={<ResetPassword />} />
              <Route path="payment-success" element={<PaymentProcessing />} />

              <Route path="*" element={<Navigate to="/auth" />} />
              <Route index element={<Login />} />
            </Route>
          </Routes>
        </Col>
        <Col className="text-center mx-auto my-auto">
          <img id="image-auth" alt="Auth" src="/media/img/auth.png" />
        </Col>
      </Row>
    </div>
  </>
)
export {AuthPage}
