import {Formik, Form} from "formik"
import * as Yup from "yup"
import toast from "react-hot-toast"

import AxiosMethod from "../../../../_metronic/Api/AxiosMethod"
import {PageTitle} from "../../../../_metronic/layout/core"
import FormikController from "../../../../_metronic/Shared-Component-formik/FormikController"
import SchemaList from "../../../../_metronic/Shared-Component-formik/schema/SchemaList"
import {useLocation, useNavigate} from "react-router-dom"
import {getLabel} from "../../../../_metronic/language-provider/LanProvider"
import {useState} from "react"

const usersBreadcrumbs = [
  {
    title: "Language Page",
    path: "/language-management/view-language",
    isSeparator: false,
    isActive: false,
  },
  {
    title: "",
    path: "",
    isSeparator: true,
    isActive: false,
  },
]
const CreateEditLanguage = () => {
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)

  const location = useLocation()

  // initialValues for formik
  const initialValues = {
    Label: location?.state?.editLanguageObj ? location?.state?.editLanguageObj.label : "",
    English: location?.state?.editLanguageObj ? location?.state?.editLanguageObj.en : "",
    French: location?.state?.editLanguageObj ? location?.state?.editLanguageObj.fr : "",
    Spanish: location?.state?.editLanguageObj ? location?.state?.editLanguageObj.es : "",
  }
  // validationSchema for formik
  let validationSchema = Yup.object({
    Label: SchemaList[0],
    English: SchemaList[0],
    French: SchemaList[0],
    Spanish: SchemaList[0],
  })
  // Submit Language
  const onAddEditLan = async (lan) => {
    let obj = {
      field: lan.Label,
      values: [
        {
          language_id: 1,
          value: lan.English,
        },
        {
          language_id: 2,
          value: lan.French,
        },
        {
          language_id: 3,
          value: lan.Spanish,
        },
      ],
    }
    const addEditValRes = await AxiosMethod(
      `language`,
      location?.state?.add ? `post` : `patch`,
      obj
    )
    if (addEditValRes && addEditValRes.status === 200) {
      toast.success(addEditValRes.data.message)
      navigate("/language-management/view-language")
    }
    if (JSON.parse(addEditValRes.request.response).status === "error") {
      toast.error(JSON.parse(addEditValRes.request.response).message)
    }
  }

  return (
    <>
      <PageTitle breadcrumbs={usersBreadcrumbs}>
        {location?.state?.add ? `Add Language` : `Edit Language`}
      </PageTitle>
      <div className="card card-custom">
        <div className="card-header">
          <div className="card-title">
            <h3 className="card-label">{location?.state?.add ? `Add` : `Edit`} Language</h3>
          </div>
        </div>

        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onAddEditLan}
        >
          {(formik) => {
            return (
              <Form onSubmit={formik.handleSubmit} className="form">
                {/*begin::Scroll*/}
                <div className="card-body py-4" style={{height: "48vh"}}>
                  <div
                    className="d-flex row me-n7 pe-7"
                    // className="d-flex row scroll-y me-n7 pe-7"
                    id="kt_modal_add_user_scroll"
                    data-kt-scroll="true"
                    data-kt-scroll-activate="{default: false, lg: true}"
                    // data-kt-scroll-max-height="auto"
                    data-kt-scroll-dependencies="#kt_modal_add_user_header"
                    data-kt-scroll-wrappers="#kt_modal_add_user_scroll"
                    // data-kt-scroll-offset="300px"
                  >
                    <div className="col-6 mb-7">
                      <FormikController
                        control="input"
                        type="text"
                        name="Label"
                        label="Label"
                        labelClassName="required fw-bold fs-6 mb-2"
                        placeholder="Label"
                        className="form-control form-control-solid mb-lg-0"
                        formik={formik}
                        value={formik.values.Label}
                        onChange={formik.handleChange}
                        error={formik.errors.Label}
                        disabled={location?.state?.edit}
                      />
                    </div>
                    <div className="col-6 mb-7">
                      <FormikController
                        control="input"
                        type="text"
                        name="English"
                        label="English"
                        labelClassName="required fw-bold fs-6 mb-2"
                        placeholder="English"
                        className="form-control form-control-solid mb-lg-0"
                        formik={formik}
                        value={formik.values.English}
                        onChange={formik.handleChange}
                        error={formik.errors.English}
                      />
                    </div>
                    <div className="col-6 mb-7">
                      <FormikController
                        control="input"
                        type="text"
                        name="French"
                        label="French"
                        labelClassName="required fw-bold fs-6 mb-2"
                        placeholder="French"
                        className="form-control form-control-solid mb-lg-0"
                        formik={formik}
                        value={formik.values.French}
                        onChange={formik.handleChange}
                        error={formik.errors.French}
                      />
                    </div>
                    <div className="col-6 mb-7">
                      <FormikController
                        control="input"
                        type="text"
                        name="Spanish"
                        label="Spanish"
                        labelClassName="required fw-bold fs-6 mb-2"
                        placeholder="Spanish"
                        className="form-control form-control-solid mb-lg-0"
                        formik={formik}
                        value={formik.values.Spanish}
                        onChange={formik.handleChange}
                        error={formik.errors.Spanish}
                      />
                    </div>
                  </div>
                </div>
                {/*end::Scroll*/}
                {/*begin::Actions*/}
                <div className="card-footer d-flex justify-content-center">
                  <button
                    className="btn btn-light-primary font-weight-bold"
                    style={{marginRight: "1rem"}}
                    type="button"
                    onClick={() => {
                      navigate("/language-management/view-language")
                    }}
                  >
                    <span className="indicator-label">{getLabel("CANCEL")}</span>
                  </button>
                  <button
                    type="submit"
                    id="kt_password_reset_submit"
                    className="btn btn-lg btn-primary fw-bolder me-4"
                    disabled={formik.isSubmitting || !formik.isValid}
                  >
                    {loading ? (
                      <span className="indicator-progress" style={{display: "block"}}>
                        {getLabel("PLEASE_WAIT")}
                        <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                      </span>
                    ) : (
                      <span className="indicator-label">{getLabel("SUBMIT")}</span>
                    )}
                  </button>
                </div>
                {/*end::Actions*/}
              </Form>
            )
          }}
        </Formik>
      </div>
    </>
  )
}

export default CreateEditLanguage
