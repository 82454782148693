import {useEffect, useState} from "react"
import {useDispatch} from "react-redux"
import {Link, useLocation, useNavigate} from "react-router-dom"
import AxiosMethod from "../../../../_metronic/Api/AxiosMethod"
import {getTempModules} from "../../../../_metronic/helpers/reuseable-fun/FunReuse"
import {getLabel} from "../../../../_metronic/language-provider/LanProvider"
import {SetLoader} from "../../../../_metronic/redux/actions/LoaderAction"
import {toast} from "react-hot-toast"
import Menu from "./Menu"

const SelectPlan = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation()
  const [plans, setPlans] = useState([])
  const [moduleList, setModuleList] = useState([])

  const getModules = async () => {
    setModuleList(await getTempModules())
  }
	const getPlans = async () =>
	{
	    dispatch(SetLoader(true))
        const response = await AxiosMethod(`plans`, `get`)
	  
	    // GET ACTIVE PLANS ONLY
	    let planList = [];
		if (response?.data?.data?.rows?.length > 0)
		{
			response?.data?.data?.rows?.map ((row) =>
			{
				if (row?.activestatus === "A")
				{
					planList.push (row)
				}
			})
		}
	  
        setPlans (planList);
        dispatch(SetLoader(false))
    }
  useEffect(() => {
    getPlans()
    getModules()
  }, [])

  const funPayment = async (plan) => {
    if (location?.state?.userData) {
      // pay obj of uId and Pid
      let obj = {userId: location?.state?.userData.user_id, planId: plan.plan_id}
      localStorage.setItem("obj", JSON.stringify(obj))

      let makePaymetnRes = await AxiosMethod(`payments/makePayment`, `post`, plan)
      if (makePaymetnRes?.data?.status === "success") {
        window.open(makePaymetnRes.data.payUrl, "_self")
      }
      if (JSON.parse(makePaymetnRes.request.response).status === "error") {
        toast.error("Something went wrong, Please try again after some time.")
      }
    } else {
      navigate("/client-registration", {
        state: {editObj: plan},
      })
    }
  }
  return (
    <>
      <Menu />

      <div className="d-flex flex-column flex-column-fluid" style={{height: "100vh"}}>
        <div className="mb-10">
          <div className="card" id="kt_pricing">
            <div className="card-body p-lg-17">
              <div className="d-flex flex-column">
                <div
                  className="card card-custom"
                  style={{boxShadow: "0px 0px 30px 10px rgb(157 157 157)"}}
                >
                  <span className="text-center py-5 pt-10 fs-1 fw-bolder">
                    {getLabel("SELECT_PLAN")}
                  </span>

                  <div className="card-body py-4">
                    <div className="d-flex row me-n7 pe-7">
                      {plans?.map((plan) => {
                        return (
                          <div key={plan?.plan_id} className="col-xl-4 mt-7">
                            <div className="d-flex h-100 align-items-center">
                              <div className="w-100 d-flex flex-column flex-center rounded-3 bg-light bg-opacity-75 pt-15 px-10">
                                <div className="mb-7 text-center">
                                  <h1 className="text-dark mb-5 fw-boldest text-capitalize">
                                    {plan?.name}
                                  </h1>
                                  <div className="text-gray-400 fw-bold mb-5 text-capitalize">
                                    {plan?.tagline}
                                  </div>
                                  <div className="text-gray-400 fw-bold mb-5 text-capitalize">
                                    {getLabel("PLAN_TYPE")}:- {plan?.plan_type}
                                  </div>
                                  {plan?.static_qr_limit ? (
                                    <div className="text-gray-400 fw-bold mb-5 text-capitalize">
                                      {getLabel("STATIC_QR_LIMIT")}:- {plan?.static_qr_limit}
                                    </div>
                                  ) : null}
                                  {plan?.dynamic_qr_limit ? (
                                    <div className="text-gray-400 fw-bold mb-5 text-capitalize">
                                      {getLabel("DYNAMIC_QR_LIMIT")}:- {plan?.dynamic_qr_limit}
                                    </div>
                                  ) : null}

                                  <div className="text-center">
                                    <span
                                      className="fs-3x fw-bolder text-primary"
                                      data-kt-plan-price-month={39}
                                      data-kt-plan-price-annual={399}
                                    >
                                      {plan.price}
                                    </span>
                                    <span className="mb-2 text-primary"> € </span>
                                    <span className="fs-7 fw-bold opacity-50">
                                      /
                                      <span data-kt-element="period">
                                        {plan.duration} {getLabel("DAYS")}
                                      </span>
                                    </span>
                                  </div>
                                </div>
                                <div className="w-100 mb-10">
                                  {moduleList?.map((module) => {
                                    let temp = false
                                    return (
                                      <div
                                        key={module?.module_id}
                                        className="d-flex align-items-center mb-5"
                                      >
                                        <span className="fw-bold fs-6 text-gray-800 flex-grow-1 pe-3">
                                          {module?.module_name}
                                        </span>
                                        {plan?.modules?.map((el) => {
                                          if (module?.module_id === el) {
                                            temp = true
                                            return (
                                              <span
                                                key={el}
                                                className="svg-icon svg-icon-1 svg-icon-success"
                                              >
                                                <svg
                                                  xmlns="http://www.w3.org/2000/svg"
                                                  width={24}
                                                  height={24}
                                                  viewBox="0 0 24 24"
                                                  fill="none"
                                                >
                                                  <rect
                                                    opacity="0.3"
                                                    x={2}
                                                    y={2}
                                                    width={20}
                                                    height={20}
                                                    rx={10}
                                                    fill="currentColor"
                                                  />
                                                  <path
                                                    d="M10.4343 12.4343L8.75 10.75C8.33579 10.3358 7.66421 10.3358 7.25 10.75C6.83579 11.1642 6.83579 11.8358 7.25 12.25L10.2929 15.2929C10.6834 15.6834 11.3166 15.6834 11.7071 15.2929L17.25 9.75C17.6642 9.33579 17.6642 8.66421 17.25 8.25C16.8358 7.83579 16.1642 7.83579 15.75 8.25L11.5657 12.4343C11.2533 12.7467 10.7467 12.7467 10.4343 12.4343Z"
                                                    fill="currentColor"
                                                  />
                                                </svg>
                                              </span>
                                            )
                                          }
                                        })}
                                        {!temp && (
                                          <span className="svg-icon svg-icon-1">
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              width={24}
                                              height={24}
                                              viewBox="0 0 24 24"
                                              fill="none"
                                            >
                                              <rect
                                                opacity="0.3"
                                                x={2}
                                                y={2}
                                                width={20}
                                                height={20}
                                                rx={10}
                                                fill="currentColor"
                                              />
                                              <rect
                                                x={7}
                                                y="15.3137"
                                                width={12}
                                                height={2}
                                                rx={1}
                                                transform="rotate(-45 7 15.3137)"
                                                fill="currentColor"
                                              />
                                              <rect
                                                x="8.41422"
                                                y={7}
                                                width={12}
                                                height={2}
                                                rx={1}
                                                transform="rotate(45 8.41422 7)"
                                                fill="currentColor"
                                              />
                                            </svg>
                                          </span>
                                        )}
                                      </div>
                                    )
                                  })}
                                </div>
                                <div className="card-footer d-flex justify-content-center">
                                  <Link to="/auth">
                                    <button
                                      type="button"
                                      id="kt_login_password_reset_form_cancel_button"
                                      className="btn btn-lg btn-light-primary me-4"
                                      disabled={plan.plan_id === 1 || plan.plan_id === 2}
                                    >
                                      {getLabel("CANCEL")}
                                    </button>
                                  </Link>
                                  <button
                                    type="button"
                                    className="btn btn-lg btn-primary me-4"
                                    style={{marginRight: "1rem"}}
                                    // onClick={() =>
                                    //   navigate("/client-registration", {
                                    //     state: {editObj: plan},
                                    //   })
                                    // }
                                    onClick={() => funPayment(plan)}
                                    // disabled={plan.plan_id === 1 || plan.plan_id === 2}
                                  >
                                    <span className="indicator-label">
                                      {getLabel("SELECT_PLAN")}
                                    </span>
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        )
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default SelectPlan
