import React, {useEffect, useState} from 'react';
import {useSelector} from "react-redux"
import {generateDeviceId} from "../services/helper.service"
import {database} from "../services/firebase.service"
import {onValue, ref} from "firebase/database"
import {logOut} from "../../_metronic/helpers/reuseable-fun/FunReuse"

// FUNCTION TO CHECK IF THE USER IS LOGGED IN ON THIS DEVICE.
const LoggedInDevice = () =>
{
	const user_id = useSelector((state: any) => state?.AuthReducer?.user_id)
	const [deviceId, setDeviceId] = useState("");

	// EFFECT TO GET THE DEVICE ID
	useEffect (() =>
	{
		const getDeviceId =async () =>
		{
			setDeviceId (await generateDeviceId());
		}
		getDeviceId();
	}, []);

	// EFFECT TO CHECK IF THE USER IS LOGGED IN ON THIS DEVICE
	useEffect(() =>
	{
		// CHECK IF THE USER IS LOGGED IN ON THIS DEVICE
		if (deviceId && user_id)
		{
			console.log ("deviceIddeviceId", deviceId)
			const loggedinDeviceRef = ref ( database, `loggedindevice/${user_id}/${deviceId}`);

			onValue (loggedinDeviceRef , async (snapshot: any) =>
			{
				if (!snapshot.exists ())
				{
					// IF THE CURRENT DEVICE IS NOT LOGGED IN, NAVIGATE TO LOGIN PAGE
					await logOut();
				}
				else
				{
					let data =  snapshot.val();
					console.log ("data.isLoggedIn:", data.isLoggedIn);
					if (!data.isLoggedIn)
					{
						// IF THE CURRENT DEVICE IS NOT LOGGED IN, NAVIGATE TO LOGIN PAGE
						await logOut();
					}
				}
			});

			// Clean up the listener when component unmounts
			// return () => onValue (loggedinDeviceRef, null);
		}
	}, [deviceId, user_id]); // DEPENDENCY ON DEVICE ID.
	return (<></>);
};
export default LoggedInDevice;
