const Micon = () => {
  return (
    <svg width={27} height={27} viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.9 2C10.6909 2 8.90002 3.79086 8.90002 6V13C8.90002 15.2091 10.6909 17 12.9 17C15.1092 17 16.9 15.2091 16.9 13V6C16.9 3.79086 15.1092 2 12.9 2ZM8.76323 19.6568C6.43217 18.4223 4.99997 16.0368 4.99997 13.0019V10.8099C4.99997 10.3563 5.35631 10 5.80983 10C6.26335 10 6.63049 10.3563 6.63049 10.8099V12.9371C6.63049 16.6192 9.02767 19.0596 12.7206 19.0596C13.822 19.0596 14.8047 18.8329 15.6253 18.4333L15.6391 18.4453C17.626 17.5007 18.8109 15.5258 18.8109 12.9371V10.8099C18.8109 10.3563 19.178 10 19.6315 10C20.085 10 20.4414 10.3563 20.4414 10.8099V13.0019C20.4414 17.1891 17.7152 20.1401 13.6635 20.5377C13.6232 20.5424 13.5825 20.5465 13.5413 20.5498V23.0657H17.5474C18.0009 23.0657 18.3572 23.4329 18.3572 23.8864C18.3572 24.3399 18.0009 24.6962 17.5474 24.6962H7.89387C7.44035 24.6962 7.08401 24.3399 7.08401 23.8864C7.08401 23.4329 7.44035 23.0657 7.89387 23.0657L11.9001 23.0657V20.5498C10.7349 20.4561 9.66999 20.152 8.76323 19.6568Z"
        fill="white"
      />
    </svg>
  )
}

export default Micon
