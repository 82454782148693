import {useNavigate} from "react-router-dom"

/* eslint-disable jsx-a11y/anchor-is-valid */
const ViewSvg = ({path, editObj}) => {
  const navigate = useNavigate()

  return (
    <a
      className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1"
      onClick={() => {
        navigate(path, {
          state: {editObj: editObj},
        })
      }}
    >
      {/*begin::Svg Icon | path: icons/duotune/art/art005.svg*/}
      <span className="svg-icon svg-icon-3">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-6 w-6"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          strokeWidth="2"
        >
          <path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"
          />
        </svg>
      </span>
      {/*end::Svg Icon*/}
    </a>
  )
}

export default ViewSvg
