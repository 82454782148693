import moment from "moment"
import {useRef, useState} from "react"
import {useLocation, useNavigate} from "react-router-dom"
import {toQrImgUrl} from "../../../_metronic/helpers"
import {handleNull, userUtcOffset} from "../../../_metronic/helpers/reuseable-fun/FunReuse"
import {getLabel} from "../../../_metronic/language-provider/LanProvider"
import {PageTitle} from "../../../_metronic/layout/core"
import ModalImage from "./ModalImage"
import toast from "react-hot-toast";
import {URLS} from "../../../_metronic/Api/UrlList";

const ViewQrCode = () =>
{
	const usersBreadcrumbs =
	[
		{
			title: `${getLabel("QR_CODE")} ${getLabel("LIST")}`,
			path: "/qr-code-management",
			isSeparator: false,
			isActive: false,
		},
		{
			title: "",
			path: "",
			isSeparator: true,
			isActive: false,
		},
	]
    const navigate = useNavigate()
    const location = useLocation()
    const [modalOpen, setModalOpen] = useState(false)
    const [selectedImage, setSelectedImage] = useState(null)

	// REF TO HOLD A REFERENCE TO THE IMAGE ELEMENT
	const imageRef = useRef(null);
	
	const openModal = (image) =>
	{
	  setSelectedImage (image);
	  setModalOpen (true);
	};

	const closeModal = () =>
	{
		setSelectedImage (null)
		setModalOpen (false)
	}
	
	// FUNCTION TO HANDLE THE DOWNLOAD BUTTON CLICK.
	const handleDownloadClick = (imageUrl) =>
	{
		// CREATE A LINK ELEMENT.
		const link = document.createElement('a');
	
		// SET THE HREF ATTRIBUTE TO THE DATA URL OF THE IMAGE.
		link.href = toQrImgUrl (imageUrl);
	
		// SET THE DOWNLOAD ATTRIBUTE WITH A DESIRED FILE NAME.
		link.download = imageUrl;
	
		// APPEND THE LINK TO THE DOCUMENT.
		document.body.appendChild(link);

		// TRIGGER A CLICK ON THE LINK TO START THE DOWNLOAD.
		link.click();

		// REMOVE THE LINK FROM THE DOCUMENT.
		document.body.removeChild(link);
	};
	
	// FUNCTION TO HANDLE THE COPY LINK BUTTON CLICK.
	const handleCopyLinkClick = (text) =>
	{
		// CREATE A TEMPORARY TEXTAREA ELEMENT.
		const textarea = document.createElement('textarea');
		
		// SET THE VALUE OF THE TEXTAREA TO THE TEXT.
		textarea.value = text;
		
		// APPEND THE TEXTAREA TO THE DOCUMENT.
		document.body.appendChild(textarea);
		
		// SELECT THE CONTENT OF THE TEXTAREA.
		textarea.select();
		
		// EXECUTE THE COPY COMMAND.
		document.execCommand('copy');
		
		// REMOVE THE TEXTAREA FROM THE DOCUMENT.
		document.body.removeChild(textarea);
		
		// SHOW A NOTIFICATION.
		toast.success ( getLabel ("COPY_SUCCESS"))
	};
	
    // RENDER THE PAGE.
    return (
    <>
		<PageTitle breadcrumbs={usersBreadcrumbs}>
			{`${getLabel("VIEW")} ${getLabel("QR_CODE")}`}
		</PageTitle>

      <div className="card py-2 py-sm-4">
        <div className="card-body">
          <div className="row">
            <div className="col-3">
              <div className="me-7 mb-4 d-flex align-items-center justify-content-center h-100">
                <div className="symbol symbol-100px symbol-lg-160px symbol-fixed position-relative">
                  {location?.state?.editObj?.qr_img && (
                    <img
                      src={toQrImgUrl (location?.state?.editObj?.qr_img)}
                      alt=""
	                  ref={imageRef}
                      className="w-100"
                      onClick={() => openModal (toQrImgUrl (location?.state?.editObj?.qr_img))}
                    />
                  )}
                </div>
	             
              </div>
	            
	            <div className="d-flex justify-content-center symbol-fixed position-relative">
		            <button
			            type="button"
			            style={{color: "black"}}
			            className="btn btn-light me-3"
			            onClick={() => handleDownloadClick (location?.state?.editObj?.qr_img)}
		            >
			            {getLabel("DOWNLOAD")}
		            </button>
		            <button
			            type="button"
			            style={{color: "black"}}
			            className="btn btn-light me-3"
			            onClick={() => handleCopyLinkClick (`${URLS.BASE_URL}/qrcodes/scan/${location?.state?.editObj["calllink_id"]}`)}
		            >
			            {getLabel("COPY_LINK")}
		            </button>
	            </div>
	         
            </div>

            <div className="col-12 col-sm-9">
              <div className="row">
                <div className="col-6 mb-5 mt-3">
                  <label className="fs-5 fw-bolder form-label mb-2">
                    <span> {getLabel("NAME")}</span>
                  </label>
                  <div className="text-capitalize">
                    {handleNull(location?.state?.editObj?.qr_name)}
                  </div>
                </div>
                <div className="col-6 mb-5 mt-3">
                  <label className="fs-5 fw-bolder form-label mb-2">
                    <span> {getLabel("ORG")}</span>
                  </label>
                  <div className="text-capitalize">
                    {handleNull(location?.state?.editObj["user.full_name"])}
                  </div>
                </div>
                <div className="col-6 mb-5 mt-3">
                  <label className="fs-5 fw-bolder form-label mb-2">
                    <span>{getLabel("DEFAULT") + " " + getLabel("STAFF")}</span>
                  </label>
                  <div className="text-capitalize">
                    {handleNull(location?.state?.editObj.default_staff_name)}
                  </div>
                </div>
                <div className="col-6 mb-5 mt-3">
                  <label className="fs-5 fw-bolder form-label mb-2">
                    <span>{getLabel("SCHEDULE")}</span>
                  </label>
                  <div className="text-capitalize">
                    {location?.state?.editObj?.deptArr?.length > 0
                      ? location?.state?.editObj.deptArr.map((e) => (
                          <span style={{display: "block", whiteSpace: "nowrap"}}>{e}</span>
                        ))
                      : "N/A"}
                  </div>
                </div>
                {/* <div className="col-6 mb-5 mt-3">
                  <label className="fs-5 fw-bolder form-label mb-2">
                    <span>Staff</span>
                  </label>
                  <div className="text-capitalize">
                    {location?.state?.editObj?.staffArr?.length > 0
                      ? location?.state?.editObj.staffArr.map((e) => (
                          <span style={{display: "block", whiteSpace: "nowrap"}}>{e}</span>
                        ))
                      : "N/A"}
                  </div>
                </div> */}
                <div className="col-6 mb-5 mt-3">
                  <label className="fs-5 fw-bolder form-label mb-2">
                    <span>{getLabel("TYPE")}</span>
                  </label>
                  <div className="text-capitalize">
                    {handleNull(location?.state?.editObj?.type)}
                  </div>
                </div>

                <div className="col-6 mb-5 mt-3">
                  <label className="fs-5 fw-bolder form-label mb-2">
                    <span className="text-capitalize">{getLabel("VALID_FROM")}</span>
                  </label>
                  <div>
                    {location?.state?.editObj?.qr_start_time &&
                    location?.state?.editObj?.qr_start_time != "Invalid date"
                      ? moment(location?.state?.editObj?.qr_start_time)
                          .utcOffset(userUtcOffset)
                          .format("DD/MM/yyyy HH:mm")
                      : "Never"}
                  </div>
                </div>
                <div className="col-6 mb-5 mt-3">
                  <label className="fs-5 fw-bolder form-label mb-2">
                    <span className="text-capitalize">{getLabel("EXP_TIME")}</span>
                  </label>
                  <div>
                    {
						location?.state?.editObj?.qr_end_time &&
	                    location?.state?.editObj?.qr_end_time != "Invalid date"
	                      ? moment(location?.state?.editObj?.qr_end_time)
	                          .utcOffset(userUtcOffset)
	                          .format("DD/MM/yyyy HH:mm")
	                      : "Never"
					}
                  </div>
                </div>
                <div className="col-6 mb-5 mt-3">
                  <label className="fs-5 fw-bolder form-label mb-2">
                    <span>{getLabel("STATUS")}</span>
                  </label>
                  <div className="text-capitalize">
                    {handleNull (location?.state?.editObj?.status)}
                  </div>
                </div>
	              
	              <div className="col-6 mb-5 mt-3">
		              <label className="fs-5 fw-bolder form-label mb-2">
			              <span>{"Door Id"}</span>
		              </label>
		              <div className="text-capitalize">
			              {handleNull(location?.state?.editObj?.door_id)}
		              </div>
	              </div>
              </div>
            </div>
          </div>
        </div>
        <div className="text-center mb-5">
          <button
            type="reset"
            style={{color: "black"}}
            className="btn btn-light me-3"
            onClick={() => navigate(-1)}
          >
            {getLabel("DISCARD")}
          </button>
        </div>
      </div>

      {selectedImage !== null ? (
        <ModalImage isOpen={modalOpen} onClose={closeModal} imageUrl={selectedImage} />
      ) : (
        ""
      )}
    </>
  )
}

export default ViewQrCode
