import toast, {Toaster, ToastBar} from "react-hot-toast"

const ToasterHelper = () => {
  return (
    <Toaster
      position="top-right"
      reverseOrder={false}
      gutter={8}
      containerClassName=""
      containerStyle={{}}
      toastOptions={{
        // Define default options
        className: "",
        duration: 5000,
        style: {
          cursor: "pointer",
          background: "#363636",
          color: "#fff",
        },

        // Default options for specific types
        success: {
          duration: 5000,
          theme: {
            cursor: "pointer",
            primary: "green",
            secondary: "black",
          },
        },
      }}
    >
      {(t) => (
        <ToastBar toast={t}>
          {({icon, message}) => (
            <div className="d-flex" onClick={() => toast.dismiss(t.id)}>
              {icon}
              {message}
            </div>
          )}
        </ToastBar>
      )}
    </Toaster>
  )
}

export default ToasterHelper
