/* eslint-disable react-hooks/exhaustive-deps */
import clsx from "clsx"
import React, {useEffect} from "react"
import { KTSVG, toAbsoluteUrl } from "../../../helpers"
import { useLayout } from "../../core"
import { DefaultTitle } from "./page-title/DefaultTitle"
import { Topbar } from "./Topbar"
import {useDispatch, useSelector} from "react-redux";

export function HeaderWrapper()
{
    const { config, classes, attributes, isShowNotificatonPermission } = useLayout()
    const { header, aside } = config;
	const dispatch = useDispatch();
	const enabledRingtone =  useSelector ((state) => state?.AuthReducer?.enabledRingtone);
	const handleEnableRingtone = () =>
	{
		dispatch ({ type: "UPDATE_USERINTERACTION_SHOWBUTTON", payload: { enabledRingtone: true } });
		console.log("Enable Ringtone")
	}

	useEffect (() =>
	{
	console.log("HEADER enabledRingtone", enabledRingtone)
	}, [enabledRingtone]);
	
  return (
    <div
      id="kt_header"
      className={clsx("header", classes.header.join(" "), "align-items-stretch")}
	    style={{top:isShowNotificatonPermission? '47px' : '0px'}}
      {...attributes.headerMenu}
    >
      <div
        className={clsx(
          classes.headerContainer.join(" "),
          "d-flex align-items-stretch justify-content-between"
        )}
      >
        {/* begin::Aside mobile toggle */}
        {aside.display && (
          <div className="d-flex align-items-center d-lg-none ms-n3 me-1" title="Show aside menu">
            <div
              className="btn btn-icon btn-active-light-primary w-30px h-30px w-md-40px h-md-40px"
              id="kt_aside_mobile_toggle"
            >
              <KTSVG path="/media/icons/duotune/abstract/abs015.svg" className="svg-icon-2x mt-1" />
            </div>
	          
	          <div className="d-flex align-items-center" id="kt_header_nav2">
		          <DefaultTitle isMobileTitle={true}/>
	          </div>
          </div>
        )}
        {/* end::Aside mobile toggle */}
        {/* begin::Logo */}
        {/* {!aside.display && (
          <div className="d-flex align-items-center flex-grow-1 flex-lg-grow-0">
            <Link to="/dashboard" className="d-lg-none">
              <img alt="Logo" src={toAbsoluteUrl("/media/logos/logo-2.svg")} className="h-30px" />
            </Link>
          </div>
        )} */}
        {/* end::Logo */}

        {/* {aside.display && (
          <div className="d-flex align-items-center flex-grow-1 flex-lg-grow-0">
            <Link to="/" className="d-lg-none">
              <img alt="Logo" src={toAbsoluteUrl("/media/logos/logo-2.svg")} className="h-30px" />
            </Link>
          </div>
        )} */}

        {/* begin::Wrapper */}
        <div className="d-flex align-items-stretch justify-content-between flex-lg-grow-1">
			{
			  header.left === "page-title" && (
			    <div className="d-flex align-items-center" id="kt_header_nav">
			        <DefaultTitle />
			    </div>
			)}
			{
				header.left === "menu" && (
				<div className="d-flex align-items-stretch" id="kt_header_nav">
					{/* <Header /> */}
				</div>
	        )}
          <div className="d-flex align-items-stretch flex-shrink-0">
	          {
				  !enabledRingtone && (
				  <div className={"d-flex justify-content-center align-items-center"} style={{marginRight:'15px'}}>
			          <button className={clsx("btn btn-sm btn-active-light-primary btn-dark", classes.toggleButton)} id="kt_header_menu_mobile_toggle">
				          Enable Ringtone
			          </button>
		          </div>
		          )
	          }
	          <Topbar />
          </div>
        </div>
        {/* end::Wrapper */}
      </div>
    </div>
  )
}
