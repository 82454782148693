const Micoff = () => {
  return (
    <svg width={27} height={27} viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.0137 2C10.8973 2 9.46115 3.54413 9.40716 5.79014V6.22207L16.5879 13.392C16.6095 13.3164 16.6203 13.2192 16.6203 13.1329V5.90892C16.6203 3.59812 15.1734 2 13.0137 2ZM20.9504 21.9225C21.2743 22.2465 21.8034 22.2465 22.1273 21.9225C22.4405 21.5878 22.4513 21.0695 22.1273 20.7455L5.42265 4.05164C5.09871 3.7385 4.5588 3.7277 4.23486 4.05164C3.92171 4.37559 3.92171 4.91549 4.23486 5.22864L20.9504 21.9225ZM5.29308 13.3056C5.29308 17.5385 8.07899 20.508 12.2039 20.8535V23.3695H8.18697C7.73345 23.3695 7.37711 23.7366 7.37711 24.1901C7.37711 24.6437 7.73345 25 8.18697 25H17.8405C18.294 25 18.6504 24.6437 18.6504 24.1901C18.6504 23.7366 18.294 23.3695 17.8405 23.3695H13.8344V20.8535C15.0438 20.7563 16.1452 20.4324 17.0738 19.9033L15.9184 18.7371C15.0978 19.1366 14.1151 19.3634 13.0137 19.3634C9.32077 19.3634 6.92359 16.923 6.92359 13.2408V11.1136C6.92359 10.6601 6.55646 10.3038 6.10293 10.3038C5.64941 10.3038 5.29308 10.6601 5.29308 11.1136V13.3056ZM18.7367 15.53L20.0001 16.7934C20.4752 15.7784 20.7452 14.6014 20.7452 13.3056V11.1136C20.7452 10.6601 20.3781 10.3038 19.9245 10.3038C19.471 10.3038 19.1147 10.6601 19.1147 11.1136V13.2408C19.1147 14.0723 18.9851 14.839 18.7367 15.53ZM13.0137 17.0418C13.3917 17.0418 13.748 16.9878 14.0612 16.869L9.40716 12.2258V13.1329C9.40716 15.4329 10.8649 17.0418 13.0137 17.0418Z"
        fill="white"
      />
    </svg>
  )
}

export default Micoff
