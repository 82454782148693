import * as Yup from "yup"
import toast from "react-hot-toast"

import { Formik, Form } from "formik"
import { PageTitle } from "../../../_metronic/layout/core"
import FormikController from "../../../_metronic/Shared-Component-formik/FormikController"
import AxiosMethod from "../../../_metronic/Api/AxiosMethod"
import { useLocation, useNavigate } from "react-router-dom"
import { getLabel } from "../../../_metronic/language-provider/LanProvider"
import { ValSchemaList } from "../../../_metronic/Shared-Component-formik/schema/ValSchemaList"
import { useState } from "react"

const CreateEditInfo = () => {
  const [loading, setLoading] = useState(false)
  const usersBreadcrumbs = [
    {
      title: `${getLabel("FIELD")} ${getLabel("LIST")}`,
      path: "/config/fields-customization",
      isSeparator: false,
      isActive: false,
    },
    {
      title: "",
      path: "",
      isSeparator: true,
      isActive: false,
    },
  ]
  const navigate = useNavigate()
  const location = useLocation()
  const typeList = [
    { label: "Text", value: "text" },
    { label: "Email", value: "email" },
    { label: "Contact No.", value: "contact_no" },
  ]

  // initialValues for formik
  const initialValues = {
    field_name: location?.state?.editObj ? location?.state?.editObj.field_name : "",
    type: location?.state?.editObj ? location?.state?.editObj.type : "",
  }
  // validationSchema for formik
  let validationSchema = Yup.object({
    field_name: ValSchemaList(0, "NAME"),
    type: ValSchemaList(0, "TYPE"),
  })

  // Submit Language
  const onAddEditInfo = async (obj) => {
    // capitalize
    // obj.field_name = obj.field_name.charAt(0).toUpperCase() + obj.field_name.slice(1)
    setLoading(true)
    const addEditDeptRes = await AxiosMethod(
      location?.state?.add
        ? `qrUserDetails`
        : `qrUserDetails/${location?.state?.editObj.qr_user_detail_id}`,
      location?.state?.add ? `post` : `patch`,
      obj
    )
    if (addEditDeptRes && addEditDeptRes.status === 200) {
      setLoading(false)
      // toast.success(addEditDeptRes.data.message)
      toast.success(location?.state?.add ? getLabel("FIELD_CREATED") : getLabel("FIELD_UPDATED"))
      navigate("/config/fields-customization")
    }
    if (JSON.parse(addEditDeptRes.request.response).status === "error") {
      setLoading(false)
      toast.error(JSON.parse(addEditDeptRes.request.response).message)
    }
  }

  return (
    <>
      <PageTitle breadcrumbs={usersBreadcrumbs}>
        {`${getLabel(location?.state?.add ? "ADD" : "EDIT")} ${getLabel("FIELD")}`}
      </PageTitle>

      <div className="card card-custom">
        <div className="card-header">
          <div className="card-title">
            <h3 className="card-label">
              {`${getLabel(location?.state?.add ? "ADD" : "EDIT")} ${getLabel("FIELD")}`}
            </h3>
          </div>
        </div>

        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onAddEditInfo}
        >
          {(formik) => {
            return (
              <Form onSubmit={formik.handleSubmit} className="form">
                {/*begin::Scroll*/}
                <div className="card-body py-4" style={{ height: "48vh" }}>
                  <div
                    className="d-flex row me-n7 pe-7"
                    // className="d-flex row scroll-y me-n7 pe-7"
                    id="kt_modal_add_user_scroll"
                    data-kt-scroll="true"
                    data-kt-scroll-activate="{default: false, lg: true}"
                    // data-kt-scroll-max-height="auto"
                    data-kt-scroll-dependencies="#kt_modal_add_user_header"
                    data-kt-scroll-wrappers="#kt_modal_add_user_scroll"
                  // data-kt-scroll-offset="300px"
                  >
                    {/* Filed name */}
                    <div className="col-6 mb-7">
                      <FormikController
                        control="input"
                        type="text"
                        name="field_name"
                        label={getLabel("NAME")}
                        placeholder={`${getLabel("ENTER")} ${getLabel("NAME")}`}
                        labelClassName="required fw-bold fs-6 mb-2"
                        className="form-control form-control-solid mb-lg-0"
                        maxLength="30"
                        formik={formik}
                        value={formik.values.field_name}
                        onChange={formik.handleChange}
                        error={formik.errors.field_name}
                      />
                    </div>
                    {/* field type */}
                    <div className="col-6 mb-7">
                      <FormikController
                        control="react_select"
                        label={getLabel("TYPE")}
                        labelClassName="required fw-bold fs-6 mb-2"
                        name="type"
                        className="form-control-solid mb-lg-0"
                        formik={formik}
                        options={typeList}
                        value={formik.values.type}
                        onChange={formik.handleChange}
                        error={formik.errors.type}
                      />
                    </div>
                  </div>
                </div>
                {/*end::Scroll*/}
                {/*begin::Actions*/}
                <div className="card-footer d-flex justify-content-center">
                  <button
                    className="btn btn-light-primary font-weight-bold"
                    style={{ marginRight: "1rem" }}
                    type="button"
                    onClick={() => {
                      navigate("/config/fields-customization")
                    }}
                  >
                    <span className="indicator-label">{getLabel("CANCEL")}</span>
                  </button>
                  <button
                    type="submit"
                    id="kt_password_reset_submit"
                    className="btn btn-lg btn-primary fw-bolder me-4"
                    disabled={formik.isSubmitting || !formik.isValid}
                  >
                    {loading ? (
                      <span className="indicator-progress" style={{ display: "block" }}>
                        {getLabel("PLEASE_WAIT")}
                        <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                      </span>
                    ) : (
                      <span className="indicator-label">{getLabel("SUBMIT")}</span>
                    )}
                  </button>
                </div>
                {/*end::Actions*/}
              </Form>
            )
          }}
        </Formik>
      </div>
    </>
  )
}

export default CreateEditInfo
