import { useState } from "react"
import { Link, useNavigate } from "react-router-dom"
import { Formik, Form } from "formik"
import * as Yup from "yup"
import FormikController from "../../../../_metronic/Shared-Component-formik/FormikController"
import AxiosMethod from "../../../../_metronic/Api/AxiosMethod"
import { getLabel } from "../../../../_metronic/language-provider/LanProvider"
import { ValSchemaList } from "../../../../_metronic/Shared-Component-formik/schema/ValSchemaList"

export function ForgotPassword() {
  const [loading, setLoading] = useState(false)
  const [hasErrors, setHasErrors] = useState(undefined)
  const navigate = useNavigate()

  const initialValues = {
    Email: "",
  }
  let validationSchema = Yup.object({
    Email: ValSchemaList(1, "EMAIL"),
  })

  const onForgotPass = async (values, { setSubmitting, setStatus }) => {
    setLoading(true)
    setHasErrors(true)
    let obj = {
      email: values.Email,
    }

    const getForgotPassRes = await AxiosMethod(`users/forgotPassword`, `post`, obj)

    if (getForgotPassRes?.data?.status === "success") {
      setHasErrors(false)
      setLoading(false)
      setStatus(getForgotPassRes.data.message)
      setTimeout(() => {
        navigate("/auth/two-step-verification", { state: { email: values.Email } })
      }, 2500)
    }
    if (JSON.parse(getForgotPassRes.request.response).status === "error") {
      setLoading(false)
      setSubmitting(false)
      setStatus(JSON.parse(getForgotPassRes.request.response).message)
      setTimeout(() => {
        navigate("/")
      }, 2500)
    }
  }

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onForgotPass}
      >
        {(formik) => {
          return (
            <Form
              onSubmit={formik.handleSubmit}
              className="form w-100 fv-plugins-bootstrap5 fv-plugins-framework"
            >
              <div className="text-center mb-10">
                {/* <h1 className="text-dark mb-3">{getLabel("EMAIL")}?</h1> */}

                <div className="text-gray-400 fw-bold fs-4">
                  {getLabel("ENTER_EMAIL_RESET_PASS")}.
                </div>
              </div>

              {hasErrors && formik.status && (
                <div className="mb-lg-15 alert alert-danger">
                  <div className="alert-text font-weight-bold">{formik.status}</div>
                </div>
              )}

              {hasErrors === false && (
                <div className="mb-10 bg-light-info p-8 rounded">
                  <div className="text-info">{formik.status}</div>
                </div>
              )}

              <div className="fv-row mb-10">
                <FormikController
                  control="input"
                  type="email"
                  label={getLabel("EMAIL")}
                  labelClassName="required form-label fw-bolder text-gray-900 fs-6"
                  name="Email"
                  className="form-control form-control-lg form-control-solid"
                  placeholder={`${getLabel("ENTER")} ${getLabel("EMAIL")}`}
                  autoComplete="off"
                  formik={formik}
                  value={formik.values.Email}
                  onChange={formik.handleChange}
                  error={formik.errors.Email}
                  touched={formik.errors.Email}
                />
              </div>

              <div className="d-flex flex-wrap justify-content-center pb-lg-0">
                <Link to="/auth/login">
                  <button
                    type="button"
                    id="kt_login_password_reset_form_cancel_button"
                    className="btn btn-lg btn-light-primary fw-bolder me-4"
                    disabled={formik.isSubmitting || !formik.isValid}
                  >
                    {getLabel("CANCEL")}
                  </button>
                </Link>
                <button
                  type="submit"
                  id="kt_password_reset_submit"
                  className="btn btn-lg btn-primary fw-bolder me-4"
                  disabled={formik.isSubmitting || !formik.isValid}
                >
                  {loading ? (
                    <span className="indicator-progress" style={{ display: "block" }}>
                      {getLabel("PLEASE_WAIT")}
                      <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                    </span>
                  ) : (
                    <span className="indicator-label">{getLabel("SUBMIT")}</span>
                  )}
                </button>
              </div>
            </Form>
          )
        }}
      </Formik>
    </>
  )
}
