import Menu from "../auth/components/Menu"
import {getLabel} from "../../../_metronic/language-provider/LanProvider"

const HowToDeleteAccount = () =>
{
	
	return (
		<>
			<Menu />
			
			<div style={{padding: "20px"}}>
				<div className={"content-header"}>
					<h1>{"How to delete account"}</h1>
				</div>
				<div className={"content-container"}>
					<div className={"main-content"} >
						<h3>How to delete your data and account on QR-Phone app?</h3>
						<p><span>This link provides information on how users can request the deletion of their QR-Phone
							account and outlines the specific steps to follow for a seamless process.</span></p>
						<p></p>
						
						<h3><strong>Steps to Delete Your Account:</strong></h3>
						<p><strong>1. Navigate to My Profile:</strong></p>
						<p>After logging in, access the mt profile tab by either selecting it from the bottom tabs or
							clicking on the avatar icon located in the top-right corner of the QR-Phone mobile app.</p>
						<p></p>
						<p><strong>2. Locate "Delete Account" Button:</strong></p>
						<p>On the Account screen, you will find a prominently displayed "Delete Account" button.</p>
						<p></p>
						<p><strong>3. Confirmation Prompt:</strong></p>
						<p>Tapping the "Delete Account" button will prompt a confirmation dialogue. To proceed, type
							the word "delete" as confirmation.</p>
						<p></p>
						<h3><strong>Data Deletion Details:</strong></h3>
						<p>Upon completing the account deletion process, the following data will be permanently erased:</p>
						
						<ul>
							<li>
								<p><strong>Personal Information:</strong>
									All personal information linked to your account will be deleted.</p>
							</li>
							<li><p><strong>Email Address:</strong>
								The email address collected during the signup process will also be permanently removed
								from our records.</p>
							</li>
						</ul>
						<h3><strong>Retention Period:</strong></h3>
						<p>There is no additional retention period for the deleted data. Once the account deletion is
							confirmed, the information is promptly and permanently removed from our system.</p>
						<p></p>
					</div>
				</div>
			</div>
		
		</>
	)
}

export default HowToDeleteAccount;
