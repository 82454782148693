import DatePicker from "react-datepicker"
import {ErrorMessage, useFormikContext} from "formik"
import "react-datepicker/dist/react-datepicker.css"

const DateTimePicker = (props) => {
  const {name, label, placeholder, labelClassName, className, value, formik, readOnly, ...rest} =
    props
  const {setFieldValue} = useFormikContext()
  return (
	  <div>
      {label && (
        <label htmlFor={name} className={labelClassName}>
          {label}
        </label>
      )}
		  <div class="react-form-control">
		      <DatePicker
		        {...rest}
		        placeholderText={placeholder}
		        selected={(value && new Date(value)) || null}
		        onChange={(val) => {
		          setFieldValue(name, val)
		        }}
		        readOnly={readOnly}
		        className={className|| ""}
		        showTimeSelect
		        timeCaption="Time"
		        timeFormat="HH:mm"
		        // dateFormat="dd/MM/yyyy HH:mm"
		        onBlur={() => formik?.setFieldTouched(name, true)}
		        onChangeRaw={(e) => e.preventDefault()}
		        // isClearable={true}
		      />
		  </div>
      <ErrorMessage name={name} component="span" className="invalid-input" style={{color: "red"}} />
    </div>
  )
}

export default DateTimePicker
