export const TakeEditedVal = (values, initialValues) => {
  return Object.entries(values).reduce((acc, [key, value]) => {
    const hasChanged = initialValues[key] !== value
    if (hasChanged) {
      acc[key.toLowerCase()] = value
    }
    return acc
  }, {})
}

export const TakeEditedPlan = (values, initialValues) => {
  const test = Object.entries(values).reduce((acc, [key, value]) => {
    const hasChanged = initialValues[key] !== value
    if (hasChanged) {
      acc[key.toLowerCase()] = value
    }
    return acc
  }, {})

  if (Object.keys(test).includes("price") && !Object.keys(test).includes("duration")) {
    Object.assign(test, {duration: initialValues.duration})
  }

  if (Object.keys(test).includes("duration") && !Object.keys(test).includes("price")) {
    Object.assign(test, {price: initialValues.price})
  }

  if (Object.keys(test).includes("duration") && Object.keys(test).includes("price")) {
  }

  return test
}
