import { useState } from "react"
import * as Yup from "yup"
import { Link, useLocation, useNavigate } from "react-router-dom"
import { Formik, Form } from "formik"
import FormikController from "../../../../_metronic/Shared-Component-formik/FormikController"
import AxiosMethod from "../../../../_metronic/Api/AxiosMethod"
import { getLabel } from "../../../../_metronic/language-provider/LanProvider"
import { ValSchemaList } from "../../../../_metronic/Shared-Component-formik/schema/ValSchemaList"

export function ResetPassword() {
  const [loading, setLoading] = useState(false)
  const [hasErrors, setHasErrors] = useState(undefined)
  const navigate = useNavigate()
  const location = useLocation()

  const initialValues = {
    newPassword: "",
    confirmPassword: "",
  }
  let validationSchema = Yup.object({
    newPassword: ValSchemaList(8, "NEW_PASSWORD"),
    confirmPassword: ValSchemaList(3, "CONFIRM_PASSWORD", "newPassword"),
  })

  const onResetPass = async (values, { setStatus }) => {
    setLoading(true)
    setHasErrors(undefined)
    Object.assign(values, { email: location.state.email })

    const getResetPassRes = await AxiosMethod(`users/resetPassword`, `post`, values)
    if (getResetPassRes.data.status === "success") {
      setHasErrors(false)
      setLoading(false)
      setStatus(getResetPassRes.data.message)
      setTimeout(() => {
        navigate("/")
      }, 1200)
    }
    if (JSON.parse(getResetPassRes.request.response).status === "error") {
      setHasErrors(true)
      setLoading(false)
      setStatus(JSON.parse(getResetPassRes.request.response).message)
    }
  }
  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onResetPass}
      >
        {(formik) => {
          return (
            <Form
              onSubmit={formik.handleSubmit}
              className="form w-100 fv-plugins-bootstrap5 fv-plugins-framework"
            >
              <div className="text-center mb-10">
                <h1 className="text-dark mb-3">{getLabel("RESET_PASSWORD")}</h1>
              </div>

              {hasErrors === true && (
                <div className="mb-lg-15 alert alert-danger">
                  <div className="alert-text font-weight-bold">{getLabel("ERR_DETECTED_MSZ")}</div>
                </div>
              )}

              {hasErrors === false && (
                <div className="mb-10 bg-light-info p-8 rounded">
                  <div className="text-info">{getLabel("PASS_RESETED_MSZ")}</div>
                </div>
              )}

              <div className="fv-row mb-10">
                <FormikController
                  control="input"
                  type="password"
                  label={getLabel("NEW_PASSWORD")}
                  labelClassName="required form-label fw-bolder text-gray-900 fs-6"
                  name="newPassword"
                  className="form-control form-control-lg form-control-solid pe-13"
                  placeholder={`${getLabel("ENTER")} ${getLabel("NEW_PASSWORD")}`}
                  autoComplete="on"
                  maxLength="30"
                  formik={formik}
                  value={formik.values.newPassword}
                  onChange={formik.handleChange}
                  error={formik.errors.newPassword}
                  touched={formik.errors.newPassword}
                />
              </div>
              <div className="fv-row mb-10">
                <FormikController
                  control="input"
                  type="password"
                  autoComplete="on"
                  label={getLabel("CONFIRM_PASSWORD")}
                  labelClassName="required form-label fw-bolder text-gray-900 fs-6"
                  name="confirmPassword"
                  className="form-control form-control-lg form-control-solid pe-13"
                  placeholder={`${getLabel("ENTER")} ${getLabel("CONFIRM_PASSWORD")}`}
                  formik={formik}
                  value={formik.values.confirmPassword}
                  onChange={formik.handleChange}
                  error={formik.errors.confirmPassword}
                  touched={formik.errors.confirmPassword}
                />
              </div>
              <div className="d-flex flex-wrap justify-content-center pb-lg-0">
                <Link to="/auth/login">
                  <button
                    type="button"
                    id="kt_login_password_reset_form_cancel_button"
                    className="btn btn-lg btn-light-primary fw-bolder"
                    disabled={formik.isSubmitting || !formik.isValid}
                  >
                    {getLabel("CANCEL")}
                  </button>
                </Link>
                <button
                  type="submit"
                  id="kt_password_reset_submit"
                  className="btn btn-lg btn-primary fw-bolder me-4"
                  disabled={formik.isSubmitting || !formik.isValid}
                >
                  {loading ? (
                    <span className="indicator-progress" style={{ display: "block" }}>
                      {getLabel("PLEASE_WAIT")}
                      <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                    </span>
                  ) : (
                    <span className="indicator-label">{getLabel("SUBMIT")}</span>
                  )}
                </button>
              </div>
            </Form>
          )
        }}
      </Formik>
    </>
  )
}
