import {Suspense, useEffect, useRef, useState} from "react"
import {Outlet, useLocation, useNavigate} from "react-router-dom"
import {I18nProvider} from "../_metronic/language-provider/i18nProvider"
import {LayoutProvider, LayoutSplashScreen} from "../_metronic/layout/core"
import {MasterInit} from "../_metronic/layout/MasterInit"
import jwt_decode from "jwt-decode"
import {useDispatch, useSelector} from "react-redux"
import localforage from "localforage"
import toast from "react-hot-toast"
import {AuthAction} from "../_metronic/redux/actions/AuthAction"
import {globals} from "./utils/globals";
import {database, firestore, handleIncomingNotificationData, sendFCMToken} from "./services/firebase.service";
import {checkBasicPermission} from "./services/permission.service";
import AlertModal from "./services/alert.service";
import { onMessage } from 'firebase/messaging';
import { messaging } from './services/firebase.service';
import LoggedInDevice from "./common/loggedindevice.component";
import {
	generateDeviceId, socket,
	updateCallRealTime,
	updateCallStatus,
	updateCallStatusByUserId
} from "./services/helper.service";
import AxiosMethod from "../_metronic/Api/AxiosMethod";
import { TurnServerAction } from "../_metronic/redux/actions/LoaderAction";
import {doc, getDoc, updateDoc, collection, where, query, getDocs, orderBy} from "firebase/firestore"
import {child, get, ref} from "firebase/database";

const App = () =>
{
    const navigate = useNavigate()
    const dispatch = useDispatch()
	const location = useLocation();
	const audio = new Audio ('./media/ringtone.mp3'); // PROVIDE PATH TO YOUR RINGTONE
	
	// GET THE URL SEARCH PARAMETERS.
	const queryParams = new URLSearchParams (location.search);
	const callId = queryParams.get ('call_id');
	const bgNotificationClicked = queryParams.get ('notification_clicked');

	// GET THE USER DATA FROM REDUX STORE.
	const token = useSelector((state) => state?.AuthReducer?.token)
    const userData = useSelector((state) => state?.AuthReducer?.userData)
    const email = useSelector((state) => state?.AuthReducer?.email)
    const enabledRingtone = useSelector((state) => state?.AuthReducer?.enabledRingtone)

	// GET THE USER ID FROM THE USER DATA.
	const [isShowPermissionModel, setIsShowPermissionModel] = useState()
	const [modelDescription, setModalDescription] = useState("");
	const [modalType, seModalType] = useState("info");
	const [isLoading, setIsLoading] = useState (true);
	
	const [interacted, setInteracted] = useState(false);
	const timeoutUserinteractedId =useRef(null);
	const [hasUserInteractedWithDocument,setHasUserInteractedWithDocument] = useState(false);
	console.log("enabledRingtone", enabledRingtone,"hasUserInteractedWithDocument", hasUserInteractedWithDocument)
	
	
	// HOOK TO HANDLE IF USER LOGGED IN THEN CHECKED THE USER INTERACTION WITH THE DOCUMENT AND IF NOT USER INTERACTED WITHIN 15
	// SECONDS THEN SHOW THE ENABLE RINGTONE BUTTON ON HEADER.
	useEffect(() =>
	{
		// CHECK IF THE TOKEN IS AVAILABLE THEN CHECK THE USER INTERACTION WITH THE DOCUMENT.
		if (token)
		{
			// IF HAS USER INTERACTED WITH DOCUMENT IS TRUE THEN CLEAR THE TIMEOUT AND REMOVE THE EVENT LISTENERS.
			if (hasUserInteractedWithDocument)
			{
				clearTimeout (timeoutUserinteractedId.current); // CLEAR THE EXISTING TIMEOUT
				
				document.removeEventListener ('click', handleInteraction);
				// document.removeEventListener ('keydown', handleInteraction);
				return () => {};
			}
			
			// ADD EVENT LISTENERS TO DETECT INTERACTIONS
			document.addEventListener ('click', handleInteraction);
			// document.addEventListener ('keydown', handleInteraction);
			
			// START THE INITIAL 15-SECOND TIMER
			timeoutUserinteractedId.current = setTimeout (() =>
			{
				if (!hasUserInteractedWithDocument)
				{
					// SHOW ALERT IF NO INTERACTION DETECTED AFTER 15 SECONDS SHOW  ENABLE RINGTONE BUTTON ON HEADER.
					dispatch ({type: "UPDATE_USERINTERACTION_SHOWBUTTON", payload: {enabledRingtone: false}});
					// alert ('No interaction detected for 15 seconds.');
				}
			}, 15000);
			
			// CLEAN UP EVENT LISTENERS AND TIMEOUT ON UNMOUNT
			return () =>
			{
				document.removeEventListener ('click', handleInteraction);
				// document.removeEventListener ('keydown', handleInteraction);
				if (hasUserInteractedWithDocument)
				{
					clearTimeout (timeoutUserinteractedId.current); // CLEAR TIMEOUT ON UNMOUNT
				}
			};
		}
	}, [hasUserInteractedWithDocument, token]);
	
	// HANDLE THE USER INTERACTION WITH THE DOCUMENT FROM LISTENING TO CLICK AND KEYDOWN EVENTS.
	const handleInteraction = () =>
	{
		clearTimeout (timeoutUserinteractedId.current); // CLEAR THE EXISTING TIMEOUT
		
		// UPDATE THE REDUX STORE TO USER INTERACTION WITH DOCUMENT STATE.
		setHasUserInteractedWithDocument (true);
		dispatch ({type: "UPDATE_USERINTERACTION_SHOWBUTTON", payload: {enabledRingtone: true}});
		document.removeEventListener ('click', handleInteraction);
		// document.removeEventListener ('keydown', handleInteraction);
	};
	
	useEffect(() =>
	{
		// REGISTER THE SERVICE WORKER FOR FIREBASE CLOUD MESSAGING
		if ('serviceWorker' in navigator)
		{
			navigator.serviceWorker.register ('/firebase-messaging-sw.js').then ((registration) =>
			{
				console.log ('debug: Service Worker registered with scope: ', registration.scope);
			}).catch ((err) =>
			{
				console.error ('debug: Service Worker registration failed: ', err);
			});

			// SET UP AN EVENT LISTENER FOR MESSAGES FROM THE SERVICE WORKER
			navigator.serviceWorker.addEventListener ('message', async (event)  =>
			{
				try
				{
					console.log ('debug: Received message from service worker:', event);
					
					// GET THE DATA FROM THE SERVICE WORKER
					const {data} = event;
					console.log("debug: data", data);
					
					// CHECK IF THE DATA IS AVAILABLE
					if (data)
					{
						// GET THE DATA FROM THE SERVICE WORKER
						const {type, payload, action} = data;
						console.log ("debug: message - step 0: type", type, "payload", payload, "actions", action);
						
						// CHECK IF THE PAYLOAD IS AVAILABLE
						if (payload && typeof payload === 'object')
						{
							console.log("debug: message - step 1", payload);
							globals.callerSocketID = payload.body;
							globals.callerUserName = payload.name;
							globals.callerDetail = ((payload.callerDetail) ? JSON.parse (payload.callerDetail) : null);
							globals.call_id = payload.call_id;
							globals.enabledDirectCall = payload?.enabledDirectCall;
							globals.isThisDefaultStaffNotified = data.isThisDefaultStaffNotified;
							globals.callStaffData = payload.staffs;
							globals.notification_received_time = payload.time;
							globals.userFromNotification = false;
							await updateCallStatusByUserId (payload.call_id, "FCMPR", payload.user_id);
						}
						console.log ("type", type, "payload", payload, "actions", action);
						
						// HANDLE THE NOTIFICATION CLICK ACTION WHEN THE USER CLICKS ON THE NOTIFICATION.
						handleNotificationClickAction (type, payload.call_id, payload);
					}
				}
				catch (e)
				{
					console.log ("debug: message - Error in service worker message event:", e);
				}
			});
			console.log ('debug: Service Worker registration started', navigator.serviceWorker.controller, navigator.serviceWorker);
		}

		return () =>
		{
			navigator.serviceWorker.getRegistrations().then (registrations =>
			{
				registrations.forEach (registration =>
				{
					registration.unregister();
				});
			});
		}
	}, []);
	
	// HANDLE THE BACKGROUND CALL NOTIFICATION ACTION. THIS WILL BE CALLED WHEN THE USER CLICKS ON THE NOTIFICATION.
	const handleNotificationClickAction = async (type, callId, payload) =>
	{
		console.log ("debug: handleNotificationClickAction step 1", type, payload);
		
		// GET CALL DETAIL FROM REALTIME DATABASE BY USING CALL_ID
		let databaseRef = ref (database, 'call');
		
		// CHECK IF THE TYPE IS INCOMING_CALL_ACTION THEN NAVIGATE THE INCOMING CALL SCREEN.
		if (type === 'INCOMING_CALL_ACTION')
		{
			console.log ("debug: handleNotificationClickAction step 2.0", type);
			
			// CHECK IF THE ACTION IS CALL_ACCEPT
			if (globals.currentScreenName !== "calling")
			{
				console.log ("debug: handleNotificationClickAction step 2.1", globals.currentScreenName );
				
				// NAVIGATE TO THE INCOMING CALL COMPONENT
				navigate ("incomingcall");
			}
		} else if (type === 'INCOMING_CALL_REJECT')
		{
			console.log("debug: handleNotificationClickAction - step 3", type);
			
			// CHECK THE SOCKET IS NOT CONNECTED THEN CONNECTED SOCKET.
			if (globals.socket === null)
			{
				globals.socket = socket;
			}
			
			if (globals?.socket != null)
			{
				const callerSocketID = globals.callerSocketID;
				const staffs = JSON.stringify (globals.callStaffData);
				try
				{
					// UPDATE THE DATABASE CALL WITH A NEW CALL STATUS "REJTD"
					await updateCallStatusByUserId (callId, "REJTD", userData.user_id);
					
					// IF USER IS NOT DEFAULT USER THEN UPDATE THE REJECTED_USER_ID FILED.
					if (!globals.isThisDefaultStaffNotified)
					{
						// GET THE CHILD REFERENCE FROM THE DATABASE. AND UPDATE THE REJECTED USER ID ARRAY.
						const childRef = await child (databaseRef, callId);
						get (childRef).then ((snapshot) =>
						{
							const fetched = snapshot.val ();
							console.log (fetched)
							let rejectedby_user_id = fetched.rejectedby_user_id || [];
							if (!rejectedby_user_id.includes (userData.user_id))
							{
								rejectedby_user_id.push (userData.user_id);
							}
							updateCallRealTime (callId, {
								rejectedby_user_id: rejectedby_user_id,
								reason: "Call Rejected"
							});
						});
					}
				} catch (error)
				{
					console.error ("Error retrieving rejected user ID array from the database:", error);
				}
				
				// SEND THE REJECTION MESSAGE TO THE CALLER.
				let rejection =
				{
					call_id: globals.call_id,
					type: "Call Rejected",
					callstatus: "REJTD",
					callee_email: email
				}
				
				// EMIT THE REJECTION MESSAGE TO THE CALLER.
				globals.socket.emit ("reject-call", staffs, callerSocketID, email, rejection);
				navigate ("dashboard"); // NAVIGATE TO THE DASHBOARD.
			}
			
		} else if (type === 'INCOMING_CALL_ACCEPT')
		{
			const callIdRef = await child (databaseRef, globals.call_id);
			const answeredData = await get (child (callIdRef, "answaredby_fullname"));
			const answeredByUser = await answeredData.val ();
			
			console.log("debug: handleNotificationClickAction - step 4 answeredByUser", answeredByUser);
			
			if (typeof answeredByUser === "undefined" || answeredByUser == null)
			{
				globals.isCallActive = true;
				globals.activeCallId = 1;
				globals.activeCallStatus = "CONNECTED";
				globals.hasAnswered = true;
				let answeredBy =
				{
					answeredby_user_id: userData?.user_id || payload?.user_id,
					answaredby_fullname: userData?.full_name,
					isCallConnected: 1,
				}
				
				// UPDATE THE DATABASE CALL WITH A NEW CALL STATUS "ACCPT"
				updateCallStatusByUserId (globals.call_id, "ACCPT", userData?.user_id || payload?.user_id)
				await updateCallStatus (globals.call_id, "ACCPT", answeredBy)
				let callerDetail = JSON.parse (payload.callerDetail);
				
				// NAVIGATE TO VIDEO CALL COMPONENT SHOW FOR VIDEO CALL.
				navigate ("/callcomponent",
				{
					state:
					{
						callerName: payload.name,
						organizationName: callerDetail.organization_name,
						qrName: callerDetail.qrcode_name,
						navigateFrom: "BACKGROUND_NOTIFICATION",
					}
				});
			} else
			{
				//toast.error ("The call was answered by "+ answeredByUser);
				
				// DISCONNECT THE CALL
				navigate ("dashboard");
			}
		}
	}
	
	// HOOK TO HANDLE THE FCM CALL BACKGROUND NOTIFICATION CLICK ACTION. THIS WILL BE CALLED WHEN THE USER CLICKS ON THE NOTIFICATION.
	// OPEN THE CALLING COMPONENT OR GETTING INCOMMING CALL COMPONENT.
	useEffect(() =>
	{
		handleBackgroundCallNotificationAction();
	}, [bgNotificationClicked, callId]);

	// HANDLE THE BACKGROUND CALL NOTIFICATION ACTION. THIS WILL BE CALLED WHEN THE USER CLICKS ON THE NOTIFICATION.
	const handleBackgroundCallNotificationAction = async () =>
	{
		// CHECK IF THE BACKGROUND NOTIFICATION CLICKED IS TRUE AND CALL ID IS AVAILABLE.
		// THEN GET THE CALL DETAIL FROM THE REALTIME DATABASE AND CHECK THE ACTION TYPE.
		// IF THE ACTION TYPE IS CALL_ACCEPT THEN OPEN THE CALL COMPONENT. IF THE ACTION TYPE IS CALL_REJECT THEN
		// REJECT THE CALL. IF THE ACTION TYPE IS EMPTY THEN OPEN THE INCOMING CALL COMPONENT.
		if (bgNotificationClicked === 'true' && callId)
		{
			try
			{
				// GET OTHER QUERY PARAMETERS
				const action = queryParams.get ('action');
				const callerSocketId = queryParams.get ('callerSocketId');
				const maxResponseSeconds = queryParams.get ('maxResponseSeconds');

				// GET CALL DETAIL FROM REALTIME DATABASE BY USING CALL_ID
				const databaseRef = ref (database, 'call');
				const specificCallRef = child (databaseRef, callId);

				// GET THE SNAPSHOT OF THE CALL
				const snapshot = await get (specificCallRef);

				// HOLD THE REALTIME DATABASE DATA.
				let data;
				let name; // HOLD THE NAME OF THE CALLER AND QRCODE NAME.

				// CHECK IF THE SNAPSHOT EXISTS THEN GET THE DATA.
				if (snapshot.exists())
				{
					// GET THE DATA FROM THE SNAPSHOT.
					data = snapshot.val();
					console.log("debug: snapshot => ", data);
					let userinfo = data.userinfo; // HOLD THE USER INFO.

					// SET THE NAME OF THE CALLER
					name = `${userinfo.qrOrgName} - ${userinfo.qrcodeName}` + (userinfo?.name)? ` - ${userinfo?.name}` : "";

					// SET THE CALLER DETAIL
					const callerDetail =
					{
						name: (userinfo?.name)? userinfo?.name  : "",
						email: (userinfo?.email)? userinfo?.email  : "",
						phone: (userinfo?.telephone)? userinfo?.telephone  : "",
						defaultstaff: userinfo.defaultstaff,
						organization_name: userinfo.qrOrgName,
						qrcode_name: userinfo.qrcodeName
					};

					// SET THE GLOBALS VARIABLES
					globals.callerSocketID = callerSocketId;
					globals.callerUserName = name;
					globals.callerDetail = callerDetail;
					globals.call_id = callId;
					globals.enabledDirectCall = data?.enabledDirectCall;
					globals.callStaffData = JSON.stringify(data.staffs);
					globals.notification_received_time = data.timecallinitiated;
					globals.userFromNotification = false;

					// UPDATE THE CALL STATUS TO "FCMPR" IN THE DATABASE.
					await updateCallStatusByUserId (callId, "FCMPR", userData.user_id);

					// CHECKING THE ACTION TYPE HANDLE CALL_ACCEPT ACTION
					if (action === 'CALL_ACCEPT')
					{
						const callIdRef = await child (databaseRef, globals.call_id);
						const answeredData = await get (child (callIdRef, "answaredby_fullname"));
						const answeredByUser = await  answeredData.val();

						if (typeof answeredByUser === "undefined" || answeredByUser == null)
						{
							globals.isCallActive = true;
							globals.activeCallId = 1;
							globals.activeCallStatus = "CONNECTED";
							globals.hasAnswered = true;

							let answeredBy =
								{
								    answeredby_user_id: userData.user_id,
                                    answaredby_fullname: userData?.full_name,
                                    isCallConnected: 1,
								}

							// UPDATE THE DATABASE CALL WITH A NEW CALL STATUS "ACCPT"
							updateCallStatusByUserId (globals.call_id, "ACCPT", userData.user_id)
							await updateCallStatus (globals.call_id, "ACCPT", answeredBy)

							// NAVIGATE TO VIDEO CALL COMPONENT SHOW FOR VIDEO CALL.
							navigate ("/callcomponent",
								{
									state:
										{
											callerName: name,
											organizationName: data.userinfo.qrOrgName,
											qrName: data.userinfo.qrcodeName,
											navigateFrom: "BACKGROUND_NOTIFICATION",
										}
								});
						}
						else
						{
							//toast.error ("The call was answered by "+ answeredByUser);

							// DISCONNECT THE CALL
							navigate ("dashboard");
						}
					}

					// HANDLE CALL_REJECT ACTION
					else if (action === 'CALL_REJECT')
					{
						// CHECK THE SOCKET IS NOT CONNECTED THEN CONNECTED SOCKET.
						if (globals.socket === null)
						{
							globals.socket = socket;
						}

						if (globals?.socket != null)
						{
							const callerSocketID = callerSocketId;
							const staffs = JSON.stringify (data.staffs);
							try
							{
								await updateCallStatusByUserId (callId, "REJTD", userData.user_id);
								
								// IF USER IS NOT DEFAULT USER THEN UPDATE THE REJECTED_USER_ID FILED.
								if (!globals.isThisDefaultStaffNotified)
								{
									// GET THE CHILD REFERENCE FROM THE DATABASE. AND UPDATE THE REJECTED USER ID ARRAY.
									const childRef = await child (databaseRef, globals.call_id);
									get (childRef).then ((snapshot) =>
									{
										const fetched = snapshot.val ();
										console.log (fetched)
										let rejectedby_user_id = fetched.rejectedby_user_id || [];
										if (!rejectedby_user_id.includes (userData.user_id))
										{
											rejectedby_user_id.push (userData.user_id);
										}
										updateCallRealTime (globals.call_id, {
											rejectedby_user_id: rejectedby_user_id,
											reason: "Call Rejected"
										});
									});
								}
							} catch (error)
							{
								console.error ("Error retrieving rejected user ID array from the database:", error);
							}

							let rejection =
							{
								call_id : globals.call_id,
								type: "Call Rejected",
								callstatus: "REJTD",
								callee_email: email
							}
							globals.socket.emit ("reject-call", staffs, callerSocketID, email, rejection);
							navigate ("dashboard");
						}
					}

					// HANDLE EMPTY ACTION CASE OPEN THE INCOMING CALL COMPONENT FOR SHOW THE INCOMING CALLING SCREEN.
					else if (action === '')
					{
						// FIRST CHECK THE CURRENT SCREEN IS NOT CALLING THEN NAVIGATE TO THE INCOMING CALL COMPONENT.
						if (globals.currentScreenName !== "calling")
						{
							navigate ("incomingcall",
							{
								state:
								{
									navigateFrom: "BACKGROUND_NOTIFICATION",
								}
							});
						}
					}
				}
				else
				{
					console.log ("debug: snapshot => ","No data found");
				}
			}
			catch (e)
			{
				console.log("debug: snapshot => ",'Error fetching call details:', e);
			}
		}

		AxiosMethod (`serverDetails/iceserver`, `GET`).then (apiRes =>
		{
			let data = apiRes?.data;
			console.log ("apiResapiRes" , apiRes, typeof apiRes?.webIceConfig)
			if (data && typeof data?.webIceConfig === "object")
			{
				console.log ("set ice server config", data.webIceConfig.iceServers)
				dispatch (TurnServerAction (data.webIceConfig))
			};

			// SET THE LOADING TO FALSE
			setIsLoading (false);
		})
	};

	// EXPIRE TOKEN
    const tokenExpLogout = async () =>
    {
		// GENERATE THE DEVICE ID.
		await generateDeviceId();
		
		// CHECK IF THE TOKEN IS EXPIRED THEN LOGOUT THE USER.
		if (token)
		{
			// DECODE THE TOKEN TO GET THE EXPIRY DATE.
			const jwt_Token_decoded = jwt_decode (token)
			
			// CHECK IF THE CURRENT TIME IS GREATER THAN THE EXPIRY TIME THEN LOGOUT THE USER.
			if (Math.round(Date.now() / 1000) > jwt_Token_decoded.exp)
			{
				// DISPATCH THE ACTION TO CLEAR THE REDUX STORE AND CLEAR THE LOCAL STORAGE.
			    dispatch (AuthAction ({email: "", token: "", userData: {}}))
			    localforage.clear(); // CLEAR THE LOCAL STORAGE
			    toast("Session expired, Please login again."); // SHOW THE TOAST MESSAGE
				
				// NAVIGATE TO THE LOGIN SCREEN
			    setTimeout(() =>
			    {
			        navigate("/")
			    }, 10)
			}
		}
    }

	// HOOK TO HANDLE THE LOCATION CHANGE AND SET THE CURRENT SCREEN NAME IN THE GLOBALS.
	useEffect(() =>
	{
		console.log ("Debug: Location:", location);

		// EXTRACT THE FIRST SEGMENT FROM THE PATHNAME
		const pathSegments = location.pathname.split('/');
		const firstSegment = pathSegments[1]; // Get the first segment after splitting by '/'
		console.log ("Debug: First segment of the pathname:", firstSegment);

		// STORE THE CURRENT ROUTE IN GLOBALS.CURRENTSCREENNAME OR ANY OTHER WAY YOU PREFER
		globals.currentScreenName = firstSegment;
	}, [location]); // ADD LOCATION TO THE DEPENDENCY ARRAY

    useEffect(() => {
        tokenExpLogout();
		console.log ("Build Version: ", globals.appVersion, " - Build Date: ", globals.lastUpdated);
     }, [Math.round(Date.now() / 1000)])

	useEffect(() =>
	{
		console.log ("Debug: user data: ", userData, userData?.user_id);
		
		// CHECK IF THE USER DATA IS AVAILABLE THEN SEND THE FCM TOKEN TO THE SERVER.
		if (typeof userData === "object" && typeof userData?.user_id !== "undefined")
		{
			console.log ("Debug: sendFCMToken: ", userData);
			// GENERATE THE FCM TOKEN AND SEND IT TO THE SERVER.
			sendFCMToken (userData.user_id);

			// HANDLE THE INCOMING NOTIFICATION DATA.
			onMessage (messaging, (notification) =>
			{
				console.log("debug: message received foreground notification. ", notification);
				if (typeof notification.data.type !== "undefined" || typeof notification.data.action !==  "undefined")
				{
					handleIncomingNotificationData (notification.data,navigate, userData);
					return true; // WE DON'T NEED TO GO FURTHER.
				}
			});
		}
		if (email)
		{
			globals.email = email;
		}
	}, [userData, email]);

	useEffect(() =>
	{
		if (email)
		{
			const checkPermission = async () =>
			{
				const permission = await checkBasicPermission ();
				if (permission[0]?.permission == "denied" || permission[1]?.permission == "denied")
				{
					setModalDescription ("We need access to your camera and microphone. Click the LOCK icon in your" +
						" browser's address bar > click on permission > reset permissions > refresh page");
					//setIsShowPermissionModel (true);
					//seModalType ("danger");
				}
			}
			checkPermission().then()
		}

		// SET THE DATA-BUILD ATTRIBUTE WHEN THE COMPONENT MOUNTS
		document.body.setAttribute ('data-build', globals.appVersion);

		// CLEANUP FUNCTION TO REMOVE THE ATTRIBUTE WHEN THE COMPONENT UNMOUNTS
		return () =>
		{
			document.body.removeAttribute ('data-build');
		};
	}, [email]);


  return (
    <Suspense fallback={<LayoutSplashScreen />}>
	    {
			!isLoading &&
	        <I18nProvider>
		    <LayoutProvider>
		        {
					isShowPermissionModel &&
			        <AlertModal
				        description={modelDescription}
				        title="Need Permission"
				        visible={isShowPermissionModel}
				        onRequestClose={()=> setIsShowPermissionModel (false)}
				        type={modalType}
				        buttonObject={[
					        {
						        buttonTitle: "Cancel",
						        onButtonPress: ()=> setIsShowPermissionModel (false)
					        }
				        ]}
			        />
				}
			    <LoggedInDevice />
				<Outlet />
				<MasterInit />
		    </LayoutProvider>
		</I18nProvider>
	    }
    </Suspense>
  );
}
export {App}
