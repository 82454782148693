import {useEffect, useState} from "react"
import { Outlet } from "react-router-dom"
import { AsideDefault } from "./components/aside/AsideDefault"
import { Footer } from "./components/Footer"
import { HeaderWrapper } from "./components/header/HeaderWrapper"
import { Toolbar } from "./components/toolbar/Toolbar"
// import {RightToolbar} from "../partials/layout/RightToolbar"
import { ScrollTop } from "./components/ScrollTop"
import { Content } from "./components/Content"
import {PageDataProvider, useLayout} from "./core"
import { useLocation } from "react-router-dom"
import { MenuComponent } from "../assets/ts/components"
import {checkBasicPermission} from "../../app/services/permission.service";
import {globals} from "../../app/utils/globals";
import {useSelector} from "react-redux";
const MasterLayout = (props) =>
{
    const location = useLocation()
	const {isShowNotificatonPermission} = useLayout()
	
	useEffect(() =>
    {
	    setTimeout(() => {
	      MenuComponent.reinitialization()
	    }, 500)
    }, [])
	
  useEffect(() => {
    setTimeout(() => {
      MenuComponent.reinitialization()
    }, 500)
  }, [location.key])
  return (
	  <>
		  {
			  isShowNotificatonPermission &&
			  <div className="alert alert-warning text-center" style={{zIndex:120}} role="alert">
				  You will not receive any calls because notifications are disabled. Please enable notifications
				  by clicking on the lock icon located to the left of the address bar and granting permission for
				  notifications.
			  </div>
		  }
    <PageDataProvider>
	    
      <div className="page d-flex flex-row flex-column-fluid">
        <AsideDefault />
        <div className="wrapper d-flex flex-column flex-row-fluid" id="kt_wrapper">
          <HeaderWrapper />
          <div id="kt_content" className="content d-flex flex-column flex-column-fluid">
            {/*<Toolbar />*/}
            <div className="post d-flex flex-column-fluid" id="kt_post">
              <Content>
	              {
					  props?.children? props?.children: <Outlet />
	              }
              </Content>
            </div>
          </div>

          <Footer />
        </div>
      </div>

      {/* begin:: Drawers */}
      {/* <ActivityDrawer /> */}
      {/* <RightToolbar /> */}

      {/* end:: Modals */}
      <ScrollTop />
    </PageDataProvider>
	 </>
  )
}
export { MasterLayout }
