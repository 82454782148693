import {useEffect, useRef, useState} from "react"
import {MasterLayout} from "../../../../_metronic/layout/MasterLayout"
import {getLabel} from "../../../../_metronic/language-provider/LanProvider"
import {PageTitle} from "../../../../_metronic/layout/core"
import Menu from "./Menu"

const PrivacyPolicy = () =>
{
	
	return (
		<>
			<Menu />
			
			<div style={{padding: "20px"}}>
				<div className={"privacy-header"}>
					<h1>{getLabel ("privacy_policy")}</h1>
				</div>
				<div className={"privacy-container"}>
					<div className={"privacy-content"} >
						<p>
							{
								"The website www.qr-phone.com, owned by ICTEL INGENIEROS SL (hereinafter ICTEL)," +
								" complies with current legislation, and more specifically, by way of example, with the " +
								" provisions of Regulation (EU) 2016/679 of the European Parliament and of the" +
								" Council of April 27, 2016 (RGPD or European Data Protection Regulation) and other " +
								" concordant data protection regulations, the European Directive 95/46 / CE and Law" +
								" 34/2002, of July 11, of Information Society and Electronic Commerce Services and" +
								" European Directive 2000/31 / CE."
							}
						</p>
						<p>
							<strong>1. Right to information:</strong> <br />
							For the purposes of compliance with Law 34/2002, of July 11, services of the
							information society and electronic commerce, (hereinafter LSSI), and the RGPD, ICTEL
							informs you that the data you provide through the different channels that it makes
							available to you through this website will be treated to contact you in order to
							respond to your requests for information. They cannot be used for other purposes or
							transferred to third parties without your consent. The purpose of the website is to
							provide information and sell accessories, as well as the option to contact us or
							request information about the products we offer.
						</p>
						
						<p>
							{
								"The legitimacy for the treatment is determined by the consent given by the user" +
								" when freely and voluntarily providing their data."
							}
						</p>
						<p>
							{
								"The information will be treated with the maximum privacy, confidentiality and" +
								" security in accordance with current legislation. To ensure the confidentiality of" +
								" your data, ICTEL will implement the necessary technical, legal and organizational" +
								" security measures, in accordance with the provisions of the RGPD, thus avoiding the" +
								" loss, alteration and unauthorized access to them."
							}
						</p>
						
						<p>
							{
								"The data will be kept for the time necessary to comply with the obligations that may" +
								" arise, proceeding after the expiration of said period to its destruction.\n"
							}
						</p>
						
						<p>
							{
							
								"We also remind you that you can exercise your rights of access, opposition," +
								" deletion, limitation of treatment and portability by writing to the Responsible at" +
								" the email address info@qr-phone.com, attaching a copy of your ID or equivalent" +
								" supporting document."
							}
						</p>
						
						<p>
							<strong>2. User consent:</strong> <br />
							It will be understood that the user accepts the conditions established from the moment
							they register on the web. Therefore, the completion of our registration form
							implies the unequivocal consent, on the part of the user, to the automated
							processing of the data in accordance with the privacy conditions of ICTEL. The data
							collected is adequate, pertinent, and not excessive in relation to the scope and the
							determined purposes. Notwithstanding the foregoing, said consent may be revoked at
							any time by the interested party, unsubscribing by any of the means made available
							to the user.
						</p>
						
						<p>
							<strong>3. Transfer of user data:</strong> <br />
							Your data will not be transferred to third parties, except for those transfers
							authorized by current legislation.
						</p>
					</div>
				</div>
</div>

</>
	)
}

export default PrivacyPolicy;
