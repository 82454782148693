import React from 'react';
import {Button, Modal} from "react-bootstrap"
import "./alert.style.scss";

interface Props
{
	visible: boolean,
	onRequestClose: any,
	title: string | null,
	description: string,
	buttonObject?: any,
	type?:any,
	iconHide?: boolean
}

const AlertModal = ({ visible,iconHide, onRequestClose,  title, description, buttonObject, type }: Props) =>
{
	let icon: any = "#08ADED";
	if(type === "danger")
	{
		icon ="#FF5959"
	}
	return (
		<Modal
			animation = {true}
			backdrop={"static"}
			show = {visible}
			onHide={onRequestClose}
		>
			<Modal.Body>
				<div className="popupContainer">
					<div className="popup">
						{
							!iconHide &&
							<div className="iconContainer">
								<svg width="72" height="72" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
									<rect x="5" y="5.77734" width="32" height="32" rx="16" fill={icon}/>
									<path fillRule="evenodd" clipRule="evenodd" d="M19.5889 16.1633C19.5889 15.1341 20.4232 14.2998 21.4524 14.2998C22.4816 14.2998 23.3159 15.1341 23.3159 16.1633C23.3159 16.667 23.116 17.1241 22.7913 17.4595C23.5537 17.6661 24.2585 18.0485 24.8356 18.5831C25.7412 19.422 26.2589 20.5695 26.2589 21.776C26.2589 23.9774 26.767 25.3502 27.2412 26.1555C27.4794 26.5601 27.7129 26.8281 27.8784 26.9899C27.9612 27.0709 28.0273 27.1256 28.0688 27.1577C28.0896 27.1737 28.1042 27.1841 28.1117 27.1892L28.117 27.1928C28.3282 27.3265 28.4266 27.5835 28.3582 27.8244C28.2892 28.0674 28.0673 28.2351 27.8147 28.2351H15.0901C14.8375 28.2351 14.6156 28.0674 14.5466 27.8244C14.4782 27.5835 14.5766 27.3265 14.7878 27.1928L14.7932 27.1892C14.8006 27.1841 14.8152 27.1737 14.836 27.1577C14.8775 27.1256 14.9436 27.0709 15.0265 26.9899C15.1919 26.8281 15.4254 26.5601 15.6636 26.1555C16.1378 25.3502 16.6459 23.9774 16.6459 21.776C16.6459 20.5695 17.1637 19.422 18.0692 18.5831C18.6463 18.0485 19.3512 17.6661 20.1136 17.4595C19.7888 17.1241 19.5889 16.667 19.5889 16.1633ZM22.7751 29.8753C23.166 29.5972 23.4909 29.1971 23.7173 28.715H19.2624C19.4888 29.1971 19.8137 29.5972 20.2046 29.8753C20.5955 30.1535 21.0387 30.2999 21.4899 30.2999C21.941 30.2999 22.3842 30.1535 22.7751 29.8753Z" fill="white"/>
									<rect x="2.5" y="3.27734" width="37" height="37" rx="18.5" stroke={icon} strokeOpacity="0.2" strokeWidth="5"/>
								</svg>
	
							</div>
						}
						{title !== null
							&&
							<h1 className="title">{title}</h1>}
						<p className="description">
							{description}
						</p>
						{
							typeof buttonObject === "object" && buttonObject.length > 0 &&
							<div className="rowCentered">
								{
									buttonObject.map ((item: any, index: any) =>
										<Button
											key={index}
											className="buttonObjectContainer"
											onClick={item.onButtonPress || onRequestClose}
											//textStyle={styles.buttonText}
										>
											{item.buttonTitle}
										</Button>
									)
								}
							</div>
						}
					</div>
				</div>

			</Modal.Body>
		</Modal>
	);
};
export default AlertModal;
