import localforage from "localforage"
import AxiosMethod from "../../Api/AxiosMethod"

export const getTempPlan = async () => {
  let tempArray = []
  let getPlanRes = await AxiosMethod(`plans`, `get`)
  getPlanRes?.data?.data?.rows?.map((usr) => {
    Object.assign(usr, {label: usr.name, value: usr.plan_id})
    tempArray.push(usr)
    return tempArray
  })
  return tempArray
}
export const getTempClient = async () => {
  let tempArray = []
  let getClientRes = await AxiosMethod(`users?role=4`, `get`)
  getClientRes?.data?.data?.rows?.map((usr) => {
    Object.assign(usr, {label: usr.full_name, value: usr.user_id})
    tempArray.push(usr)
    return tempArray
  })
  return tempArray
}
export const getTempOrg = async () => {
  let tempArray = []
  let getClientRes = await AxiosMethod(`users?role=2`, `get`)
  getClientRes?.data?.data?.rows?.map((usr) => {
    Object.assign(usr, {label: usr.full_name, value: usr.user_id})
    tempArray.push(usr)
    return tempArray
  })
  return tempArray
}
export const getTempCountries = async () => {
  let tempArray = []
  let getClientRes = await AxiosMethod(`countries`, `get`)
  getClientRes?.data?.data?.rows?.map((country) => {
    Object.assign(country, {label: country.name, value: country.country_id})
    tempArray.push(country)
    return tempArray
  })
  return tempArray
}
export const getTempState = async (countryIdForState) => {
  if (countryIdForState) {
    let tempArray = []
    let getClientRes = await AxiosMethod(`countries/states/${countryIdForState}`, `get`)
    getClientRes?.data?.data?.rows?.map((state) => {
      Object.assign(state, {label: state.name, value: state.state_id})
      tempArray.push(state)
      return tempArray
    })
    return tempArray
  }
}
export const getTempModules = async () => {
  const getModuleRes = await AxiosMethod(`modules`, `get`)
  let tempArray = []
  getModuleRes?.data?.data?.rows?.map((module) => {
    Object.assign(module, {label: module.module_name, value: module.module_id})
    tempArray.push(module)
    return tempArray
  })
  return tempArray
}
export const getTempDept = async (orgForDept) => {
  let tempArray = []
  let getClientRes = await AxiosMethod(
    `departments?filterKey=organization_id&filterValue=${orgForDept}`,
    `get`
  )
  getClientRes?.data?.data?.rows?.map((usr) => {
    Object.assign(usr, {label: usr.name, value: usr.department_id})
    tempArray.push(usr)
    return tempArray
  })
  return tempArray
}
export const generateEmail = () => {
  var allchars = "abcdefghijklmnopqrstuvwxyz1234567890"
  var emailLength = 8
  var string = ""
  for (var i = 0; i < emailLength; i++) {
    string += allchars[Math.floor(Math.random() * allchars.length)]
  }
  string = string + "@yopmail.com"
  return string
}
export const handleNull = (data) => {
  return data || "N/A"
}
export const logOut = async () => {
  await localforage.clear()
  window.location.reload()
}

// export const userUtcOffset = "+01:00"
export const userUtcOffset = Intl.DateTimeFormat().resolvedOptions().timeZone
