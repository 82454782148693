import {useEffect, useState} from "react"
import {PageTitle} from "../../../_metronic/layout/core"
import Pagination from "../../../_metronic/helpers/pagination-helper/Pagination"
import TableHeaderToolbar from "../../../_metronic/helpers/table-header-helper/TableHeaderToolbar"
import {useDispatch, useSelector} from "react-redux"
import {setCallDetailParams} from "../../../_metronic/redux/actions/QueryParamsAction"
import SortingHelper from "../../../_metronic/helpers/sorting-helper/SortingHelper"
import {getLabel} from "../../../_metronic/language-provider/LanProvider"
import {SetLoader} from "../../../_metronic/redux/actions/LoaderAction"
import moment from "moment"
import ViewSvg from "../../../_metronic/helpers/svg-helper/ViewSvg"
import {handleNull, userUtcOffset} from "../../../_metronic/helpers/reuseable-fun/FunReuse"
import CallDetailsFilter from "./CallDetailsFilter"
import {firestore} from "../../services/firebase.service";
import { collection, query, where, orderBy, limit, getDocs, startAfter } from 'firebase/firestore';
import {getTempOrg} from "../../../_metronic/helpers/reuseable-fun/FunReuse"

const CallDetails = () =>
{
	// DECLARE THE VARIABLES AND STATES AND PROPS.
	const dispatch = useDispatch()
	const [callList, setCallList] = useState();
    const [numberOfCalls, setNumberOfCalls] = useState(0);
	const user_id = useSelector ((state) => state?.AuthReducer?.user_id)
	const currentParams = useSelector ((state) => state?.QueryParamsReducer?.callDetailParams)
	const [params, setparams] = useState (currentParams)
	const [notFound, setNotFound] = useState("")
	const [tableLabel, setTableLabel] = useState([])
    const [startAfterDoc, setStartAfterDoc] = useState(false)

    // GET THE USER DATA FROM REDUX STORE.
    const user = useSelector((state) => state?.AuthReducer?.userData)

    // DEFINE THE TABLE HEADER COLUMNS.
	const tableHeader =
	[
		{ label: getLabel ("CALLER"), key: "user_info", column: "user_info.name", styleClass: "min-w-125px sorting"},
		{ label: "Attended By", key: "answaredby_fullname", column: "answaredby_fullname", styleClass: "min-w-125px sorting"},
		{ label: "Call Time", key: "start_time", column: "start_time", styleClass: "min-w-125px sorting"},
		{ label: getLabel ("DURATION"), key: "end_time", column: "duration", styleClass: "min-w-125px sorting"},
		{ label: "Status", key: "isCallConnected", column: "Status", styleClass: "min-w-125px sorting"},
		// { label: getLabel("ACTIONS"), key: "action", hidePrint:true, styleClass: "text-end min-w-100px sorting_disabled"},
	];

	// ERROR FOUND TEMPLATE.
	const errorFoundTemplate =() =>
	{
		return (
			<tr>
				<td colSpan={tableHeader.length + 1}>
					<div className="d-flex text-center w-100 align-content-center justify-content-center text-uppercase">
						{notFound}
					</div>
				</td>
			</tr>
		);
	};

	// GET THE CALL DETAIL LIST FROM SERVER.
	const getCallDetail = async (search, setlimit, page, sortBy, sort, filterKey, filterValue) =>
	{
		// SET THE LOADER.
		dispatch (SetLoader(true));

		// CREATE THE QUERY.
		// const query =  `callDetails?search=${search}&limit=${setlimit}&page=${page}&sortwith=${sortBy}&sortby=${sort}&filterKey=${String(filterKey) || ""}&filterValue=${String(filterValue) || ""}`;

		// GET CALL DETAIL FROM SERVER.
		// const reposnse = await AxiosMethod (query, `get`);
		// console.log ("reposnse Call detail log ", reposnse.data, reposnse.request.response);

		// PARAM VALUES
		let values = {
			search: search,
			limit: setlimit,
			page: page,
			sortBy: sortBy,
			sort: sort,
			filterKey: filterKey,
			filterValue: filterValue,
		}

		try
		{
            // GET A REFERENCE TO THE COLLECTION CONTAINING CALLS
            const callsCollectionRef = collection (firestore, 'call');
            let snapshot;

            // MAKE A QUERY TO GET CALLS FROM FIRESTORE.
            if (filterKey.length >0 && filterValue.length > 0)
            {
                let q1 = query (callsCollectionRef, where ("organization_id", '==', Number(filterValue[0])));
                snapshot =  await getDocs (q1);
            }
            else
            {
	            let querySize = "";
				
	            // CHECK IF USER IS ADMIN THEN SHOW ALL CALLS ELSE SHOW ONLY CALLS OF LOGGED IN USER.
	            if (user.role_id === 1)
	            {
		            querySize = query (callsCollectionRef);
	            }
	            else if (user.role_id === 3) // IF USER IS STAFF THEN SHOW ONLY CALLS OF THAT USER.
	            {
		            querySize = query (callsCollectionRef, where ('staffids', 'array-contains', user_id));
	            }
	            else if (user.role_id === 4 || user.role_id === 5) // IF USER IS ORGANIZATION THEN SHOW ONLY CALLS OF THAT ORGANIZATION.
	            {
		            // GET THE USER ORGANIZATION ID
		            let organizationList = await getTempOrg();
		            let organizationIds = organizationList.map ((org) => org.value? org.value: org.user_id);
		            
		            console.log ("organizationIds", organizationIds, organizationList);
		            querySize= query (callsCollectionRef, where ('organization_id', 'in', organizationIds));
	            }
				
                // GET ALL DOCUMENTS IN THE COLLECTION
                snapshot =  await getDocs (querySize);
            }

            // GET THE NUMBER OF DOCUMENTS (CALLS)
            const numberOfCalls = snapshot.size;
            setNumberOfCalls (numberOfCalls); // SET THE NUMBER OF CALLS STATE.

            // MAKE A QUERY TO GET CALLS FROM FIRESTORE.
            let q = "";

            // CHECK IF USER IS ADMIN THEN SHOW ALL CALLS ELSE SHOW ONLY CALLS OF LOGGED IN USER.
            if (user.role_id === 1)
            {
                q = query (callsCollectionRef);
            }
            else if (user.role_id === 3) // IF USER IS STAFF THEN SHOW ONLY CALLS OF THAT USER.
            {
                q= query (callsCollectionRef, where ('staffids', 'array-contains', user_id));
            }
			else if (user.role_id === 4 || user.role_id === 5) // IF USER IS ORGANIZATION THEN SHOW ONLY CALLS OF THAT ORGANIZATION.
            {
	            // GET THE USER ORGANIZATION ID
	            let organizationList = await getTempOrg();
	            let organizationIds = organizationList.map ((org) => org.value? org.value: org.user_id);
	            
	            console.log ("organizationIds", organizationIds, organizationList);
				q= query (callsCollectionRef, where ('organization_id', 'in', organizationIds));
            }

            // IF FILTER KEY AND VALUE IS PROVIDED THEN FILTER THE CALLS.
            if (filterKey.length >0 && filterValue.length > 0)
            {
                 q = query (q, where ("organization_id", '==', Number(filterValue[0])));
            }

            q = query (q, orderBy ('timecallinitiated', 'desc'), limit (setlimit));

            // IF STARTAFTERDOC IS PROVIDED, START THE QUERY AFTER THAT DOCUMENT
            if (startAfterDoc)
            {
                q = query (q, startAfter(startAfterDoc));
            }

            // GET THE DOCUMENTS FROM FIRESTORE.
            const querySnapshot = await getDocs (q);
			console.log ('querySnapshot documents found.', querySnapshot);

            // CHECK IF DOCUMENTS FOUND.
			if (!querySnapshot.empty)
			{
				// SET THE NOT ERROR FOUND STATE.
				setNotFound (false);
				const calls = []; // CALLS ARRAY.

                // ITERATE THE DOCUMENTS.
				querySnapshot.forEach ((doc) =>
				{
                    // GET THE CALL DATA.
					let call = doc.data();
					let formattedDuration = '';

                    // CALCULATE THE DURATION OF CALL.
					if (call.endtime && call.starttime)
					{
						const durationInMilliseconds = moment.duration (moment (call.endtime).diff (moment (call.starttime))).asMilliseconds ();
						formattedDuration = moment.utc (durationInMilliseconds).format ('HH:mm:ss');
					}

                    // SET THE DURATION OF CALL.
					call.duration = formattedDuration;
					calls.push (call); // PUSH THE CALL DATA TO CALLS ARRAY.
				});

                // UPDATE LASTDOC WITH THE REFERENCE TO THE LAST DOCUMENT OF THE FIRST PAGE
                if (calls.length > 0)
                {
                    // GET THE LAST DOCUMENT.
                   let lastDoc = querySnapshot.docs[calls.length - 1];

                     // SET THE LAST DOCUMENT.
                    setStartAfterDoc (lastDoc);
                }
                // SET THE CALL LIST STATE.
				setCallList (calls);
				console.log('calls documents found.', calls);
			}
			else
			{
				console.log('No documents found.');
				setNotFound ('No documents found.');
			}
		} catch (error)
        {
			console.error('Error fetching call list:', error);
		}

		// SET THE PARAMS STATE LOCALLY.
		setparams (values);

		// DISPATCH THE CONNECTION DETAIL PARAMS.
		dispatch (setCallDetailParams(values))

		// // CHECK THE RESPONSE STATUS IF SUCEESS THEN SET THE STATE.
		// if (reposnse && reposnse.status === 200)
		// {
		// 	// SET THE NOT ERROR FOUND STATE.
		// 	setNotFound (false);
		//
		// 	// SET THE CALL DETAIL STATE.
		// 	setInfo (reposnse?.data?.data)
		//
		// 	// COLUMN NAME TO EXPORT
		// 	let csvNameLabel = []
		// 	tableHeader.map ((el) =>
		// 	{
		// 		let obj =
		// 			{
		// 				name: el.column,
		// 				label: capitalize (el.label),
		// 			}
		// 		csvNameLabel.push (obj)
		// 	})
		// 	setTableLabel (csvNameLabel);
		// }
		//
		// // SERVER RESPONSE STATUS ERROR THE SET THE ERROR FOUND STATE.
		// if (JSON.parse (reposnse.request.response).status === "error")
		// {
		// 	// SET THE ERROR STATE.
		// 	setNotFound (JSON.parse(reposnse.request.response).message)
		// }

		// STOP THE LOADER.
		dispatch (SetLoader(false));
	}

	// MOUNT THE INITIALIZED THE FUNCTION.
	useEffect(() =>
	{
		getCallDetail(
			params.search,
			params.limit,
			callList?.length <= 1 && params.page > 1 ? params.page - 1 : params.page,
			'start_time',
			1,
			params.filterKey || "",
			params.filterValue || ""
		)
	}, []);

	// RENDER THE PAGE.
	return (
		<>
			<PageTitle>{getLabel("CALL_DETAILS_LIST")}</PageTitle>
			<div className="card">

				{/* CARD HEADER TABLE TOOLBAR */}
				<TableHeaderToolbar
					params={params}
					setparams={setparams}
					action={getCallDetail}
					filterBody={<CallDetailsFilter params={params} action={getCallDetail} />}
					showSearch={true}
					showPrintBtn={true}
					showExportBtn={true}
					filename={`calldetail_${new Date().toUTCString()}.csv`}
					csvNameLabel={tableLabel}
					exportData={callList}
					csvTableName={'calldetail'}
					exportType={"S"} // S = FROM SERVER , L = LOCAL VIEW DATA EXPORT.
				/>

				{/* CARD BODY */}
				<div className="card-body py-4">
					<div id="kt_table_users_wrapper" className="dataTables_wrapper dt-bootstrap4 no-footer">
						<div className="table-responsive">
							<table
								className="table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer"
								id="kt_table_users"
							>
								<thead>
								<tr className="text-start text-muted fw-bolder fs-7 text-uppercase gs-0">
									{
										tableHeader && tableHeader.map ((row, index) =>
										{
											return (
												<th
													key={index}
													className={`${row.styleClass}`}
													tabIndex={index}
													aria-controls="kt_table_users"
													rowSpan={1}
													colSpan={1}
													aria-label={`${row.label}: activate to sort column ascending`}
													style={{cursor: "pointer"}}
													onClick={() =>
														getCallDetail (
															params.search,
															params.limit,
															params.page,
															`${row.key}`,
															params.sort === 0 ? 1 : 0,
															params?.filterKey || "",
															params?.filterValue || ""
														)
													}
												>
													{row.label}
													{SortingHelper(params.sort, params.sortBy, `${row.key}`)}
												</th>
											)
										})
									}
									<th
										className="text-end min-w-100px sorting_disabled d-print-none"
										rowSpan={1}
										colSpan={1}
										aria-label="Actions"
										style={{width: "128.946px"}}
									>
										{getLabel("ACTIONS")}
									</th>
								</tr>
								</thead>
								<tbody className="text-gray-600 fw-bold">
								{
									notFound
										?
										errorFoundTemplate()
										:
										(
											callList &&
											callList.map ((call, key) =>
											{
												return (
													<tr key={key}>
														<td className="text-capitalize">{handleNull (call.userinfo.name)}</td>
														<td className="text-capitalize">{(call.answaredby_fullname)}</td>
														<td>
															{
																call.createdAt && call.createdAt !== "Invalid date"
																	? moment (call.createdAt)
																	.utcOffset (userUtcOffset)
																	.format ("DD/MM/yyyy hh:mm A")
																	: "N/A"
															}
														</td>
														{/*<td>*/}
														{/*  {*/}
														{/*	  call.starttime && call.starttime !== "Invalid date"*/}
														{/*        ?*/}
														{/*            moment(call.endtime).utcOffset(userUtcOffset).format("DD/MM/yyyy HH:mm:ss")*/}
														{/*                :"N/A"}*/}
														{/*</td>*/}
														{/*  <td>*/}
														{/*      /!*{info.end_time && info.end_time !== "Invalid date"*!/*/}
														{/*      /!*  ?*/}
														{/*      moment(info.end_time).utcOffset(userUtcOffset).format("DD/MM/yyyy HH:mm")*!/*/}
														{/*      /!*  : "N/A"}*!/*/}
														{/*      /!* moment( moment(info.end_time).utcOffset(userUtcOffset).diff(moment(info.start_time).utcOffset(userUtcOffset))).format("HH:mm:ss")*!/*/}
														{/*      {*/}
														{/*          call.starttime &&  call.starttime  !== "Invalid date"*/}
														{/*              ?*/}
														{/*              moment.utc (moment.duration ( moment(call.endtime)*/}
														{/*              .utcOffset (userUtcOffset).diff (moment(call.starttime).utcOffset(userUtcOffset))).asMilliseconds()).format("HH:mm:ss")*/}
														{/*              :"N/A"}*/}
														{/*  </td>*/}
														<td>
															{
																call?.duration && call.duration !== "Invalid date"
																	?
																	call.duration
																	:
																	"N/A"
															}
														</td>
														<td>
															{
																call?.isCallConnected && call.isCallConnected == 1
																	?
																	"Answered"
																	:
																	"Missed"
															}
														</td>
														<td className="d-print-none">
															<div className="d-flex justify-content-end flex-shrink-0">
																{/* VIEW BUTTON */}
																<ViewSvg path={`/call-details/${call.call_id}`} editObj={call} />
															</div>
														</td>
													</tr>
												)
											})
										)
								}
								</tbody>
							</table>
						</div>
						{
							!notFound &&
							<Pagination
								params={params}
								setparams={setparams}
								count={numberOfCalls}
								pageData={numberOfCalls}
								action={getCallDetail}
							/>
						}
					</div>
				</div>
				{/* END::CARD BODY */}
			</div>
		</>
	)
}

export default CallDetails
