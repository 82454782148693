import * as Yup from "yup"
import toast from "react-hot-toast"

import { Formik, Form } from "formik"
import { PageTitle } from "../../../_metronic/layout/core"
import FormikController from "../../../_metronic/Shared-Component-formik/FormikController"
import AxiosMethod from "../../../_metronic/Api/AxiosMethod"
import { useLocation, useNavigate } from "react-router-dom"
import { useSelector } from "react-redux"
import { useEffect, useState } from "react"
import { getTempOrg } from "../../../_metronic/helpers/reuseable-fun/FunReuse"
import { getLabel } from "../../../_metronic/language-provider/LanProvider"
import { ValSchemaList } from "../../../_metronic/Shared-Component-formik/schema/ValSchemaList"
import { TakeEditedVal } from "../../../_metronic/helpers/only-edit-value/TakeEditedVal"

const CreateEditDepartment = () => {
  const [loading, setLoading] = useState(false)
  const usersBreadcrumbs = [
    {
      title: `${getLabel("SCHEDULE")} ${getLabel("LIST")}`,
      path: "/org/schedule-management",
      isSeparator: false,
      isActive: false,
    },
    {
      title: "",
      path: "",
      isSeparator: true,
      isActive: false,
    },
  ]
  const navigate = useNavigate()
  const location = useLocation()
  const [userList, setUserList] = useState([])
  const loggedInUser = useSelector((state) => state?.AuthReducer?.userData)
  // const [showWeek, setshowWeek] = useState(location?.state?.editObj?.weekdays)

  const dayList = [
    { label: "Monday", value: 1 },
    { label: "Tuesday", value: 2 },
    { label: "Wednesday", value: 3 },
    { label: "Thursday", value: 4 },
    { label: "Friday", value: 5 },
    { label: "Saturday", value: 6 },
    { label: "Sunday", value: 0 },
  ]

  // initialValues for formik
  const initialValues = {
    name: location?.state?.editObj ? location?.state?.editObj.name : "",
    description: location?.state?.editObj ? location?.state?.editObj.description : "",
    organization_id: location?.state?.editObj?.organization_id
      ? location?.state?.editObj?.organization_id
      : "",
    start_shift: location?.state?.editObj ? location?.state?.editObj?.start_shift : "",
    end_shift: location?.state?.editObj ? location?.state?.editObj?.end_shift : "",
    weekdays: location?.state?.editObj ? location?.state?.editObj?.weekdays : "",
  }
  // validationSchema for formik
  let validationSchema = Yup.object({
    name: ValSchemaList(0, "NAME"),
    description: ValSchemaList(0, "DESC"),
    ...((loggedInUser.role_id !== 4 || loggedInUser.role_id !== 5) && {
      organization_id: ValSchemaList(0, "ORG"),
    }),
    ...{ start_shift: ValSchemaList(6, "START_HOUR") },
    ...{
      end_shift: ValSchemaList(6, "END_HOUR"),
    },
    // ...{weekdays: ValSchemaList(7, "weekdays")},
  })

  const getClient = async () => {
    setUserList(await getTempOrg())
  }
  // Submit Language
  const onAddEditDept = async (values) => {
    setLoading(true)
    let temp = TakeEditedVal(values, initialValues)
    temp?.weekdays?.length < 1 && delete temp.weekdays
    // showWeek ? delete temp.start_shift && delete temp.end_shift : delete temp.weekdays

    const addEditDeptRes = await AxiosMethod(
      location?.state?.add
        ? `departments`
        : `departments/${location?.state?.editObj.department_id}`,
      location?.state?.add ? `post` : `patch`,
      temp
    )

    if (addEditDeptRes && addEditDeptRes.status === 200) {
      setLoading(false)
      // toast.success(addEditDeptRes.data.message)
      toast.success(
        location?.state?.add ? getLabel("SCHEDULE_ADDED") : getLabel("SCHEDULE_UPDATED")
      )
      navigate("/org/schedule-management")
    }
    if (JSON.parse(addEditDeptRes.request.response).status === "error") {
      setLoading(false)
      toast.error(JSON.parse(addEditDeptRes.request.response).message)
    }
  }

  const [allHours, setAllHours] = useState(false);
  const handleCheckboxChange = (formik) => {
    const inverseAllHours = !allHours;
    const minTime = new Date();
    minTime.setHours(0, 0, 0);
    const maxTime = new Date();
    maxTime.setHours(23, 59, 59);
    formik.setFieldValue("start_shift", inverseAllHours ? minTime : "");
    formik.setFieldValue("end_shift", inverseAllHours ? maxTime : "");
    formik.setFieldTouched("start_shift", inverseAllHours ? false : true);
    formik.setFieldTouched("end_shift", inverseAllHours ? false : true);
    setAllHours(inverseAllHours);
  };

  useEffect(() => {
    getClient()
  }, [])
  return (
    <>
      <PageTitle breadcrumbs={usersBreadcrumbs}>
        {`${getLabel(location?.state?.add ? "ADD" : "EDIT")} ${getLabel("SCHEDULE")}`}
      </PageTitle>
      <div className="card card-custom">
        <div className="card-header">
          <div className="card-title">
            <h3 className="card-label">
              {`${getLabel(location?.state?.add ? "ADD" : "EDIT")} ${getLabel("SCHEDULE")}`}
            </h3>
          </div>
        </div>

        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onAddEditDept}
        >
          {(formik) => {
            return (
              <Form onSubmit={formik.handleSubmit} className="form">
                {/*begin::Scroll*/}
                <div className="card-body py-4" style={{ height: "48vh" }}>
                  <div
                    className="d-flex row me-n7 pe-7"
                    // className="d-flex row scroll-y me-n7 pe-7"
                    id="kt_modal_add_user_scroll"
                    data-kt-scroll="true"
                    data-kt-scroll-activate="{default: false, lg: true}"
                    // data-kt-scroll-max-height="auto"
                    data-kt-scroll-dependencies="#kt_modal_add_user_header"
                    data-kt-scroll-wrappers="#kt_modal_add_user_scroll"
                  // data-kt-scroll-offset="300px"
                  >
                    {/* if role == staff then Client ID */}
                    <div className="col-6 mb-7">
                      <FormikController
                        control="react_select"
                        label={getLabel("ORG")}
                        labelClassName="required fw-bold fs-6 mb-2"
                        name="organization_id"
                        className="form-control-solid mb-lg-0"
                        formik={formik}
                        options={userList}
                        value={formik.values.organization_id}
                        onChange={formik.handleChange}
                        error={formik.errors.organization_id}
                        isDisabled={!location?.state?.add}
                      />
                    </div>
                    <div className="col-6 mb-7">
                      <FormikController
                        control="input"
                        type="text"
                        name="name"
                        label={`${getLabel("SCHEDULE")} ${getLabel("NAME")}`}
                        placeholder={`${getLabel("ENTER")} ${getLabel("SCHEDULE")} ${getLabel("NAME")}`}
                        labelClassName="required fw-bold fs-6 mb-2"
                        className="form-control form-control-solid mb-lg-0"
                        maxLength="30"
                        formik={formik}
                        value={formik.values.name}
                        onChange={formik.handleChange}
                        error={formik.errors.name}
                      // disabled={editLanguage}
                      />
                    </div>
                    <div className="col-6 mb-7">
                      <FormikController
                        control="input"
                        type="text"
                        name="description"
                        label={getLabel("DESC")}
                        placeholder={`${getLabel("ENTER")} ${getLabel("DESC")}`}
                        labelClassName="required fw-bold fs-6 mb-2"
                        className="form-control form-control-solid mb-lg-0"
                        maxLength="100"
                        formik={formik}
                        value={formik.values.description}
                        onChange={formik.handleChange}
                        error={formik.errors.description}
                      />
                    </div>

                    {/* <div className="col-6 mb-7">
                      <label className="fw-bold fs-6 mb-2">
                        <span>Time/Weekdays</span>
                      </label>
                      <div className="row">
                        <div className="col-3" onClick={() => setshowWeek(false)}>
                          <input
                            type="radio"
                            className="form-check-input me-2"
                            checked={!showWeek}
                            name="showWeek"
                          />
                          <label className="mb-2">
                            <span>Time</span>
                          </label>
                        </div>
                        <div className="col-3" onClick={() => setshowWeek(true)}>
                          <input
                            type="radio"
                            className="form-check-input me-2"
                            checked={showWeek}
                            name="showWeek"
                          />
                          <label className="mb-2">
                            <span>Weekday</span>
                          </label>
                        </div>
                      </div>
                    </div> */}

                    {/* startTime */}
                    <div className="col-6 mb-7">
                      <FormikController
                        control="datetime"
                        label={getLabel("START_HOUR")}
                        placeholder={getLabel("SELECT")}
                        labelClassName="required fw-bold fs-6 mb-2"
                        className="form-control form-control-solid mb-lg-0"
                        name="start_shift"
                        timeFormat="HH:mm"
                        timeCaption="Time"
                        showTimeSelectOnly={true}
                        dateFormat="HH:mm"
                        filterTime={(time) => {
                          if (formik.values.end_shift) {
                            return time //< new Date(formik.values.end_shift)
                          } else {
                            return time
                          }
                        }}
                        readOnly={allHours}
                        formik={formik}
                        value={formik.values.start_shift}
                        onChange={formik.handleChange}
                        error={formik.errors.start_shift}
                      />
                    </div>
                    {/* End Time */}
                    <div className="col-6 mb-7">
                      <FormikController
                        control="datetime"
                        label={getLabel("END_HOUR")}
                        placeholder={getLabel("SELECT")}
                        labelClassName="required fw-bold fs-6 mb-2"
                        className="form-control form-control-solid mb-lg-0"
                        name="end_shift"
                        showTimeSelectOnly={true}
                        dateFormat="HH:mm"
                        filterTime={(time) => {
                          if (formik.values.start_shift) {
                            return time //> new Date(formik.values.start_shift)
                          } else {
                            return time
                          }
                        }}
                        readOnly={allHours}
                        formik={formik}
                        value={formik.values.end_shift}
                        onChange={formik.handleChange}
                        error={formik.errors.end_shift}
                      />
                      <br />

                      <label htmlFor="expire">
                        <input
                          id="allHours"
                          style={{ marginTop: "1rem" }}
                          type="checkbox"
                          name="checkBoxChoice"
                          value={allHours}
                          onClick={() => handleCheckboxChange(formik)}
                        />
                        <span style={{ marginLeft: ".5rem" }}>
                          24 Hours
                        </span>
                      </label>
                    </div>

                    {/* Weekdays */}
                    <div className="col-6 mb-7">
                      <FormikController
                        control="react_select"
                        label={getLabel("SELECT") + " " + getLabel("WEEKDAYS")}
                        labelClassName="fw-bold fs-6 mb-2"
                        name="weekdays"
                        className="form-control-solid mb-lg-0"
                        formik={formik}
                        options={dayList}
                        isMulti={true}
                        value={formik.values.weekdays}
                        onChange={formik.handleChange}
                        error={formik.errors.weekdays}
                      />
                    </div>
                  </div>
                </div>
                {/*end::Scroll*/}
                {/*begin::Actions*/}
                <div className="card-footer d-flex justify-content-center">
                  <button
                    className="btn btn-light-primary font-weight-bold"
                    style={{ marginRight: "1rem" }}
                    type="button"
                    onClick={() => {
                      navigate("/org/schedule-management")
                    }}
                  >
                    <span className="indicator-label">{getLabel("CANCEL")}</span>
                  </button>
                  <button
                    type="submit"
                    id="kt_password_reset_submit"
                    className="btn btn-lg btn-primary fw-bolder me-4"
                    disabled={formik.isSubmitting}
                  >
                    {loading ? (
                      <span className="indicator-progress" style={{ display: "block" }}>
                        {getLabel("PLEASE_WAIT")}
                        <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                      </span>
                    ) : (
                      <span className="indicator-label">{getLabel("SUBMIT")}</span>
                    )}
                  </button>
                </div>
                {/*end::Actions*/}
              </Form>
            )
          }}
        </Formik>
      </div>
    </>
  )
}

export default CreateEditDepartment
