/* eslint-disable react-hooks/exhaustive-deps */
import * as Yup from "yup"
import toast from "react-hot-toast"

import { Formik, Form } from "formik"
import { useLocation, useNavigate } from "react-router-dom"
import { useEffect, useState } from "react"
import { PageTitle } from "../../../_metronic/layout/core"
import AxiosMethod from "../../../_metronic/Api/AxiosMethod"
import FormikController from "../../../_metronic/Shared-Component-formik/FormikController"
import { getTempDept, getTempOrg } from "../../../_metronic/helpers/reuseable-fun/FunReuse"
import { TakeEditedVal } from "../../../_metronic/helpers/only-edit-value/TakeEditedVal"
import { getLabel } from "../../../_metronic/language-provider/LanProvider"
import { ValSchemaList } from "../../../_metronic/Shared-Component-formik/schema/ValSchemaList"

const CreateEditStaffMapping = () => {
  const [loading, setLoading] = useState(false)
  const usersBreadcrumbs = [
    {
      title: `${getLabel("STAFF")} ${getLabel("MAPPING")}`,
      path: "/org/staff-mapping",
      isSeparator: false,
      isActive: false,
    },
    {
      title: "",
      path: "",
      isSeparator: true,
      isActive: false,
    },
  ]
  const navigate = useNavigate()
  const location = useLocation()
  const [userList, setUserList] = useState([])
  const [deptList, setDeptList] = useState([])
  const [orgForDept, setOrgForDept] = useState(
    location?.state?.editObj?.organization_id ? location?.state?.editObj?.organization_id : null
  )
  const [staffList, setStaffList] = useState([])

  const initialValues = {
    organization_id: location?.state?.editObj?.organization_id
      ? location?.state?.editObj?.organization_id
      : "",
    group_id: location?.state?.editObj ? location?.state?.editObj?.group_id : "",
    staff_id: location?.state?.editObj ? location?.state?.editObj?.staff_id : "",
  }

  let validationSchema = Yup.object({
    organization_id: ValSchemaList(0, "ORG"),
    group_id: ValSchemaList(0, "SCHEDULE"),
    staff_id: ValSchemaList(0, "STAFF"),
  })
  const getDept = async () => {
    setDeptList(await getTempDept(orgForDept))
  }
  const getStaff = async () => {
    let tempArray = []
    let getClientRes = await AxiosMethod(`users/allStaffs`, `post`, {
      organizationId: orgForDept,
    })

    getClientRes?.data?.data?.rows?.map((usr) => {
      Object.assign(usr, { label: usr["user.full_name"], value: usr.user_id })
      tempArray.push(usr)
    })
    setStaffList(tempArray)
  }
  const getOrg = async () => {
    setUserList(await getTempOrg())
  }

  const onAddEditUser = async (values) => {
    setLoading(true)
    let temp = TakeEditedVal(values, initialValues)
    temp.is_primary = 0

    // !location?.state?.add &&  temp.organization_id =values.organization_id || location?.state?.editObj?.organization_id

    // !location?.state?.add &&
    //   bodyFormData.append(
    //     "group_id",
    //     values.group_id || location?.state?.editObj?.group_id
    //   )

    let createUsersRes = await AxiosMethod(
      location?.state?.add
        ? `userGroupOrganization`
        : `userGroupOrganization/${location?.state?.editObj.user_id}`,
      location?.state?.add ? `post` : `patch`,
      temp
    )
    if (createUsersRes && createUsersRes.status === 200) {
      setLoading(false)
      toast.success(getLabel("STAFF_MAP_SUCCESS"))
      navigate("/org/staff-mapping")
    }

    if (JSON.parse(createUsersRes.request.response).status === "error") {
      setLoading(false)
      toast.error(JSON.parse(createUsersRes.request.response).message)
    }
  }
  useEffect(() => {
    getOrg()
  }, [])
  useEffect(() => {
    getDept()
    getStaff()
  }, [orgForDept])

  return (
    <>
      <PageTitle breadcrumbs={usersBreadcrumbs}>
        {`${getLabel(location?.state?.add ? "ADD" : "EDIT")} ${getLabel("STAFF")} ${getLabel(
          "MAPPING"
        )}`}
      </PageTitle>

      <div className="card card-custom">
        <div className="card-header">
          <div className="card-title">
            <h3 className="card-label">
              {`${getLabel(location?.state?.add ? "ADD" : "EDIT")} ${getLabel("STAFF")} ${getLabel(
                "MAPPING"
              )}`}
            </h3>
          </div>
        </div>

        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onAddEditUser}
        >
          {(formik) => {
            formik.values.organization_id && setOrgForDept(formik.values.organization_id)
            return (
              <Form onSubmit={formik.handleSubmit} className="form">
                {/*begin::Scroll*/}
                <div className="card-body py-4">
                  <div
                    className="d-flex row me-n7 pe-7"
                    // className="d-flex row scroll-y me-n7 pe-7"
                    id="kt_modal_add_user_scroll"
                    data-kt-scroll="true"
                    data-kt-scroll-activate="{default: false, lg: true}"
                    // data-kt-scroll-max-height="auto"
                    data-kt-scroll-dependencies="#kt_modal_add_user_header"
                    data-kt-scroll-wrappers="#kt_modal_add_user_scroll"
                  // data-kt-scroll-offset="300px"
                  >
                    <div className="col-6 mb-7">
                      <FormikController
                        control="react_select"
                        label={getLabel("ORG")}
                        labelClassName="required fw-bold fs-6 mb-2"
                        name="organization_id"
                        className="form-control-solid mb-lg-0"
                        formik={formik}
                        options={userList}
                        value={formik.values.organization_id}
                        onChange={formik.handleChange}
                        error={formik.errors.organization_id}
                        isDisabled={!location?.state?.add}
                      />
                    </div>
                    {/* simple react dept */}
                    <div className="col-6 mb-7">
                      <FormikController
                        control="select"
                        label={getLabel("SCHEDULE")}
                        labelClassName="required fw-bold fs-6 mb-2"
                        name="group_id"
                        className="form-select form-select-solid"
                        formik={formik}
                        options={deptList}
                        value={formik.values.group_id}
                        onChange={formik.handleChange}
                        error={formik.errors.group_id}
                        disabled={!location?.state?.add}
                      />
                    </div>
                    {/* stafflist */}
                    <div className="col-6 mb-7">
                      <FormikController
                        control="react_select"
                        label={getLabel("STAFF")}
                        labelClassName="required fw-bold fs-6 mb-2"
                        name="staff_id"
                        className="form-control-solid mb-lg-0"
                        formik={formik}
                        options={staffList}
                        value={formik.values.staff_id}
                        onChange={formik.handleChange}
                        error={formik.errors.staff_id}
                        isDisabled={!location?.state?.add}
                      />
                    </div>
                  </div>
                </div>
                {/*end::Scroll*/}
                {/*begin::Actions*/}
                <div className="card-footer d-flex justify-content-center">
                  <button
                    className="btn btn-light-primary font-weight-bold"
                    style={{ marginRight: "1rem" }}
                    type="button"
                    onClick={() => {
                      navigate("/org/staff-mapping")
                    }}
                  >
                    <span className="indicator-label">{getLabel("CANCEL")}</span>
                  </button>
                  <button
                    type="submit"
                    id="kt_password_reset_submit"
                    className="btn btn-lg btn-primary fw-bolder me-4"
                    disabled={formik.isSubmitting || !formik.isValid}
                  >
                    {loading ? (
                      <span className="indicator-progress" style={{ display: "block" }}>
                        {getLabel("PLEASE_WAIT")}
                        <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                      </span>
                    ) : (
                      <span className="indicator-label">{getLabel("SUBMIT")}</span>
                    )}
                  </button>
                </div>
                {/*end::Actions*/}
              </Form>
            )
          }}
        </Formik>
      </div>
    </>
  )
}

export default CreateEditStaffMapping
