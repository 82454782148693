import {useEffect, useState} from "react"
import {useDispatch, useSelector} from "react-redux"
import AxiosMethod from "../../../_metronic/Api/AxiosMethod"
import TableHeaderToolbar from "../../../_metronic/helpers/table-header-helper/TableHeaderToolbar"
import {PageTitle} from "../../../_metronic/layout/core"
import QrCodeRows from "./QrCodeRows"
import toast from "react-hot-toast"

import Pagination from "../../../_metronic/helpers/pagination-helper/Pagination"
import {setQrParams} from "../../../_metronic/redux/actions/QueryParamsAction"
import {getLabel} from "../../../_metronic/language-provider/LanProvider"
import {SetLoader} from "../../../_metronic/redux/actions/LoaderAction"
import SortingHelper from "../../../_metronic/helpers/sorting-helper/SortingHelper"
import DepartmentBuildingFilter from "../department-management/DepartmentBuildingFilter"

const QrCodeList = () => {
  const loggedInUser = useSelector((state) => state?.AuthReducer?.userData)
  const dispatch = useDispatch()
  const [qrCode, setQrCode] = useState()
  const [notFound, setNotFound] = useState("")

  const currentParams = useSelector((state) => state?.QueryParamsReducer?.qrParams)
  const [params, setparams] = useState(currentParams)

  // get Admin
  const getQrCode = async (search, limit, page, sortBy, sort, filterKey, filterValue) => {
    dispatch(SetLoader(true))
    let getUsersRes = await AxiosMethod(
      loggedInUser["plan.plan_type"] === "individual"
        ? `qrcodes/individualQrcodes?search=${search}&limit=${limit}&page=${page}&sortwith=${sortBy}&sortby=${sort}&role=1&filterKey=${
            String(filterKey) || ""
          }&filterValue=${String(filterValue) || ""}`
        : `qrcodes?search=${search}&limit=${limit}&page=${page}&sortwith=${sortBy}&sortby=${sort}&role=1&filterKey=${
            String(filterKey) || ""
          }&filterValue=${String(filterValue) || ""}`,
      `get`
    )

    let values = {
      search: search,
      limit: limit,
      page: page,
      sortBy: sortBy,
      sort: sort,
      filterKey: filterKey,
      filterValue: filterValue,
    }

    setparams(values)
    dispatch(setQrParams(values))
    if (getUsersRes && getUsersRes.status === 200) {
      dispatch(SetLoader(false))
      setNotFound(false)
      setQrCode(getUsersRes?.data?.data)
    }
    if (JSON.parse(getUsersRes.request.response).status === "error") {
      dispatch(SetLoader(false))
      // toast.error(JSON.parse(getUsersRes.request.response).message)
      setNotFound(getLabel("QR_NOT_FOUND"))
    }
  }

  const deleteQr = async (qrcode) => {
    let deleteUsersRes = await AxiosMethod(`qrcodes/${qrcode}`, `delete`)
    if (deleteUsersRes.status === 200) {
      toast.success(getLabel("QR_DELETED"))
      getQrCode(
        params.search,
        params.limit,
        qrCode.rows.length <= 1 ? params.page - 1 : params.page,
        params.sortBy,
        params.sort,
        params.filterKey || "",
        params.filterValue || ""
      )
    } else {
      toast.error(JSON.parse(deleteUsersRes.request.response).message)
    }
  }

  useEffect(() => {
    getQrCode(
      params.search,
      params.limit,
      params?.page || 1,
      params?.sortBy,
      params?.sort,
      params?.filterKey || "",
      params?.filterValue || ""
    )
  }, [])

  return (
    <>
      <PageTitle>{getLabel("QR_CODES_LIST")}</PageTitle>

      <div className="card">
        {/* Begin:: Card Header */}
        <TableHeaderToolbar
          params={params}
          setparams={setparams}
          // action={getAdmin}
          filterBody={<DepartmentBuildingFilter params={params} action={getQrCode} />}
          showSearch={false}
          showExportBtn={false}
          // csvModelName="user"
          // csvNameLabel={csvNameLabel}
          // filename="Admin"
          showAddButton={true}
          addButton={`${getLabel("GENERATE")} ${getLabel("QR_CODE")}`}
          path="/qr-code-management/manage-qr-code"
        />
        {/* End:: Card Header */}
        {/*begin::Card body*/}
        <div className="card-body py-4">
          {/*begin::Table*/}
          <div id="kt_table_users_wrapper" className="dataTables_wrapper dt-bootstrap4 no-footer">
            <div className="table-responsive">
              <table
                className="table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer"
                id="kt_table_users"
              >
                {/*begin::Table head*/}
                <thead>
                  {/*begin::Table row*/}
                  <tr className="text-start text-muted fw-bolder fs-7 text-uppercase gs-0">
                    <th
                      className="min-w-60px sorting sorting_asc"
                      tabIndex={0}
                      aria-controls="kt_table_users"
                      rowSpan={1}
                      colSpan={1}
                      aria-label="User: activate to sort column ascending"
                      style={{width: "60.678px", cursor: "pointer"}}
                    >
                      {`${getLabel("QR_CODE")}`}
                    </th>
                    <th
                      className="min-w-125px sorting sorting_asc"
                      tabIndex={0}
                      aria-controls="kt_table_users"
                      rowSpan={1}
                      colSpan={1}
                      aria-label="User: activate to sort column ascending"
                      style={{width: "188.678px", cursor: "pointer"}}
                      onClick={() =>
                        getQrCode(
                          params.search,
                          params.limit,
                          params.page,
                          "qr_name",
                          params.sort === 0 ? 1 : 0,
                          params?.filterKey || "",
                          params?.filterValue || ""
                        )
                      }
                    >
                      {getLabel("NAME")}
                      {SortingHelper(params.sort, params.sortBy, "qr_name")}
                    </th>
                    {loggedInUser["plan.plan_type"] !== "individual" && (
                      <>
                        <th
                          className="min-w-120px sorting"
                          tabIndex={0}
                          aria-controls="kt_table_users"
                          rowSpan={1}
                          colSpan={1}
                          aria-label="Role: activate to sort column ascending"
                          style={{width: "155.678px", cursor: "pointer"}}
                          onClick={() =>
                            getQrCode(
                              params.search,
                              params.limit,
                              params.page,
                              "user.full_name",
                              params.sort === 0 ? 1 : 0,
                              params?.filterKey || "",
                              params?.filterValue || ""
                            )
                          }
                        >
                          {getLabel("ORG")}
                          {SortingHelper(params.sort, params.sortBy, "user.full_name")}
                        </th>
                        <th
                          className="min-w-110px sorting"
                          tabIndex={0}
                          aria-controls="kt_table_users"
                          rowSpan={1}
                          colSpan={1}
                          aria-label="Role: activate to sort column ascending"
                          style={{cursor: "pointer"}}
                          onClick={() =>
                            getQrCode(
                              params.search,
                              params.limit,
                              params.page,
                              "default_staff_id",
                              params.sort === 0 ? 1 : 0,
                              params?.filterKey || "",
                              params?.filterValue || ""
                            )
                          }
                        >
                          {getLabel("DEFAULT") + " " + getLabel("STAFF")}
                          {SortingHelper(params.sort, params.sortBy, "default_staff_id")}
                        </th>
                        <th
                          className="min-w-125px sorting"
                          tabIndex={0}
                          aria-controls="kt_table_users"
                          rowSpan={1}
                          colSpan={1}
                          aria-label="Role: activate to sort column ascending"
                        >
                          {getLabel("SCHEDULE")}
                        </th>
                      </>
                    )}

                    {/* <th
                      className="min-w-125px sorting"
                      tabIndex={0}
                      aria-controls="kt_table_users"
                      rowSpan={1}
                      colSpan={1}
                      aria-label="Role: activate to sort column ascending"
                    >
                      {getLabel("STAFF")}
                    </th> */}
                    <th
                      className="min-w-88px sorting"
                      tabIndex={0}
                      aria-controls="kt_table_users"
                      rowSpan={1}
                      colSpan={1}
                      aria-label="Role: activate to sort column ascending"
                      style={{width: "88.678px", cursor: "pointer"}}
                      onClick={() =>
                        getQrCode(
                          params.search,
                          params.limit,
                          params.page,
                          "type",
                          params.sort === 0 ? 1 : 0,
                          params?.filterKey || "",
                          params?.filterValue || ""
                        )
                      }
                    >
                      {getLabel("TYPE")}
                      {SortingHelper(params.sort, params.sortBy, "type")}
                    </th>
                    <th
                      className="min-w-115px sorting"
                      tabIndex={0}
                      aria-controls="kt_table_users"
                      rowSpan={1}
                      colSpan={1}
                      aria-label="Role: activate to sort column ascending"
                      style={{width: "108.678px", cursor: "pointer"}}
                      onClick={() =>
                        getQrCode(
                          params.search,
                          params.limit,
                          params.page,
                          "qr_start_time",
                          params.sort === 0 ? 1 : 0,
                          params?.filterKey || "",
                          params?.filterValue || ""
                        )
                      }
                    >
                      {getLabel("VALID_FROM")}
                      {SortingHelper(params.sort, params.sortBy, "qr_start_time")}
                    </th>
                    <th
                      className="min-w-110px sorting"
                      tabIndex={0}
                      aria-controls="kt_table_users"
                      rowSpan={1}
                      colSpan={1}
                      aria-label="Role: activate to sort column ascending"
                      style={{cursor: "pointer"}}
                      onClick={() =>
                        getQrCode(
                          params.search,
                          params.limit,
                          params.page,
                          "qr_end_time",
                          params.sort === 0 ? 1 : 0,
                          params?.filterKey || "",
                          params?.filterValue || ""
                        )
                      }
                    >
                      {getLabel("EXP_TIME")}
                      {SortingHelper(params.sort, params.sortBy, "qr_end_time")}
                    </th>
                    <th
                      className="min-w-60px sorting"
                      tabIndex={0}
                      aria-controls="kt_table_users"
                      rowSpan={1}
                      colSpan={1}
                      aria-label="Role: activate to sort column ascending"
                      style={{width: "60.678px !important", cursor: "pointer"}}
                      onClick={() =>
                        getQrCode(
                          params.search,
                          params.limit,
                          params.page,
                          "status",
                          params.sort === 0 ? 1 : 0,
                          params?.filterKey || "",
                          params?.filterValue || ""
                        )
                      }
                    >
                      {getLabel("STATUS")}
                      {SortingHelper(params.sort, params.sortBy, "status")}
                    </th>
                    <th
                      className="text-end min-w-100px sorting_disabled"
                      rowSpan={1}
                      colSpan={1}
                      aria-label="Actions"
                      style={{width: "128.946px"}}
                    >
                      {getLabel("ACTIONS")}
                    </th>
                  </tr>
                  {/*end::Table row*/}
                </thead>
                {/*end::Table head*/}
                {/*begin::Table body*/}
                <tbody className="text-gray-600 fw-bold">
                  {notFound ? (
                    <tr>
                      <td colSpan={10}>
                        <div className="d-flex text-center w-100 align-content-center justify-content-center text-uppercase">
                          {notFound}
                        </div>
                      </td>
                    </tr>
                  ) : (
                    qrCode?.rows &&
                    qrCode?.rows?.map((qrCode, i) => {
                      return <QrCodeRows key={i} qrCode={qrCode} deleteQr={deleteQr} />
                    })
                  )}
                </tbody>
                {/*end::Table body*/}
              </table>
            </div>

            {!notFound && (
              <Pagination
                params={params}
                setparams={setparams}
                count={qrCode?.count}
                pageData={qrCode?.rows?.length}
                action={getQrCode}
              />
            )}
          </div>
          {/*end::Table*/}
        </div>
        {/*end::Card body*/}
      </div>
    </>
  )
}

export default QrCodeList
