import "./style/userscan.scss"
import logo from "./images/logo.svg"
import InvalidImage from "./images/invalid-qr.svg"
const InvalidQr = () => {
  return (
    <>
      <div className="main-wrap">
        <div className="container">
          <div className="row">
            <div className="col-xxl-12 m-auto text-center after-call-screen thankscall-wrap">
              <img src={InvalidImage} alt="" className="image_call" />
              <div className="text-center thanks_text">
                <img src={logo} alt="" />
                <h2 className="mt-5 pt-5">
                  Invalid QR<span className="d-block"> Code</span>
                </h2>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default InvalidQr
