import {ActionTypes} from "../constant/ActionType"

const defaultParams = {
	search: "",
	limit: 10,
	page: 1,
	sortBy: "",
	sort: 0,
	filterKey: [],
	filterValue: [],
}
const initialstate = {
	langParams: {
		search: "",
		limit: 10,
		page: 1,
	},
	valParams: {
		search: "",
		limit: 10,
		page: 1,
	},
	adminParams: {...defaultParams},
	clientParams: {...defaultParams},
	staffParams: {...defaultParams},
	deptParams: {...defaultParams},
	orgParams: {...defaultParams},
	orgManagerParams: {...defaultParams},
	qrParams: {...defaultParams},
	orgMappingParams: {...defaultParams},
	callDetailParams:{...defaultParams},
	connectionParams: {...defaultParams},
}

const QueryParamsReducer = (state = initialstate, {type, payload}) =>
{
	switch (type)
	{
		case ActionTypes.SET_LANG_PARAMS:
			return {
				...state,
				langParams: payload,
			}
		case ActionTypes.SET_VAL_PARAMS:
			return {
			    ...state,
			    valParams: payload,
			}
		case ActionTypes.SET_ADMIN_PARAMS:
			return {
				...state,
				adminParams: payload,
			}
		case ActionTypes.SET_CLIENT_PARAMS:
			return {
				...state,
				clientParams: payload,
			}
		case ActionTypes.SET_STAFF_PARAMS:
			return {
				...state,
				staffParams: payload,
			}
		case ActionTypes.SET_DEPT_PARAMS:
			return {
				...state,
				deptParams: payload,
			}
		case ActionTypes.SET_ORG_PARAMS:
			return {
				...state,
				orgParams: payload,
			}
		case ActionTypes.SET_ORG_MANAGER_PARAMS:
			return {
				...state,
				orgManagerParams: payload,
			}
		case ActionTypes.SET_QR_PARAMS:
			return {
				...state,
				qrParams: payload,
			}
		case ActionTypes.SET_ORG_MAPPING_PARAMS:
			return {
				...state,
				orgMappingParams: payload,
			}
		case ActionTypes.SET_CALL_DETAIL_PARAMS:
			return {
				...state,
				callDetailParams: payload,
			}
		case ActionTypes.SET_CONNECTIONS_DETAIL_PARAMS:
			return {
				...state,
				connectionParams: payload,
			}
		default:
	        return state
	}
}

export default QueryParamsReducer
