import {useEffect, useState} from "react"
import AxiosMethod from "../../../_metronic/Api/AxiosMethod"
import {getTempCountries, getTempOrg} from "../../../_metronic/helpers/reuseable-fun/FunReuse"
import {getLabel} from "../../../_metronic/language-provider/LanProvider"
import FilterSvg from "./../../../_metronic/helpers/svg-helper/FilterSvg"

const AdminBuildingFilter = ({params, action, filterOptions= ["country"] }) =>
{
    const [countryList, setCountryList] = useState([])
    const [organizationList, setOrganizationList] = useState([])
	const [stateList, setStateList] = useState([])
    const [keyVal, setkeyVal] = useState({
        filterKeys: [params.filterKey],
        filterValues: [params.filterValue],
    })
	
	// GET THE ALL COUNTRIES LIST
	const getCountries = async () =>
	{
		setCountryList (await getTempCountries())
	}
	
	// GET THE ALL STATES LIST ON AGAINST COUNTRY.
    const getState = async (id) =>
    {
        let tempArray = []
        setStateList([])
        if (typeof id !== "object")
		{
            let getClientRes = await AxiosMethod(`countries/states/${id}`, `get`)
            getClientRes?.data?.data?.rows?.map((state) =>
            {
                Object.assign(state, {label: state.name, value: state.state_id})
                tempArray.push(state)
                return tempArray;
            })
            setStateList(tempArray)
        }
    }
	
	// GET THE ALL ORGANIZATION LIST
	const getOrganizationList = async () =>
	{
		let listData  =  await getTempOrg();
		console.log ("Organition list", listData);
		setOrganizationList (listData);
	}
  
  const checkReset = () => {
    if (
      JSON.stringify(keyVal.filterKeys) !== JSON.stringify(params.filterKey) ||
      JSON.stringify(keyVal.filterValues) !== JSON.stringify(params.filterValue)
    ) {
      return true
    } else {
      return false
    }
  }
  const reset = (flag) => {
    if (flag) {
      setStateList([])
      setkeyVal({
        filterKeys: [],
        filterValues: [],
      })
      action(params.search, params.limit, 1, params.sortBy, params.sort, [], [])
    } else {
      setkeyVal({
        filterKeys: params.filterKey,
        filterValues: params.filterValue,
      })
      if (!params.filterValue[0]) {
        setStateList([])
      }
    }
  }
  const applyFilter = () => {
    action(
      params.search,
      params.limit,
      1,
      params.sortBy,
      params.sort,
      keyVal.filterKeys,
      keyVal.filterValues
    )
  }
  
  console.log (keyVal);
	useEffect(() =>
	{
	    getCountries();
	    getOrganizationList();
	}, [])
	
	useEffect(() =>
	{
        keyVal.filterValues[0] && getState(keyVal.filterValues[0])
    }, [keyVal.filterValues])

	// GET VALUE IN FILTER OBJECT BY INDEX.
	const getValueFilter = (name) =>
	{
		let value = ""; // HOLD THE VALUE OF FILTER KEY
		
		// GET INDEX NUMBER IN FILTER OBJECT
		let index = keyVal.filterKeys.indexOf (name);
		if (index > -1)
		{
			value = keyVal.filterValues[index];
		}
		return value;
	}
	
	// ON CHANGE VALUES OF FILTER OBJECT
	const handleChangeFilter = (key, value) =>
	{
		// CHECK THE IF SELECTED KEY IS ALREADY IN FILTERKEYS IN KEYVAL OBJECT.
		const keyIndex = keyVal.filterKeys.indexOf (key);
		if (keyIndex !== -1)
		{
			// IF THE KEY IS ALREADY IN FILTER-KEY, THEN UPDATE ITS CORRESPONDING VALUE.
			const updatedKeys = [...keyVal.filterKeys];
			const updatedValues = [...keyVal.filterValues];
			
			if (value !== null && typeof value !== "undefined")
			{
				updatedValues [keyIndex] = value;
				setkeyVal({
					...keyVal,
					filterValues: updatedValues,
				});
			} else
			{
				// IF VALUE IS NULL, REMOVE THE KEY AND VALUE FROM THE ARRAYS.
				updatedKeys.splice (keyIndex, 1);
				updatedValues.splice (keyIndex, 1);
				
				setkeyVal({
					...keyVal,
					filterKeys: updatedKeys,
					filterValues: updatedValues,
				});
				
				console.log ("value null",key,  value)
			}
		}
		else // IF THE KEY IS NOT IN FILER-KEYS IN KEYVAL, THEN ADD BOTH THE KEY AND VALUE IN KEYVAL OBJECT.
		{
			setkeyVal(
				{
					...keyVal,
					filterKeys: [...keyVal.filterKeys, key],
					filterValues: [...keyVal.filterValues, value],
				}
			);
		}
	}
	
	// RENDER THE COMPONENT.
    return (
		<>
			<button
			type="button"
			className="btn btn-light-primary me-3 d-flex"
			onClick={() => reset()}
			data-kt-menu-trigger="click"
			data-kt-menu-placement="bottom-end"
			>
				<span className="svg-icon svg-icon-2">
				  <FilterSvg />
				</span>
				{getLabel("FILTER")}
				
				{
					(params.filterKey?.length > 0) &&
					<div className="badge badge-pill badge-danger"  style={{marginLeft:10}}>
						{ params.filterKey?.length}
					</div>
				}
			</button>

			<div
				className="menu menu-sub menu-sub-dropdown w-300px w-md-325px"
				data-kt-menu="true"
				style={{}}
			>
		        <div className="px-7 py-5">
		          <div className="fs-5 text-dark fw-bolder">{getLabel("FILTER_OPTIONS")}</div>
		        </div>
	            <div className="separator border-gray-200"></div>
	            <div className="px-7 py-5" data-kt-user-table-filter="form">
		           
					{/* COUNTRY FILTER */}
		            {
			            (filterOptions || []).indexOf("country") > -1 && countryList?.length > 0 && (
						<div className="mb-10">
							<label className="form-label fs-6 fw-bold">{getLabel("COUNTRY")} :</label>
							<select
								  className="form-select form-select-solid fw-bolder cursor-pointer"
								  value={getValueFilter ("country_id")}
								  onChange={(e) =>
								  {
									  handleChangeFilter ("country_id", e.target?.value);
									  
									  // GET THE STATE OF SELECTED COUNTRY.
									  getState(e.target.value)
								  }}
							>
							  <option value="">{getLabel("ALL")}</option>
							  {countryList.map((option) => {
							    return (
							      <option key={option.value} value={option.value}>
							        {option.name}
							      </option>
							    )
							  })}
							</select>
						</div>
		            )}
		            
		            {/* STATE FILTER */}
		            {keyVal.filterValues[0] && (keyVal.filterKeys.indexOf("country_id") > -1 ) && stateList.length > 0 && (
			            <div className="mb-10">
				            []  <label className="form-label fs-6 fw-bold">{getLabel ("STATE")} :</label>
				            <select
					            className="form-select form-select-solid fw-bolder cursor-pointer"
					            value={getValueFilter ("state_id")}
					            disabled={stateList.length <= 0}
					            onChange={(e) =>
					            {
						            handleChangeFilter ("state_id", e.target?.value);
					            }}
				            >
					            <option value="">{getLabel("ALL")}</option>
					            {
									stateList.map((option) =>
					                {
						                return (
							                <option key={option.value} value={option.value}>
								                {option.name}
							                </option>
						                )
					                })
								}
				            </select>
			            </div>
		            )}
		            
		            {/* ORGANIZATION FILTER */}
		            {
			            (filterOptions || []).indexOf ("organization") > -1 && organizationList?.length > 0 && (
				            <div className="mb-10">
					            <label className="form-label fs-6 fw-bold">{getLabel("ORG")} :</label>
					            <select
						            className="form-select form-select-solid fw-bolder cursor-pointer"
						            value={getValueFilter ("organization_id")}
						            onChange={(e) =>
						            {
							            handleChangeFilter ("organization_id", e.target?.value);
						            }}
					            >
						            <option value="">{getLabel("ALL")}</option>
						            {
										organizationList.map((option) =>
										{
								            return (
									            <option key={option.value} value={option.value}>
										            {option?.label}
									            </option>
								            )
						                })
									}
					            </select>
				            </div>
			            )
					}
					
	
					{/* reset apply buttons */}
					<div className="d-flex justify-content-end">
					{(checkReset() || (keyVal.filterKeys.length > 0 && keyVal.filterKeys[0] !== "")) && (
					  <button
					    type="button"
					    className="btn btn-light btn-active-light-primary fw-bold me-2 px-6"
					    data-kt-menu-dismiss="true"
					    data-kt-user-table-filter="reset"
					    onClick={() => reset(1)}
					  >
					    {getLabel("RESET")}
					  </button>
					)}
					<button
					  type="button"
					  className="btn btn-primary fw-bold px-6"
					  data-kt-menu-dismiss="true"
					  data-kt-user-table-filter="filter"
					  disabled={!checkReset()}
					  onClick={() => applyFilter()}
					>
					  {getLabel("APPLY")}
					</button>
					</div>
                </div>
	        </div>
	    </>
    )
}

export default AdminBuildingFilter
