import Spinner from "react-bootstrap/Spinner"

const LoaderSpinner = () => {
  const styles = {
    display: "flex",
    alignItems: "center",
    backgroundColor: "#f2f2f2",
    zIndex: 100,
    boxShadow: "0 0 50px 0 rgb(82 63 105 / 15%)",
    borderRadius: "10px",
    color: "#7e8299",
    fontWeight: "500",
    margin: "0",
    width: "auto",
    padding: "1rem 2rem",
    top: "calc(50% - 2rem)",
    left: "calc(50%)",
    fontColor: "#1e1e2d",
  }
  return (
    <div style={{...styles, position: "absolute"}}>
      <span className="ms-2 fs-3">
        Loading
        <Spinner
          animation="grow"
          size="sm"
          style={{width: ".3rem", height: ".3rem"}}
          className="ms-1"
        />
        <Spinner
          animation="grow"
          size="sm"
          style={{width: ".3rem", height: ".3rem"}}
          className="ms-1"
        />
        <Spinner
          animation="grow"
          size="sm"
          style={{width: ".3rem", height: ".3rem"}}
          className="ms-1"
        />
      </span>
      {/* <Spinner animation="grow" size="sm" style={{width: "1.5rem", height: "1.5rem"}} /> */}
    </div>
  )
}

export default LoaderSpinner
