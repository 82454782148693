import {useEffect} from "react"
import Video from "./Video"
import VideoState from "./VideoState"
import VideoCallView from "./videocall.view";

const Home = () =>
{
  useEffect(() => {
    if (!navigator.onLine) alert("Connect to internet!")
  }, [navigator])

  // dont allow to do back
  useEffect(() => {
    window.history.pushState(null, null, null)
    window.onpopstate = function () {
      window.history.go(1)
    }
  }, [])

  // dont allow to scroll down refresh
  useEffect(() => {
    // 👇️ set style on body element
    document.body.style.overscrollBehaviorY = "contain"

    return () => {
      // 👇️ optionally remove styles when component unmounts
      document.body.style.overscrollBehaviorY = null
    }
  }, [])

  useEffect(() => {
    var cookies = document.cookie.split(";")

    for (var i = 0; i < cookies.length; i++) {
      var cookie = cookies[i]
      var eqPos = cookie.indexOf("=")
      var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie
      document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT"
    }
  }, [])

  const handleBeforeUnload = (e) =>
  {
    let event = e || window.event
    event.preventDefault()
    event.returnValue = ""

    // if (sessionStorage.getItem("call")) {
    //   sessionStorage.clear()
    //   navigate("/thanks-call")
    // } else {
    //   sessionStorage.setItem("call", true)
    // }
    return ""
  }

  useEffect(() => {
    window.addEventListener("beforeunload", handleBeforeUnload)
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload)
    }
  }, [])

  return (
    <VideoState isCallStatus={"OUT"}>
		<Video />
		{/*	<VideoCallView />*/}
		{/*</Video>*/}
		{/* only needed for demo */}
		{/* <Options /> */}
		<input type="text" value="DON'T REFRESH" className="d-none" />
    </VideoState>
  )
}

export default Home
