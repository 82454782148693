import {useEffect, useState} from "react"
import {useDispatch, useSelector} from "react-redux"
import AxiosMethod from "../../../_metronic/Api/AxiosMethod"
import {getLabel} from "../../../_metronic/language-provider/LanProvider"
import {SetLoader} from "../../../_metronic/redux/actions/LoaderAction"
import {setConnectionsDetailsParams} from "../../../_metronic/redux/actions/QueryParamsAction"
import {PageTitle} from "../../../_metronic/layout/core"
import TableHeaderToolbar from "../../../_metronic/helpers/table-header-helper/TableHeaderToolbar"
import SortingHelper from "../../../_metronic/helpers/sorting-helper/SortingHelper"
import ConnectionsRows from "./ConnectionsRows"
import Pagination from "../../../_metronic/helpers/pagination-helper/Pagination"
import AdminBuildingFilter from "../qr-admin-management/AdminBuildingFilter";
import {capitalize} from "../../services/helper.service";
import moment from "moment";

const ConnectionsList = () =>
{
	// DECLARE THE VARIABLES AND STATES AND PROPS AND NAVIGATION.
	const dispatch = useDispatch()
	const [connections, setConnections] = useState()
	const [notFound, setNotFound] = useState("")
	const currentParams = useSelector((state) => state?.QueryParamsReducer?.connectionParams);
	const [params, setparams] = useState(currentParams)
	const [tableLabel, setTableLabel] = useState([])
	
	// DEFINE THE TABLE HEADER COLUMNS.
	const tableHeader = [
		{ label: getLabel("USER"), key: "full_name", column: "user_name", styleClass: "min-w-125px sorting"},
		{ label: getLabel("DATE"), key: "date", column: "date", styleClass: "min-w-125px sorting"},
		{ label: getLabel("CONNECTION_LOGIN_TIME"), key: "login_connection.date", column: "login_connection_date", styleClass: "min-w-125px sorting"},
		{ label: getLabel("CONNECTION_LOGOUT_TIME"), key: "logout_connection.date", column: "logout_connection_date", styleClass: "min-w-125px sorting"},
		// { label: getLabel("ACTIONS"), key: "action", hidePrint:true, styleClass: "text-end min-w-100px sorting_disabled"},
    ];
	
	// ERROR FOUND TEMPLATE.
	const errorFoundTemplate =() =>
	{
		return (
			<tr>
				<td colSpan={tableHeader.length + 1}>
					<div className="d-flex text-center w-100 align-content-center justify-content-center text-uppercase">
						{notFound}
					</div>
				</td>
			</tr>
		);
	};
	
	// GET THE CONNECTION DETAIL LIST FROM DATABASE.
	const getConnectionDetail = async (search, limit, page, sortBy, sort, filterKey, filterValue) =>
	{
		// SET THE LOADER
		dispatch(SetLoader(true));
		let query = `connections/details?search=${search}&limit=${limit}&page=${page}&sortwith=${sortBy}&sortby=${sort}&role=3&filterKey=${
			String(filterKey) || "" }&filterValue=${String(filterValue) || ""}`;
		
		// GET REQUEST FROM SERVER.
		const reposnse = await AxiosMethod (query, `get`);
		console.log ("reposnse Connections log ", reposnse.data, reposnse.request.response);
		
		// THE PARAMS VALUES.
		let values =
			{
				search: search,
				limit: limit,
				page: page,
				sortBy: sortBy,
				sort: sort,
				filterKey: filterKey,
				filterValue: filterValue,
			}
		
		// SET THE PARAMS STATE LOCALLY.
		setparams (values);
		
		// DISPATCH THE CONNECTION DETAIL PARAMS.
		dispatch (setConnectionsDetailsParams (values));
		
		// CHECK THE RESPONSE STATUS IF SUCEESS THEN SET THE STATE.
		if (reposnse && reposnse.status === 200)
		{
			// SET THE NOT ERROR FOUND STATE.
			setNotFound (false);
			
			// SET THE CONNECTIONS STATE.
			setConnections (reposnse.data.data);
			
			// COLUMN NAME TO EXPORT
			let csvNameLabel = []
			tableHeader.map ((el) =>
			{
				// if (!el.key.includes("."))
				// {
					let obj =
					{
						name: el.column,
						label: capitalize (el.label),
					}
					csvNameLabel.push (obj)
				// }
			})
			setTableLabel (csvNameLabel);
		}
		
		// SERVER RESPONSE STATUS ERROR THE SET THE ERROR FOUND STATE.
		if (JSON.parse (reposnse.request.response).status === "error")
		{
			// SET THE ERROR STATE.
			setNotFound (JSON.parse(reposnse.request.response).message)
		}
		
		// STOP THE LOADER.
		dispatch (SetLoader(false));
	}
	
	// MOUNT THE INITIALIZED THE FUNCTION.
	useEffect(() =>
	{
		getConnectionDetail (
			params.search,
			params.limit,
			connections?.rows?.length <= 1 && params.page > 1 ? params.page - 1 : params.page,
			"date",
			1,
			params.filterKey || "",
			params.filterValue || ""
		)
	}, []);
	
	// RENDER THE PAGE.
    return (
    <>
		<PageTitle>{getLabel("CONNECTION_DETAILS_LIST")}</PageTitle>

        <div className="card">
      
		    {/* CARD HEADER TABLE TOOLBAR */}
	        <TableHeaderToolbar
				params={params}
				setparams={setparams}
				filterBody={<AdminBuildingFilter params={params} action={getConnectionDetail} filterOptions={["organization"]} />}
				showSearch={true}
		        action={getConnectionDetail}
				showPrintBtn={true}
		        showExportBtn={true}
		        filename={`connectiondetail_${new Date().toUTCString()}.csv`}
		        csvNameLabel={tableLabel}
		        exportData={connections?.rows}
		        csvTableName={'connections'}
		        exportType={"S"} // S = FROM SERVER , L = LOCAL VIEW DATA EXPORT.
	        />
	        
	        {/* CARD BODY */}
			<div className="card-body py-4">
                <div id="kt_table_users_wrapper" className="dataTables_wrapper dt-bootstrap4 no-footer">
                    <div className="table-responsive">
						<table
							className="table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer"
							id="kt_table_users"
						>
                            <thead>
                                <tr className="text-start text-muted fw-bolder fs-7 text-uppercase gs-0">
	                                {
										tableHeader && tableHeader.map ((row, index) =>
										{
										  return (
											  <th
												  key={index}
												  className={`${row.styleClass}`}
												  tabIndex={index}
												  aria-controls="kt_table_users"
												  rowSpan={1}
												  colSpan={1}
												  aria-label={`${row.label}: activate to sort column ascending`}
												  style={{cursor: "pointer"}}
												  onClick={() =>
													  getConnectionDetail (
														  params.search,
														  params.limit,
														  params.page,
														  `${row.key}`,
														  params.sort === 0 ? 1 : 0,
														  params?.filterKey || "",
														  params?.filterValue || ""
													  )
												  }
											  >
												  {row.label}
												  {SortingHelper(params.sort, params.sortBy, `${row.key}`)}
											  </th>
										  )
										})
									}
				                    <th
				                        className="text-end min-w-100px sorting_disabled d-print-none"
										tabIndex={tableHeader.length + 1}
				                        rowSpan={1}
				                        colSpan={1}
				                        aria-label="Actions"
				                        style={{width: "128.946px"}}
				                    >
				                        {getLabel("ACTIONS")}
				                    </th>
								</tr>
                            </thead>
                            <tbody className="text-gray-600 fw-bold">
                            {
					            notFound
						        ?
						            errorFoundTemplate()
						        :
						            (
					                    connections?.rows &&
					                    connections?.rows?.map((connection, i) => (
					                        <ConnectionsRows key={i} connection={connection} />
					                    ))
                                    )
							}
                            </tbody>
                        </table>
                    </div>
	                {
						!notFound &&
		                <Pagination
			                params={params}
			                setparams={setparams}
			                count={connections?.count}
			                pageData={connections?.rows?.length}
			                action={getConnectionDetail}
		                />
					}
                </div>
            </div>
            {/* END::CARD BODY */}
        </div>
    </>
  )
}

export default ConnectionsList
