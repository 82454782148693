import {ActionTypes} from "../constant/ActionType"

export const setLangParams = (params) => {
  return {
    type: ActionTypes.SET_LANG_PARAMS,
    payload: params,
  }
}
export const setValParams = (params) => {
  return {
    type: ActionTypes.SET_VAL_PARAMS,
    payload: params,
  }
}
export const setAdminParams = (params) => {
  return {
    type: ActionTypes.SET_ADMIN_PARAMS,
    payload: params,
  }
}
export const setClientParams = (params) => {
  return {
    type: ActionTypes.SET_CLIENT_PARAMS,
    payload: params,
  }
}
export const setStaffParams = (params) => {
  return {
    type: ActionTypes.SET_STAFF_PARAMS,
    payload: params,
  }
}
export const setDeptParams = (params) => {
  return {
    type: ActionTypes.SET_DEPT_PARAMS,
    payload: params,
  }
}
export const setOrgParams = (params) => {
  return {
    type: ActionTypes.SET_ORG_PARAMS,
    payload: params,
  }
}
export const setOrgManagerParams = (params) => {
  return {
    type: ActionTypes.SET_ORG_MANAGER_PARAMS,
    payload: params,
  }
}
export const setQrParams = (params) => {
  return {
    type: ActionTypes.SET_QR_PARAMS,
    payload: params,
  }
}
export const setOrgMappingParams = (params) => {
  return {
    type: ActionTypes.SET_ORG_MAPPING_PARAMS,
    payload: params,
  }
}
export const setCallDetailParams = (params) => {
  return {
    type: ActionTypes.SET_CALL_DETAIL_PARAMS,
    payload: params,
  }
}
export const setConnectionsDetailsParams = (params) => {
  return {
    type: ActionTypes.SET_CONNECTIONS_DETAIL_PARAMS,
    payload: params,
  }
}
