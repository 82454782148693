import {ActionTypes} from "../constant/ActionType"

const initialstate = {
  reduxLang: [],
  reduxVal: [],
}

const LanguageReducer = (state = initialstate, {type, payload}) => {
  switch (type) {
    case ActionTypes.SET_REDUX_LANGUAGE:
      return {...state, reduxLang: payload}
    case ActionTypes.SET_REDUX_VALIDATION:
      return {...state, reduxVal: payload}

    default:
      return state
  }
}

export default LanguageReducer
