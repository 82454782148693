import {useEffect} from "react"
import AxiosMethod from "../../../../_metronic/Api/AxiosMethod"
import toast from "react-hot-toast"

import {useNavigate} from "react-router-dom"
import {getLabel} from "../../../../_metronic/language-provider/LanProvider"

const PaymentProcessing = () => {
  const navigate = useNavigate()
  let temp = JSON.parse(localStorage.getItem("obj"))
  if (temp) {
    temp.sessionId = window.location.href.split("session_id=")[1] || "temp123"
  }

  const paymentDone = async () => {
    let makePaymetnRes = await AxiosMethod(`payments/successPayment`, `post`, temp)
    if (makePaymetnRes?.data?.status === "success") {
      toast.success(makePaymetnRes.data.message)
      navigate("/")
      localStorage.clear()
    }
    if (JSON.parse(makePaymetnRes.request.response).status === "error") {
      toast.error(JSON.parse(makePaymetnRes.request.response).message)
      navigate("/")
      localStorage.clear()
    }
  }

  const handleBeforeUnload = (e) => {
    let event = e || window.event
    event.preventDefault()
    event.returnValue = ""

    return ""
  }

  useEffect(() => {
    paymentDone()
    window.addEventListener("beforeunload", handleBeforeUnload)
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload)
    }
  }, [])

  return (
    <h1>
      {getLabel("PAYMENT_PROCESSING")} <br /> {getLabel("DONT_REFRESH")}
      <input type="text" value="DONT REFRESH" className="d-none" />
    </h1>
  )
}

export default PaymentProcessing
