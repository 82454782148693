/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import toast from "react-hot-toast"

import { PageTitle } from "../../../../_metronic/layout/core"
import AxiosMethod from "../../../../_metronic/Api/AxiosMethod"
import Pagination from "../../../../_metronic/helpers/pagination-helper/Pagination"
import { useNavigate } from "react-router-dom"
import TableHeaderToolbar from "../../../../_metronic/helpers/table-header-helper/TableHeaderToolbar"
import { setValParams } from "../../../../_metronic/redux/actions/QueryParamsAction"
import SelectValidation from "../../../../_metronic/language-provider/select-validation/SelectValidation"
import { setReduxValidation } from "../../../../_metronic/redux/actions/LanguageAction"
import { getLabel } from "../../../../_metronic/language-provider/LanProvider"

const usersBreadcrumbs = [
  {
    title: "Validation Page",
    path: "/validation-management/view-validation",
    isSeparator: false,
    isActive: false,
  },
  {
    title: "",
    path: "",
    isSeparator: true,
    isActive: false,
  },
]
const ViewValidation = () => {
  const setReduxVal = async () => {
    let validation = await SelectValidation(1)
    dispatch(setReduxValidation(validation))
  }
  const navigate = useNavigate()
  const dispatch = useDispatch()

  // params
  const currentParams = useSelector((state) => state?.QueryParamsReducer?.valParams)
  const [params, setparams] = useState(currentParams)

  const [validationArr, setValidationArr] = useState()
  //   acending / descending
  const [asc, setAsc] = useState(undefined)
  const [notFound, setNotFound] = useState("")

  const getValidation = async (search, limit, page) => {
    await setReduxVal()
    // GET ENGLISH Validation
    let getENRes = await AxiosMethod(`language/validations/1?limit=${limit}&page=${page}`, `get`)
    // GET FRENCH language
    let getFRRes = await AxiosMethod(`language/validations/2`, `get`)
    // GET SPANISH Validation
    let getESRes = await AxiosMethod(`language/validations/3`, `get`)

    let values = {
      search: search,
      limit: limit,
      page: page,
    }
    setparams(values)
    dispatch(setValParams(values))
    if (
      Object.keys(getENRes?.data?.data)?.length === 0 &&
      getENRes?.data?.data?.constructor === Object
    ) {
      setValidationArr({})
    } else {
      let tempArr = []
      getENRes?.data.data.rows.map((e, i) => {
        let obj = {
          label: e.label,
          en: e.value,
          fr: getFRRes?.data?.data?.rows[i]?.value,
          es: getESRes?.data?.data?.rows[i]?.value,
          type: e.type,
          validation: e.validation,
          application_type: e.application_type,
        }
        tempArr.push(obj)
      })
      let obj = {
        tempArr: tempArr,
        count: getENRes.data.data.count,
      }
      setValidationArr(obj)
    }
    if (JSON.parse(getENRes.request.response).status === "error") {
      // toast.error(JSON.parse(getENRes.request.response).message)
      setNotFound(JSON.parse(getENRes.request.response).message)
    }
  }
  // Delete Validation
  const deleteValidation = async (val) => {
    let arr = [
      {
        language_id: 1,
        label: val.label,
      },
      {
        language_id: 2,
        label: val.label,
      },
      {
        language_id: 3,
        label: val.label,
      },
    ]
    if (window.confirm("Are you sure you want to delete this Validation?")) {
      let deleteValidationRes = await AxiosMethod(`language/validations`, `delete`, arr)
      if (deleteValidationRes && deleteValidationRes.status === 200) {
        await getValidation(
          params.search,
          params.limit,
          validationArr.length <= 1 ? params.page - 1 : params.page
        )
        toast.success(deleteValidationRes.data.message)
      }
      if (JSON.parse(deleteValidationRes.request.response).status === "error") {
        toast.error(JSON.parse(deleteValidationRes.request.response).message)
      }
    }
  }
  // search Validation
  const searchValidation = async (search) => {
    let values = {
      search: search,
      limit: params.limit,
      page: params.page,
    }
    setparams(values)
    dispatch(setValParams(values))

    if (search) {
      let getENRes = await AxiosMethod(
        `language/validations/search/${search ? search.trim() : ""}`,
        `post`
      )
      if (getENRes && getENRes?.status === 200) {
        setNotFound(false)
        let obj = {}
        getENRes?.data.data.forEach(
          (e, i) => ((i = e.label), obj[i] ? obj[i].push(e["value"]) : (obj[i] = [e["value"]]))
        )
        const keys = Object.keys(obj)
        const lang = ["en", "fr", "es"]
        let test = []
        keys.forEach((key) => {
          let t = {}
          obj[key].map((el, i) => {
            const k = lang[i]
            const v = el
            Object.assign(t, { [k]: v })
          })
          test.push({
            label: key,
            ...t,
            type: getENRes.data.data[0].type,
            validation: getENRes.data.data[0].validation,
            application_type: getENRes.data.data[0].application_type,
          })
        })
        let tempObj = {
          tempArr: test,
        }
        setValidationArr(tempObj)
      }
      if (getENRes && JSON.parse(getENRes?.request?.response).status === "error") {
        setNotFound("No Data Found.")
      }
    } else {
      getValidation("", params.limit, params.page)
      setNotFound("")
    }
  }
  useEffect(() => {
    getValidation("", params.limit, params.page)
  }, [])
  return (
    <>
      <PageTitle breadcrumbs={usersBreadcrumbs}>Validation List</PageTitle>
      <div className="card">
        {/* Begin:: Card Header */}
        <TableHeaderToolbar
          params={params}
          setparams={setparams}
          action={searchValidation}
          showSearch={true}
          showExportBtn={false}
          // handleCsv={handleCsv}
          // csvData={csvData}
          // filename="roomtypes"
          showAddButton={true}
          addButton="Add New Validation"
          path="/validation-management/manage-validation"
        />
        {/* End:: Card Header */}
        {/*begin::Card body*/}
        <div className="card-body py-4">
          {/*begin::Table*/}
          <div id="kt_table_users_wrapper" className="dataTables_wrapper dt-bootstrap4 no-footer">
            <div className="table-responsive">
              <table
                className="table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer"
                id="kt_table_users"
              >
                {/*begin::Table head*/}
                <thead>
                  {/*begin::Table row*/}
                  <tr className="text-start text-muted fw-bolder fs-7 text-uppercase gs-0">
                    <th
                      className="min-w-125px sorting"
                      tabIndex={0}
                      aria-controls="kt_table_users"
                      rowSpan={1}
                      colSpan={1}
                      aria-label="Role: activate to sort column ascending"
                      style={{ width: "160.527px" }}
                    // onClick={sortByLabel}
                    >
                      LABEL
                      {asc ? (
                        <i className="bi bi-arrow-up"></i>
                      ) : false ? (
                        <i className="bi bi-arrow-down"></i>
                      ) : (
                        ""
                      )}
                    </th>
                    <th
                      className="min-w-125px sorting"
                      tabIndex={0}
                      aria-controls="kt_table_users"
                      rowSpan={1}
                      colSpan={1}
                      aria-label="Joined Date: activate to sort column ascending"
                      style={{ width: "208.13px" }}
                    >
                      ENGLISH
                    </th>
                    <th
                      className="min-w-125px sorting"
                      tabIndex={0}
                      aria-controls="kt_table_users"
                      rowSpan={1}
                      colSpan={1}
                      aria-label="Joined Date: activate to sort column ascending"
                      style={{ width: "208.13px" }}
                    >
                      FRENCH
                    </th>
                    <th
                      className="min-w-125px sorting"
                      tabIndex={0}
                      aria-controls="kt_table_users"
                      rowSpan={1}
                      colSpan={1}
                      aria-label="Joined Date: activate to sort column ascending"
                      style={{ width: "208.13px" }}
                    >
                      SPANISH
                    </th>
                    <th
                      className="min-w-125px sorting"
                      tabIndex={0}
                      aria-controls="kt_table_users"
                      rowSpan={1}
                      colSpan={1}
                      aria-label="Joined Date: activate to sort column ascending"
                      style={{ width: "208.13px" }}
                    >
                      TYPE
                    </th>
                    <th
                      className="min-w-125px sorting"
                      tabIndex={0}
                      aria-controls="kt_table_users"
                      rowSpan={1}
                      colSpan={1}
                      aria-label="Joined Date: activate to sort column ascending"
                      style={{ width: "208.13px" }}
                    >
                      VALIDATION
                    </th>
                    <th
                      className="min-w-125px sorting"
                      tabIndex={0}
                      aria-controls="kt_table_users"
                      rowSpan={1}
                      colSpan={1}
                      aria-label="Joined Date: activate to sort column ascending"
                      style={{ width: "208.13px" }}
                    >
                      APP TYPE
                    </th>
                    <th
                      className="text-end min-w-100px sorting_disabled"
                      rowSpan={1}
                      colSpan={1}
                      aria-label="Actions"
                      style={{ width: "128.946px" }}
                    >
                      {getLabel("ACTIONS")}
                    </th>
                  </tr>
                  {/*end::Table row*/}
                </thead>
                {/*end::Table head*/}
                {/*begin::Table body*/}
                <tbody className="text-gray-600 fw-bold">
                  {notFound ? (
                    <tr>
                      <td style={{ textAlign: "center" }} colSpan={8}>
                        <h3>
                          <span style={{ display: "block", width: "100%" }}>{notFound}</span>
                        </h3>
                      </td>
                    </tr>
                  ) : (
                    validationArr?.tempArr &&
                    validationArr?.tempArr.map((val, i) => {
                      return (
                        <tr key={i} className="odd">
                          <td>{val.label}</td>
                          <td>{val.en}</td>
                          <td>{val.fr}</td>
                          <td>{val.es}</td>
                          <td>{val.type}</td>
                          <td>{val.validation}</td>
                          <td>{val.application_type}</td>
                          {/*begin::Action=*/}
                          <td>
                            <div className="d-flex justify-content-end flex-shrink-0">
                              {/* Edit Button */}
                              <a
                                className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1"
                                onClick={() => {
                                  navigate("/validation-management/manage-validation", {
                                    state: { edit: true, editValidationObj: val },
                                  })
                                }}
                              >
                                {/*begin::Svg Icon | path: icons/duotune/art/art005.svg*/}
                                <span className="svg-icon svg-icon-3">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width={24}
                                    height={24}
                                    viewBox="0 0 24 24"
                                    fill="none"
                                  >
                                    <path
                                      opacity="0.3"
                                      d="M21.4 8.35303L19.241 10.511L13.485 4.755L15.643 2.59595C16.0248 2.21423 16.5426 1.99988 17.0825 1.99988C17.6224 1.99988 18.1402 2.21423 18.522 2.59595L21.4 5.474C21.7817 5.85581 21.9962 6.37355 21.9962 6.91345C21.9962 7.45335 21.7817 7.97122 21.4 8.35303ZM3.68699 21.932L9.88699 19.865L4.13099 14.109L2.06399 20.309C1.98815 20.5354 1.97703 20.7787 2.03189 21.0111C2.08674 21.2436 2.2054 21.4561 2.37449 21.6248C2.54359 21.7934 2.75641 21.9115 2.989 21.9658C3.22158 22.0201 3.4647 22.0084 3.69099 21.932H3.68699Z"
                                      fill="currentColor"
                                    />
                                    <path
                                      d="M5.574 21.3L3.692 21.928C3.46591 22.0032 3.22334 22.0141 2.99144 21.9594C2.75954 21.9046 2.54744 21.7864 2.3789 21.6179C2.21036 21.4495 2.09202 21.2375 2.03711 21.0056C1.9822 20.7737 1.99289 20.5312 2.06799 20.3051L2.696 18.422L5.574 21.3ZM4.13499 14.105L9.891 19.861L19.245 10.507L13.489 4.75098L4.13499 14.105Z"
                                      fill="currentColor"
                                    />
                                  </svg>
                                </span>
                                {/*end::Svg Icon*/}
                              </a>
                              {/* Delete Button */}
                              <a
                                className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm"
                                onClick={() => deleteValidation(val)}
                              >
                                {/*begin::Svg Icon | path: icons/duotune/general/gen027.svg*/}
                                <span className="svg-icon svg-icon-3">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width={24}
                                    height={24}
                                    viewBox="0 0 24 24"
                                    fill="none"
                                  >
                                    <path
                                      d="M5 9C5 8.44772 5.44772 8 6 8H18C18.5523 8 19 8.44772 19 9V18C19 19.6569 17.6569 21 16 21H8C6.34315 21 5 19.6569 5 18V9Z"
                                      fill="currentColor"
                                    />
                                    <path
                                      opacity="0.5"
                                      d="M5 5C5 4.44772 5.44772 4 6 4H18C18.5523 4 19 4.44772 19 5V5C19 5.55228 18.5523 6 18 6H6C5.44772 6 5 5.55228 5 5V5Z"
                                      fill="currentColor"
                                    />
                                    <path
                                      opacity="0.5"
                                      d="M9 4C9 3.44772 9.44772 3 10 3H14C14.5523 3 15 3.44772 15 4V4H9V4Z"
                                      fill="currentColor"
                                    />
                                  </svg>
                                </span>
                                {/*end::Svg Icon*/}
                              </a>
                            </div>
                          </td>

                          {/*end::Action=*/}
                        </tr>
                      )
                    })
                  )}
                </tbody>
                {/*end::Table body*/}
              </table>
            </div>

            {!notFound && (
              <Pagination
                params={params}
                setparams={setparams}
                count={validationArr?.count}
                pageData={validationArr?.rows?.length}
                action={getValidation}
              />
            )}
          </div>
          {/*end::Table*/}
        </div>
        {/*end::Card body*/}
      </div>
    </>
  )
}

export default ViewValidation
