/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect, useState} from "react"
import {useDispatch, useSelector} from "react-redux"
import toast from "react-hot-toast"

import AxiosMethod from "../../../_metronic/Api/AxiosMethod"
import Pagination from "../../../_metronic/helpers/pagination-helper/Pagination"
import SortingHelper from "../../../_metronic/helpers/sorting-helper/SortingHelper"
import TableHeaderToolbar from "../../../_metronic/helpers/table-header-helper/TableHeaderToolbar"
import {getLabel} from "../../../_metronic/language-provider/LanProvider"
import {PageTitle} from "../../../_metronic/layout/core"
import {SetLoader} from "../../../_metronic/redux/actions/LoaderAction"
import {setClientParams} from "../../../_metronic/redux/actions/QueryParamsAction"
import AdminBuildingFilter from "../qr-admin-management/AdminBuildingFilter"
import ClientRows from "./ClientRows"

const ClientList = () => {
  const dispatch = useDispatch()
  const [users, setUsers] = useState()
  const currentParams = useSelector((state) => state?.QueryParamsReducer?.clientParams)
  const [params, setparams] = useState(currentParams)

  const [notFound, setNotFound] = useState("")

  const getClient = async (search, limit, page, sortBy, sort, filterKey, filterValue) => {
    dispatch(SetLoader(true))

    let getUsersRes = await AxiosMethod (
      `users?search=${search}&limit=${limit}&page=${page}&sortwith=${sortBy}&sortby=${sort}&role=4&filterKey=${
        String(filterKey) || ""
      }&filterValue=${String(filterValue) || ""}`,
      `get`
    )
    let values = {
      search: search,
      limit: limit,
      page: page,
      sortBy: sortBy,
      sort: sort,
      filterKey: filterKey,
      filterValue: filterValue,
    }
    setparams(values)
    dispatch(setClientParams(values))
    if (getUsersRes && getUsersRes.status === 200)
	{
        dispatch(SetLoader(false))
        setNotFound(false)
        setUsers(getUsersRes.data.data)
    }
    if (JSON.parse(getUsersRes.request.response).status === "error")
	{
      dispatch(SetLoader(false))
      // toast.error(JSON.parse(getUsersRes.request.response).message)
      setNotFound(JSON.parse(getUsersRes.request.response).message)
    }
  }

  const deleteClient = async (userId) => {
    // if (
    //   window.confirm(
    //     "Are you sure, You want to delete this user? The user can not be recovered after deleting."
    //   )
    // ) {

    // }
	    let deleteUsersRes = await AxiosMethod (`users/${userId}?role_id=${4}`, `delete`)
    if (deleteUsersRes.status === 200) {
      toast.success(deleteUsersRes.data.message)
      getClient(
        params.search,
        params.limit,
        users?.rows?.length <= 1 && params.page > 1 ? params.page - 1 : params.page,
        params.sortBy,
        params.sort,
        params.filterKey || "",
        params.filterValue || ""
      )
    } else {
      toast.error(JSON.parse(deleteUsersRes.request.response).message)
    }
  }

  useEffect(() => {
    getClient(
      params.search,
      params.limit,
      users?.rows?.length <= 1 && params.page > 1 ? params.page - 1 : params.page,
      params.sortBy,
      params.sort,
      params.filterKey || "",
      params.filterValue || ""
    )
  }, [])

  return (
    <>
      <PageTitle>{getLabel("USER_LIST")}</PageTitle>

      <div className="card">
        {/* Begin:: Card Header */}
        <TableHeaderToolbar
          params={params}
          setparams={setparams}
          action={getClient}
          filterBody={<AdminBuildingFilter params={params} action={getClient} />}
          showSearch={true}
          showExportBtn={false}
          // handleCsv={handleCsv}
          // csvData={csvData}
          // filename="roomtypes"
          showAddButton={true}
          addButton={`${getLabel("ADD")} ${getLabel ("USER")}`}
          path="/user-management/manage-user"
        />
        {/* End:: Card Header */}
        {/*begin::Card body*/}
        <div className="card-body py-4">
          {/*begin::Table*/}
          <div id="kt_table_users_wrapper" className="dataTables_wrapper dt-bootstrap4 no-footer">
            <div className="table-responsive">
              <table
                className="table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer"
                id="kt_table_users"
              >
                {/*begin::Table head*/}
                <thead>
                  {/*begin::Table row*/}
                  <tr className="text-start text-muted fw-bolder fs-7 text-uppercase gs-0">
                    <th
                      className="min-w-125px sorting"
                      tabIndex={0}
                      aria-controls="kt_table_users"
                      rowSpan={1}
                      colSpan={1}
                      aria-label="User: activate to sort column ascending"
                      style={{width: "288.678px", cursor: "pointer"}}
                      onClick={() =>
                        getClient(
                          params.search,
                          params.limit,
                          params.page,
                          "full_name",
                          params.sort === 0 ? 1 : 0,
                          params?.filterKey || "",
                          params?.filterValue || ""
                        )
                      }
                    >
                      {getLabel("USER")}
                      {SortingHelper(params.sort, params.sortBy, "full_name")}
                    </th>
                    <th
                      className="min-w-125px sorting"
                      tabIndex={0}
                      aria-controls="kt_table_users"
                      rowSpan={1}
                      colSpan={1}
                      aria-label="User: activate to sort column ascending"
                      style={{width: "288.678px", cursor: "pointer"}}
                    >
                      {getLabel("COMPANY_NAME")}
                    </th>
                    <th
                      className="min-w-125px sorting"
                      tabIndex={0}
                      aria-controls="kt_table_users"
                      rowSpan={1}
                      colSpan={1}
                      aria-label="Role: activate to sort column ascending"
                      style={{width: "288.678px", cursor: "pointer"}}
                      onClick={() =>
                        getClient(
                          params.search,
                          params.limit,
                          params.page,
                          "plan_id",
                          params.sort === 0 ? 1 : 0,
                          params?.filterKey || "",
                          params?.filterValue || ""
                        )
                      }
                    >
                      {getLabel("PLAN")}
                      {SortingHelper(params.sort, params.sortBy, "plan_id")}
                    </th>
                    <th
                      className="min-w-125px sorting"
                      tabIndex={0}
                      aria-controls="kt_table_users"
                      rowSpan={1}
                      colSpan={1}
                      aria-label="Role: activate to sort column ascending"
                      style={{width: "288.678px", cursor: "pointer"}}
                      onClick={() =>
                        getClient(
                          params.search,
                          params.limit,
                          params.page,
                          "country_id",
                          params.sort === 0 ? 1 : 0,
                          params?.filterKey || "",
                          params?.filterValue || ""
                        )
                      }
                    >
                      {getLabel("COUNTRY")}
                      {SortingHelper(params.sort, params.sortBy, "country_id")}
                    </th>
                    <th
                      className="text-end min-w-100px sorting_disabled"
                      rowSpan={1}
                      colSpan={1}
                      aria-label="Actions"
                      style={{width: "128.946px"}}
                    >
                      {getLabel ("ACTIONS")}
                    </th>
                  </tr>
                  {/*end::Table row*/}
                </thead>
                {/*end::Table head*/}
                {/*begin::Table body*/}
                <tbody className="text-gray-600 fw-bold">
                  {notFound ? (
                    <tr>
                      <td colSpan={9}>
                        <div className="d-flex text-center w-100 align-content-center justify-content-center text-uppercase">
                          {notFound}
                        </div>
                      </td>
                    </tr>
                  ) : (
                    users?.rows &&
                    users?.rows?.map((user, i) => {
                      return <ClientRows key={i} user={user} deleteClient={deleteClient} />
                    })
                  )}
                </tbody>
                {/*end::Table body*/}
              </table>
            </div>

            {!notFound && (
              <Pagination
                params={params}
                setparams={setparams}
                count={users?.count}
                pageData={users?.rows?.length}
                action={getClient}
              />
            )}
          </div>
          {/*end::Table*/}
        </div>
        {/*end::Card body*/}
      </div>
    </>
  )
}

export default ClientList
