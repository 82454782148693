import {useEffect, useState} from "react"
import AxiosMethod from "../../../_metronic/Api/AxiosMethod"
import toast from "react-hot-toast"

import {PageTitle} from "../../../_metronic/layout/core"
import Pagination from "../../../_metronic/helpers/pagination-helper/Pagination"
import TableHeaderToolbar from "../../../_metronic/helpers/table-header-helper/TableHeaderToolbar"
import {useDispatch, useSelector} from "react-redux"
import {setDeptParams} from "../../../_metronic/redux/actions/QueryParamsAction"
import SortingHelper from "../../../_metronic/helpers/sorting-helper/SortingHelper"
import EditSvg from "../../../_metronic/helpers/svg-helper/EditSvg"
import {DeleteSvg} from "../../../_metronic/helpers/svg-helper/DeleteSvg"
import ViewSvg from "../../../_metronic/helpers/svg-helper/ViewSvg"
import {getLabel} from "../../../_metronic/language-provider/LanProvider"
import {SetLoader} from "../../../_metronic/redux/actions/LoaderAction"
import ConfirmModel from "../../../_metronic/helpers/model/ConfirmModel"

const UserInfoList = () => {
  const [delId, setdelId] = useState("")
  const dispatch = useDispatch()
  const [info, setInfo] = useState()
  const currentParams = useSelector((state) => state?.QueryParamsReducer?.deptParams)
  const [params, setparams] = useState(currentParams)

  const [notFound, setNotFound] = useState("")

  const getInfoList = async (search, limit, page, sortBy, sort, filterKey, filterValue) => {
    dispatch(SetLoader(true))
    // GET Group
    let getDeptRes = await AxiosMethod(
      `qrUserDetails?search=${search}&limit=${limit}&page=${page}&sortwith=${sortBy}&sortby=${sort}&filterKey=${
        String(filterKey) || ""
      }&filterValue=${String(filterValue) || ""}`,
      `get`
    )
    let values = {
      search: search,
      limit: limit,
      page: page,
      sortBy: sortBy,
      sort: sort,
      filterKey: filterKey,
      filterValue: filterValue,
    }
    setparams(values)
    dispatch(setDeptParams(values))
    if (getDeptRes && getDeptRes.status === 200) {
      dispatch(SetLoader(false))
      setNotFound(false)
      setInfo(getDeptRes?.data?.data)
    }
    if (JSON.parse(getDeptRes.request.response).status === "error") {
      dispatch(SetLoader(false))
      // toast.error(JSON.parse(getDeptRes.request.response).message)
      setNotFound(getLabel("FIELD_NOT_FOUND"))
    }
  }
  // Delete Language
  const deleteInfo = async (id) => {
    let deleteValidationRes = await AxiosMethod(`qrUserDetails/${id}`, `delete`)
    if (deleteValidationRes && deleteValidationRes.status === 200) {
      toast.success(deleteValidationRes.data.message)
      await getInfoList(
        params.search,
        params.limit,
        info?.rows.length <= 1 && params.page > 1 ? params.page - 1 : params.page,
        params.sortBy,
        params.sort,
        params.filterKey || "",
        params.filterValue || ""
      )
    }
    if (JSON.parse(deleteValidationRes.request.response).status === "error") {
      toast.error(JSON.parse(deleteValidationRes.request.response).message)
    }
  }

  useEffect(() => {
    getInfoList(
      params.search,
      params.limit,
      info?.rows?.length <= 1 && params.page > 1 ? params.page - 1 : params.page,
      params.sortBy,
      params.sort,
      params.filterKey || "",
      params.filterValue || ""
    )
  }, [])
  return (
    <>
      <PageTitle>{getLabel("FIELD_LIST")}</PageTitle>

      <div className="card">
        <TableHeaderToolbar
          params={params}
          setparams={setparams}
          action={getInfoList}
          // filterBody={<DepartmentBuildingFilter params={params} action={getInfoList} />}
          showSearch={true}
          showExportBtn={false}
          // handleCsv={handleCsv}
          // csvData={csvData}
          // filename="roomtypes"
          showAddButton={true}
          addButton={`${getLabel("ADD")} ${getLabel("FIELD")}`}
          path="/config/fields-customization/manage-info"
        />
        <div className="card-body py-4">
          <div id="kt_table_users_wrapper" className="dataTables_wrapper dt-bootstrap4 no-footer">
            <div className="table-responsive">
              <table
                className="table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer"
                id="kt_table_users"
              >
                <thead>
                  <tr className="text-start text-muted fw-bolder fs-7 text-uppercase gs-0">
                    <th
                      className="min-w-125px sorting"
                      tabIndex={0}
                      aria-controls="kt_table_users"
                      rowSpan={1}
                      colSpan={1}
                      aria-label="Joined Date: activate to sort column ascending"
                      style={{width: "208.13px", cursor: "pointer"}}
                      onClick={() =>
                        getInfoList(
                          params.search,
                          params.limit,
                          params.page,
                          "field_name",
                          params.sort === 0 ? 1 : 0,
                          params.filterKey || "",
                          params.filterValue || ""
                        )
                      }
                    >
                      {getLabel("NAME")}
                      {SortingHelper(params.sort, params.sortBy, "field_name")}
                    </th>
                    <th
                      className="min-w-125px sorting"
                      tabIndex={0}
                      aria-controls="kt_table_users"
                      rowSpan={1}
                      colSpan={1}
                      aria-label="Joined Date: activate to sort column ascending"
                      style={{width: "208.13px", cursor: "pointer"}}
                      onClick={() =>
                        getInfoList(
                          params.search,
                          params.limit,
                          params.page,
                          "type",
                          params.sort === 0 ? 1 : 0,
                          params.filterKey || "",
                          params.filterValue || ""
                        )
                      }
                    >
                      {getLabel("TYPE")}
                      {SortingHelper(params.sort, params.sortBy, "type")}
                    </th>
                    {/* <th
                      className="min-w-125px sorting"
                      tabIndex={0}
                      aria-controls="kt_table_users"
                      rowSpan={1}
                      colSpan={1}
                      aria-label="Joined Date: activate to sort column ascending"
                      style={{width: "208.13px", cursor: "pointer"}}
                      onClick={() =>
                        getInfoList(
                          params.search,
                          params.limit,
                          params.page,
                          "created_by",
                          params.sort === 0 ? 1 : 0,
                          params.filterKey || "",
                          params.filterValue || ""
                        )
                      }
                    >
                      {getLabel("CREATED_BY")}
                      {SortingHelper(params.sort, params.sortBy, "created_by")}
                    </th> */}
                    <th
                      className="text-end min-w-100px sorting_disabled"
                      rowSpan={1}
                      colSpan={1}
                      aria-label="Actions"
                      style={{width: "128.946px"}}
                    >
                      {getLabel("ACTIONS")}
                    </th>
                  </tr>
                </thead>
                <tbody className="text-gray-600 fw-bold">
                  {notFound ? (
                    <tr>
                      <td colSpan={5}>
                        <div className="d-flex text-center w-100 align-content-center justify-content-center text-uppercase">
                          {notFound}
                        </div>
                      </td>
                    </tr>
                  ) : (
                    info?.rows &&
                    info?.rows.map((info, i) => {
                      return (
                        <tr key={i} className="odd">
                          <td>{info["field_name"]}</td>
                          <td>{info.type}</td>
                          {/* <td>{info.created_by}</td> */}
                          <td>
                            <div className="d-flex justify-content-end flex-shrink-0">
                              <ViewSvg
                                path="/config/fields-customization/view-user-info"
                                editObj={info}
                              />
                              <EditSvg
                                path="/config/fields-customization/manage-info"
                                editObj={info}
                              />
                              <DeleteSvg onClick={() => setdelId(info.qr_user_detail_id)} />

                              <ConfirmModel
                                delMsz={getLabel("FIELD")}
                                show={delId}
                                onHide={setdelId}
                                onConfirmDel={deleteInfo}
                              />
                            </div>
                          </td>
                        </tr>
                      )
                    })
                  )}
                </tbody>
              </table>
            </div>

            {!notFound && (
              <Pagination
                params={params}
                setparams={setparams}
                count={info?.count}
                pageData={info?.rows?.length}
                action={getInfoList}
              />
            )}
          </div>
        </div>
      </div>
    </>
  )
}

export default UserInfoList
