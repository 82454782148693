import React, {PureComponent, useEffect} from 'react';
//import Highcharts from 'highcharts'
//import HighchartsReact from 'highcharts-react-official'
import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import {firestore} from "../../services/firebase.service"
import {doc, getDoc, updateDoc, collection, where, query, getDocs, orderBy} from "firebase/firestore"
import firebase from "firebase/compat"
import Timestamp = firebase.firestore.Timestamp
import moment from "moment"
import {getTempOrg} from "../../../_metronic/helpers/reuseable-fun/FunReuse"

const CallHistoryChart = (props: any) =>
{
	// STATE VARIABLES
	const [callDetails, setCallDetails] = React.useState<any>([]);

	// USE EFFECT HOOK TO GET CALL DETAILS FROM FIRESTORE DATABASE.
	useEffect (() =>
	{
		getCallDetails();
	}, []);

	// GET CALL DETAILS FROM FIRESTORE DATABASE.
	const getCallDetails = async () =>
	{
		try
		{
			// GET THE CURRENT DATE
			const currentDate = new Date();

			// CREATE A DATE OBJECT FOR 7 DAYS AGO
			const sevenDaysAgo = new Date();
			sevenDaysAgo.setDate (currentDate.getDate() - 7);

			// Convert dates to Firestore Timestamps
			const currentTimestamp = Timestamp.fromDate (currentDate);
			const sevenDaysAgoTimestamp = Timestamp.fromDate(sevenDaysAgo);
			let q: any;

			// CHECK THE USER ROLL IS ADMIN THEN ALL CALL DETAILS WILL BE FETCHED.
			// OTHER-WISE ONLY THE CALL DETAILS OF THE USER ORGANIZATION WILL BE FETCHED.
			if (props.role_id === 1)
			{
				q = query (collection (firestore, 'call'),
					where ("timecallinitiated", ">=", sevenDaysAgoTimestamp),
					where ("timecallinitiated", "<=", currentTimestamp),
					orderBy ("timecallinitiated", "desc"));
			}
			else if (props.role_id === 4) // IF THE USER ROLE IS CLIENT THEN GET THE ALL USER ORGANIZATION LIST THEN GET THE CALL DETAILS OF THE ORGANIZATION.
			{
				// GET THE USER ORGANIZATION ID
				let organizationList = await getTempOrg ();
				let organizationIds = organizationList.map ((org: any) => org.value? org.value: org.user_id);

				console.log ("organizationIds", organizationIds, organizationList);
				q = query (collection (firestore, 'call'),
					where ("timecallinitiated", ">=", sevenDaysAgoTimestamp),
					where ("timecallinitiated", "<=", currentTimestamp),
					where ("organization_id", "in", organizationIds),
					orderBy ("timecallinitiated", "desc"));
			}

			// IF THE USER ROLE IS STAFF THEN GET THE CALL DETAILS. STAFF CAN ONLY VIEW THEIR OWN CALL HISTORY.
			else if (props.role_id === 3)
			{
				q = query (collection (firestore, 'call'),
					where ("timecallinitiated", ">=", sevenDaysAgoTimestamp),
					where ("timecallinitiated", "<=", currentTimestamp),
					where ("staffids", "array-contains", props.user_id),
					orderBy ("timecallinitiated", "desc"));
			}
			else if (props.role_id === 5) // IF THE USER ROLE IS ORGANIZATION MANAGER THEN GET THE CALL DETAILS OF THE ORGANIZATION.
			{
				// GET THE USER ORGANIZATION ID
				let organizationList = await getTempOrg ();
				let organizationIds = organizationList.map ((org: any) => org.value? org.value: org.user_id);

				console.log ("organizationIds", organizationIds, organizationList);
				q = query (collection (firestore, 'call'),
					where ("timecallinitiated", ">=", sevenDaysAgoTimestamp),
					where ("timecallinitiated", "<=", currentTimestamp),
					where ("organization_id", "in", organizationIds),
					orderBy ("timecallinitiated", "desc"));
			}

			getDocs(q)
			.then (async (querySnapshot) =>
			{
				let dataCallDetails: any = [];
				querySnapshot.forEach((doc) =>
				{
					// console.log (doc.id, " => ", doc.data());
					dataCallDetails.push(doc.data());
				});
				const processedData = await processData (dataCallDetails);
				setCallDetails (processedData);
			})
			.catch((error) =>
			{
				console.error ("Error getting documents: ", error);
			});
		}
		catch (e)
		{
			console.log ("Error in getCallDetails: ", e);
		}
	}

	// FUNCTION TO TRANSFORM DATA INTO THE DESIRED FORMAT
	const processData = (callDetails: any) =>
	{
		const currentDate = new Date();
		const days = [];

		for (let i = 0; i < 7; i++) {
			const date = new Date();
			date.setDate(currentDate.getDate() - i);
			days.push( moment(date).format('ddd'));
		}

		// REVERSE THE DAYS ARRAY
		let daysList = days.reverse();

		// INITIALIZE RESULT OBJECT WITH COUNTS INITIALIZED TO 0
		const result = daysList.map(day => ({
			name: day,
			Missed: 0,
			Declined: 0,
			Answered: 0
		}));

		// ITERATE OVER CALLDETAILS AND UPDATE RESULT COUNTS ACCORDINGLY
		callDetails.forEach ((call:any) =>
		{
			const createdAt = new Date (call.createdAt);
			const day = moment (createdAt).format('ddd');
			// GET INDEX OF DAY;
			let dayIndex = daysList.indexOf(day);
			//console.log (dayIndex, day)

			const status = call.callstatus;
			const isCallConnected = call.isCallConnected;
			//console.log(status, isCallConnected, call)

			// INCREMENT THE COUNT FOR THE STATUS
			if (isCallConnected === 1)
			{
				result [dayIndex]["Answered"]++;
			}
			else
			{
				if (status === "MISS")
				{
					result [dayIndex]["Missed"]++;
				}
				else if (status === "REJTD")
				{
					result [dayIndex]["Declined"]++;
				}
			}
		});

		return result;
	};

	// RETURN THE HTML CONTENTS.
	return (
			<ResponsiveContainer width="100%" height={220}>
				{callDetails  && <BarChart
				width={400}
				height={220}
				data={callDetails}
				margin={{
					top: 0,
					right: 20,
					left: -30,
					bottom: 5,
				}}
			>
				<CartesianGrid strokeDasharray="3 3" />
				<XAxis dataKey="name" />
				<YAxis />
				<Tooltip />
				<Legend  align={"center"}/>
				<Bar dataKey="Answered" stackId="a" fill="#4B83ED" maxBarSize={15} />
				<Bar dataKey="Missed" stackId="a" fill="#E2B43F" maxBarSize={15} />
				<Bar dataKey="Declined" stackId="a" fill="#E77974"  maxBarSize={15} />
			</BarChart>
				}
		</ResponsiveContainer>
	);
}

export default CallHistoryChart;
