/* eslint-disable react-hooks/exhaustive-deps */
import * as Yup from "yup"
import toast from "react-hot-toast"

import {Formik, Form} from "formik"
import {useLocation, useNavigate} from "react-router-dom"
import {PageTitle} from "../../../_metronic/layout/core"
import AxiosMethod from "../../../_metronic/Api/AxiosMethod"
import FormikController from "../../../_metronic/Shared-Component-formik/FormikController"
import {useEffect, useState} from "react"
import {getTempOrg} from "../../../_metronic/helpers/reuseable-fun/FunReuse"
import {getLabel} from "../../../_metronic/language-provider/LanProvider"
import {ValSchemaList} from "../../../_metronic/Shared-Component-formik/schema/ValSchemaList"

const InviteUserModel = () => {
  const [loading, setLoading] = useState(false)

  const navigate = useNavigate()
  const location = useLocation()
  const usersBreadcrumbs = [
    {
      title: `${
        location.state.inviteType === "staff" ? getLabel("STAFF") : getLabel("ORG_MANAGER")
      } ${getLabel("LIST")}`,
      path: location.state.inviteType === "staff" ? "/org/staff-management" : "/org/manager",
      isSeparator: false,
      isActive: false,
    },
    {
      title: "",
      path: "",
      isSeparator: true,
      isActive: false,
    },
  ]
  const [userList, setUserList] = useState([])

  const initialValues = {
    organization_id: location?.state?.editObj?.organization_id
      ? location?.state?.editObj?.organization_id
      : "",
    email: location?.state?.editObj ? location?.state?.editObj?.email : "",
  }

  const getOrg = async () => {
    setUserList(await getTempOrg())
  }

  let validationSchema = Yup.object({
    organization_id: ValSchemaList(0, "ORG"),
    email: ValSchemaList(1, "EMAIL"),
  })

  const onAddEditUser = async (values) => {
    setLoading(true)
    let obj = {
      email: values.email,
      organization_id: values.organization_id,
      // existing: location?.state?.add ? false : true,
      type: location?.state?.inviteType,
      role_id: location.state.inviteType === "staff" ? 3 : 5,
    }
    let createUsersRes = await AxiosMethod(`users/inviteUser`, `post`, obj)
    if (createUsersRes && createUsersRes?.data?.status === "success") {
      setLoading(false)
      navigate(
        `${location.state.inviteType === "staff" ? "/org/staff-management" : "/org/manager"}`
      )
      toast.success(createUsersRes.data.message)
    }

    if (createUsersRes.response.status == 400 || createUsersRes.response.status == 401) {
      setLoading(false)
      toast.error(createUsersRes?.response.data?.message)
    }
  }

  useEffect(() => {
    getOrg()
  }, [])

  return (
    <>
      <PageTitle breadcrumbs={usersBreadcrumbs}>{`${getLabel("INVITE")}  ${
        location?.state?.inviteType === "staff"
          ? location?.state?.inviteType
          : getLabel("ORG_MANAGER")
      }`}</PageTitle>

      <div className="card card-custom">
        <div className="card-header">
          <div className="card-title">
            <h3 className="card-label">
              {`${getLabel("INVITE")}  ${
                location?.state?.inviteType === "staff"
                  ? location?.state?.inviteType
                  : getLabel("ORG_MANAGER")
              }`}
            </h3>
          </div>
        </div>

        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onAddEditUser}
        >
          {(formik) => {
            return (
              <Form onSubmit={formik.handleSubmit} className="form">
                {/*begin::Scroll*/}
                <div className="card-body py-4">
                  <div
                    className="d-flex row me-n7 pe-7"
                    // className="d-flex row scroll-y me-n7 pe-7"
                    id="kt_modal_add_user_scroll"
                    data-kt-scroll="true"
                    data-kt-scroll-activate="{default: false, lg: true}"
                    // data-kt-scroll-max-height="auto"
                    data-kt-scroll-dependencies="#kt_modal_add_user_header"
                    data-kt-scroll-wrappers="#kt_modal_add_user_scroll"
                    // data-kt-scroll-offset="300px"
                  >
                    {/* org */}
                    {location?.state?.add && (
                      <div className="col-6 mb-7">
                        <FormikController
                          control="react_select"
                          label={getLabel("ORG")}
                          labelClassName="required fw-bold fs-6 mb-2"
                          name="organization_id"
                          className="form-control-solid mb-lg-0"
                          formik={formik}
                          options={userList}
                          value={formik.values.organization_id}
                          onChange={formik.handleChange}
                          error={formik.errors.organization_id}
                        />
                      </div>
                    )}
                    {/* email */}
                    <div className="col-6 mb-7">
                      <FormikController
                        control="input"
                        type="email"
                        disabled={!location?.state?.add}
                        style={location?.state?.edit && {color: "hsl(0, 0%, 60%)"}}
                        label={getLabel("EMAIL")}
                        placeholder={`${getLabel("ENTER")} ${getLabel("EMAIL")}`}
                        labelClassName="required fw-bold fs-6 mb-2"
                        name="email"
                        className="form-control form-control-solid mb-lg-0"
                        maxLength="50"
                        autoComplete="false"
                        formik={formik}
                        value={formik.values.email}
                        onChange={formik.handleChange}
                        error={formik.errors.email}
                      />
                    </div>
                  </div>
                </div>
                {/*end::Scroll*/}
                {/*begin::Actions*/}
                <div className="card-footer d-flex justify-content-center">
                  <button
                    className="btn btn-light-primary font-weight-bold"
                    style={{marginRight: "1rem"}}
                    type="button"
                    onClick={() => {
                      if (location?.state?.inviteType === "staff") {
                        navigate("/org/staff-management")
                      } else {
                        navigate("/org/manager")
                      }
                    }}
                  >
                    <span className="indicator-label">{getLabel("CANCEL")}</span>
                  </button>
                  <button
                    type="submit"
                    id="kt_password_reset_submit"
                    className="btn btn-lg btn-primary fw-bolder me-4"
                    disabled={formik.isSubmitting || !formik.isValid}
                  >
                    {loading ? (
                      <span className="indicator-progress" style={{display: "block"}}>
                        {getLabel("PLEASE_WAIT")}
                        <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                      </span>
                    ) : (
                      <span className="indicator-label">{getLabel("SUBMIT")}</span>
                    )}
                  </button>
                </div>
                {/*end::Actions*/}
              </Form>
            )
          }}
        </Formik>
      </div>
    </>
  )
}

export default InviteUserModel
