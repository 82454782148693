import { Formik, Form } from "formik"
import * as Yup from "yup"
import toast from "react-hot-toast"
import { PageTitle } from "../../../_metronic/layout/core"
import AxiosMethod from "../../../_metronic/Api/AxiosMethod"
import FormikController from "../../../_metronic/Shared-Component-formik/FormikController"
import { useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import { TakeEditedPlan } from "../../../_metronic/helpers/only-edit-value/TakeEditedVal"
import { getLabel } from "../../../_metronic/language-provider/LanProvider"
import { ValSchemaList } from "../../../_metronic/Shared-Component-formik/schema/ValSchemaList"

const CreateEditPlan = () => {
  const [loading, setLoading] = useState(false)
  const [qrOptions, setqrOptions] = useState({ static: false, dynamic: false, activestatus: false })
  const usersBreadcrumbs = [
    {
      title: `${getLabel("PLAN")} ${getLabel("LIST")}`,
      path: "/config/available-plan",
      isSeparator: false,
      isActive: false,
    },
    {
      title: "",
      path: "",
      isSeparator: true,
      isActive: false,
    },
  ]
  const location = useLocation()
  const navigate = useNavigate()
  const planDuration = [
    { label: "Per Month", value: 30 },
    { label: "Per Year", value: 365 },
  ]
  const initialValues = {
    name: location?.state?.editObj ? location?.state?.editObj?.name : "",
    description: location?.state?.editObj ? location?.state?.editObj?.description : "",
    price: location?.state?.editObj ? location?.state?.editObj?.price : "",
    duration: location?.state?.editObj ? location?.state?.editObj?.duration : "",
    tagline: location?.state?.editObj ? location?.state?.editObj?.tagline : "",
	staff_member_limit: location?.state?.editObj ? location?.state?.editObj?.staff_member_limit : "",
    plan_type: "individual",
    is_qr_type_static: location?.state?.editObj ? location?.state?.editObj?.is_qr_type_static : false,
    static_qr_limit: location?.state?.editObj ? location?.state?.editObj?.static_qr_limit : "",
    is_qr_type_dynamic: location?.state?.editObj ? location?.state?.editObj?.is_qr_type_dynamic : false,
    dynamic_qr_limit: location?.state?.editObj ? location?.state?.editObj?.dynamic_qr_limit : "",
	activestatus: location?.state?.editObj ? (location?.state?.editObj?.activestatus === "A"? true : false) : false,
	// modules: location?.state?.editObj ? location?.state?.editObj?.modules : "",
    // call_limit: location?.state?.editObj ? location?.state?.editObj?.call_limit : "",
  }
  console.log ("my location variables check:", location?.state?.editObj)
  let validationSchema = Yup.object({
    name: ValSchemaList(0, "NAME"),
    description: ValSchemaList(0, "DESC"),
    price: ValSchemaList(0, "PRICE"),
    duration: ValSchemaList(0, "DURATION"),
    // modules: ValSchemaList(7, "MODULES", "", 1),
    tagline: ValSchemaList(0, "TAGLINE"),
	  staff_member_limit: ValSchemaList(0, "STAFF_MEMBER_LIMIT"),
    ...(qrOptions.dynamic && {
      dynamic_qr_limit: ValSchemaList(0, "DYNAMIC_QR_LIMIT"),
    }),
    ...(qrOptions.static && {
      static_qr_limit: ValSchemaList(0, "STATIC_QR_LIMIT"),
    }),
    ...(!qrOptions.static && !qrOptions.dynamic && {
      static_qr_limit: ValSchemaList(0, "STATIC_QR_LIMIT"),
    }),
  })
  const onAddEditPlan = async (values) => {
    if (values.plan_type === "individual") {
      values.is_qr_type_dynamic = false
      values.dynamic_qr_limit = 0
      values.modules = [4]
    } else {
      values.modules = [1, 2, 3, 4, 5]
    }

    setLoading(true)
    // let temp = TakeEditedPlan(values, initialValues)
    Object.assign(values, { stripe_id: location?.state?.editObj?.stripe_id })
    values.dynamic_qr_limit = Number(values.dynamic_qr_limit);
    values.static_qr_limit = Number(values.static_qr_limit)
	values.activestatus =  (values.activestatus)? "A" : "I";
	
    let addEditPlanRes = await AxiosMethod(
      location?.state?.add ? `plans` : `plans/${location?.state?.editObj.plan_id}`,
      location?.state?.add ? `post` : `patch`,
      values
    )
    if (addEditPlanRes.status == 200) {
      setLoading(false)
      toast.success(location?.state?.add ? getLabel("PLAN_CREATED") : getLabel("PLAN_UPDATED"))
      navigate("/config/available-plan")
    }
    if (JSON.parse(addEditPlanRes.request.response).status === "error") {
      setLoading(false)
      toast.error(JSON.parse(addEditPlanRes.request.response).message)
    }
  }

  return (
    <>
      <PageTitle breadcrumbs={usersBreadcrumbs}>
        {`${getLabel(location?.state?.add ? "ADD" : "EDIT")} ${getLabel("PLAN")}`}
      </PageTitle>
      <div className="card card-custom">
        <div className="card-header">
          <div className="card-title">
            <h3 className="card-label">
              {`${getLabel(location?.state?.add ? "ADD" : "EDIT")} ${getLabel("PLAN")}`}
            </h3>
          </div>
        </div>

        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onAddEditPlan}
        >
          {(formik) => {
            return (
              <Form onSubmit={formik.handleSubmit} className="form">
                {/*begin::Scroll*/}
                <div className="card-body py-4">
                  <div
                    // className="d-flex row scroll-y me-n7 pe-7"
                    className="d-flex row me-n7 pe-7"
                    id="kt_modal_add_user_scroll"
                    data-kt-scroll="true"
                    data-kt-scroll-activate="{default: false, lg: true}"
                    // data-kt-scroll-max-height="auto"
                    data-kt-scroll-dependencies="#kt_modal_add_user_header"
                    data-kt-scroll-wrappers="#kt_modal_add_user_scroll"
                  // data-kt-scroll-offset="300px"
                  >
                    {/* Plan name */}
                    <div className="col-6 mb-7">
                      <FormikController
                        control="input"
                        type="text"
                        label={getLabel("NAME")}
                        placeholder={`${getLabel("ENTER")} ${getLabel("NAME")}`}
                        labelClassName="required fw-bold fs-6 mb-2"
                        name="name"
                        className="form-control form-control-solid mb-lg-0"
                        maxLength="30"
                        formik={formik}
                        value={formik.values.name}
                        onChange={formik.handleChange}
                        error={formik.errors.name}
                      />
                    </div>
                    <div className="col-6 mb-7">
                      <FormikController
                        control="input"
                        type="text"
                        label={getLabel("DESC")}
                        placeholder={`${getLabel("ENTER")} ${getLabel("DESC")}`}
                        labelClassName="required fw-bold fs-6 mb-2"
                        name="description"
                        className="form-control form-control-solid mb-lg-0"
                        maxLength="30"
                        formik={formik}
                        value={formik.values.description}
                        onChange={formik.handleChange}
                        error={formik.errors.description}
                      />
                    </div>
                    {/* Plan price */}
                    <div className="col-6 mb-7">
                      <FormikController
                        control="input"
                        type="text"
                        label={getLabel("PRICE") + " " + "(€)"}
                        placeholder={`${getLabel("ENTER")} ${getLabel("PRICE")}`}
                        labelClassName="required fw-bold fs-6 mb-2"
                        name="price"
                        className="form-control form-control-solid mb-lg-0"
                        onKeyPress={(event) => {
                          if (event.target.value.includes('.') && event.key === '.') {
                            event.preventDefault();
                          } else if (!/[0-9.]/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                        maxLength="10"
                        formik={formik}
                        value={formik.values.price}
                        onChange={formik.handleChange}
                        error={formik.errors.price}
                      />
                    </div>
                    {/* Plan duration */}
                    <div className="col-6 mb-7">
                      <FormikController
                        control="react_select"
                        label={getLabel("DURATION") + " " + "(" + getLabel("DAYS") + ")"}
                        labelClassName="required fw-bold fs-6 mb-2"
                        name="duration"
                        className="form-control-solid mb-lg-0"
                        options={planDuration}
                        formik={formik}
                        value={formik.values.duration}
                        onChange={formik.handleChange}
                        error={formik.errors.duration}
                      />
                    </div>
                    {/* Module List */}
                    {/* <div className="col-6 mb-7">
                      <FormikController
                        control="react_select"
                        label={getLabel("SELECT") + " " + getLabel("MODULES")}
                        labelClassName="required fw-bold fs-6 mb-2"
                        name="modules"
                        className="form-control-solid mb-lg-0"
                        formik={formik}
                        options={moduleList}
                        isMulti={true}
                        value={formik.values.modules}
                        onChange={formik.handleChange}
                        error={formik.errors.modules}
                      />
                    </div> */}
                    {/* Plan tagline */}
                    <div className="col-6 mb-7">
                      <FormikController
                        control="input"
                        type="text"
                        label={getLabel("TAGLINE")}
                        placeholder={`${getLabel("ENTER")} ${getLabel("TAGLINE")}`}
                        labelClassName="required fw-bold fs-6 mb-2"
                        name="tagline"
                        className="form-control form-control-solid mb-lg-0"
                        maxLength="70"
                        formik={formik}
                        value={formik.values.tagline}
                        onChange={formik.handleChange}
                        error={formik.errors.tagline}
                      />
                    </div>
	                  
	                  {/* PLAN STAFF MEMBER LIMIT */}
	                  <div className="col-6 mb-7">
		                  <FormikController
			                  control="input"
			                  type="text"
			                  label={getLabel("STAFF_MEMBER_LIMIT")}
			                  placeholder={`${getLabel("ENTER")} ${getLabel("STAFF_MEMBER_LIMIT")}`}
			                  labelClassName="required fw-bold fs-6 mb-2"
			                  name="staff_member_limit"
			                  className="form-control form-control-solid mb-lg-0"
			                  maxLength="10"
			                  onKeyPress={(event) => {
				                  if (!/[0-9]/.test(event.key)) {
					                  event.preventDefault()
				                  }
			                  }}
			                  formik={formik}
			                  value={formik.values.staff_member_limit}
			                  onChange={formik.handleChange}
			                  error={formik.errors.price}
		                  />
	                  </div>
                    {/* <div className="col-6 mb-7">
                      <FormikController
                        control="input"
                        type="text"
                        label={getLabel("CALL_LIMIT")}
                        placeholder={`${getLabel("ENTER")} ${getLabel("CALL_LIMIT")}`}
                        labelClassName="fw-bold fs-6 mb-2"
                        name="call_limit"
                        className="form-control form-control-solid mb-lg-0"
                        maxLength="30"
                        formik={formik}
                        value={formik.values.call_limit}
                        onChange={formik.handleChange}
                        error={formik.errors.call_limit}
                        onKeyPress={(event) => {
                          if (!event.target.value && event.charCode == 48) {
                            event.preventDefault()
                          }
                          if (!/[0-9]/.test(event.key)) {
                            event.preventDefault()
                          }
                        }}
                      />
                    </div> */}

                    <div className="col-6 mb-7">
                      {/* Plan type */}
                      <div className="mt-5">
                        <label className="required fw-bold fs-6 mb-2">{getLabel("PLAN_TYPE")}</label>
                        <div className="row">
                          {[
                            { value: "individual", label: "Individual" },
                            { value: "company", label: "Company" },
                          ].map((checkField) => {
                            const { value, label } = checkField
                            return (
                              <div className="col-6 col-sm- col-md-6 col-xl-6">
                                <label
                                  className={`btn btn-outline btn-outline-dashed btn-outline-default d-flex text-start p-2${formik.values.plan_type === value ? " active" : ""
                                    }`}
                                >
                                  <span className="form-check form-check-custom form-check-solid form-check-sm align-items-start mt-1">
                                    <input
                                      type="radio"
                                      name="applied"
                                      checked={formik.values.plan_type === value}
                                      value={value}
                                      onChange={(e) => {
                                        formik.setFieldValue("plan_type", e.target.value)
                                        formik.setFieldValue("is_qr_type_dynamic", false)
                                        formik.setFieldTouched("dynamic_qr_limit", false)
                                        setqrOptions({
                                          ...qrOptions,
                                          dynamic: false,
                                        })
                                      }}
                                      className="form-check-input"
                                    />
                                  </span>
                                  <span className="ms-5">
                                    <span className="fs-5 fw-bolder text-gray-600 d-block">
                                      {label}
                                    </span>
                                  </span>
                                </label>
                              </div>
                            )
                          })}
                        </div>
                      </div>
                    </div>
	                  {/* is default checkbox */}
	                  <div className="col-6 mb-7">
		                  <div className="mt-5">
			                  <label className="fw-bold fs-6 mb-2" htmlFor="activestatus">
				                  {getLabel("CURRENTLY_AVAILABLE")}
			                  </label>
			                  <label className="form-check form-switch form-check-custom form-check-solid">
			                  <input
				                  className="form-check-input"
				                  type="checkbox"
				                  name="activestatus"
				                  style={{ width: "5rem" }}
				                  value={formik.values.activestatus}
				                  checked={formik.values.activestatus}
				                  onChange={(e) => {
					                 console.log("eeeeeee",e.currentTarget.checked);
					                  formik.handleChange(e)
					                  setqrOptions({
						                  ...qrOptions,
						                  activestatus: e.currentTarget.checked,
					                  })
								  }}
			                  />
		                  </label>
		                  </div>
	                  </div>

                    <div className="row col-12">
                      <label className="required fw-bold fs-6 mb-2">{getLabel("QR_TYPE")}</label>

                      {/* Static */}
                      <div className="col-12 col-md-6">
                        <FormikController
                          control="input"
                          type="text"
                          mainClass="p-5"
                          labelChildrenSpace="before"
                          labelChildren={
                            <div className="form-check form-check-custom form-check-solid mb-2">
                              <input
                                type="checkbox"
                                name="is_qr_type_static"
                                checked={formik.values.is_qr_type_static}
                                onChange={(e) => {
                                  formik.handleChange(e)
                                  formik.setFieldTouched("static_qr_limit", false)
                                  formik.setFieldValue("static_qr_limit", "")
                                  setqrOptions({
                                    ...qrOptions,
                                    static: e.currentTarget.checked,
                                  })
                                }}
                                className="form-check-input me-2"
                              />
                              <label className="required fw-bold fs-6">{getLabel("STATIC_QR_LIMIT")}</label>
                            </div>
                          }
                          onKeyPress={(event) => {
                            if (!/[0-9]/.test(event.key)) {
                              event.preventDefault()
                            }
                          }}
                          placeholder={`${getLabel("ENTER")} ${getLabel("STATIC_QR_LIMIT")}`}
                          formik={formik}
                          name="static_qr_limit"
                          className="form-control form-control-solid p-2"
                          disabled={!formik.values.is_qr_type_static}
                          value={formik.values.static_qr_limit}
                          maxLength="5"
                          onChange={formik.handleChange}
                          error={formik.errors.static_qr_limit}
                        />
                      </div>

                      {/* Dynamic */}
                      {formik.values.plan_type === "company" && (
                        <div className="col-12 col-md-6">
                          <FormikController
                            control="input"
                            type="text"
                            mainClass="p-5"
                            labelChildrenSpace="before"
                            labelChildren={
                              <div className="form-check form-check-custom form-check-solid mb-2">
                                <input
                                  type="checkbox"
                                  name="is_qr_type_dynamic"
                                  checked={formik.values.is_qr_type_dynamic}
                                  onChange={(e) => {
                                    formik.handleChange(e)
                                    formik.setFieldTouched("dynamic_qr_limit", false)
                                    formik.setFieldValue("dynamic_qr_limit", "")
                                    setqrOptions({
                                      ...qrOptions,
                                      dynamic: e.currentTarget.checked,
                                    })
                                  }}
                                  className="form-check-input me-2"
                                />
                                <label className="required fw-bold fs-6">{getLabel("DYNAMIC_QR_LIMIT")}</label>
                              </div>
                            }
                            onKeyPress={(event) => {
                              if (!/[0-9]/.test(event.key)) {
                                event.preventDefault()
                              }
                            }}
                            placeholder={`${getLabel("ENTER")} ${getLabel("DYNAMIC_QR_LIMIT")}`}
                            name="dynamic_qr_limit"
                            formik={formik}
                            className="form-control form-control-solid p-2"
                            disabled={!formik.values.is_qr_type_dynamic}
                            value={formik.values.dynamic_qr_limit}
                            maxLength="5"
                            onChange={formik.handleChange}
                            error={formik.errors.dynamic_qr_limit}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                {/*end::Scroll*/}
                {/*begin::Actions*/}
                <div className="card-footer d-flex justify-content-center">
                  <button
                    type="button"
                    className="btn btn-light-primary font-weight-bold"
                    onClick={() => {
                      navigate("/config/available-plan")
                    }}
                    style={{ marginRight: "1rem" }}
                  >
                    <span className="indicator-label">{getLabel("CANCEL")}</span>
                  </button>
                  <button
                    type="submit"
                    id="kt_password_reset_submit"
                    className="btn btn-lg btn-primary fw-bolder me-4"
                    disabled={formik.isSubmitting}
                  >
                    {loading ? (
                      <span className="indicator-progress" style={{ display: "block" }}>
                        {getLabel("PLEASE_WAIT")}
                        <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                      </span>
                    ) : (
                      <span className="indicator-label">{getLabel("SUBMIT")}</span>
                    )}
                  </button>
                </div>
                {/*end::Actions*/}
              </Form>
            )
          }}
        </Formik>
      </div>
    </>
  )
}

export default CreateEditPlan
