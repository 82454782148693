import { Formik, Form } from "formik"
import * as Yup from "yup"
import { Row, Col } from "react-bootstrap"
import { useEffect, useState } from "react"
import { Link, useNavigate } from "react-router-dom"
import AxiosMethod from "../../../../_metronic/Api/AxiosMethod"
import FormikController from "../../../../_metronic/Shared-Component-formik/FormikController"
import toast from "react-hot-toast"
import Menu from "./Menu"
import { getLabel } from "../../../../_metronic/language-provider/LanProvider"
import { ValSchemaList } from "../../../../_metronic/Shared-Component-formik/schema/ValSchemaList"

const Contact = () => {
  const [loading, setLoading] = useState(false);
  const [hasErrors, setHasErrors] = useState(undefined)
  const navigate = useNavigate()

  const initialValues = {
    name: "",
    email: "",
    message: "",
    check_privacy: false,
    check_promotion: false,
  }

  let validationSchema = Yup.object({
    name: ValSchemaList(0, "name"),
    email: ValSchemaList(1, "email"),
    message: ValSchemaList(0, "message"),
    check_privacy: ValSchemaList(5, "check_privacy"),
    check_promotion: ValSchemaList(5, "check_promotion"),
  })

  const onSubmitContact = async (values, { setSubmitting, setStatus }) => {
    setLoading(true)
    setHasErrors(true)
    let obj = {
      name: values.name,
      email: values.email,
      message: values.message,
      check_privacy: values.check_privacy,
      check_promotion: values.check_promotion,
    }

    const getContactMail = await AxiosMethod(`users/contactMail`, `post`, obj);

    if (getContactMail?.data?.status === "success") {
      setHasErrors(false)
      setLoading(false)
      setStatus(getContactMail.data.message)
    }
    if (JSON.parse(getContactMail.request.response).status === "error") {
      setLoading(false)
      setSubmitting(false)
      setStatus(JSON.parse(getContactMail.request.response).message)
      setTimeout(() => {
        navigate("/")
      }, 2500)
    }
  }

  return (
    <>
      <Menu />

      <Row id="row-contact">
        <Col md={12} lg={6} className="mx-auto my-auto py-10">
          {/*begin::Row*/}
          <div
            className="card card-custom mx-5 shadow"
          >
            <div className="card-body py-4">
              <div className="d-flex row me-n7 pe-7">
                <Formik
                  initialValues={initialValues}
                  validationSchema={validationSchema}
                  onSubmit={onSubmitContact}
                >
                  {(formik) => {
                    return (
                      <Form onSubmit={formik.handleSubmit} className="form">
                        {/*begin::Scroll*/}
                        <h1 className="pt-15 text-center">{getLabel("contact_form_title")}</h1>
                        <br />
                        <div className="card-body py-4">
                          <Row
                            className="d-flex row me-n7 pe-7"
                            // className="d-flex row scroll-y me-n7 pe-7"
                            id="kt_modal_add_user_scroll"
                            data-kt-scroll="true"
                            data-kt-scroll-activate="{default: false, lg: true}"
                            // data-kt-scroll-max-height="auto"
                            data-kt-scroll-dependencies="#kt_modal_add_user_header"
                            data-kt-scroll-wrappers="#kt_modal_add_user_scroll"
                          // data-kt-scroll-offset="300px"
                          >
                            {hasErrors && formik.status && (
                              <div className="mb-lg-15 alert alert-danger">
                                <div className="alert-text font-weight-bold">{formik.status}</div>
                              </div>
                            )}

                            {hasErrors === false && (
                              <div className="mb-10 bg-light-info p-8 rounded">
                                <div className="text-info">{formik.status}</div>
                              </div>
                            )}
                            {/* Full Name */}
                            <Col className="mb-7" sm={12} md={6}>
                              <FormikController
                                control="input"
                                type="text"
                                label={getLabel("contact_form_name")}
                                placeholder={`${getLabel("enter")} ${getLabel("contact_form_name")}`}
                                labelClassName="required fw-bold fs-6 mb-2"
                                name="name"
                                className="form-control form-control-solid mb-lg-0"
                                maxLength="25"
                                formik={formik}
                                value={formik.values.name}
                                onChange={formik.handleChange}
                                error={formik.errors.name}
                              />
                            </Col>
                            {/* Email */}
                            <Col className="mb-7" sm={12} md={6}>
                              <FormikController
                                control="input"
                                type="email"
                                label={getLabel("contact_form_email")}
                                placeholder={`${getLabel("enter")} ${getLabel("contact_form_email")}`}
                                labelClassName="required fw-bold fs-6 mb-2"
                                name="email"
                                className="form-control form-control-solid mb-lg-0"
                                maxLength="50"
                                formik={formik}
                                value={formik.values.email}
                                onChange={formik.handleChange}
                                error={formik.errors.email}
                              />
                            </Col>
                            <Col className="mb-7" sm={12}>
                              <FormikController
                                control="textarea"
                                label={getLabel("contact_form_message")}
                                placeholder={`${getLabel("enter")} ${getLabel("contact_form_message")}`}
                                labelClassName="required fw-bold fs-6 mb-2"
                                name="message"
                                className="form-control form-control-solid mb-lg-0"
                                formik={formik}
                                value={formik.values.message}
                                onChange={formik.handleChange}
                                error={formik.errors.message}
                              />
                            </Col>
                            <Col className="mb-7" sm={12}>
                              <div className='form-check form-check-solid'>
                                <input className='form-check-input' required type='checkbox' value={formik.values.check_privacy} id='kt_settings_notification_phone' defaultChecked={formik.values.check_privacy} onChange={(e) => { formik.values.check_privacy = e.target.checked }} />
                                <label className='form-check-label ps-2' htmlFor='kt_settings_notification_phone'>
                                  {getLabel("contact_form_check_privacy")}
                                </label>
                              </div>
                            </Col>
                            <Col className="mb-7" sm={12}>
                              <div className='form-check form-check-solid'>
                                <input className='form-check-input' type='checkbox' value={formik.values.check_promotion} id='kt_settings_notification_phone' defaultChecked={formik.values.contact_form_check_promotion} onChange={(e) => { formik.values.check_promotion = e.target.checked }} />
                                <label className='form-check-label ps-2' htmlFor='kt_settings_notification_phone'>
                                  {getLabel("contact_form_check_promotion")}
                                </label>
                              </div>
                            </Col>
                          </Row>
                        </div>
                        {/*end::Scroll*/}
                        {/*begin::Actions*/}
                        <div className="card-footer d-flex justify-content-center">
                          <button
                            type="submit"
                            id="kt_password_reset_submit"
                            className="btn btn-lg btn-primary fw-bolder me-4"
                            disabled={formik.isSubmitting || !formik.isValid}
                          >
                            {loading ? (
                              <span className="indicator-progress" style={{ display: "block" }}>
                                {getLabel("please_wait")}
                                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                              </span>
                            ) : (
                              <span className="indicator-label">{getLabel("submit")}</span>
                            )}
                          </button>
                        </div>
                        {/*end::Actions*/}
                      </Form>
                    )
                  }}
                </Formik>
              </div>
            </div>
          </div>
          {/*end::Row*/}
        </Col>
        <Col md={12} lg={5} className="text-center mx-auto my-auto">
          <img alt="Auth" id="image-contact" src="/media/img/contact.png" />
        </Col>
      </Row>
    </>
  )
}

export default Contact;