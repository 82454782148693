import {useEffect, useState} from "react"
import {getTempOrg} from "../../../_metronic/helpers/reuseable-fun/FunReuse"
import {getLabel} from "../../../_metronic/language-provider/LanProvider"
import FilterSvg from "../../../_metronic/helpers/svg-helper/FilterSvg"

const DepartmentBuildingFilter = ({params, action}) => {
  const [org, setorg] = useState([])
  const [keyVal, setkeyVal] = useState({
    filterKeys: [params.filterKey],
    filterValues: [params.filterValue],
  })
  const checkReset = () => {
    if (
      JSON.stringify(keyVal.filterKeys) !== JSON.stringify(params.filterKey) ||
      JSON.stringify(keyVal.filterValues) !== JSON.stringify(params.filterValue)
    ) {
      return true
    } else {
      return false
    }
  }
  const getOrg = async () => {
    setorg(await getTempOrg())
  }

  const reset = (flag) => {
    if (flag) {
      setkeyVal({
        filterKeys: [],
        filterValues: [],
      })
      action(params.search, params.limit, 1, params.sortBy, params.sort, [], [])
    } else {
      setkeyVal({
        filterKeys: params.filterKey,
        filterValues: params.filterValue,
      })
    }
  }
  const applyFilter = () => {
    action(
      params.search,
      params.limit,
      1,
      params.sortBy,
      params.sort,
      keyVal.filterKeys,
      keyVal.filterValues
    )
  }
  useEffect(() => {
    getOrg()
  }, [])

  return (
    <>
      <button
        type="button"
        className="btn btn-light-primary me-3 d-flex"
        onClick={() => reset()}
        data-kt-menu-trigger="click"
        data-kt-menu-placement="bottom-end"
      >
        <span className="svg-icon svg-icon-2">
          <FilterSvg />
        </span>
        {getLabel("FILTER")}
	      {
		      (params.filterKey?.length > 0) &&
		      <div className="badge badge-pill badge-danger"  style={{marginLeft:10}}>
			      { params.filterKey?.length}
		      </div>
	      }
      </button>

      <div
        className="menu menu-sub menu-sub-dropdown w-300px w-md-325px"
        data-kt-menu="true"
        style={{}}
      >
        <div className="px-7 py-5">
          <div className="fs-5 text-dark fw-bolder">{getLabel("FILTER_OPTIONS")}</div>
        </div>
        <div className="separator border-gray-200"></div>
        <div className="px-7 py-5" data-kt-user-table-filter="form">
          {/* org filter */}
          <div className="mb-10">
            <label className="form-label fs-6 fw-bold">{getLabel("ORG")} :</label>
            <select
              className="form-select form-select-solid fw-bolder cursor-pointer"
              value={keyVal.filterValues[0] || ""}
              onChange={(e) => {
                e.target.value
                  ? setkeyVal({
                      ...keyVal,
                      filterKeys: ["organization_id"],
                      filterValues: [e.target.value],
                    })
                  : setkeyVal({...keyVal, filterKeys: [""], filterValues: [""]})
              }}
            >
              <option value="">{getLabel("ALL")}</option>
              {org.map((option) => {
                return (
                  <option key={option.user_id} value={option.user_id}>
                    {option.full_name}
                  </option>
                )
              })}
            </select>
          </div>
          {/* reset apply buttons */}
          <div className="d-flex justify-content-end">
            {(checkReset() || (keyVal.filterKeys.length > 0 && keyVal.filterKeys[0] !== "")) && (
              <button
                type="button"
                className="btn btn-light btn-active-light-primary fw-bold me-2 px-6"
                data-kt-menu-dismiss="true"
                data-kt-user-table-filter="reset"
                onClick={() => reset(1)}
              >
                {getLabel("RESET")}
              </button>
            )}
            <button
              type="button"
              className="btn btn-primary fw-bold px-6"
              data-kt-menu-dismiss="true"
              data-kt-user-table-filter="filter"
              disabled={!checkReset()}
              onClick={() => applyFilter()}
            >
              {getLabel("APPLY")}
            </button>
          </div>
        </div>
      </div>
    </>
  )
}

export default DepartmentBuildingFilter
