import {useLocation, useNavigate} from "react-router-dom"
import {toAbsoluteUrl, toProfileImgUrl} from "../../../_metronic/helpers"
import {handleNull} from "../../../_metronic/helpers/reuseable-fun/FunReuse"
import {getLabel} from "../../../_metronic/language-provider/LanProvider"
import {PageTitle} from "../../../_metronic/layout/core"

const ViewOrganization = () => {
  const usersBreadcrumbs = [
    {
      title: `${getLabel("ORG")} ${getLabel("LIST")}`,
      path: "/org/organization-management",
      isSeparator: false,
      isActive: false,
    },
    {
      title: "",
      path: "",
      isSeparator: true,
      isActive: false,
    },
  ]
  const navigate = useNavigate()
  const location = useLocation()

  return (
    <>
      <PageTitle breadcrumbs={usersBreadcrumbs}>
        {`${getLabel("VIEW")} ${getLabel("ORG")}`}
      </PageTitle>

      <div className="card py-2 py-sm-4">
        <div className="card-body">
          <div className="row">
            <div className="col-12 col-sm-4 d-flex justify-content-center align-items-center mt-3 mt-sm-7">
              <div className="me-7 mb-4">
                <div className="symbol symbol-100px symbol-lg-160px symbol-fixed position-relative">
                  {location?.state?.editObj?.profile_pic ? (
                    <img
                      src={toProfileImgUrl(location?.state?.editObj?.profile_pic)}
                      alt=""
                      className="w-100"
                    />
                  ) : (
                    <img
                      src={toAbsoluteUrl("/media/not-found/nologo.png")}
                      alt=""
                      className="w-100"
                    />
                  )}
                </div>
              </div>
            </div>

            <div className="col-12 col-sm-8">
              <div className="row">
                <div className="col-4 mb-5 mt-3">
                  <label className="fs-5 fw-bolder form-label mb-2">
                    <span className="text-capitalize">{getLabel("NAME")}</span>
                  </label>
                  <div>{handleNull(location?.state?.editObj?.full_name)}</div>
                </div>
                <div className="col-5 mb-5 mt-3">
                  <label className="fs-5 fw-bolder form-label mb-2">
                    <span className="text-capitalize">{getLabel("CONTACT_NO")}</span>
                  </label>
                  <div>{handleNull(location?.state?.editObj?.contact_no)}</div>
                </div>
                <div className="col-3 mb-5 mt-3">
                  <label className="fs-5 fw-bolder form-label mb-2">
                    <span className="text-capitalize">{getLabel("USER")}</span>
                  </label>
                  <div>{handleNull(location?.state?.editObj?.["client.full_name"])}</div>
                </div>
                <div className="col-4 mb-5 mt-3">
                  <label className="fs-5 fw-bolder form-label mb-2">
                    <span className="text-capitalize">{getLabel("TIN_NO")}</span>
                  </label>
                  <div>{handleNull(location?.state?.editObj?.tin_no)}</div>
                </div>
                <div className="col-5 mb-5 mt-3">
                  <label className="fs-5 fw-bolder form-label mb-2">
                    <span className="text-capitalize">{getLabel("ADDRESS")}</span>
                  </label>
                  <div>{handleNull(location?.state?.editObj?.address)}</div>
                </div>
                <div className="col-3 mb-5 mt-3">
                  <label className="fs-5 fw-bolder form-label mb-2">
                    <span className="text-capitalize">{getLabel("CITY")}</span>
                  </label>
                  <div>{handleNull(location?.state?.editObj?.city)}</div>
                </div>
                <div className="col-4 mb-5 mt-3">
                  <label className="fs-5 fw-bolder form-label mb-2">
                    <span className="text-capitalize">{getLabel("STATE")}</span>
                  </label>
                  <div>{handleNull(location?.state?.editObj["state.name"])}</div>
                </div>
                <div className="col-5 mb-5 mt-3">
                  <label className="fs-5 fw-bolder form-label mb-2">
                    <span className="text-capitalize">{getLabel("COUNTRY")}</span>
                  </label>
                  <div>{handleNull(location?.state?.editObj["country.name"])}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="text-center mb-5">
          <button
            type="reset"
            style={{color: "black"}}
            className="btn btn-light me-3"
            onClick={() => navigate(-1)}
          >
            {getLabel("DISCARD")}
          </button>
        </div>
      </div>
    </>
  )
}

export default ViewOrganization
