import {useState} from "react"
import OtpInput from "react-otp-input"
import {useLocation, useNavigate} from "react-router-dom"
import AxiosMethod from "../../../../_metronic/Api/AxiosMethod"
import {toAbsoluteUrl} from "../../../../_metronic/helpers"
import {getLabel} from "../../../../_metronic/language-provider/LanProvider"

const TwoStep = () => {
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()
  const location = useLocation()
  const otpLen = 5
  const [otp, setOtp] = useState("")
  const [hasErrors, setHasErrors] = useState()
  const [status, setStatus] = useState()

  const otpChange = (otp) => {
    setOtp(otp)
  }

  const verifyOtp = async () => {
    if (otp.length === otpLen) {
      let obj = {
        otp: otp,
        email: location.state.email,
      }
      setLoading(true)
      const veryfyOtpRes = await AxiosMethod(`users/verifyOtp`, `post`, obj)
      if (veryfyOtpRes?.data?.status === "success") {
        setHasErrors(false)
        setLoading(false)

        navigate("/auth/reset-password", {state: {email: location.state.email}})
      } else {
        setHasErrors(true)
        setLoading(false)
        setStatus(JSON.parse(veryfyOtpRes.request.response).message)
      }
    }
  }

  return (
    <>
      <form
        className="form w-100 mb-10"
        noValidate="novalidate"
        data-kt-redirect-url="/metronic8/demo1/../demo1/index.html"
        id="kt_sing_in_two_steps_form"
      >
        {hasErrors === true && status && (
          <div className="mb-lg-15 alert alert-danger">
            <div className="alert-text font-weight-bold">{status}</div>
          </div>
        )}
        <div className="text-center mb-10">
          <img
            alt="Logo"
            className="mh-125px"
            src={toAbsoluteUrl("/media/svg/misc/smartphone.svg")}
          />
        </div>
        <div className="text-center mb-10">
          <h1 className="text-dark mb-3">{getLabel("TWO_STEP_VERIFICATION")}</h1>
          <div className="text-muted fw-bold fs-5 mb-5">{getLabel("ENTER_SENDED_CODE")}</div>
          <div className="fw-bolder text-dark fs-3">{location?.state?.email}</div>
        </div>
        <div className="mb-10 px-md-10">
          <div className="fw-bolder text-center text-dark fs-6 mb-1 ms-1">
            {getLabel("ENTER_SECURITY_CODE")}
          </div>
          <OtpInput
            containerStyle="justify-content-center"
            value={otp}
            onChange={otpChange}
            numInputs={otpLen}
            isInputNum={true}
            isInputSecure={false}
            separator={<span style={{width: "8px"}}></span>}
            inputStyle={{
              fontWeight: 500,
              fontSize: "calc(1.35rem + 1.2vw) !important",
              backgroundColor: "#F5F8FA",
              cursor: "pointer",
              border: "1px solid #E4E6EF",
              borderRadius: "8px",
              width: "54px",
              height: "54px",
              color: "#000",
            }}
            focusStyle={{
              border: "1px solid #CFD3DB",
              outline: "none",
            }}
          />
        </div>
        <div className="d-flex flex-center" style={{}}>
          <button
            type="button"
            id="kt_sing_in_two_steps_submit"
            className="btn btn-lg btn-primary fw-bolder"
            onClick={() => verifyOtp()}
          >
            {loading ? (
              <span className="indicator-progress" style={{display: "block"}}>
                {getLabel("PLEASE_WAIT")}
                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
              </span>
            ) : (
              <span className="indicator-label">{getLabel("SUBMIT")}</span>
            )}
          </button>
        </div>
      </form>
    </>
  )
}

export default TwoStep
