import {useLocation, useNavigate} from "react-router-dom"
import {getLabel} from "../../../_metronic/language-provider/LanProvider"
import {PageTitle} from "../../../_metronic/layout/core"
import moment from "moment"
import {weekView} from "./DepartmentList"
import {useEffect} from "react"
import {handleNull, userUtcOffset} from "../../../_metronic/helpers/reuseable-fun/FunReuse"

const ViewDepartment = () => {
  const usersBreadcrumbs = [
    {
      title: `${getLabel("SCHEDULE")} ${getLabel("LIST")}`,
      path: "/org/schedule-management",
      isSeparator: false,
      isActive: false,
    },
    {
      title: "",
      path: "",
      isSeparator: true,
      isActive: false,
    },
  ]
  const navigate = useNavigate()
  const location = useLocation()

  useEffect(() => {}, [])
  return (
    <>
      <PageTitle breadcrumbs={usersBreadcrumbs}>
        {`${getLabel("VIEW")} ${getLabel("SCHEDULE")}`}
      </PageTitle>

      <div className="card py-2 py-sm-4">
        <div className="card-body">
          <div className="row">
            <div className="col-12">
              <div className="row">
                <div className="col-4 mb-5 mt-3">
                  <label className="fs-5 fw-bolder form-label mb-2">
                    <span>{getLabel("ORG")}</span>
                  </label>
                  <div className="text-capitalize">
                    {handleNull(location?.state?.editObj["user.full_name"])}
                  </div>
                </div>
                <div className="col-4 mb-5 mt-3">
                  <label className="fs-5 fw-bolder form-label mb-2">
                    <span>{getLabel("NAME")}</span>
                  </label>
                  <div className="text-capitalize">
                    {handleNull(location?.state?.editObj?.name)}
                  </div>
                </div>
                <div className="col-4 mb-5 mt-3">
                  <label className="fs-5 fw-bolder form-label mb-2">
                    <span>{getLabel("DESC")}</span>
                  </label>
                  <div className="text-capitalize">
                    {handleNull(location?.state?.editObj?.description)}
                  </div>
                </div>
                <div className="col-4 mb-5 mt-3">
                  <label className="fs-5 fw-bolder form-label mb-2">
                    <span>{getLabel("START_HOUR")}</span>
                  </label>
                  <div className="text-capitalize">
                    {handleNull(
                      moment(location?.state?.editObj?.start_shift)
                        .utcOffset(userUtcOffset)
                        .format("HH:mm")
                    )}
                  </div>
                </div>
                <div className="col-4 mb-5 mt-3">
                  <label className="fs-5 fw-bolder form-label mb-2">
                    <span>{getLabel("END_HOUR")}</span>
                  </label>
                  <div className="text-capitalize">
                    {handleNull(
                      moment(location?.state?.editObj?.end_shift)
                        .utcOffset(userUtcOffset)
                        .format("HH:mm")
                    )}
                  </div>
                </div>
                <div className="col-4 mb-5 mt-3">
                  <label className="fs-5 fw-bolder form-label mb-2">
                    <span>{getLabel("WEEKDAYS")}</span>
                  </label>
                  <div className="text-capitalize">
                    {location?.state?.editObj?.weekdays
                      ? weekView(location?.state?.editObj)
                      : "N/A"}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="text-center mb-5">
          <button
            type="reset"
            style={{color: "black"}}
            className="btn btn-light me-3"
            onClick={() => navigate(-1)}
          >
            {getLabel("DISCARD")}
          </button>
        </div>
      </div>
    </>
  )
}

export default ViewDepartment
