import { useEffect, useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import { Formik, Form } from "formik"
import toast from "react-hot-toast"
import * as Yup from "yup"

// Components imports
import { getTempCountries, getTempState } from "../../../../_metronic/helpers/reuseable-fun/FunReuse"
import { TakeEditedVal } from "../../../../_metronic/helpers/only-edit-value/TakeEditedVal"
import { getLabel } from "../../../../_metronic/language-provider/LanProvider"
import { ValSchemaList } from "../../../../_metronic/Shared-Component-formik/schema/ValSchemaList"
import AxiosMethod from "../../../../_metronic/Api/AxiosMethod"
import FormikController from "../../../../_metronic/Shared-Component-formik/FormikController"

const ClientRegister = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const [needInvoice, setneedInvoice] = useState(false)

  // useStates
  const [loading, setLoading] = useState(false)
  const [profileImg, setProfileImg] = useState("")
  const [stateList, setStateList] = useState([])
  const [countryList, setCountryList] = useState([])
  const [countryIdForState, setCountryIdForState] = useState(
    location?.state?.editObj?.country_id || null
  )

  const initialValues = {
    full_name: "",
    email: "",
    contact_no: "",
    address: "",
    city: "",
    state_id: "",
    country_id: "",
    password: "",
    company_name: "",
    postal_code: "",
  }
  let validationSchema = Yup.object({
    full_name: ValSchemaList(0, "FULL_NAME"),
    email: ValSchemaList(1, "EMAIL"),
    // contact_no: ValSchemaList(4, "CONTACT_NO"),
    password: ValSchemaList(8, "PASSWORD"),
    ...(needInvoice && {
      company_name: ValSchemaList(0, "COMPANY_NAME"),
      postal_code: ValSchemaList(0, "POSTAL_CODE"),
    }),
  })

  const getCountries = async () => {
    setCountryList(await getTempCountries())
  }

  const getState = async () => {
    setStateList(await getTempState(countryIdForState))
  }

  const onAddClient = async (values) => {
    setLoading(true)
    let temp = TakeEditedVal(values, initialValues)
    var bodyFormData = new FormData()
    for (var key in temp) {
      bodyFormData.append(key, temp[key])
    }
    bodyFormData.append("role_id", 4)
    bodyFormData.append("plan_id", location?.state?.editObj.plan_id)

    let createUsersRes = await AxiosMethod(`users/registerClient`, `post`, bodyFormData)
    if (createUsersRes && createUsersRes.status === 200) {
      // pay obj of uId and Pid
      let obj = { userId: createUsersRes.data.data.user_id, planId: location?.state?.editObj.plan_id }
      localStorage.setItem("obj", JSON.stringify(obj))

      let makePaymetnRes = await AxiosMethod(
        `payments/makePayment`,
        `post`,
        location?.state?.editObj
      )
      if (makePaymetnRes?.data?.status === "success") {
        // generate qrcode
        let generateQrRes = await AxiosMethod(`qrcodes/generate`, `post`, {
          email: values.email,
        })
        if (generateQrRes && generateQrRes.status === 200) {
          setLoading(false)
          toast.success(getLabel("USER_CREATED_SUCCESS"))
          // toast.success("QRCode generated successfully")
          window.open(makePaymetnRes.data.payUrl, "_self")
        }
        if (JSON.parse(generateQrRes.request.response).status === "error") {
          toast.error(JSON.parse(generateQrRes.request.response).message)
        }
      }
      if (JSON.parse(makePaymetnRes.request.response).status === "error") {
        setLoading(false)
        toast.error("Something went wrong, Please try again after some time.")
      }
    }
    if (JSON.parse(createUsersRes.request.response).status === "error") {
      setLoading(false)
      toast.error(JSON.parse(createUsersRes.request.response).message)
    }
  }

  useEffect(() => {
    getCountries()
  }, [])
  useEffect(() => {
    getState()
  }, [countryIdForState])

  return (
    <>
      <div className="d-flex flex-column flex-column-fluid" style={{ height: "100vh" }}>
        <div className="mb-10">
          <div className="card" id="kt_pricing">
            <div className="card-body p-lg-17">
              <div className="d-flex flex-column">
                <div
                  className="card card-custom"
                  style={{ boxShadow: "0px 0px 30px 10px rgb(157 157 157)" }}
                >
                  <div className="card-body py-4">
                    <div className="d-flex row me-n7 pe-7">
                      <Formik
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        onSubmit={onAddClient}
                      >
                        {(formik) => {
                          formik.values.country_id && setCountryIdForState(formik.values.country_id)
                          return (
                            <Form onSubmit={formik.handleSubmit} className="form">
                              <h1 className="pt-15 text-center">{getLabel("REG_ORG_USER")}</h1>
                              <br />
                              <div className="card-body py-4">
                                <div
                                  className="d-flex row me-n7 pe-7"
                                  // className="d-flex row scroll-y me-n7 pe-7"
                                  id="kt_modal_add_user_scroll"
                                  data-kt-scroll="true"
                                  data-kt-scroll-activate="{default: false, lg: true}"
                                  // data-kt-scroll-max-height="auto"
                                  data-kt-scroll-dependencies="#kt_modal_add_user_header"
                                  data-kt-scroll-wrappers="#kt_modal_add_user_scroll"
                                // data-kt-scroll-offset="300px"
                                >
                                  <div className="col-6 mb-7">
                                    <FormikController
                                      control="input"
                                      type="text"
                                      label={getLabel("FULL_NAME")}
                                      placeholder={`${getLabel("ENTER")} ${getLabel("FULL_NAME")}`}
                                      labelClassName="required fw-bold fs-6 mb-2"
                                      name="full_name"
                                      className="form-control form-control-solid mb-lg-0"
                                      maxLength="25"
                                      formik={formik}
                                      value={formik.values.full_name}
                                      onChange={formik.handleChange}
                                      error={formik.errors.full_name}
                                    />
                                  </div>
                                  <div className="col-6 mb-7">
                                    <FormikController
                                      control="input"
                                      type="email"
                                      label={getLabel("EMAIL")}
                                      placeholder={`${getLabel("ENTER")} ${getLabel("EMAIL")}`}
                                      labelClassName="required fw-bold fs-6 mb-2"
                                      name="email"
                                      className="form-control form-control-solid mb-lg-0"
                                      maxLength="50"
                                      formik={formik}
                                      value={formik.values.email}
                                      onChange={formik.handleChange}
                                      error={formik.errors.email}
                                    />
                                  </div>
                                  <div className="col-6 mb-7">
                                    <FormikController
                                      control="input"
                                      type="text"
                                      label={getLabel("CONTACT_NO")}
                                      placeholder={`${getLabel("ENTER")} ${getLabel("CONTACT_NO")}`}
                                      labelClassName="fw-bold fs-6 mb-2"
                                      name="contact_no"
                                      className="form-control form-control-solid mb-lg-0"
                                      onKeyPress={(event) => {
                                        if (!/[0-9]/.test(event.key)) {
                                          event.preventDefault()
                                        }
                                      }}
                                      maxLength="20"
                                      formik={formik}
                                      value={formik.values.contact_no}
                                      onChange={formik.handleChange}
                                      error={formik.errors.contact_no}
                                    />
                                  </div>
                                  <div className="col-6 mb-7">
                                    <FormikController
                                      control="input"
                                      type="text"
                                      label={getLabel("ADDRESS")}
                                      placeholder={`${getLabel("ENTER")} ${getLabel("ADDRESS")}`}
                                      labelClassName="fw-bold fs-6 mb-2"
                                      name="address"
                                      className="form-control form-control-solid mb-lg-0"
                                      maxLength="100"
                                      formik={formik}
                                      value={formik.values.address}
                                      onChange={formik.handleChange}
                                      error={formik.errors.address}
                                    />
                                  </div>
                                  <div className="col-6 mb-7">
                                    <FormikController
                                      control="input"
                                      type="text"
                                      label={getLabel("CITY")}
                                      placeholder={`${getLabel("ENTER")} ${getLabel("CITY")}`}
                                      labelClassName="fw-bold fs-6 mb-2"
                                      name="city"
                                      className="form-control form-control-solid mb-lg-0"
                                      maxLength="25"
                                      formik={formik}
                                      value={formik.values.city}
                                      onChange={formik.handleChange}
                                      error={formik.errors.city}
                                    />
                                  </div>
                                  <div className="col-6 mb-7">
                                    <FormikController
                                      control="select"
                                      label={getLabel("COUNTRY")}
                                      labelClassName="fw-bold fs-6 mb-2"
                                      name="country_id"
                                      className="form-select form-select-solid"
                                      options={countryList}
                                      value={formik.values.country_id}
                                      onChange={(e) => {
                                        formik.handleChange(e)
                                        getState(e.target.value)
                                      }}
                                      error={formik.errors.country_id}
                                    />
                                  </div>
                                  <div className="col-6 mb-7">
                                    <FormikController
                                      control="select"
                                      label={getLabel("STATE")}
                                      labelClassName="fw-bold fs-6 mb-2"
                                      name="state_id"
                                      className="form-select form-select-solid"
                                      options={stateList}
                                      value={formik.values.state_id}
                                      onChange={formik.handleChange}
                                      error={formik.errors.state_id}
                                    />
                                  </div>
                                  {/* <div className="col-6 mb-7">
                                    <FormikController
                                      control="input"
                                      type="text"
                                      label={getLabel("ORG")}
                                      labelClassName="required fw-bold fs-6 mb-2"
                                      name="Organization"
                                      className="form-control form-control-solid mb-lg-0"
                                      maxLength="60"
                                      formik={formik}
                                      value={formik.values.Organization}
                                      onChange={formik.handleChange}
                                      error={formik.errors.Organization}
                                    />
                                  </div> */}

                                  <div className="col-6 mb-7">
                                    <FormikController
                                      control="input"
                                      type="password"
                                      label={getLabel("PASSWORD")}
                                      placeholder={`${getLabel("ENTER")} ${getLabel("PASSWORD")}`}
                                      labelClassName="required fw-bold fs-6 mb-2"
                                      name="password"
                                      className="form-control form-control-solid mb-lg-0 pe-13"
                                      autoComplete="on"
                                      maxLength="30"
                                      formik={formik}
                                      value={formik.values.password}
                                      onChange={formik.handleChange}
                                      error={formik.errors.password}
                                    />
                                  </div>
                                  <div className="col-6 mb-7">
                                    <label htmlFor="expire">
                                      <input
                                        id="expire"
                                        style={{ marginTop: "1rem" }}
                                        type="checkbox"
                                        name="checkBoxChoice"
                                        value={needInvoice}
                                        onChange={() => {
                                          !needInvoice && formik.setFieldTouched("company_name", false)
                                          !needInvoice && formik.setFieldTouched("postal_code", false)
                                          setneedInvoice(!needInvoice)
                                        }}
                                      />
                                      <span style={{ marginLeft: ".5rem" }}>
                                        {getLabel("NEED_INVOICE")}
                                      </span>
                                    </label>
                                    <br />
                                    <br />

                                    {needInvoice && (
                                      <>
                                        <FormikController
                                          control="input"
                                          type="text"
                                          label={getLabel("COMPANY_NAME")}
                                          placeholder={`${getLabel("ENTER")} ${getLabel(
                                            "COMPANY_NAME"
                                          )}`}
                                          labelClassName="required fw-bold fs-6 mb-2"
                                          name="company_name"
                                          className="form-control form-control-solid mb-lg-0"
                                          maxLength="25"
                                          formik={formik}
                                          value={formik.values.company_name}
                                          onChange={formik.handleChange}
                                          error={formik.errors.company_name}
                                        />
                                        <br />
                                        <br />
                                        <FormikController
                                          control="input"
                                          type="text"
                                          label={getLabel("POSTAL_CODE")}
                                          placeholder={`${getLabel("ENTER")} ${getLabel(
                                            "POSTAL_CODE"
                                          )}`}
                                          labelClassName="required fw-bold fs-6 mb-2"
                                          name="postal_code"
                                          className="form-control form-control-solid mb-lg-0"
                                          maxLength="25"
                                          formik={formik}
                                          value={formik.values.postal_code}
                                          onChange={formik.handleChange}
                                          error={formik.errors.postal_code}
                                        />
                                      </>
                                    )}
                                  </div>
                                  <div className="col-6 mb-7">
                                    <FormikController
                                      control="image"
                                      label={getLabel("PROFILE_IMG")}
                                      labelClassName="fw-bold fs-6 mb-2"
                                      name="profile_pic"
                                      // className="form-control form-control-solid mb-lg-0"
                                      profileImg={profileImg}
                                      setProfileImg={setProfileImg}
                                      editFlag={location?.state?.edit}
                                      editPath={location?.state?.editObj?.profile_pic}
                                      formik={formik}
                                      value={formik.values.profile_pic}
                                      onChange={formik.handleChange}
                                      error={formik.errors.profile_pic}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="card-footer d-flex justify-content-center">
                                <button
                                  className="btn btn-light-primary font-weight-bold"
                                  style={{ marginRight: "1rem" }}
                                  type="button"
                                  onClick={() => {
                                    navigate("/auth")
                                  }}
                                >
                                  <span className="indicator-label">{getLabel("CANCEL")}</span>
                                </button>
                                <button
                                  type="submit"
                                  id="kt_password_reset_submit"
                                  className="btn btn-lg btn-primary fw-bolder me-4"
                                  disabled={formik.isSubmitting || loading}
                                >
                                  {loading ? (
                                    <span className="indicator-progress" style={{ display: "block" }}>
                                      {getLabel("PLEASE_WAIT")}
                                      <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                    </span>
                                  ) : (
                                    <span className="indicator-label">{getLabel("SUBMIT")}</span>
                                  )}
                                </button>
                              </div>
                            </Form>
                          )
                        }}
                      </Formik>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ClientRegister
