import {useEffect, useRef, useState} from "react"
import {globals} from "../../utils/globals"
import {useDispatch, useSelector} from "react-redux"
import {useLocation, useNavigate} from "react-router-dom"
import "./calling.style.scss";
import {
	onSocketConnected,
	onSocketConnectionFailure,
	onSocketDisconnect,
	readyTheSocketConnection
} from "../../services/calling.service"
import {database} from "../../services/firebase.service"
import {ref, child, update, get, onValue, off} from "firebase/database"
import {socket, updateCallRealTime, updateCallStatus, updateCallStatusByUserId} from "../../services/helper.service"
import toast from "react-hot-toast"
import {MasterLayout} from "../../../_metronic/layout/MasterLayout"
import {PageTitle} from "../../../_metronic/layout/core"
import {getLabel} from "../../../_metronic/language-provider/LanProvider"

const IncomingCallComponent = () =>
{
	useEffect (() =>
	{
		if (!navigator.onLine) alert ("Connect to internet!")
	}, [navigator])

	// STATE WHICH IS USE TO DECLARE VARIABLES
	const dispatch = useDispatch ();
	const navigate = useNavigate ()

    // GET THE CURRENT LOCATION AND STATE FROM REACT ROUTER
    const location = useLocation();
	const {state}: any = location;

	const email = useSelector ((state: any) => state?.AuthReducer?.email)
	const user_id = useSelector ((state: any) => state?.AuthReducer?.user_id)
	const user = useSelector((state: any) => state?.AuthReducer?.userData)
	const [caller, setCaller] = useState<any> ();
	const [isCallActive, setIsCallActive] = useState<boolean> (false);
	let countDownTimer: any = useRef<any> (null);  // HOLD THE AUTO DISCONNECTED CALL TIMER.
	const [ringtone, setRingtone] = useState<any> (null); // VARIABLE TO STORE RINGTONE OBJECT
	const audio: any = new Audio ('./media/ringtone.mp3'); // PROVIDE PATH TO YOUR RINGTONE

	// GET A CALL DATABASE REFERENCE.
	const databaseRef = ref (database, 'call');

	// HOOK TO DISCONNECT THE CALL AFTER ONE MINUTE IF NOT PICKED
	useEffect (() =>
	{
		if (globals?.call_id !== "" && globals?.call_id !== null)
		{
			// CHECK THE SOCKET IS NOT CONNECTED THEN CONNECTED SOCKET.
			if (globals.socket === null)
			{
				globals.socket = socket;
			}

			// LISTEN FOR CHANGES IN THE DATABASE REFERENCE "globals.call_id"
			// child ( databaseRef, globals.call_id).on ("value", handleCallDetailSnapshot);
			console.log ("useEffect globals.call_id", globals.call_id);

			if (globals.call_id)
			{
				// UPDATE THE DATABASE "CALL" WITH A NEW CALL STATUS "RING"
				updateCallStatus (globals.call_id, "RING").then ();
				updateCallStatusByUserId (globals.call_id, "RING", user_id).then ();
				console.log ("RING globals.call_id", globals.call_id);
			}
			subscribeToAllCallSocketEvents ().then (); // SUBSCRIBE THE CALL SOCKET EVENTS.

			// SET THE ONE-MINUTE CALL AUTO DISCONNECTED WHEN USER  NO ANY RESPONSE.
			countDownTimer.current = setTimeout (async () =>
			{
				console.log ("Timer Stop here you need to do something ");

				// CHECK IF THE CALL IS STILL ACTIVE BEFORE DISCONNECTING
				if (!isCallActive && !globals.isCallActive)
				{
					console.log ("onFinish disconnectCallAfterOneMinute");

					// STOP THE RINGTONE
					if (ringtone)
					{
						ringtone.pause ();
						ringtone.currentTime = 0;
					}

					// UPDATE THE DATABASE CALL WITH A NEW CALL STATUS "MISS"
					await updateCallStatusByUserId (globals.call_id, "MISS", user_id)

					// DISCONNECT THE CALL
					rejectCall (false, false, "");
					let route: any = state?.navigateFrom && state.navigateFrom ==="BACKGROUND_NOTIFICATION"? "/dashboard" : "/dashboard"//-1
					navigate (route)
				}
			}, 	globals.maxResponseSeconds * 1000 ); // 45,000 MILLISECONDS;

			// SET THE CALLER NAME GETTING QR CODE AND ORGANIZATION NAME AND CALLER FILED NAME.
			if (globals.callerDetail)
			{
				setCaller (globals.callerDetail);
				console.log ("globals.callerDetail", globals.callerDetail);
			}

			// FUNCTION TO START THE RINGTONE.
			if (audio)
			{
				audio.loop = true;
				audio.play ();
				setRingtone (audio);
			}

			// CLEANUP FUNCTION TO STOP THE RINGTONE AND TIME OUT FUNCTION
			return () =>
			{
				if (countDownTimer)
				{
					clearTimeout (countDownTimer.current);
				}
				audio.pause ();
				audio.currentTime = 0;

				//	InCallManager.stop();
				unsubscribeToAllCallSocketEvents ().then ();
			};
		}
	}, [navigate, globals?.call_id]);

	useEffect (() =>
	{
		if (globals.call_id)
		{
			const callRealTimeRef = child (databaseRef, globals.call_id);
			const callStatusRef = child (callRealTimeRef, "callstatus");

			// LISTEN FOR CHANGES IN 'CALL' NODE
			onValue (callStatusRef, handleCallDetailSnapshot);

			// CLEANUP FUNCTION TO SUBSCRIPTION WHEN COMPONENT UNMOUNTS
			return () =>
			{
				// STOP LISTENING FOR CHANGES
				off (callStatusRef, 'value', handleCallDetailSnapshot);
			};
		}
	}, [globals.call_id]);

	// HANDLE SNAPSHOT UPDATES: IF THE CALL STATUS IS "ANSWERED" AND hasAnswered FLAG IS FALSE THEN
	// TERMINATE THE INCOMING CALL SCREEN.
	const handleCallDetailSnapshot = (snapshot:any)=>
	{
		const callDetail = snapshot.val();
		console.log ("Call detail:", callDetail);

		// CHECK THE CALL STATUS IS "ANSRD"
		if (["ANSRD", "ACCPT"].indexOf(callDetail) !== -1 && !globals.hasAnswered)
		{
			rejectCall (true, false, "Kill Notification");
		}
	}

	// WHEN USER REJECT THE CALL THEN SEND THE SERVER EMIT THE CALL REJECTED BY USER.
	const rejectCall = async (isKillNotification: boolean, isRejected:boolean, reason: string) =>
	{
		// STOP THE TIMER.
		cancelCoundDownTimer ();
		// clearUserFlag();

		// ISFROMKILLNOTIFICATION FLAG IS WHEN FLASE THEN EMIT SOCKET
		// OTHER WISE DO NOT DO ANYTHING
		if (globals?.socket != null && !isKillNotification && isRejected)
		{
			const callerSocketID = globals.callerSocketID;
			const staffs = globals.callStaffData;
			try
			{
				await updateCallStatusByUserId (globals.call_id, "REJTD", user_id);
				
				// IF USER IS NOT DEFAULT USER THEN UPDATE THE REJECTED_USER_ID FILED.
				if (!globals.isThisDefaultStaffNotified)
				{
					const childRef = await child (databaseRef, globals.call_id);
					get (childRef).then ((snapshot) =>
					{
						const fetched = snapshot.val ();
						console.log (fetched)
						let rejectedby_user_id = fetched.rejectedby_user_id || [];
						if (!rejectedby_user_id.includes (user_id))
						{
							rejectedby_user_id.push (user_id);
						}
						updateCallRealTime (globals.call_id, {rejectedby_user_id: rejectedby_user_id, reason: reason});
					});
				}
			} catch (error)
			{
				console.error ("Error retrieving rejected user ID array from the database:", error);
			}

			let rejection =
			{
				call_id : globals.call_id,
				type: reason,
				callstatus: "REJTD",
				callee_email: email
			}
			globals.socket.emit ("reject-call", staffs, callerSocketID, email, rejection);
		}
		disconnectedSocket();

		// DISCONNECT THE CALL
		//cancelTheScreen (navigation);
		//navigate(-1)
		let route: any = state?.navigateFrom && state.navigateFrom ==="BACKGROUND_NOTIFICATION"? "/dashboard" : "/dashboard"//-1
        navigate (route)
	}

	// THIS FUNCTION PURPOSE TO ANSWER THE BUTTON AND SEND TO NOTIFICATION TO SERVER RECEIVE THE CALL.
	const answerCall = async () =>
	{
		const callIdRef = await child (databaseRef, globals.call_id);
		const answeredData = await get (child (callIdRef, "answaredby_fullname"));
		const answeredByUser = await  answeredData.val();

		// STOP THE TIMER.
		cancelCoundDownTimer ();
		if (typeof answeredByUser === "undefined" || answeredByUser == null)
		{
			setIsCallActive (true);
			globals.isCallActive = true;
			globals.activeCallId = 1;
			globals.activeCallStatus = "CONNECTED";
			globals.hasAnswered = true;

			let answeredBy =
			{
                answeredby_user_id: user_id,
                answaredby_fullname: user?.full_name,
                isCallConnected: 1,
			}

			// UPDATE THE DATABASE CALL WITH A NEW CALL STATUS "ACCPT"
			updateCallStatusByUserId (globals.call_id, "ACCPT", user_id)
			await updateCallStatus (globals.call_id, "ACCPT", answeredBy)

			// NAVIGATE TO VIDEO CALL COMPONENT SHOW FOR VIDEO CALL.
			navigate ("/callcomponent",
			{
				state: {
					callerName: caller?.name,
					organizationName: caller["organization_name"],
					qrName: caller["qrcode_name"]
				}
			});
		}
		else
		{
			toast.error ("The call was answered by "+ answeredByUser);

			// DISCONNECT THE CALL
			//navigate (-1);
            let route: any = state?.navigateFrom && state.navigateFrom ==="BACKGROUND_NOTIFICATION"? "/dashboard" : "/dashboard"//-1
            navigate (route)
		}
	}

	// FUNCTION PURPOSE CANCEL THE AUTO DISCONNECTED TIMER WHEN USER PICK THE CALL.
	const cancelCoundDownTimer = () =>
	{
		// STOP THE RINGTONE.
		if (ringtone)
		{
			ringtone.pause();
			ringtone.currentTime = 0;
		}

		clearTimeout (countDownTimer);
		countDownTimer = null ; // RESET THE TIMER STATE
		console.log ("cancelCoundDownTimer  ");
	}

	// FUNCTION TO HANDLE KILL NOTIFICATION AND SENT TO SERVER.
	const handleKillNotification = async (data: any) =>
	{
		console.log ("onKillNotification() ", data, "socket_id:", globals.socket.id);
		rejectCall (true, false, "Kill Notification");
	};

	// CONNECT SOCKET SERVER.
	const connectSocket = async () =>
	{

		if (globals.socket != null && globals.socket.connected)
		{
			console.log ("connect_Socket socket connected found ");
		}
		else
		{
			console.warn ("connect_socket socket is disconnected.then try to connected.");
			globals.socket = null;
			await readyTheSocketConnection();
		}
	}

	// DISCONNECTED SOCKET SERVER.
	const disconnectedSocket= ()=>
	{
		if (globals.socket != null && globals.socket.connected)
		{
			globals.socket.disconnect();
			unsubscribeToAllCallSocketEvents().then();
			globals.socket = null;
		}
		else
		{
			console.log ("disconnectedSocket() socket null or disconnected found ");
		}
	}

	// LISTEN TO CALL SOCKET EVENTS.
	const subscribeToAllCallSocketEvents = async () =>
	{
		if (globals.socket && globals.socket.connected)
		{
			globals.socket.on ("disconnect", onSocketDisconnect);
			globals.socket.on ("connect_error", onSocketConnectionFailure);
			globals.socket.on ("kill-notification", handleKillNotification);
		}
	}

	// UNSUBSCRIBE THE LISTENER TO CALL SOCKET EVENTS.
	const unsubscribeToAllCallSocketEvents = async () =>
	{
		// CHECK IF GLOBALS.SOCKET IS DEFINED BEFORE TRYING TO UNSUBSCRIBE
		if (globals.socket && globals.socket.connected)
		{
			// UNSUBSCRIBE FROM SOCKET EVENTS
			globals.socket.off ("connect", onSocketConnected);
			globals.socket.off ("disconnect", onSocketDisconnect);
			globals.socket.off ("connect_error", onSocketConnectionFailure);
			globals.socket.off ("kill-notification", handleKillNotification);
		}
	}

	return (

		<>
		{
			globals?.call_id !== "" && globals?.call_id !== null
					?
				<div className="incoming-call">
			<div className="callerContainer">
				<div className="callerInfo">
					{caller && <h2 className="orgText">{caller["organization_name"]}</h2>}
					{caller && <h3 className="qrcText">{caller["qrcode_name"]}</h3>}
					{caller?.email && <h4 className="textCall">{caller?.email}</h4>}
					{caller?.name && <h4 className="textCall">{caller?.name}</h4>}
					{caller?.phone && <h4 className="textCall">{caller?.phone}</h4>}
					<p className="marginTop18" style={{color:"green"}}>Incoming call</p>
				</div>
			</div>
			<div className="button-container">

				<button
					className="reject-btn"
					onClick={()=> rejectCall (false, true, "Call Rejection")}
				>
					Reject
				</button>
				<button
					className="answer-btn"
					onClick={answerCall}
				>
					Accept
				</button>
			</div>
		</div>
			:
			<MasterLayout>
				<PageTitle breadcrumbs={[]}>{getLabel ("CALLS")}</PageTitle>
				<h1>{"Sorry, no incoming calls at the moment."}</h1>
			</MasterLayout>
		}
		</>
	)
}

export default IncomingCallComponent;
