/* eslint-disable jsx-a11y/anchor-is-valid */
// import {useNavigate} from "react-router-dom"
import { toProfileImgUrl } from "../../../_metronic/helpers"
import { DeleteSvg } from "../../../_metronic/helpers/svg-helper/DeleteSvg"
// import EditSvg from "../../../_metronic/helpers/svg-helper/EditSvg"
import ViewSvg from "../../../_metronic/helpers/svg-helper/ViewSvg"
import { useState } from "react"
import { handleNull } from "../../../_metronic/helpers/reuseable-fun/FunReuse"
import ConfirmModel from "../../../_metronic/helpers/model/ConfirmModel"
import { getLabel } from "../../../_metronic/language-provider/LanProvider"

const OrgManagerRows = ({ user, deleteClient }) => {
  const [delId, setdelId] = useState("")

  return (
    <tr key={user.user_id} className="odd">
      <td className="d-flex align-items-center">
        <div className="symbol symbol-circle symbol-50px overflow-hidden me-3">
          <a>
            <div className="symbol-label fs-3 bg-light-primary text-primary">
              {user.profile_pic ? (
                <img src={toProfileImgUrl(user.profile_pic)} alt="" className="w-100" />
              ) : (
                user.full_name
                  .match(/\b(\w)/g)
                  .join("")
                  .toUpperCase()
              )}
            </div>
          </a>
        </div>
        <div className="d-flex flex-column">
          <a className="text-gray-800 text-hover-primary mb-1">
            {user.full_name}
            <br />
            {user.contact_no}
          </a>
          <span>{user.email}</span>
        </div>
      </td>
      <td>{handleNull(user["country.name"])}</td>
      <td>
        {user.organizations_array
          .map((x) => (
            <span style={{ display: "block", whiteSpace: "nowrap" }} key={x}>
              {x}
            </span>
          ))}
      </td>
      <td>
        <div className="d-flex justify-content-end flex-shrink-0">
          <ViewSvg
            path="/org/manager/view-organization-manager"
            editObj={user}
          />
          {/* <EditSvg
            path="/org/manager/manage-organization-manager"
            editObj={user}
          /> */}
          <DeleteSvg onClick={() => setdelId(user.user_id)} />

          <ConfirmModel
            delMsz={getLabel("ORG_MANAGER")}
            show={delId}
            onHide={setdelId}
            onConfirmDel={deleteClient}
          />
        </div>
      </td>
    </tr>
  )
}

export default OrgManagerRows
