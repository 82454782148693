const CommonPage = ({displayDiv}) => {
  return (
    <div className="main-wrap">
      <div className="container">
        <div className="row">
          <div className="col-xxl-12 m-auto text-center after-call-screen thankscall-wrap">
            {/* props component */}
            {displayDiv}
          </div>
        </div>
      </div>
    </div>
  )
}

export default CommonPage
