import {combineReducers} from "redux"
import AuthReducer from "./AuthReducer"
import LanguageReducer from "./LanguageReducer"
import QueryParamsReducer from "./QueryParamsReducer"
import LoaderReducer from "./LoaderReducer"

const rootReducer = combineReducers({
  AuthReducer: AuthReducer,
  LanguageReducer: LanguageReducer,
  QueryParamsReducer: QueryParamsReducer,
  LoaderReducer: LoaderReducer,
})
export default rootReducer
