import toast from 'react-hot-toast'
import { Formik, Form } from 'formik'
import { useNavigate } from 'react-router-dom'
import { useEffect, useState } from 'react'
import * as Yup from "yup"
import { useDispatch, useSelector } from 'react-redux'
import { TakeEditedVal } from '../../../_metronic/helpers/only-edit-value/TakeEditedVal'
import AxiosMethod from '../../../_metronic/Api/AxiosMethod'
import { AuthAction } from '../../../_metronic/redux/actions/AuthAction'
import FormikController from '../../../_metronic/Shared-Component-formik/FormikController'
import { getLabel } from '../../../_metronic/language-provider/LanProvider'
import { ValSchemaList } from '../../../_metronic/Shared-Component-formik/schema/ValSchemaList'
import { getTempCountries, getTempState } from '../../../_metronic/helpers/reuseable-fun/FunReuse'
import { PageTitle } from '../../../_metronic/layout/core'

const UpdateUserProfile = () => {
  const [loading, setLoading] = useState(false)
  const [userData, setuserData] = useState()
  const loggedInUser = useSelector((state) => state?.AuthReducer.userData)
  const authData = useSelector((state) => state?.AuthReducer)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [profileImg, setProfileImg] = useState('')
  const [countryList, setCountryList] = useState([])
  const [stateList, setStateList] = useState([])
  const [countryIdForState, setCountryIdForState] = useState(null)


  const initialValues = {
    full_name: userData ? userData?.full_name : "",
    email: userData ? userData?.email : "",
    contact_no: userData ? userData?.contact_no : "",
    address: userData ? userData?.address : "",
    city: userData ? userData?.city : "",
    state_id: userData ? userData?.state_id : "",
    country_id: userData ? userData?.country_id : "",
    company_name: userData ? userData?.company_name : "",
    postal_code: userData ? userData?.postal_code : "",
  }
  let validationSchema = Yup.object({
    full_name: ValSchemaList(0, "FULL_NAME"),
    email: ValSchemaList(1, "EMAIL"),
    // contact_no: ValSchemaList(4, "CONTACT_NO"),
  })
  const getuser = async () => {
    let apiRes = await AxiosMethod(`users/${loggedInUser?.user_id}`, `get`)
    if (apiRes.status === 200) {
      setuserData(apiRes.data.data)
    }
  }
  const getCountries = async () => {
    setCountryList(await getTempCountries())
  }
  const getState = async () => {
    setStateList(await getTempState(countryIdForState))
  }
  const onAddEditUser = async (values) => {
    setLoading(true)
    let obj = TakeEditedVal(values, initialValues)

    var bodyFormData = new FormData()
    for (var key in obj) {
      bodyFormData.append(key, obj[key])
    }

    let apiRes = await AxiosMethod(`users/${userData.user_id}`, 'PATCH', bodyFormData)
    if (apiRes && apiRes.status === 200) {
      dispatch(
        AuthAction({
          email: authData.email,
          token: authData.token,
          module: authData.module,
          userData: apiRes.data.data,
        })
      )
      setLoading(false)
      toast.success(apiRes.data.message)
      navigate('/')
    }
    if (JSON.parse(apiRes.request.response).status === 'error') {
      setLoading(false)
      toast.error(JSON.parse(apiRes.request.response).message)
    }
  }
  useEffect(() => {
    getuser()
    getCountries()
  }, [])
  useEffect(() => {
    getState()
  }, [countryIdForState])
  return (
    <>
      <PageTitle>
        {getLabel("UPDATE_PROFILE")}
      </PageTitle>
      <div className='card mb-5 mb-xl-10 card-custom'>
        <div
          className='card-header border-0 cursor-pointer'
          role='button'
          data-bs-toggle='collapse'
          data-bs-target='#kt_account_profile_details'
          aria-expanded='true'
          aria-controls='kt_account_profile_details'
        >
          <div className='card-title m-0'>
            {getLabel("UPDATE_PROFILE")}
          </div>
        </div>

        <div id='kt_account_profile_details' className='collapse show'>
          <Formik
            enableReinitialize
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onAddEditUser}
          >
            {(formik) => {
              formik.values.country_id && setCountryIdForState(formik.values.country_id)
              return (
                <Form onSubmit={formik.handleSubmit} className="form" autoComplete="false">
                  {/*begin::Scroll*/}
                  <div className="card-body py-4">
                    <div
                      className="d-flex row me-n7 pe-7"
                      // className="d-flex row scroll-y me-n7 pe-7"
                      id="kt_modal_add_user_scroll"
                      data-kt-scroll="true"
                      data-kt-scroll-activate="{default: false, lg: true}"
                      // data-kt-scroll-max-height="auto"
                      data-kt-scroll-dependencies="#kt_modal_add_user_header"
                      data-kt-scroll-wrappers="#kt_modal_add_user_scroll"
                    // data-kt-scroll-offset="300px"
                    >
                      {/* Full Name */}
                      <div className="col-6 mb-7">
                        <FormikController
                          control="input"
                          type="text"
                          label={getLabel("FULL_NAME")}
                          placeholder={`${getLabel("ENTER")} ${getLabel("FULL_NAME")}`}
                          labelClassName="required fw-bold fs-6 mb-2"
                          name="full_name"
                          className="form-control form-control-solid mb-lg-0"
                          maxLength="25"
                          formik={formik}
                          value={formik.values.full_name}
                          onChange={formik.handleChange}
                          error={formik.errors.full_name}
                        />
                      </div>
                      {/* email */}
                      <div className="col-6 mb-7">
                        <FormikController
                          control="input"
                          type="email"
                          disabled={true}
                          style={true && { color: "hsl(0, 0%, 60%)" }}
                          label={getLabel("EMAIL")}
                          placeholder={`${getLabel("ENTER")} ${getLabel("EMAIL")}`}
                          labelClassName="required fw-bold fs-6 mb-2"
                          name="email"
                          className="form-control form-control-solid mb-lg-0"
                          maxLength="50"
                          autoComplete="false"
                          formik={formik}
                          value={formik.values.email}
                          onChange={formik.handleChange}
                          error={formik.errors.email}
                        />
                      </div>
                      {/* Contact Number */}
                      <div className="col-6 mb-7">
                        <FormikController
                          control="input"
                          type="text"
                          label={getLabel("CONTACT_NO")}
                          placeholder={`${getLabel("ENTER")} ${getLabel("CONTACT_NO")}`}
                          labelClassName="fw-bold fs-6 mb-2"
                          name="contact_no"
                          className="form-control form-control-solid mb-lg-0"
                          onKeyPress={(event) => {
                            if (!/[0-9]/.test(event.key)) {
                              event.preventDefault()
                            }
                          }}
                          maxLength="20"
                          formik={formik}
                          value={formik.values.contact_no}
                          onChange={formik.handleChange}
                          error={formik.errors.contact_no}
                        />
                      </div>
                      {/* address */}
                      <div className="col-6 mb-7">
                        <FormikController
                          control="input"
                          type="text"
                          label={getLabel("ADDRESS")}
                          placeholder={`${getLabel("ENTER")} ${getLabel("ADDRESS")}`}
                          labelClassName="fw-bold fs-6 mb-2"
                          name="address"
                          className="form-control form-control-solid mb-lg-0"
                          maxLength="100"
                          formik={formik}
                          value={formik.values.address}
                          onChange={formik.handleChange}
                          error={formik.errors.address}
                        />
                      </div>
                      {/* city */}
                      <div className="col-6 mb-7">
                        <FormikController
                          control="input"
                          type="text"
                          label={getLabel("CITY")}
                          placeholder={`${getLabel("ENTER")} ${getLabel("CITY")}`}
                          labelClassName="fw-bold fs-6 mb-2"
                          name="city"
                          className="form-control form-control-solid mb-lg-0"
                          maxLength="25"
                          formik={formik}
                          value={formik.values.city}
                          onChange={formik.handleChange}
                          error={formik.errors.city}
                        />
                      </div>
                      {/* simple react country_id */}
                      <div className="col-6 mb-7">
                        <FormikController
                          control="select"
                          label={getLabel("COUNTRY")}
                          labelClassName="fw-bold fs-6 mb-2"
                          name="country_id"
                          className="form-select form-select-solid"
                          options={countryList}
                          value={formik.values.country_id}
                          onChange={(e) => {
                            formik.resetForm({
                              values: { ...formik.values, state_id: null },
                            })
                            formik.handleChange(e)
                            getState(e.target.value)
                          }}
                          formik={formik}
                          error={formik.errors.country_id}
                        />
                      </div>
                      {/* simple react state */}
                      <div className="col-6 mb-7">
                        <FormikController
                          control="select"
                          label={getLabel("STATE")}
                          labelClassName="fw-bold fs-6 mb-2"
                          name="state_id"
                          className="form-select form-select-solid"
                          options={stateList}
                          value={formik.values.state_id}
                          onChange={formik.handleChange}
                          formik={formik}
                          error={formik.errors.state_id}
                        />
                      </div>

                      {loggedInUser.role_id
                        === 4 && <>
                          <div className="col-6 mb-7">
                            <FormikController
                              control="input"
                              type="text"
                              label={getLabel("COMPANY_NAME")}
                              placeholder={`${getLabel("ENTER")} ${getLabel(
                                "COMPANY_NAME"
                              )}`}
                              labelClassName="fw-bold fs-6 mb-2"
                              name="company_name"
                              className="form-control form-control-solid mb-lg-0"
                              maxLength="25"
                              formik={formik}
                              value={formik.values.company_name}
                              onChange={formik.handleChange}
                              error={formik.errors.company_name}
                            />
                          </div>
                          <div className="col-6 mb-7">
                            <FormikController
                              control="input"
                              type="text"
                              label={getLabel("POSTAL_CODE")}
                              placeholder={`${getLabel("ENTER")} ${getLabel(
                                "POSTAL_CODE"
                              )}`}
                              labelClassName="fw-bold fs-6 mb-2"
                              name="postal_code"
                              className="form-control form-control-solid mb-lg-0"
                              maxLength="25"
                              formik={formik}
                              value={formik.values.postal_code}
                              onChange={formik.handleChange}
                              error={formik.errors.postal_code}
                            />
                          </div>
                        </>}


                      {/*Profile ImageShared */}
                      <div className="col-6 mb-7">
                        <FormikController
                          control="image"
                          label={getLabel("PROFILE_IMG")}
                          labelClassName="fw-bold fs-6 mb-2"
                          name="profile_pic"
                          // className="form-control form-control-solid mb-lg-0"
                          profileImg={profileImg}
                          setProfileImg={setProfileImg}
                          editFlag={true}
                          editPath={userData?.profile_pic}
                          formik={formik}
                          value={formik.values.profile_pic}
                          onChange={formik.handleChange}
                          error={formik.errors.profile_pic}
                        />
                      </div>
                    </div>
                  </div>
                  {/*end::Scroll*/}
                  {/*begin::Actions*/}
                  <div className="card-footer d-flex justify-content-center">
                    <button
                      className="btn btn-light-primary font-weight-bold"
                      style={{ marginRight: "1rem" }}
                      type="button"
                      onClick={() => {
                        navigate("/")
                      }}
                    >
                      <span className="indicator-label">{getLabel("CANCEL")}</span>
                    </button>
                    <button
                      type="submit"
                      id="kt_password_reset_submit"
                      className="btn btn-lg btn-primary fw-bolder me-4"
                      disabled={formik.isSubmitting || !formik.isValid}
                    >
                      {loading ? (
                        <span className="indicator-progress" style={{ display: "block" }}>
                          {getLabel("PLEASE_WAIT")}
                          <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                        </span>
                      ) : (
                        <span className="indicator-label">{getLabel("SUBMIT")}</span>
                      )}
                    </button>
                  </div>
                  {/*end::Actions*/}
                </Form>
              )
            }}
          </Formik>
        </div>
      </div>
    </>
  )
}

export default UpdateUserProfile
