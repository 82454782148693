import AxiosMethod from "../../Api/AxiosMethod"

const SelectValidation = async (no) => {
  let temp = new Promise(async (resolve, reject) => {
    AxiosMethod(`language/validationsJson/${no}`, `get`)
      .then((res) => {
        resolve(res?.data)
      })
      .catch((err) => {
        reject(err)
      })
  })
  return await temp
}

export default SelectValidation
