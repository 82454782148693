import { useEffect } from "react"
import { useDispatch } from "react-redux"
import toast from "react-hot-toast"
import { setReduxLanguage, setReduxValidation } from "../redux/actions/LanguageAction"
import { useLang } from "./Metronici18n"
import SelectLanguage from "./select-language/SelectLanguage"
import SelectValidation from "./select-validation/SelectValidation"
import { getLabel } from "./LanProvider"

const I18nProvider = ({ children }) => {
  const dispatch = useDispatch()
  const locale = useLang()

  const fun = async (lanId) => {
    let messages = await SelectLanguage(lanId)
    // let validation = await SelectValidation(lanId)
    // if (messages || validation) {
    if (messages) {
      dispatch(setReduxLanguage(messages))
      // dispatch(setReduxValidation(validation))
    } else {
      toast.error(getLabel("SERVER_ERR"), {
        duration: 10000,
      })
    }
  }

  // get labels and set to redux store
  const storeLable = async () => {
    if (locale === "en") {
      fun(1)
    } else if (locale === "fr") {
      fun(2)
    } else if (locale === "es") {
      fun(3)
    }
  }

  useEffect(() => {
    storeLable()
  }, [locale])

  return <>{children}</>
}
export { I18nProvider }
