const Videooff = () => {
  return (
    <svg width={27} height={27} viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M18.4525 21.286C18.6846 21.518 19.0713 21.518 19.2956 21.286C19.5199 21.0539 19.5277 20.6749 19.2956 20.4429L4.01908 5.17404C3.78703 4.94199 3.40028 4.94199 3.16824 5.17404C2.94392 5.39835 2.94392 5.79283 3.16824 6.01715L18.4525 21.286ZM15.1033 6.9144H7.05119L17.4238 17.287C17.4779 17.024 17.5089 16.6682 17.5089 16.3433V9.36639C17.5089 7.81166 16.658 6.9144 15.1033 6.9144ZM18.5608 15.477L21.3763 17.9135C21.6393 18.1456 21.9487 18.2925 22.2272 18.2925C22.8305 18.2925 23.225 17.8516 23.225 17.2174V9.17301C23.225 8.53875 22.8305 8.09785 22.2272 8.09785C21.9487 8.09785 21.6393 8.24482 21.3763 8.47687L18.5608 10.9056V15.477ZM6.71858 19.476H15.3431L4.38262 8.51554C4.29754 8.70891 4.2434 9.06472 4.2434 9.39733V17.0163C4.2434 18.5633 5.16386 19.476 6.71858 19.476Z"
        fill="white"
      />
    </svg>
  )
}

export default Videooff
