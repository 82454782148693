import {ActionTypes} from "../constant/ActionType"

export const setReduxLanguage = (lan) => {
  return {
    type: ActionTypes.SET_REDUX_LANGUAGE,
    payload: lan,
  }
}
export const setReduxValidation = (val) => {
  return {
    type: ActionTypes.SET_REDUX_VALIDATION,
    payload: val,
  }
}
