/* eslint-disable jsx-a11y/anchor-is-valid */
import {getLabel} from "../../../_metronic/language-provider/LanProvider"
import {PageTitle} from "../../../_metronic/layout/core"
import "./dashboard.style.css";
import CallHistoryChart from "./callhistorychart";
import CardUsersToday from "../../modules/dashboard/cards/CardUsersToday";
import React, {useEffect, useState} from "react";
import {httpRequest} from "../../services/network.service";
import {useNavigate} from "react-router-dom";
import {useSelector} from "react-redux";

const DashboardWrapper = () =>
{
	// NAVIGATION TO SPECIFIC PAGE.
	const navigate = useNavigate();
	const [statInfo, setStatInfo] = useState({});
	const user = useSelector((state) => state?.AuthReducer?.userData)
	const plan = useSelector((state) => state?.LoaderReducer?.plan)
	const permission = plan[plan?.findIndex((obj) => obj?.plan_id === user?.plan_id)]?.modules
	
	// HOOKS FOR GETTING STATISTICS INFORMATION.
	useEffect (() =>
	{
		getStatInfo().then();
	}, []);
	
	// GET STATISTICS INFORMATION.
	const getStatInfo = async () =>
	{
		try
		{
			const response = await httpRequest ('dashboard/statsinfo', "GET", {});
			
			// RESPONSE STATUS IS SUCCESS THEN SET STATISTICS INFORMATION.
			if (response.status === "success")
			{
				setStatInfo (response.data); // SET STATISTICS INFORMATION.
			}
			console.log ("response", response.data);
		}
		catch (err)
		{
			console.log (err);
		}
	};
	
	// FUNCTION TO NAVIGATE TO SPECIFIC PAGE.
	const goToNavigate = (path) =>
	{
		navigate (`/${path}`);
	};
	
	console.log("statInfo useruseruser", user);
	return (
    <>
	    {<PageTitle breadcrumbs={[]}>{getLabel ("DASHBOARD")}</PageTitle>}
	    
	    <div className="page-wrapper">
		    <div className="page-body">
			    <div className="row">
				    <div className="col-md-8 col-xl-8">
						<div className="row">
							{
								(user.role_id === 1 || user.role_id === 4) && (
						        <div className="col-md-6 col-xl-4">
							    <div className="card widget-card-1">
								    <div className="card-block-small">
									    <i className="las la-industry bg-c-blue card1-icon"></i>
									    <span className="text-c-blue fw-bolder">Organizations</span>
									    <h4 className="fw-bold">{statInfo.organizations}</h4>
									    <div>
		                                    <span className="float-start mt-3 text-muted pointer" onClick={()=> goToNavigate ("org/organization-management")}>
		                                        <i className="text-c-blue f-16 las la-arrow-circle-right me-3"></i>
			                                    See Details
		                                    </span>
									    </div>
								    </div>
							    </div>
						    </div>
								)
							}
							{
								(user.role_id === 1 || user.role_id === 4) && (
								<div className="col-md-6 col-xl-4">
								    <div className="card widget-card-1">
									    <div className="card-block-small">
										    <i className="las la-users bg-c-pink card1-icon"></i>
										    <span className="text-c-pink fw-bolder">Staff</span>
										    <h4 className="fw-bold">{statInfo.staffs}</h4>
										    <div>
			                                    <span className="float-start mt-3 text-muted pointer" onClick={()=> goToNavigate ("org/staff-management")}>
				                                    <i className="text-c-pink f-16 las la-arrow-circle-right me-3"></i>
					                                See details
												</span>
										    </div>
									    </div>
								    </div>
							    </div>
								)
							}
							{
								(user.role_id === 1 || user.role_id === 4) && (
								<div className="col-md-6 col-xl-4">
								    <div className="card widget-card-1">
									    <div className="card-block-small">
										    <i className="las la-qrcode bg-c-green card1-icon"></i>
										    <span className="text-c-green fw-bolder">QR Codes</span>
										    <h4 className="fw-bold">{statInfo.qrcodes}</h4>
										    <div>
			                                    <span className="float-start mt-3 text-muted pointer" onClick={()=> goToNavigate ("qr-code-management")}>
			                                        <i className="text-c-green f-16 las la-arrow-circle-right me-3"></i>
				                                    See details
			                                    </span>
										    </div>
									    </div>
								    </div>
							    </div>
								)
							}
							{
								// IF THE USER IS ADMIN OR IF THEIR BUNDLE ALLOWS THEM TO ACCESS THE CLIENT/MANAGERS
								// MODULE THEN SHOW THE CLIENTS/MANAGERS CARD.
								(user.role_id === 1 || permission?.includes(5)) && (
								<div className="col-md-6 col-xl-4">
								    <div className="card widget-card-1">
									    <div className="card-block-small">
										    <i className="las la-user-tie bg-c-yellow card1-icon"></i>
										    {
												// ADMIN WILL ONLY SEE THE CLIENTS CARD.
											    user.role_id === 1
											    ?
											    <>
													<span className="text-c-yellow fw-bolder">Clients</span>
													<h4 className="fw-bold">{statInfo.clients}</h4>
													<div>
													<span className="float-start mt-3 text-muted pointer" >
													    <i className="text-c-yellow f-16 las la-arrow-circle-right me-3"></i>
													    See Details
													</span>
													</div>
											    </>
										        :
											    <>
												    {/* THIS USER IS NOT ADMIN SO SHOW THEM MANAGERS TAB. */}
												    <span className="text-c-yellow fw-bolder">Managers</span>
													<h4 className="fw-bold">{statInfo?.organization_manager}</h4>
													<div>
														<span className="float-start mt-3 text-muted pointer" onClick={()=> goToNavigate ("org/manager")}>
														    <i className="text-c-yellow f-16 las la-arrow-circle-right me-3"></i>
														    See Details
														</span>
													</div>
											    </>
										    }
									    </div>
								    </div>
							    </div>)
							}
							{
								(user.role_id === 1 || user.role_id === 4) && (
								<div className="col-md-6 col-xl-4">
								    <div className="card widget-card-1">
									    <div className="card-block-small">
										    <i className="las la-calendar bg-c-orenge card1-icon"></i>
										    <span className="text-c-orenge fw-bolder">{getLabel("SCHEDULE_LIST")}</span>
										    <h4 className="fw-bold">{statInfo.departments | 0}</h4>
										    <div>
											<span className="float-start mt-3 text-muted pointer" onClick={()=> goToNavigate ("org/schedule-management")}>
											    <i className="text-c-orenge f-16 las la-arrow-circle-right me-3"></i>
											    See Details
											</span>
										    </div>
									    </div>
								    </div>
							    </div>)
							}
							{
								(user.role_id === 1) && (
								<div className="col-md-6 col-xl-4">
							        <div className="card widget-card-1">
									    <div className="card-block-small">
										    <>
											    <i className="las la-trophy bg-c-lite-green card1-icon" />
											    <span className="text-c-lite-green fw-bolder">Plans</span>
											    <h4 className="fw-bold">{statInfo.plans | 0}</h4>
											    <div>
													<span className="float-start mt-3 text-muted pointer" onClick={()=> goToNavigate ("config/available-plan")}>
													    <i className="text-c-lite-green f-16 las la-arrow-circle-right me-3"></i>
													    See Details
													</span>
											    </div>
										    </>
									    </div>
								    </div>
						        </div>)
							}
				        </div>
					</div>
			        <div className="col-md-4 col-xl-4">
					    <div className="card" style={{marginTop: '20px'}}>
						    <div className="card-block">
							    <h4 className="text-black fw-bolder" style={{marginBottom:'10px'}}>Call details last 7 days</h4>
							    <CallHistoryChart role_id={user?.role_id} />
						    </div>
					    </div>
				    </div>
				</div>
		    </div>
	    </div>
    </>
  )
}
export {DashboardWrapper}
