import * as Yup from "yup"
import {getLabel} from "../../language-provider/LanProvider"
import {returnName} from "../input/QrUserDetailsInput"

export const ValSchemaList = (i, name, passRef, arrMin, proxyName) => {
  const SchemaList = [
    // TEXT => SchemaList[0]
    Yup.string()
      .nullable()
      .required(`${returnName(proxyName) || getLabel(name)} ${getLabel("IS_REQ")}`),
    // EMAIL =? SchemaList[1]
    Yup.string()
      .nullable()
      .required(`${returnName(proxyName) || getLabel(name)} ${getLabel("IS_REQ")}`)
      .email(`${getLabel("VALID_EMAIL")}`),
    // PASSWORD => SchemaList[2]
    Yup.string()
      .required(`${returnName(proxyName) || getLabel(name)} ${getLabel("IS_REQ")}`)
      .nullable()
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
        `${getLabel("PASS_VAL")}`
      )
      .min(8),
    // CONFIRM PASSWORD => SchemaList[3]
    Yup.string()
      .nullable()
      .required(`${returnName(proxyName) || getLabel(name)} ${getLabel("IS_REQ")}`)
      .oneOf(
        [Yup.ref(passRef || "password"), null],
        `${getLabel("PASSWORD")} ${getLabel("MUST_WATCH")}`
      ),
    // PHONE NUMBER ==> SchemaList[4]
    Yup.string()
      // .matches(
      //   /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/,
      //   "Contact No. is not valid"
      // )
      // .min(10, "Contact Number should be not less than 10 digits")
      // .max(10, "to long")
      .required(`${returnName(proxyName) || getLabel(name)} ${getLabel("IS_REQ")}`)
      .nullable(),
    // IMAGE ==> SchemaList[5]
    Yup.mixed().nullable(),
    // .test("fileType", "Unsupported File Format", (value) => SUPPORTED_FORMATS.includes(value?.type))
    // .test(
    //   "fileSize",
    //   "File Size is too large, select file upto 5MB",
    //   (value) => value?.size <= 10500000
    // ),
    // DATE ==> SchemaList[6]
    Yup.date()
      .required(`${returnName(proxyName) || getLabel(name)} ${getLabel("IS_REQ")}`)
      .nullable(),
    // .max(new Date(Date.now() - 567648000000), "You must be at least 18 years")
    // ARRAY ==> SchemaList[7]
    Yup.array()
      .nullable()
      .required(
        `${getLabel("SELECT_ATLEAST")} ${arrMin || 1} ${returnName(proxyName) || getLabel(name)}`
      )
      .min(
        arrMin || 1,
        `${getLabel("SELECT_ATLEAST")} ${arrMin || 1} ${returnName(proxyName) || getLabel(name)}`
      ),
    // ARRAY ==> SchemaList[8]
    Yup.string()
      .nullable()
      .required(`${returnName(proxyName) || getLabel(name)} ${getLabel("IS_REQ")}`)
      .min(8, `${returnName(proxyName) || getLabel(name)} ${getLabel("MUST_BE_8CHAR")}`),
  ]

  return SchemaList[i]
}
