import {useEffect, useState} from "react"
import {getLabel} from "../../language-provider/LanProvider"

const Pagination = ({params, setparams, count, pageData, action}) => {
  const totalPages = Math.ceil(Number(count / params.limit))

  const [displayPage, setdisplayPage] = useState(2)

  const pageNumber = (page) => {
    if (page !== params.page) {
      if (page <= 1) {
        setdisplayPage(2)
      } else {
        setdisplayPage(page)
      }
      action(
        params?.search,
        params?.limit,
        page,
        params?.sortBy,
        params?.sort,
        params?.filterKey || "",
        params?.filterValue || ""
      )
    }
  }

  const changeLimit = (limit) => {
    setparams({...params, limit: Number(limit)})
    action(
      params?.search,
      Number(limit),
      1,
      params?.sortBy,
      params?.sort,
      params?.filterKey || "",
      params?.filterValue || ""
    )
  }

  useEffect(() => {
    if (params.page <= 1) {
      setdisplayPage(2)
    } else {
      setdisplayPage(params.page)
    }
  }, [params.page])
  return (
    <>
      {totalPages >= 1 ? (
        <div className="row">
          <div className="col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start">
            <div className="dataTables_length" id="kt_datatable_example_1_length">
              <label>
                <select
                  name="kt_datatable_example_1_length"
                  className="form-select form-select-sm form-select-solid"
                  onChange={(e) => changeLimit(e.target.value)}
                  value={params?.limit}
                >
                  <option value="10">10</option>
                  <option value="25">25</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                  <option value="100000">{getLabel("all")}</option>
                </select>
              </label>
            </div>

            <div
              className="dataTables_info"
              style={{marginLeft: "1rem"}}
              id="kt_datatable_example_1_info"
              role="status"
              aria-live="polite"
            >
              {/* here rows.length is needed */}
              {`${getLabel("SHOWING")} ${pageData} ${getLabel("OF")} ${count} ${getLabel(
                "RECORDS"
              )}`}
            </div>
          </div>

          <div className="mt-5 col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end">
            <ul className="pagination">
              {params?.page > 1 && (
                <li className="page-item previous">
                  <button
                    className="page-link cursor-pointer"
                    disabled={params?.page <= 1}
                    onClick={() => {
                      pageNumber(params?.page - 1)
                    }}
                  >
                    {`« ${getLabel("PREV")}`}
                  </button>
                </li>
              )}

              <li
                className={`page-item${params?.page === displayPage - 1 ? " active" : ""}`}
                onClick={() => {
                  pageNumber(displayPage - 1)
                }}
              >
                <button className="page-link cursor-pointer">{displayPage - 1}</button>
              </li>

              {displayPage <= totalPages && (
                <li
                  className={`page-item${params?.page === displayPage ? " active" : ""}`}
                  onClick={() => {
                    pageNumber(displayPage)
                  }}
                >
                  <button className="page-link cursor-pointer">{displayPage}</button>
                </li>
              )}
              {displayPage + 1 <= totalPages && (
                <li
                  className={`page-item${params?.page === displayPage + 1 ? " active" : ""}`}
                  onClick={() => {
                    pageNumber(displayPage + 1)
                  }}
                >
                  <button className="page-link cursor-pointer">{displayPage + 1}</button>
                </li>
              )}
              {totalPages !== params?.page && (
                <li className="page-item next">
                  <button
                    className="page-link cursor-pointer"
                    disabled={params?.page >= totalPages}
                    onClick={() => {
                      pageNumber(params?.page + 1)
                    }}
                  >
                    {`${getLabel("NEXT")} »`}
                  </button>
                </li>
              )}
            </ul>
          </div>
        </div>
      ) : null}
    </>
  )
}

export default Pagination
