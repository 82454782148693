
import "./ModalImage.css"
import {useEffect} from "react";

const ModalImage = ({isOpen, onClose, imageUrl}) =>
{
	// HANDLE ESCAPE KEY PRESS HIDE THE MODAL BOX.
	const handleKeyPress = (e) =>
	{
		if (e.key === 'Escape')
		{
			onClose();
		}
	};
	
	// INITIALIZED/ SUBSCRIBE THE EVENT LISTENER.
	useEffect (() =>
	{
		document.addEventListener ('keydown', handleKeyPress);
		return () =>
		{
			document.removeEventListener ('keydown', handleKeyPress);
		};
	}, []);
  return (
    isOpen && (
		<div className="modal-image">
			<div className="modal-image-content">
				<div className="model-header">
				    <div className="modal-close-btn">
				        <button onClick={onClose} >
					        <img src="/media/icons/duotune/close/close-white.svg" alt="close" style={{fill: "#fff"}} />
				        </button>
				    </div>
				</div>
				<img src={imageUrl} alt="QR-Code" />
			</div>
        </div>
    )
  )
}

export default ModalImage
