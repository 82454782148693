/* eslint-disable jsx-a11y/anchor-is-valid */
import {getLabel} from "../../language-provider/LanProvider"
import {useLayout} from "../core"
const Footer = () => {
  const {classes} = useLayout()
  return (
    <div className="footer py-4 d-flex flex-lg-column" id="kt_footer">
      {/* begin::Container */}
      <div
        className={`${classes.footerContainer} d-flex flex-column flex-md-row align-items-center justify-content-between`}
      >
        {/* begin::Copyright */}
        <div className="text-dark order-2 order-md-1">
          <span className="text-gray-800">Roommatik</span>
          <span className="text-muted fw-bold">&copy; { new Date().getFullYear() }</span>
        </div>
        {/* end::Copyright */}
      </div>
      {/* end::Container */}
    </div>
  )
}
export {Footer}
