const CameraFlip = () => {
  return (
    <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20 5H17L15 3H9L7 5H4C2.9 5 2 5.9 2 7V19C2 20.11 2.9 21 4 21H20C21.11 21 22 20.11 22 19V7C22 5.9 21.11 5 20 5ZM9.06444 10.545C9.16852 10.4209 9.27824 10.3044 9.38073 10.2082C10.9105 8.77293 13.3565 8.85089 14.7918 10.3807C15.4613 11.0943 15.8003 12.0323 15.8127 12.9446L15.3308 12.9334C15.2232 12.93 15.1219 12.9848 15.065 13.0783C15.0165 13.1721 15.017 13.2841 15.0721 13.377C15.1038 13.4281 15.9741 15.2682 15.9078 15.1612C15.9771 15.3114 16.2579 15.3662 16.3965 15.1769C16.4314 15.1278 17.7075 13.3424 17.6345 13.4449C17.6954 13.3556 17.7073 13.2399 17.6565 13.143C17.6098 13.0503 17.5122 12.9891 17.4046 12.9857L17.0031 12.9687C17.0001 11.7583 16.5364 10.5275 15.6464 9.57887C13.7661 7.5748 10.583 7.47334 8.57891 9.3536L8.57039 9.36159C8.44993 9.4746 8.36202 9.56744 8.2968 9.64066C8.21042 9.73771 8.19839 9.87977 8.26693 9.99017L8.59101 10.5114C8.69647 10.6811 8.93679 10.6972 9.06444 10.545ZM15.4113 15.4864L15.7346 16.0107C15.8028 16.1214 15.7902 16.2634 15.7034 16.3601C15.6184 16.4547 15.5287 16.5454 15.434 16.6343L15.4211 16.6463C13.4171 18.5266 10.2339 18.4251 8.35365 16.4211C7.45964 15.4682 7.01012 14.224 7.0073 13.0135L6.60975 13.0008C6.51599 13.0028 6.4191 12.9537 6.35779 12.8435C6.30695 12.7466 6.3189 12.6309 6.37987 12.5417C6.3584 12.5719 6.45261 12.4396 6.59714 12.2366C6.94352 11.7501 7.57895 10.8577 7.60355 10.8231C7.65992 10.7461 7.75686 10.6953 7.85201 10.7025C7.94731 10.7014 8.03685 10.754 8.09223 10.8387C8.0259 10.7318 8.89627 12.5718 8.92795 12.6229C8.98301 12.7158 8.9836 12.8278 8.935 12.9216C8.87809 13.0151 8.77687 13.0699 8.66924 13.0665L8.19162 13.0513C8.1997 13.9677 8.53878 14.9057 9.20827 15.6192C10.6436 17.149 13.0896 17.2269 14.6193 15.7917C14.7117 15.705 14.8266 15.5845 14.937 15.4522C15.065 15.2991 15.3063 15.316 15.4113 15.4864Z"
        fill="white"
      />
    </svg>
  )
}

export default CameraFlip
