// FOR INCOMING CALLS. LOADS THE ROOM INTERFACE. IT WILL ACTUALLY CONNECT THE CALL WITH THE REMOTE USER.
import {useContext, useEffect} from "react"
import VideoContext from "./VideoContext"
import "../user-scan-qrcode/style/userscan.scss"
import PhoneHangup from "../user-scan-qrcode/images/phone-hangup.svg"
import Micoff from "../../../_metronic/helpers/svg-helper/Micoff"
import Micon from "../../../_metronic/helpers/svg-helper/Micon"
import Videoon from "../../../_metronic/helpers/svg-helper/Videoon"
import Videooff from "../../../_metronic/helpers/svg-helper/Videooff"
import CameraFlip from "../../../_metronic/helpers/svg-helper/CameraFlip"
import VideoState from "./VideoState";
import {globals} from "../../utils/globals";
import {getLoggedInUser} from "../../services/login.service";
import {useSelector} from "react-redux";
import {v4 as uuidv4} from "uuid";
import {updateCallStatus} from "../../services/helper.service";
import {httpRequest} from "../../services/network.service";
import {PageDataProvider, PageTitle} from "../../../_metronic/layout/core";
import {getLabel} from "../../../_metronic/language-provider/LanProvider";
import {MasterLayout} from "../../../_metronic/layout/MasterLayout";
import VideoCallView from "./videocall.view";

const CallComponent = () =>
{
	// HOLD THE USER EMAIL FROM REDUCER.
	const user_email = useSelector((state) => state?.AuthReducer?.email)
	
	useEffect(() =>
	{
		if (!navigator.onLine) alert("Connect to internet!")
	}, [navigator])
	
	useEffect(() =>
	{
		if (user_email)
		{
			// CHECK THE USER FROM NOTIFICATION IS FALSE THEN SEND THE SERVER EMIT RECEIVE NOTIFICATION.
			if (!globals.userFromNotification)
			{
				let call_id = globals.call_id;
				let firbasePayload =
					{
						"isCallConnected": 1
					}
				
				// CREATE THE UPDATE PAYLOAD OBJECT.
				const payload =
					{
						"action": "starttime",
						"call_id": globals.call_id,
					}
				// console.log ("Call answer payloadpayload:",payload);
				httpRequest (`static/updatecalltime`, 'POST', payload).then();
				
				// UPDATE THE DATABASE "CALL" WITH A NEW CALL STATUS "ANSRD"
				updateCallStatus (call_id, "ANSRD", firbasePayload).then();
				handleReceiveNotification().then();
			}
			
			return () =>
			{
				// SET THE GLOBAL VARIABLE TO FALSE
				globals.hasAnswered = false;
			}
		}
	},[user_email])
	
	// DON'T ALLOW TO GO BACK
	// DON'T ALLOW TO SCROLL DOWN REFRESH
	useEffect(() =>
	{
		window.history.pushState (null, null, null)
		window.onpopstate = function ()
		{
			window.history.go (1)
		}
		
		// SET STYLE ON BODY ELEMENT
		document.body.style.overscrollBehaviorY = "contain"
		window.addEventListener ("beforeunload", handleBeforeUnload)
		
		return () =>
		{
			// OPTIONALLY REMOVE STYLES WHEN COMPONENT UNMOUNTS
			document.body.style.overscrollBehaviorY = null
			window.removeEventListener("beforeunload", handleBeforeUnload)
		}
	}, []);
	
	const handleBeforeUnload = (e) =>
	{
		let event = e || window.event
		event.preventDefault()
		event.returnValue = ""
		
		// if (sessionStorage.getItem("call")) {
		//   sessionStorage.clear()
		//   navigate("/thanks-call")
		// } else {
		//   sessionStorage.setItem("call", true)
		// }
		return ""
	}
	
	// FUNCTION TO HANDLE RECEIVE NOTIFICATION AND SENT TO SERVER.
	const handleReceiveNotification = async () =>
	{
		console.log ("receivedNotification() intent value ");
		globals.userFromNotification = true;
		let email =user_email;
		
		// IF EMAIL NOT FOUND IN REDUX THEN CHECK THE LOCAL-STORAGE.
		if (!email)
		{
			// GET CURRENT USER'S PROFILE DATA.
			let user = await getLoggedInUser();
			console.log("user", user);
			email = user.email
		}
		const callerSocketID = globals.callerSocketID;
		const staffs = globals.callStaffData;
		console.log ("handleReceiveNotification userFromNotification value ");
		console.log ("handleReceiveNotification callerSocketID value " + callerSocketID);
		console.log ("handleReceiveNotification staffs value " + staffs);
		if (callerSocketID)
		{
			try
			{
				const notificationObject =
					{
						staffEmail: email,
						callerSocketId: callerSocketID,
						calleeSocketId: globals.socket.id,
						call_id: globals.call_id,
						staffs: staffs
					}
				console.log ("handleReceiveNotification socket_emit_notification_received_event value", notificationObject, globals.socket.id);
				console.log ("webrtc", "step1-call-answered", notificationObject);
				globals.socket.emit ("call-answered", notificationObject);
			}
			catch (error)
			{
				console.log ("onSocketConnected() error value", error);
			}
		}
	}
	
  return (
	  <>
	  {
		  // IF CALL ID IS VALID THEN SHOW THE CALL/ROOM INTERFACE.
		globals?.call_id !== "" && globals?.call_id !== null
		?
			<VideoState isCallStatus={"IN"}>
				<div className="main-wrap">
					<div className="container-fluid p-0">
						<div className="row m-auto">
							<div className="col-md-12 p-0">
								<div className="call-main video-block">
									<VideoCallView />
								</div>
							</div>
						</div>
					</div>
				</div>
				<input type="text" value="DON'T REFRESH" className="d-none"/>
			</VideoState>
			
		:
			// ELSE SHOW THAT THERE IS NO INCOMING CALL.
			<MasterLayout>
			  <PageTitle breadcrumbs={[]}>{getLabel ("CALLS")}</PageTitle>
			  <h1>{"Sorry, no incoming calls at the moment."}</h1>
			</MasterLayout>
	  }
	  </>
  );
};

export default CallComponent;
