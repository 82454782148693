import {Route, Routes, Navigate} from "react-router-dom"
import {useSelector} from "react-redux"
import {MasterLayout} from "../../_metronic/layout/MasterLayout"
import {DashboardWrapper} from "../pages/dashboard/DashboardWrapper"

// language
import ViewLanguage from "./../modules/language-validation-management/language/ViewLanguage"
import CreateEditLanguage from "./../modules/language-validation-management/language/CreateEditLanguage"

// validation
import ViewValidation from "./../modules/language-validation-management/validation/ViewValidation"
import CreateEditValidation from "./../modules/language-validation-management/validation/CreateEditValidation"

// admin
import AdminList from "../modules/qr-admin-management/AdminList"
import ViewAdmin from "../modules/qr-admin-management/ViewAdmin"
import CreateEditAdmin from "./../modules/qr-admin-management/CreateEditAdmin"
// client
import ClientList from "../modules/qr-client-management/ClientList"
import ViewClient from "../modules/qr-client-management/ViewClient"
import CreateEditClient from "./../modules/qr-client-management/CreateEditClient"
// staff
import StaffList from "./../modules/qr-staff-management/StaffList"
import ViewStaff from "../modules/qr-staff-management/ViewStaff"
import CreateEditStaff from "../modules/qr-staff-management/CreateEditStaff"
// qrcode
import QrCodeList from "../modules/qr-code-management/QrCodeList"
import ViewQrCode from "../modules/qr-code-management/ViewQrCode"
import CreateEditQrCode from "../modules/qr-code-management/CreateEditQrCode"
// dept
import DepartmentList from "../modules/department-management/DepartmentList"
import CreateEditDepartment from "../modules/department-management/CreateEditDepartment"
// plan
import PlanList from "../modules/plan-management/PlanList"
import CreateEditPlan from "./../modules/plan-management/CreateEditPlan"
import ViewDepartment from "../modules/department-management/ViewDepartment"
// organization
import OrganizationList from "../modules/qr-organization-management/OrganizationList"
import CreateEditOrganization from "../modules/qr-organization-management/CreateEditOrganization"
import ViewOrganization from "../modules/qr-organization-management/ViewOrganization"
// org manager
import OrgManagerList from "../modules/qr-organization-manager-management/OrgManagerList"
import ViewOrgManager from "../modules/qr-organization-manager-management/ViewOrgManager"
import CreateEditOrgManager from "../modules/qr-organization-manager-management/CreateEditOrgManager"
// user info list
import UserInfoList from "../modules/user-info-management/UserInfoList"
import CreateEditInfo from "../modules/user-info-management/CreateEditInfo"
// org map management
import StaffMapList from "../modules/staff-mapping/StaffMapList"
import ViewStaffMap from "../modules/staff-mapping/ViewStaffMap"
import CreateEditStaffMapping from "../modules/staff-mapping/CreateEditStaffMapping"
// invite user model
import InviteUserModel from "../modules/qr-organization-manager-management/InviteUserModel"
import ViewUserInfo from "../modules/user-info-management/ViewUserInfo"
import ManageTurnServer from "../modules/manage-turn-server/ManageTurnServer"
import CallDetails from "../modules/call-details/calldetails"
import ViewCallDetails from "../modules/call-details/ViewCallDetails"
import UpdateUserProfile from "../modules/profile/UpdateUserProfile"
import ChangePassword from "../modules/profile/ChangePassword"
// connection log
import ConnectionsList from "../modules/connections/ConnectionsList"
import ViewConnection from "../modules/connections/ViewConnection"
import ViewDashboard from "../modules/dashboard/ViewDashboard"

const PrivateRoutes = () => {
  // Route access permission start
  const loggedInUser = useSelector((state) => state?.AuthReducer?.userData)
  const plan = useSelector((state) => state?.LoaderReducer?.plan)
  const permission = plan[plan?.findIndex((obj) => obj?.plan_id === loggedInUser?.plan_id)]?.modules

  // Route access permission end
  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path="auth/*" element={<Navigate to="/dashboard" />} />

        {/* Dashboard */}
        <Route path="dashboard" element={<DashboardWrapper />} />
        <Route path="call-details" element={<CallDetails />} />
        {/*<Route path="call-details/view" element={<ViewCallDetails />} />*/}
	      <Route path="call-details/:id" element={<ViewCallDetails />} />
	      
	      {/* admin Management && Language*/}
        {loggedInUser["role.role_id"] === 1 && (
          <>
            <Route path="admin-management" element={<AdminList />} />
            <Route path="admin-management/view-admin" element={<ViewAdmin />} />
            <Route path="admin-management/manage-admin" element={<CreateEditAdmin />} />

            {/* language validation */}
            <Route path="language-management/view-language" element={<ViewLanguage />} />
            <Route path="language-management/manage-language" element={<CreateEditLanguage />} />
            <Route path="validation-management/view-validation" element={<ViewValidation />} />
            <Route
              path="validation-management/manage-validation"
              element={<CreateEditValidation />}
            />

            {/* manage turn server */}
            <Route path="config/accounts-settings" element={<ManageTurnServer />} />
          </>
        )}
        <Route path="change-password" element={<ChangePassword />} />
        {/* Client Management*/}
        {loggedInUser["role.role_id"] === 1 && (
          <>
            <Route path="user-management" element={<ClientList />} />
            <Route path="user-management/view-user" element={<ViewClient />} />
            <Route path="user-management/manage-user" element={<CreateEditClient />} />
          </>
        )}
	      
        {/* ORGANIZATION MANAGEMENT */}
        {(
			loggedInUser["role.role_id"] === 1 ||
	        loggedInUser["role.role_id"] === 4 ||
	        permission?.includes(5)) && (
          <>
            <Route path="org/organization-management" element={<OrganizationList />} />
            <Route
              path="org/organization-management/view-organization"
              element={<ViewOrganization />}
            />
            <Route
              path="org/organization-management/manage-organization"
              element={<CreateEditOrganization />}
            />
          </>
        )}
        {/* Department(Group) Management */}
        {(loggedInUser["role.role_id"] === 1 ||
          loggedInUser["role.role_id"] === 5 ||
          loggedInUser["role.role_id"] === 4 ||
          permission?.includes(1)) && (
          <>
            <Route path="org/schedule-management" element={<DepartmentList />} />
            <Route path="org/schedule-management/view-schedule" element={<ViewDepartment />} />
            <Route
              path="org/schedule-management/manage-schedule"
              element={<CreateEditDepartment />}
            />
          </>
        )}
        {/* updateProfile */}
        <Route path="update-profile" element={<UpdateUserProfile />} />
        {/* Staff Management*/}
        {(loggedInUser["role.role_id"] === 1 ||
          loggedInUser["role.role_id"] === 5 ||
	        loggedInUser["role.role_id"] === 4 ||
	        permission?.includes(2)) && (
          <>
            <Route path="org/staff-management" element={<StaffList />} />
            <Route path="org/staff-management/view-staff" element={<ViewStaff />} />
            <Route path="org/staff-management/manage-staff" element={<CreateEditStaff />} />
            <Route path="org/staff-management/invite" element={<InviteUserModel />} />
          </>
        )}
        {/* org map management*/}
        {(loggedInUser["role.role_id"] === 1 ||
          loggedInUser["role.role_id"] === 5 ||
	        loggedInUser["role.role_id"] === 4 ||
	        
	        permission?.includes(2)) && (
          <>
            <Route path="org/staff-mapping" element={<StaffMapList />} />
            <Route path="org/staff-mapping/view-org/staff-mapping" element={<ViewStaffMap />} />
            <Route
              path="org/staff-mapping/manage-org/staff-mapping"
              element={<CreateEditStaffMapping />}
            />
          </>
        )}
        {/* Qr management */}
        {(loggedInUser["role.role_id"] === 1 ||
          loggedInUser["role.role_id"] === 5 ||
          permission?.includes(4)) && (
          <>
            <Route path="qr-code-management" element={<QrCodeList />} />
            <Route path="qr-code-management/view-qr-code" element={<ViewQrCode />} />
            <Route path="qr-code-management/manage-qr-code" element={<CreateEditQrCode />} />
          </>
        )}
        {/* User Info Management */}
        {loggedInUser["role.role_id"] === 1 && (
          <>
            <Route path="config/fields-customization" element={<UserInfoList />} />
            <Route path="config/fields-customization/view-user-info" element={<ViewUserInfo />} />
            <Route path="config/fields-customization/manage-info" element={<CreateEditInfo />} />
          </>
        )}
        {/* organization manager Management */}
        {(loggedInUser["role.role_id"] === 1 || permission?.includes(5)) && (
          <>
            <Route path="org/manager" element={<OrgManagerList />} />
            <Route path="org/manager/view-organization-manager" element={<ViewOrgManager />} />
            <Route
              path="org/manager/manage-organization-manager"
              element={<CreateEditOrgManager />}
            />
            <Route path="org/manager/invite" element={<InviteUserModel />} />
          </>
        )}
        {/*Plan Management  */}
        {loggedInUser && loggedInUser["role.role_id"] === 1 && (
          <>
            <Route path="config/available-plan" element={<PlanList />} />
            <Route path="config/available-plan/manage-plan" element={<CreateEditPlan />} />
          </>
        )}

        {/* Connection Log */}
        {(loggedInUser["role.role_id"] === 1 ||
          loggedInUser["role.role_id"] === 5 ||
          permission?.includes(4)) && (
          <>
            <Route path="/connections" element={<ConnectionsList />} />
            <Route path="/connections/view" element={<ViewConnection />} />
          </>
        )}

        {/* Page Not Found */}
        <Route path="*" element={<Navigate to="/error/404" />} />
      </Route>
    </Routes>
  )
}

export {PrivateRoutes}
