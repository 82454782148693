import { getLabel } from "../../../../_metronic/language-provider/LanProvider"
import { Row, Col } from "react-bootstrap";
import Menu from "./Menu"

function Start() {
  return (
    <>
      <Menu />

      <h1 style={{ display: 'none' }}>QR Phone</h1>

      <Row id="background-start">
        <Row id="row-start">
          <Col md={6} className='pb-5 mx-2'>
            <Row className='title-start-white pt-4'>{getLabel("start_title_1").toUpperCase()}</Row>
            <Row className='title-start-white'>{getLabel("start_title_2").toUpperCase()}</Row>
            <Row className='title-start-green'>{getLabel("start_title_3").toUpperCase()}</Row>
            <Row className='subtitle-start'>{getLabel("start_subtitle_1")}</Row>
            <Row className='subtitle-start'>{getLabel("start_subtitle_2")}</Row>
          </Col>
          <Col md={5} className='text-center mx-2'>
            <img id="image-start" alt="Calling" src="/media/img/start.png" />
          </Col>
        </Row>
        <Row className='row-cards-start'>
          <Col lg={4} md={12} className="py-5">
            <div className='text-center card-start pt-5'>
              <div className='card-title-start pt-8'>{getLabel("start_card_1_title").toUpperCase()}</div>
              <div className='card-subtitle-start pt-3'>{getLabel("start_card_1_subtitle_1").toUpperCase()}</div>
              <div className='card-subtitle-start'>{getLabel("start_card_1_subtitle_2").toUpperCase()}</div>
              <div className='card-subtitle-start'>{getLabel("start_card_1_subtitle_3").toUpperCase()}</div>
              <div className='card-footer-start pt-5'>{getLabel("start_card_1_description")}</div>
            </div>
          </Col>
          <Col lg={4} md={12} className="py-5">
            <div className='text-center card-start pt-5'>
              <div className='card-title-start pt-8'>{getLabel("start_card_2_title").toUpperCase()}</div>
              <div className='card-subtitle-start pt-3'>{getLabel("start_card_2_subtitle_1").toUpperCase()}</div>
              <div className='card-subtitle-start'>{getLabel("start_card_2_subtitle_2").toUpperCase()}</div>
              <div className='card-subtitle-start'>{getLabel("start_card_2_subtitle_3").toUpperCase()}</div>
              <div className='card-footer-start pt-5'>{getLabel("start_card_2_description")}</div>
            </div>
          </Col>
          <Col lg={4} md={12} className="py-5">
            <div className='text-center card-start pt-5'>
              <div className='card-title-start pt-8'>{getLabel("start_card_3_title").toUpperCase()}</div>
              <div className='card-subtitle-start pt-3'>{getLabel("start_card_3_subtitle_1").toUpperCase()}</div>
              <div className='card-subtitle-start'>{getLabel("start_card_3_subtitle_2").toUpperCase()}</div>
              <div className='card-subtitle-start'>{getLabel("start_card_3_subtitle_3").toUpperCase()}</div>
              <div className='card-footer-start pt-5'>{getLabel("start_card_3_description")}</div>
            </div>
          </Col>
        </Row>
        <Row id="footer-start">
          <Row id="footer-container">
            <Col  className='text-right offset-4' style={{display:"flex", alignItems:"center" , justifyContent:"center"}}>
                <span style={{marginRight:'10px'}}> {getLabel("start_footer_1")}</span>
	            <a href="#/select-plan">{getLabel("start_footer_2")}</a>
            </Col>
			<Col className='col-4' style={{display:"flex", alignItems:"center" , justifyContent:"end"}} >
			  <a className='text-right' href="#/privacy-policy">{getLabel("privacy_policy")}</a>
			</Col>
          </Row>
        </Row>
      </Row>
    </>
  );
}

export default Start;
