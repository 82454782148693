import { useEffect, useState } from "react"
import toast from "react-hot-toast"

import { useNavigate } from "react-router-dom"
import { PageTitle } from "../../../_metronic/layout/core"
import AxiosMethod from "../../../_metronic/Api/AxiosMethod"
import SearchSvg from "../../../_metronic/helpers/svg-helper/SearchSvg"
import CloseSvg from "../../../_metronic/helpers/svg-helper/CloseSvg"
import { getTempModules } from "../../../_metronic/helpers/reuseable-fun/FunReuse"
import { getLabel } from "../../../_metronic/language-provider/LanProvider"
import { SetLoader } from "../../../_metronic/redux/actions/LoaderAction"
import { useDispatch } from "react-redux"

const PlanList = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [search, setSearch] = useState()
  const [plans, setPlans] = useState()
  const [moduleList, setModuleList] = useState([])
  const [notFound, setNotFound] = useState("")
  const getModules = async () => {
    setModuleList(await getTempModules())
  }
  const getPlans = async (search) => {
    dispatch(SetLoader(true))
    const getPlanRes = await AxiosMethod(`plans?search=${search || ""}`, `get`)
    if (getPlanRes?.status === 200) {
      dispatch(SetLoader(false))
      setNotFound(false)
      setPlans(getPlanRes?.data?.data?.rows)
    }
    if (JSON.parse(getPlanRes.request.response).status === "error") {
      dispatch(SetLoader(false))
      // toast.error(JSON.parse(getPlanRes.request.response).message)
      setNotFound(JSON.parse(getPlanRes.request.response).message)
    }
  }
  const deletePlan = async (id) => {
    if (window.confirm("Are you sure you want to delete this Plan?")) {
      const deletePlanRes = await AxiosMethod(`plans/${id}`, `delete`)
      if (deletePlanRes && deletePlanRes.status === 200) {
        await getPlans("")
        toast.success(deletePlanRes.data.message)
      }
      if (JSON.parse(deletePlanRes.request.response).status === "error") {
        // toast.error(JSON.parse(deletePlanRes.request.response).message)
        toast.error("You cannot delete this Plan, because it has been used already by the Users")
      }
    }
  }
  useEffect(() => {
    getPlans("")
    getModules()
  }, [])

  return (
    <>
      <PageTitle>
        {getLabel("plan_list")}
      </PageTitle>
      <div className="post d-flex flex-column-fluid" id="kt_post">
        {/*begin::Container*/}
        <div id="kt_content_container" className="container-xxl">
          {/*begin::Pricing card*/}
          <div className="card" id="kt_pricing">
            <div className="card-header border-0">
              <div className="card-title">
                <div className="d-flex align-items-center position-relative my-1">
                  <span className="svg-icon svg-icon-1 position-absolute ms-6">
                    <SearchSvg />
                  </span>
                  {search && (
                    <span
                      className="svg-icon svg-icon-1 position-absolute end-0 me-2 cursor-pointer"
                      onClick={() => {
                        getPlans("")
                        setSearch("")
                      }}
                    >
                      <CloseSvg />
                    </span>
                  )}
                  <input
                    className="form-control form-control-solid w-250px ps-14 pe-10"
                    placeholder={getLabel("SEARCH")}
                    value={search}
                    onChange={(e) => {
                      setSearch(e.target.value)
                      getPlans(e.target.value)
                    }}
                    onKeyPress={(e) => {
                      if (!/^[a-z\d\-_ ]+$/i.test(e.key)) {
                        e.preventDefault()
                      }
                    }}
                  />
                </div>
              </div>

              <div className="card-toolbar">
                <div className="d-flex justify-content-end">
                  <button
                    type="button"
                    className="btn btn-primary font-weight-bold"
                    onClick={() => {
                      navigate("/config/available-plan/manage-plan", {
                        state: { add: true },
                      })
                    }}
                  >
                    <span className="indicator-label">
                      {`${getLabel("ADD")} ${getLabel("PLAN")}`}
                    </span>
                  </button>
                </div>
              </div>
            </div>

            {/*begin::Card body*/}
            <div className="card-body p-lg-17">
              {/*begin::Plans*/}
              <div className="d-flex flex-column">
                {/*begin::Row*/}
                <div className="row g-10">
                  {notFound ? (
                    <>
                      <tr style={{ display: "flex", justifyContent: "center", textAlign: "center" }}>
                        <td className="d-flex text-center w-100 align-content-center justify-content-center text-uppercase">
                          {notFound}
                        </td>
                      </tr>
                    </>
                  ) : (
                    plans?.map((plan, i) => {
                      return (
                        <div key={plan?.plan_id} className="col-xl-4">
                          <div className="d-flex h-100 align-items-center">
                            {/*begin::Option*/}
                            <div className="w-100 d-flex flex-column flex-center rounded-3 bg-light bg-opacity-75 py-15 px-10">
                              {/*begin::Heading*/}
                              <div className="mb-7 text-center">
                                {/*begin::Title*/}
                                <h1 className="text-dark mb-5 fw-boldest text-capitalize">
                                  {plan?.name}
                                </h1>
                                <div className="text-gray-400 fw-bold mb-5 text-capitalize">
                                  {plan?.tagline}
                                </div>
                                <div className="text-gray-400 fw-bold mb-5 text-capitalize">
                                  {getLabel("PLAN_TYPE")}:- {plan?.plan_type}
                                </div>
                                {plan?.static_qr_limit ? (
                                  <div className="text-gray-400 fw-bold mb-5 text-capitalize">
                                    {getLabel("STATIC_QR_LIMIT")}:- {plan?.static_qr_limit}
                                  </div>
                                ) : null}
                                {plan?.dynamic_qr_limit ? (
                                  <div className="text-gray-400 fw-bold mb-5 text-capitalize">
                                    {getLabel("DYNAMIC_QR_LIMIT")}:- {plan?.dynamic_qr_limit}
                                  </div>
                                ) : null}
	                              {plan?.staff_member_limit ? (
		                              <div className="text-gray-400 fw-bold mb-5 text-capitalize">
			                              {getLabel("STAFF_MEMBER_LIMIT")}:- {plan?.staff_member_limit}
		                              </div>
	                              ) : null}
                                {/*end::Description*/}
                                {/*begin::Price*/}
                                <div className="text-center">
                                  <span
                                    className="fs-3x fw-bolder text-primary"
                                    data-kt-plan-price-month={39}
                                    data-kt-plan-price-annual={399}
                                  >
                                    {plan.price}
                                  </span>
                                  <span className="mb-2 text-primary">{" "}€ </span>
                                  <span className="fs-7 fw-bold opacity-50">
                                    /
                                    <span data-kt-element="period">
                                      {plan.duration} {getLabel("DAYS")}
                                    </span>
                                  </span>
                                </div>
                                {/*end::Price*/}
                              </div>
                              {/*end::Heading*/}
                              {/*begin::Features*/}
                              <div className="w-100 mb-10">
                                {moduleList?.map((module) => {
                                  let temp = false
                                  return (
                                    <div
                                      key={module?.module_id}
                                      className="d-flex align-items-center mb-5"
                                    >
                                      <span className="fw-bold fs-6 text-gray-800 flex-grow-1 pe-3">
                                        {module?.module_name}
                                      </span>
                                      {/*begin::Svg Icon | path: icons/duotune/general/gen043.svg*/}
                                      {plan?.modules?.map((el) => {
                                        if (module?.module_id === el) {
                                          temp = true
                                          return (
                                            <span
                                              key={el}
                                              className="svg-icon svg-icon-1 svg-icon-success"
                                            >
                                              <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width={24}
                                                height={24}
                                                viewBox="0 0 24 24"
                                                fill="none"
                                              >
                                                <rect
                                                  opacity="0.3"
                                                  x={2}
                                                  y={2}
                                                  width={20}
                                                  height={20}
                                                  rx={10}
                                                  fill="currentColor"
                                                />
                                                <path
                                                  d="M10.4343 12.4343L8.75 10.75C8.33579 10.3358 7.66421 10.3358 7.25 10.75C6.83579 11.1642 6.83579 11.8358 7.25 12.25L10.2929 15.2929C10.6834 15.6834 11.3166 15.6834 11.7071 15.2929L17.25 9.75C17.6642 9.33579 17.6642 8.66421 17.25 8.25C16.8358 7.83579 16.1642 7.83579 15.75 8.25L11.5657 12.4343C11.2533 12.7467 10.7467 12.7467 10.4343 12.4343Z"
                                                  fill="currentColor"
                                                />
                                              </svg>
                                            </span>
                                          )
                                        }
                                      })}
                                      {!temp && (
                                        <span className="svg-icon svg-icon-1">
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width={24}
                                            height={24}
                                            viewBox="0 0 24 24"
                                            fill="none"
                                          >
                                            <rect
                                              opacity="0.3"
                                              x={2}
                                              y={2}
                                              width={20}
                                              height={20}
                                              rx={10}
                                              fill="currentColor"
                                            />
                                            <rect
                                              x={7}
                                              y="15.3137"
                                              width={12}
                                              height={2}
                                              rx={1}
                                              transform="rotate(-45 7 15.3137)"
                                              fill="currentColor"
                                            />
                                            <rect
                                              x="8.41422"
                                              y={7}
                                              width={12}
                                              height={2}
                                              rx={1}
                                              transform="rotate(45 8.41422 7)"
                                              fill="currentColor"
                                            />
                                          </svg>
                                        </span>
                                      )}
                                      {/*end::Svg Icon*/}
                                    </div>
                                  )
                                })}
                              </div>
                              {/* begin button */}
                              <div className="card-footer d-flex justify-content-center">
                              {/* delete */}
                               <button
                                  type="button"
                                  className="btn btn-light-danger font-weight-bold"
                                  onClick={() => deletePlan(plan.plan_id)}
                                  style={{marginRight: "1rem"}}
                                >
                                  <span className="indicator-label">Delete</span>
                                </button>
                              {/* update */}
                               <button
                                  type="button"
                                  className="btn btn-light-primary font-weight-bold"
                                  onClick={() => {
                                    navigate("/config/available-plan/manage-plan", {
                                      state: {edit: true, editObj: plan},
                                     }) //navigate("/plan-management/manage-plan", {
                                    //   state: {edit: true, editObj: plan},
                                    // })
                                  }}
                                  style={{marginRight: "1rem"}}
                                >
                                  <span className="indicator-label">Edit</span>
                                </button>
                              </div>
                              {/* End button */}
                              {/*end::Features*/}
                            </div>
                            {/*end::Option*/}
                          </div>
                        </div>
                      )
                    })
                  )}
                </div>
                {/*end::Row*/}
              </div>
              {/*end::Plans*/}
            </div>
            {/*end::Card body*/}
          </div>
          {/*end::Pricing card*/}
        </div>
        {/*end::Container*/}
      </div>
    </>
  )
}

export default PlanList
