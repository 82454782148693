import {ActionTypes} from "../constant/ActionType"

export const SetLoader = (payload) => {
  return {
    type: ActionTypes.SET_LOADER,
    payload: payload,
  }
}

export const PlanAction = (plan) => {
  return {
    type: ActionTypes.SET_PLAN,
    payload: plan,
  }
}
export const TurnServerAction = (data) => {
  return {
    type: ActionTypes.SET_TURN_SERVER,
    payload: data,
  }
}
