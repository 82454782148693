import ViewSvg from "../../../_metronic/helpers/svg-helper/ViewSvg"
import {handleNull, userUtcOffset} from "../../../_metronic/helpers/reuseable-fun/FunReuse"
import moment from "moment"

const ConnectionsRows = ({connection}) => {
  return (
    <>
      <tr key={connection.connection_id} className="odd">
        <td className="text-capitalize">{handleNull(connection["user_name"])}</td>
        <td>
          {connection.date && connection.date !== "Invalid date"
            ? moment(connection.date).utcOffset(userUtcOffset).format("DD/MM/yyyy")
            : "N/A"}
        </td>
        <td>
          {connection.login_connection_date && connection.login_connection_date !== "Invalid date"
            ? moment(connection.login_connection_date)
                .utcOffset(userUtcOffset)
                .format("DD/MM/yyyy HH:mm")
            : "N/A"}
        </td>
        <td>
          {connection.logout_connection_date && connection.logout_connection_date !== "Invalid date"
            ? moment(connection.logout_connection_date)
                .utcOffset(userUtcOffset)
                .format("DD/MM/yyyy HH:mm")
            : "N/A"}
        </td>
	    <td className="d-print-none">
          <div className="d-flex justify-content-end flex-shrink-0">
            {/* view Button */}
            <ViewSvg path="/connections/view" editObj={connection} />
          </div>
        </td>
      </tr>
    </>
  )
}

export default ConnectionsRows
