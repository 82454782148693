import axios from "axios"
import store from "../redux/store"
import {URLS} from "./UrlList"

const getLanguageCode = () =>
{
  const ls = localStorage.getItem(process.env.REACT_APP_I18N_CONFIG_KEY || "i18nConfig")
  const selectedLang = JSON.parse(ls)?.selectedLang
  if (selectedLang === "en") {
    return 1
  } else if (selectedLang === "fr") {
    return 2
  } else if (selectedLang === "es") {
    return 3
  } else {
    return 1
  }
}

const AxiosMethod = async (url, type, data, resettoken) => {
  const languageCode = getLanguageCode()
  const apiUrl = `${URLS.BASE_URL}/${url}${url.includes("?") ? "&" : "?"}language=${languageCode}`
  const token = resettoken || store.getState().AuthReducer.token || ""

  const headers = {
    Authorization: `Bearer ${token}`,
  }

  const config = {
    url: apiUrl,
    method: type,
    headers,
    data,
  }

  try {
    const response = await axios(config)
    return response
  } catch (error) {
    return error
  }
}

export default AxiosMethod
