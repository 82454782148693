import "./style/userscan.scss"
import logo from "./images/logo.svg"
import ThanksImage from "./images/thanks-image.svg"
import { useEffect } from "react"
import {useLocation} from "react-router-dom";
import {insertCallRecordIntoFirestore} from "../../services/helper.service";
const ThanksCall = () =>
{
  useEffect(() =>
  {
        window.history.pushState(null, null, null)
        window.onpopstate = function ()
        {
            window.history.go(1)
        }

        // refresh page
        // if (sessionStorage.getItem("refresh")) {
        //   sessionStorage.clear()
        // } else {
        //   sessionStorage.setItem("refresh", true)
        //   window.location.reload()
        // }
  }, [])

  return (
    <>
      <div className="banner-experience py-2">
        <div>Did you enjoy the experience?</div>
        <div>Give it a try at</div>
        <div><a href="https://qr-phone.com/#/start">https://qr-phone.com/</a></div>
      </div>
      <div className="main-wrap mt-5" style={{ pointerEvents: "none" }}>
        <div className="container">
          <div className="row">
            <div className="col-xxl-12 m-auto text-center after-call-screen thankscall-wrap">
              <img src={ThanksImage} alt="" className="image_call" />
              <div className="text-center thanks_text">
                <img src={logo} alt="" />
                <h2 className="mt-5 pt-5">
                  Thanks for<span className="d-block"> Calling</span>
                </h2>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ThanksCall
