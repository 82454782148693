import {getStorage, setStorage} from "./helper.service"

// INCLUDE THIS FUNCTION IN THE COMPONENTDIDMOUNT HOOK WHEREVER USER IS REQUIRED TO LOGIN TO VIEW THE PAGE.
// RETURNS THE PROFILE DATA OF LOGGED-IN USER TO THE APPLICATION.
export const getLoggedInUser = async () =>
{
  try
  {
    let user: any = await getStorage ('qr_userdata');
	  user = ((user) ? JSON.parse (user) : null);
	  //console.log ("getLoggedInUser getLoggedInUser",user);
    return user;
  }
  catch (e)
  {
    console.error('error code 382723', e);
    return null;
  }
};
