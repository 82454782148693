import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import toast from "react-hot-toast"

import AxiosMethod from "../../../_metronic/Api/AxiosMethod"
import Pagination from "../../../_metronic/helpers/pagination-helper/Pagination"
import SortingHelper from "../../../_metronic/helpers/sorting-helper/SortingHelper"
import TableHeaderToolbar from "../../../_metronic/helpers/table-header-helper/TableHeaderToolbar"
import { getLabel } from "../../../_metronic/language-provider/LanProvider"
import { PageTitle } from "../../../_metronic/layout/core"
import { SetLoader } from "../../../_metronic/redux/actions/LoaderAction"
import { setAdminParams } from "../../../_metronic/redux/actions/QueryParamsAction"
import AdminBuildingFilter from "./AdminBuildingFilter"
import AdminRows from "./AdminRows"

const AdminList = () => {
  const dispatch = useDispatch()
  const [users, setUsers] = useState()
  const currentParams = useSelector((state) => state?.QueryParamsReducer?.adminParams)
  const [params, setparams] = useState(currentParams)
  const [notFound, setNotFound] = useState("")

  // for_CSV_NAME_LABEL_ARR
  function toTitleCase(str) {
    const arr = str.split("_")
    const result = []
    for (const word of arr) {
      result.push(word.charAt(0).toUpperCase() + word.slice(1))
    }
    return result.join(" ")
  }
  // column name to export
  let csvNameLabel = []
  Object.keys(users?.rows?.[0] || {}).map((el) => {
    if (!el.includes(".")) {
      let obj = {
        name: el,
        label: toTitleCase(el),
      }
      csvNameLabel.push(obj)
    }
  })

  // get Admin
  const getAdmin = async (search, limit, page, sortBy, sort, filterKey, filterValue) => {
    dispatch(SetLoader(true))
    let getUsersRes = await AxiosMethod(
      `users?search=${search}&limit=${limit}&page=${page}&sortwith=${sortBy}&sortby=${sort}&role=1&filterKey=${String(filterKey) || ""
      }&filterValue=${String(filterValue) || ""}`,
      `get`
    )
    let values = {
      search: search,
      limit: limit,
      page: page,
      sortBy: sortBy,
      sort: sort,
      filterKey: filterKey,
      filterValue: filterValue,
    }
    setparams(values)
    dispatch(setAdminParams(values))
    if (getUsersRes && getUsersRes.status === 200) {
      dispatch(SetLoader(false))
      setNotFound(false)
      setUsers(getUsersRes.data.data)
    }
    if (JSON.parse(getUsersRes.request.response).status === "error") {
      dispatch(SetLoader(false))
      // toast.error(JSON.parse(getUsersRes.request.response).message)
      setNotFound(JSON.parse(getUsersRes.request.response).message)
    }
  }

  const deleteAdmin = async (userId) => {
    let deleteUsersRes = await AxiosMethod(`users/${userId}?role_id=${1}`, `delete`)
    if (deleteUsersRes.status === 200) {
      toast.success(deleteUsersRes.data.message)
      getAdmin(
        params.search,
        params.limit,
        users.rows.length <= 1 ? params.page - 1 : params.page,
        params.sortBy,
        params.sort,
        params.filterKey || "",
        params.filterValue || ""
      )
    } else {
      toast.error(JSON.parse(deleteUsersRes.request.response).message)
    }
  }

  useEffect(() => {
    getAdmin(
      params?.search,
      params?.limit,
      params?.page,
      params?.sortBy,
      params?.sort,
      params?.filterKey || "",
      params?.filterValue || ""
    )
  }, [])

  return (
    <>
      <PageTitle>{getLabel("ADMIN_LIST")}</PageTitle>

      <div className="card">
        <TableHeaderToolbar
          params={params}
          setparams={setparams}
          action={getAdmin}
          filterBody={<AdminBuildingFilter params={params} action={getAdmin} />}
          showSearch={true}
          showExportBtn={false}
          csvModelName="user"
          csvNameLabel={csvNameLabel}
          filename="Admin"
          showAddButton={true}
          addButton={`${getLabel("ADD")} ${getLabel("ADMIN")}`}
          path="/admin-management/manage-admin"
        />
        <div className="card-body py-4">
          <div id="kt_table_users_wrapper" className="dataTables_wrapper dt-bootstrap4 no-footer">
            <div className="table-responsive">
              <table
                className="table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer"
                id="kt_table_users"
              >
                <thead>
                  <tr className="text-start text-muted fw-bolder fs-7 text-uppercase gs-0">
                    <th
                      className="min-w-125px sorting"
                      tabIndex={0}
                      aria-controls="kt_table_users"
                      rowSpan={1}
                      colSpan={1}
                      aria-label="User: activate to sort column ascending"
                      style={{ width: "288.678px", cursor: "pointer" }}
                      onClick={() =>
                        getAdmin(
                          params.search,
                          params.limit,
                          params.page,
                          "full_name",
                          params.sort === 0 ? 1 : 0,
                          params?.filterKey || "",
                          params?.filterValue || ""
                        )
                      }
                    >
                      {getLabel("USER")}
                      {SortingHelper(params.sort, params.sortBy, "full_name")}
                    </th>
                    <th
                      className="min-w-125px sorting"
                      tabIndex={0}
                      aria-controls="kt_table_users"
                      rowSpan={1}
                      colSpan={1}
                      aria-label="Role: activate to sort column ascending"
                      style={{ width: "288.678px", cursor: "pointer" }}
                      onClick={() =>
                        getAdmin(
                          params.search,
                          params.limit,
                          params.page,
                          "country_id",
                          params.sort === 0 ? 1 : 0,
                          params?.filterKey || "",
                          params?.filterValue || ""
                        )
                      }
                    >
                      {getLabel("COUNTRY")}
                      {SortingHelper(params.sort, params.sortBy, "country_id")}
                    </th>
                    <th
                      className="text-end min-w-100px sorting_disabled"
                      rowSpan={1}
                      colSpan={1}
                      aria-label="Actions"
                      style={{ width: "128.946px" }}
                    >
                      {getLabel("ACTIONS")}
                    </th>
                  </tr>
                </thead>
                <tbody className="text-gray-600 fw-bold">
                  {notFound ? (
                    <tr>
                      <td colSpan={8}>
                        <div className="d-flex text-center w-100 align-content-center justify-content-center text-uppercase">
                          {notFound}
                        </div>
                      </td>
                    </tr>
                  ) : (
                    users?.rows &&
                    users?.rows?.map((user, i) => {
                      return <AdminRows key={i} user={user} deleteAdmin={deleteAdmin} />
                    })
                  )}
                </tbody>
              </table>
            </div>

            {!notFound && (
              <Pagination
                params={params}
                setparams={setparams}
                count={users?.count}
                pageData={users?.rows?.length}
                action={getAdmin}
              />
            )}
          </div>
        </div>
      </div>
    </>
  )
}

export default AdminList
