/* eslint-disable eqeqeq */
/* eslint-disable react/jsx-no-target-blank */
// import {AsideMenuItemWithSub} from "./AsideMenuItemWithSub"
import {AsideMenuItem} from "./AsideMenuItem"
import {useSelector} from "react-redux"
import {useEffect, useState} from "react"
import AxiosMethod from "../../../Api/AxiosMethod"
import {getLabel} from "../../../language-provider/LanProvider"
import {AsideMenuItemWithSub} from "./AsideMenuItemWithSub"

export function AsideMenuMain() {
  const loggedInUser = useSelector((state) => state?.AuthReducer?.userData)
  const [plan, setPlan] = useState([])

  const permission = plan[plan?.findIndex((obj) => obj?.plan_id === loggedInUser?.plan_id)]?.modules

  const getPlan = async () => {
    let getPlanRes = await AxiosMethod(`plans`, `get`)
    setPlan(getPlanRes?.data?.data?.rows)
  }
  useEffect(() => {
    getPlan()
  }, [])

  return (
    <>
      {/* Dashboard */}
      <AsideMenuItem
        to="/dashboard"
        icon="/media/icons/duotune/art/art002.svg"
        title={getLabel("DASHBOARD")}
        fontIcon="bi-app-indicator"
      />
      {/* admin Management*/}
      {loggedInUser["role.role_id"] == 1 && (
        <AsideMenuItem
          to="/admin-management"
          icon="/media/icons/duotune/art/art002.svg"
          title={getLabel("ADMIN_MANAGEMENT")}
          fontIcon="bi-app-indicator"
        />
      )}
      {/* Client Management*/}
      {loggedInUser["role.role_id"] == 1 && (
        <AsideMenuItem
          to="/user-management"
          icon="/media/icons/duotune/art/art002.svg"
          title={getLabel("USER_MANAGEMENT")}
          fontIcon="bi-app-indicator"
        />
      )}

      {(loggedInUser["role.role_id"] == 1 ||
        loggedInUser["role.role_id"] == 5 ||
        permission?.includes(5) ||
        permission?.includes(1) ||
	      permission?.includes(4) ||
        permission?.includes(2)) && (
        <AsideMenuItemWithSub
          to="/org"
          title={getLabel("ORG_MANAGEMENT")}
          icon="/media/icons/duotune/art/art002.svg"
          fontIcon="bi-person"
        >
          {/* Organization Management */}
          {(
			  loggedInUser["role.role_id"] == 1 ||
		      permission?.includes(5)  ||
	          permission?.includes(4))
	          && (
            <AsideMenuItem
              to="/org/organization-management"
              title={getLabel("ORG")}
              hasBullet={true}
            />
          )}
          {/* organization manager Management */}
          {(loggedInUser["role.role_id"] == 1 || permission?.includes(5)) && (
            <AsideMenuItem to="/org/manager" title={getLabel("ORG_MANAGER")} hasBullet={true} />
          )}
          {/* Department(Group) Management */}
          {(loggedInUser["role.role_id"] == 1 ||
            loggedInUser["role.role_id"] == 5 ||
	          permission?.includes(4) ||
            permission?.includes(1)) && (
            <AsideMenuItem
              to="/org/schedule-management"
              title={getLabel("SCHEDULE")}
              hasBullet={true}
            />
          )}
          {/* Staff Management*/}
          {(loggedInUser["role.role_id"] == 1 ||
            loggedInUser["role.role_id"] == 5 ||
	          permission?.includes(4) ||
            permission?.includes(2)) && (
            <AsideMenuItem to="/org/staff-management" title={getLabel("STAFF")} hasBullet={true} />
          )}
          {/* Staff map management*/}
          {(loggedInUser["role.role_id"] == 1 ||
            loggedInUser["role.role_id"] == 5 ||
	          permission?.includes(4) ||
            permission?.includes(2)) && (
            <AsideMenuItem to="/org/staff-mapping" title={getLabel("MAPPING")} hasBullet={true} />
          )}
        </AsideMenuItemWithSub>
      )}

      {/* Qr management */}
      {(loggedInUser["role.role_id"] == 1 ||
        loggedInUser["role.role_id"] == 5 ||
        permission?.includes(4)) && (
        <AsideMenuItem
          to="/qr-code-management"
          icon="/media/icons/duotune/art/art010.svg"
          title={getLabel("QR_MANAGEMENT")}
          fontIcon="bi-layers"
        />
      )}
      {/* call details */}
      {/* {loggedInUser && loggedInUser["role.role_id"] == 1 && ( */}
      <AsideMenuItem
        to="/call-details"
        icon="/media/icons/duotune/art/art010.svg"
        title={getLabel("CALL_DETAILS")}
        fontIcon="fa fa-industry"
      />

       {/* Connections */}
       {(loggedInUser["role.role_id"] == 1 ||
        loggedInUser["role.role_id"] == 5 ||
        permission?.includes(4)) && (
        <AsideMenuItem
          to="/connections"
          icon="/media/icons/duotune/art/art010.svg"
          title={getLabel("CONNECTION_DETAILS")}
          fontIcon="bi-layers"
        />
      )}
      {/* )} */}

      {loggedInUser && loggedInUser["role.role_id"] == 1 && (
        <AsideMenuItemWithSub
          to="/config"
          title={getLabel("CONFIGURATION")}
          icon="/media/icons/duotune/art/art002.svg"
          fontIcon="bi-person"
        >
          {/*Account Settings  */}
          <AsideMenuItem
            to="/config/accounts-settings"
            title={getLabel("ACCOUNT_SETTING")}
            hasBullet={true}
          />
          {/* Fields customization */}
          <AsideMenuItem
            to="/config/fields-customization"
            title={getLabel("FIELDS_CUSTOMIZATION")}
            hasBullet={true}
          />
          {/* Available plans  */}
          <AsideMenuItem
            to="/config/available-plan"
            title={getLabel("AVAILABLE_PLANS")}
            hasBullet={true}
          />
        </AsideMenuItemWithSub>
      )}

      {/* Role management */}
      {/* <AsideMenuItemWithSub
        to="/role-management"
        icon="/media/icons/duotune/art/art010.svg"
        title="Role management"
        fontIcon="fa fa-industry"
      >
        <AsideMenuItem to="/role-management/view-role" title="View Role" hasBullet={true} />
      </AsideMenuItemWithSub> */}
      {/* Organization Management */}
      {/* <AsideMenuItem
        to="/organization-management"
        icon="/media/icons/duotune/art/art002.svg"
        title="Organization Management"
        fontIcon="bi-app-indicator"
      /> */}

      {/* <div className="separator mx-1 my-4"></div> */}

      {/* Language Management */}
      {/* <AsideMenuItemWithSub
        to="/language-management"
        icon="/media/icons/duotune/art/art010.svg"
        title="Language Management"
        fontIcon="bi-layers"
      >
        <AsideMenuItem
          to="/language-management/view-language"
          title="View Language"
          hasBullet={true}
        />
      </AsideMenuItemWithSub> */}
      {/* Validation Management */}
      {/* <AsideMenuItemWithSub
        to="/validation-management"
        icon="/media/icons/duotune/general/gen019.svg"
        title="Validation Management"
        fontIcon="bi-layers"
      >
        <AsideMenuItem
          to="/validation-management/view-validation"
          title="View Validation"
          hasBullet={true}
        />
      </AsideMenuItemWithSub> */}
      {/* <AsideMenuItem
        to="/builder"
        icon="/media/icons/duotune/general/gen019.svg"
        title="Layout Builder"
        fontIcon="bi-layers"
      /> */}
      {/* <div className="menu-item">
        <div className="menu-content pt-8 pb-2">
          <span className="menu-section text-muted text-uppercase fs-8 ls-1">Crafted</span>
        </div>
      </div>
      <AsideMenuItemWithSub
        to="/crafted/pages"
        title="Pages"
        fontIcon="bi-archive"
        icon="/media/icons/duotune/general/gen022.svg"
      >
        <AsideMenuItemWithSub to="/crafted/pages/profile" title="Profile" hasBullet={true}>
          <AsideMenuItem to="/crafted/pages/profile/overview" title="Overview" hasBullet={true} />
          <AsideMenuItem to="/crafted/pages/profile/projects" title="Projects" hasBullet={true} />
          <AsideMenuItem to="/crafted/pages/profile/campaigns" title="Campaigns" hasBullet={true} />
          <AsideMenuItem to="/crafted/pages/profile/documents" title="Documents" hasBullet={true} />
          <AsideMenuItem
            to="/crafted/pages/profile/connections"
            title="Connections"
            hasBullet={true}
          />
        </AsideMenuItemWithSub>

        <AsideMenuItemWithSub to="/crafted/pages/wizards" title="Wizards" hasBullet={true}>
          <AsideMenuItem
            to="/crafted/pages/wizards/horizontal"
            title="Horizontal"
            hasBullet={true}
          />
          <AsideMenuItem to="/crafted/pages/wizards/vertical" title="Vertical" hasBullet={true} />
        </AsideMenuItemWithSub>
      </AsideMenuItemWithSub>
      <AsideMenuItemWithSub
        to="/crafted/accounts"
        title="Accounts"
        icon="/media/icons/duotune/communication/com006.svg"
        fontIcon="bi-person"
      >
        <AsideMenuItem to="/crafted/account/overview" title="Overview" hasBullet={true} />
        <AsideMenuItem to="/crafted/account/settings" title="Settings" hasBullet={true} />
      </AsideMenuItemWithSub>
      <AsideMenuItemWithSub
        to="/error"
        title="Errors"
        fontIcon="bi-sticky"
        icon="/media/icons/duotune/general/gen040.svg"
      >
        <AsideMenuItem to="/error/404" title="Error 404" hasBullet={true} />
        <AsideMenuItem to="/error/500" title="Error 500" hasBullet={true} />
      </AsideMenuItemWithSub>
      <AsideMenuItemWithSub
        to="/crafted/widgets"
        title="Widgets"
        icon="/media/icons/duotune/general/gen025.svg"
        fontIcon="bi-layers"
      >
        <AsideMenuItem to="/crafted/widgets/lists" title="Lists" hasBullet={true} />
        <AsideMenuItem to="/crafted/widgets/statistics" title="Statistics" hasBullet={true} />
        <AsideMenuItem to="/crafted/widgets/charts" title="Charts" hasBullet={true} />
        <AsideMenuItem to="/crafted/widgets/mixed" title="Mixed" hasBullet={true} />
        <AsideMenuItem to="/crafted/widgets/tables" title="Tables" hasBullet={true} />
        <AsideMenuItem to="/crafted/widgets/feeds" title="Feeds" hasBullet={true} />
      </AsideMenuItemWithSub>
      <div className="menu-item">
        <div className="menu-content pt-8 pb-2">
          <span className="menu-section text-muted text-uppercase fs-8 ls-1">Apps</span>
        </div>
      </div>
      <AsideMenuItemWithSub
        to="/apps/chat"
        title="Chat"
        fontIcon="bi-chat-left"
        icon="/media/icons/duotune/communication/com012.svg"
      >
        <AsideMenuItem to="/apps/chat/private-chat" title="Private Chat" hasBullet={true} />
        <AsideMenuItem to="/apps/chat/group-chat" title="Group Chart" hasBullet={true} />
        <AsideMenuItem to="/apps/chat/drawer-chat" title="Drawer Chart" hasBullet={true} />
      </AsideMenuItemWithSub>
      <AsideMenuItem
        to="/apps/user-management/users"
        icon="/media/icons/duotune/art/art010.svg"
        title="User management"
        fontIcon="bi-layers"
      /> */}
      {/* <div className="menu-item">
        <div className="menu-content">
          <div className="separator mx-1 my-4"></div>
        </div>
      </div> */}
    </>
  )
}
