/* eslint-disable jsx-a11y/anchor-is-valid */
import { toProfileImgUrl } from "../../../_metronic/helpers"
import { DeleteSvg } from "../../../_metronic/helpers/svg-helper/DeleteSvg"
import EditSvg from "../../../_metronic/helpers/svg-helper/EditSvg"
import ViewSvg from "../../../_metronic/helpers/svg-helper/ViewSvg"
import { handleNull } from "../../../_metronic/helpers/reuseable-fun/FunReuse"

const StaffRows = ({ user, deleteStaff }) => {
  return (
    <>
      <tr key={user.user_id} className="odd">
        <td className="d-flex align-items-center">
          <div className="symbol symbol-circle symbol-50px overflow-hidden me-3">
            <a>
              <div className="symbol-label fs-3 bg-light-primary text-primary">
                {user.profile_pic ? (
                  <img src={toProfileImgUrl(user.profile_pic)} alt="" className="w-100" />
                ) : (
                  user.full_name
                    .match(/\b(\w)/g)
                    .join("")
                    .toUpperCase()
                )}
              </div>
            </a>
          </div>

          <div className="d-flex flex-column">
            <a className="text-gray-800 text-hover-primary mb-1">
              {user.full_name}
              <br />
              {user.contact_no}
            </a>
            <span>{user.email}</span>
          </div>
        </td>

        {/* <td>{user["user.full_name"]}</td> */}

        {/* <td>{user["department.name"]}</td> */}

        {/* <td>
          {user["start_shift"] ? moment(user["start_shift"]).format("DD/MM/yyyy HH:mm") : "N/A"}
        </td> */}

        {/* <td>
          {user["end_shift"] ? moment(user["end_shift"]).format("DD/MM/yyyy HH:mm") : "N/A"}
        </td> */}

        {/* <td>{user["is_default"] === 1 ? "True" : "False"}</td> */}
	      
        <td>{handleNull(user["country.name"])}</td>
        <td>
          {user.organizations_array
            .map((x) => (
              <span style={{ display: "block", whiteSpace: "nowrap" }} key={x}>
                {x}
              </span>
            ))}
        </td>

        <td>
          <div className="d-flex justify-content-end flex-shrink-0">
            <ViewSvg path="/org/staff-management/view-staff" editObj={user} />
            {/* <EditSvg path="/org/staff-management/manage-staff" editObj={user} /> */}
            {/* <DeleteSvg onClick={() => deleteStaff(user.user_id, user.role_id)} /> */}
          </div>
        </td>
      </tr>
    </>
  )
}

export default StaffRows
