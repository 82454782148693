import {Modal} from "react-bootstrap"
import {getLabel} from "../../language-provider/LanProvider"

const ConfirmModel = ({delMsz, show, onHide, onConfirmDel}) => {
  return (
    <>
      <Modal
        show={typeof show == "object" ? Object.values(show)[0] : show}
        onHide={() => onHide("")}
        centered
      >
        <Modal.Body>
          <div className="form-header p-5 text-center">
            <h3 className="p-5 text-gray-600 fw-bold">
              {typeof show == "string" || typeof show == "number"
                ? `${getLabel("DEL_CONFIRM")} ${delMsz}?`
                : ` ${delMsz}`}
            </h3>
          </div>
          <div className="mb-5">
            <div className="row">
              <div className="col-6 text-center">
                <button
                  type="button"
                  className="swal2-cancel btn fw-bold btn-active-light-primary btn-light"
                  style={{display: "inline-block", width: "10rem", height: "4rem"}}
                  data-bs-dismiss="modal"
                  onClick={() => onHide("")}
                >
                  {getLabel("NO")}
                </button>
              </div>
              <div className="col-6 text-center">
                <button
                  type="button"
                  className="swal2-confirm btn fw-bold btn-danger"
                  style={{display: "inline-block", width: "10rem", height: "4rem"}}
                  onClick={() => {
                    onHide("")
                    onConfirmDel(show)
                  }}
                >
                  {getLabel("YES")}
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default ConfirmModel
