export const ActionTypes =
{
	// LOADER
	SET_LOADER: "SET_LOADER",
	// LOGIN
	AUTH: "AUTH",
	// TURN SERVER
	SET_TURN_SERVER: "SET_TURN_SERVER",
	
	SET_PLAN: "SET_PLAN",
	
	// SET_REDUX_LANGUAGE at I18
	SET_REDUX_LANGUAGE: "SET_REDUX_LANGUAGE",
	// SET_REDUX_VALIDATION at I18
	SET_REDUX_VALIDATION: "SET_REDUX_VALIDATION",

	// QUERY_PARAMS
	SET_LANG_PARAMS: "SET_LANG_PARAMS",
	SET_VAL_PARAMS: "SET_VAL_PARAMS",
	SET_ADMIN_PARAMS: "SET_ADMIN_PARAMS",
	SET_CLIENT_PARAMS: "SET_CLIENT_PARAMS",
	SET_STAFF_PARAMS: "SET_STAFF_PARAMS",
	SET_DEPT_PARAMS: "SET_DEPT_PARAMS",
	SET_ORG_PARAMS: "SET_ORG_PARAMS",
	SET_ORG_MANAGER_PARAMS: "SET_ORG_MANAGER_PARAMS",
	SET_QR_PARAMS: "SET_QR_PARAMS",
	SET_ORG_MAPPING_PARAMS: "SET_ORG_MAPPING_PARAMS",
	SET_CALL_DETAIL_PARAMS: "SET_CALL_DETAIL_PARAMS",
	SET_CONNECTIONS_PARAMS: "SET_CONNECTIONS_PARAMS",
	SET_CONNECTIONS_DETAIL_PARAMS: "SET_CONNECTIONS_DETAIL_PARAMS",
}
