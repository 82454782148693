/*
  AUTHOR: QASIM ZUBAIR.
  SUMMARY: FIREBASE CONFIGURATION AND CONTAINS COMMONLY USED FUNCTIONS.
*/
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getToken, getMessaging } from 'firebase/messaging';
import AxiosMethod from "../../_metronic/Api/AxiosMethod"
import {globals} from "../utils/globals"
import {logOut} from "../../_metronic/helpers/reuseable-fun/FunReuse"
import { getDatabase } from 'firebase/database';
import { getFirestore } from 'firebase/firestore';
import {setStorage, updateCallStatusByUserId} from "./helper.service"

// YOUR WEB APP'S FIREBASE CONFIGURATION
// FOR FIREBASE JS SDK V7.20.0 AND LATER, MEASUREMENTID IS OPTIONAL
// YOUR WEB APP'S FIREBASE CONFIGURATION
const firebaseConfig =
{
	apiKey: "AIzaSyB8muYeXvEQXDuJGHkOPgw9_akeu0BPQTg",
	authDomain: "qr-phone-ef5ce.firebaseapp.com",
	databaseURL: "https://qr-phone-ef5ce-default-rtdb.europe-west1.firebasedatabase.app",
	projectId: "qr-phone-ef5ce",
	storageBucket: "qr-phone-ef5ce.appspot.com",
	messagingSenderId: "814512766448",
	appId: "1:814512766448:web:6d4e50c1d2e8c463a23135",
	measurementId: "G-MR0JMG9NXW"
};
const vapidKey = "BF4X6ryYiTfK4dqPsQcfAiceSjmNXynFQPSoPcvEX-TGgaTyR8Ti6mxHFTIZaDvCBL1n2uGPr54fcObjPEWrKpw";

//  INITIALIZE FIREBASE.
const app = initializeApp (firebaseConfig);

// INITIALIZE FIREBASE AUTHENTICATION AND GET A REFERENCE TO THE SERVICES.
const auth =  getAuth(app);

// GET A REFERENCE TO THE REALTIME DATABASE SERVICE.
const database = getDatabase (app);

// GET A REFERENCE TO THE FIRESTORE DATABASE SERVICE.
const firestore = getFirestore(app);

// GET A REFERENCE TO THE MESSAGING SERVICE
const messaging = getMessaging(app);

// UPDATE DEVICE FCM TOKEN ON SERVER.
export const updateFCMToken = async (user_id:number, token:string) =>
{
	let data = {token: token, user_id: user_id, platform: "web"};
	console.log ("updating fcm token", data);

	// SEND THE TOKEN TO SERVER.
	return await AxiosMethod ("users/fcmtoken/update", "POST", data);
}

const getFCMToken = (user_id: any) =>
{
	// PERMISSION GRANTED, NOW GET THE FCM TOKEN
	getToken (messaging, {vapidKey: vapidKey})
	.then (async (token) =>
	{
		console.log ('token getting FCM token:', token);
		await setStorage ("qr_fcm_token", token);
		updateFCMToken (user_id, token);
	})
	.catch((err) =>
	{
		console.log('Error getting FCM token:', err);
	});
}

const sendFCMToken = (user_id:any) =>
{
	if (Notification.permission !== 'granted')
	{
		Notification.requestPermission().then ((permission: any) =>
		{
			if (permission === 'granted')
			{
				getFCMToken (user_id);
			}
			else
			{
				console.log ('Notification permission denied', permission);
			}
		})
	}
	else
	{
		getFCMToken (user_id);
	}
}

const handleIncomingNotificationData = (notificationPayload: any, navigation:any, userData: any) =>
{
	try
	{
		console.log ("handleIncoming_NotificationData_1");

		if (notificationPayload.type === "alert")
		{
			globals.notificationData = notificationPayload.data;
			//handleFCMNotification (notificationPayload);
		}
		else // FOR SILENT NOTIFICATION THAT ARE REQUIRED TO UPDATE STATUS OF ONGOING CALL OR CHAT.
		{
			processSilentNotification (notificationPayload, navigation, userData).then();
		}
	}
	catch (e)
	{
		console.error ("error in handle incoming notification data", e);
	}
}

// PROCESS AND HANDLE THE DATA AVAILABLE IN SILENT/BACKGROUND NOTIFICATION.
const processSilentNotification = async (notificationData: any, navigation: any, userData: any) =>
{
	console.log ("handleIncoming-NotificationData-12", "silent notification.", notificationData);
	let data: any= notificationData;
	let action: string= data?.action;

	if (data && typeof action === "string" && action === "FCM-LOGOUT-SESSION")
	{
		await logOut()
		return true;
	}
	if (data && typeof action === "string" && action === "FCM-KILL-NOTIFICATION-CALL" && userData.user_id === data.user_id)
	{
		globals.callerSocketID = null;
		globals.callerUserName = null;
		globals.callerDetail = null;
		globals.call_id = null
		globals.callStaffData = null;
		globals.notification_received_time = null;
		globals.userFromNotification = false;
		navigation ("/dashboard");
		console.log ("dispached the dashboard.")
	}
	if (data && typeof action === "string" && action === "FCM-INCOMING-CALL")
	{
		globals.callerSocketID = data.body;
		globals.callerUserName = data.name;
		globals.callerDetail = ((data.callerDetail) ? JSON.parse (data.callerDetail) : null);
		globals.call_id = data.call_id;
		globals.maxResponseSeconds = data.maxResponseSeconds;
		globals.enabledDirectCall = data?.enabledDirectCall;
		globals.isThisDefaultStaffNotified = data.isThisDefaultStaffNotified;
		globals.callStaffData = data.staffs;
		globals.notification_received_time = data.time;
		globals.userFromNotification = false;
		await updateCallStatusByUserId (data.call_id, "FCMPR", data.user_id);
		console.log ("dispached the incomingcall.");

		if (globals.currentScreenName !== "calling")
		{
			navigation ("incomingcall");
		}
	}
}

export { app, messaging, auth, database, firestore, sendFCMToken, processSilentNotification, handleIncomingNotificationData};
