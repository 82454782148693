import {Formik, Form} from "formik"
import {useLocation, useNavigate} from "react-router-dom"
import toast from "react-hot-toast"

import * as Yup from "yup"
import AxiosMethod from "../../../../_metronic/Api/AxiosMethod"
import {getLabel} from "../../../../_metronic/language-provider/LanProvider"
import {PageTitle} from "../../../../_metronic/layout/core"
import FormikController from "../../../../_metronic/Shared-Component-formik/FormikController"
import SchemaList from "../../../../_metronic/Shared-Component-formik/schema/SchemaList"
import {useState} from "react"

const usersBreadcrumbs = [
  {
    title: "Validation Page",
    path: "/validation-management/view-validation",
    isSeparator: false,
    isActive: false,
  },
  {
    title: "",
    path: "",
    isSeparator: true,
    isActive: false,
  },
]
const CreateEditValidation = () => {
  const [loading, setLoading] = useState(false)

  const navigate = useNavigate()
  const location = useLocation()

  const appType = [
    {label: "Web", value: "web"},
    {label: "Mobile", value: "mobile"},
  ]
  const validationName = [
    {label: "Maximum", value: "Maximum"},
    {label: "minimum", value: "minimum"},
    {label: "Required", value: "Required"},
    {label: "Greater than given value", value: "Greater than given value"},
    {label: "Less than given value", value: "Less than given value"},
    {label: "Invalid", value: "Invalid"},
    {label: "Custom", value: "Custom"},
  ]
  const valType = [
    {label: "Frontend", value: "frontend"},
    {label: "Backend", value: "backend"},
  ]

  // initialValues for formik
  const initialValues = {
    Label: location?.state?.editValidationObj?.label
      ? location?.state?.editValidationObj?.label
      : "",
    English: location?.state?.editValidationObj?.en ? location?.state?.editValidationObj?.en : "",
    French: location?.state?.editValidationObj?.fr ? location?.state?.editValidationObj?.fr : "",
    Spanish: location?.state?.editValidationObj?.es ? location?.state?.editValidationObj?.es : "",
    Type: location?.state?.editValidationObj?.type ? location?.state?.editValidationObj?.type : "",
    Validation: location?.state?.editValidationObj?.validation
      ? location?.state?.editValidationObj?.validation
      : "",
    Application_type: location?.state?.editValidationObj?.application_type
      ? location?.state?.editValidationObj?.application_type
      : "",
  }
  // validationSchema for formik
  let validationSchema = Yup.object({
    Label: SchemaList[0],
    English: SchemaList[0],
    French: SchemaList[0],
    Spanish: SchemaList[0],
    Type: SchemaList[0],
    Validation: SchemaList[0],
    Application_type: SchemaList[0].required("Application Type is required"),
  })
  // Submit Validation
  const onAddEditValidation = async (lan) => {
    let arr = [
      {
        language_id: 1,
        label: lan.Label,
        value: lan.English,
        type: lan.Type,
        validation: lan.Validation,
        application_type: lan.Application_type,
      },
      {
        language_id: 2,
        label: lan.Label,
        value: lan.French,
        type: lan.Type,
        validation: lan.Validation,
        application_type: lan.Application_type,
      },
      {
        language_id: 3,
        label: lan.Label,
        value: lan.Spanish,
        type: lan.Type,
        validation: lan.Validation,
        application_type: lan.Application_type,
      },
    ]

    let addEditValRes = await AxiosMethod(
      `language/validations`,
      location?.state?.add ? `post` : `patch`,
      arr
    )
    if (addEditValRes && addEditValRes.status === 200) {
      toast.success(addEditValRes.data.message)
      navigate("/validation-management/view-validation")
    }
    if (JSON.parse(addEditValRes.request.response).status === "error") {
      toast.error(JSON.parse(addEditValRes.request.response).message)
    }
  }
  return (
    <>
      <PageTitle breadcrumbs={usersBreadcrumbs}>
        {location?.state?.add ? `Add Validation` : `Edit Validation`}
      </PageTitle>

      <div className="card card-custom">
        <div className="card-header">
          <div className="card-title">
            <h3 className="card-label">{location?.state?.add ? `Add` : `Edit`} Validation</h3>
          </div>
        </div>

        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onAddEditValidation}
        >
          {(formik) => {
            return (
              <Form onSubmit={formik.handleSubmit} className="form">
                {/*begin::Scroll*/}
                <div className="card-body py-4">
                  <div
                    className="d-flex row me-n7 pe-7"
                    // className="d-flex row scroll-y me-n7 pe-7"
                    id="kt_modal_add_user_scroll"
                    data-kt-scroll="true"
                    data-kt-scroll-activate="{default: false, lg: true}"
                    // data-kt-scroll-max-height="auto"
                    data-kt-scroll-dependencies="#kt_modal_add_user_header"
                    data-kt-scroll-wrappers="#kt_modal_add_user_scroll"
                    // data-kt-scroll-offset="300px"
                  >
                    <div className="col-6 mb-7">
                      <FormikController
                        control="input"
                        type="text"
                        name="Label"
                        label="Label"
                        labelClassName="required fw-bold fs-6 mb-2"
                        placeholder="Label"
                        className="form-control form-control-solid mb-lg-0"
                        formik={formik}
                        value={formik.values.Label}
                        onChange={formik.handleChange}
                        error={formik.errors.Label}
                        disabled={location?.state?.edit}
                      />
                    </div>
                    <div className="col-6 mb-7">
                      <FormikController
                        control="input"
                        type="text"
                        name="English"
                        label="English"
                        labelClassName="required fw-bold fs-6 mb-2"
                        placeholder="English"
                        className="form-control form-control-solid mb-lg-0"
                        formik={formik}
                        value={formik.values.English}
                        onChange={formik.handleChange}
                        error={formik.errors.English}
                      />
                    </div>
                    <div className="col-6 mb-7">
                      <FormikController
                        control="input"
                        type="text"
                        name="French"
                        label="French"
                        labelClassName="required fw-bold fs-6 mb-2"
                        placeholder="French"
                        className="form-control form-control-solid mb-lg-0"
                        formik={formik}
                        value={formik.values.French}
                        onChange={formik.handleChange}
                        error={formik.errors.French}
                      />
                    </div>
                    <div className="col-6 mb-7">
                      <FormikController
                        control="input"
                        type="text"
                        name="Spanish"
                        label="Spanish"
                        labelClassName="required fw-bold fs-6 mb-2"
                        placeholder="Spanish"
                        className="form-control form-control-solid mb-lg-0"
                        formik={formik}
                        value={formik.values.Spanish}
                        onChange={formik.handleChange}
                        error={formik.errors.Spanish}
                      />
                    </div>
                    <div className="col-6 mb-7">
                      <FormikController
                        control="react_select"
                        label="Type"
                        labelClassName="required fw-bold fs-6 mb-2"
                        name="Type"
                        className="form-control-solid mb-lg-0"
                        options={valType}
                        isMulti={false}
                        value={formik.values.Type}
                        onChange={formik.handleChange}
                        error={formik.errors.Type}
                      />
                    </div>
                    <div className="col-6 mb-7">
                      <FormikController
                        control="react_select"
                        label="Validation"
                        labelClassName="required fw-bold fs-6 mb-2"
                        name="Validation"
                        className="form-control-solid mb-lg-0"
                        options={validationName}
                        value={formik.values.Validation}
                        onChange={formik.handleChange}
                        error={formik.errors.Validation}
                      />
                    </div>
                    <div className="col-6 mb-7">
                      <FormikController
                        control="react_select"
                        label="Application Type"
                        labelClassName="required fw-bold fs-6 mb-2"
                        name="Application_type"
                        className="form-control-solid mb-lg-0"
                        options={appType}
                        value={formik.values.Application_type}
                        onChange={formik.handleChange}
                        error={formik.errors.Application_type}
                      />
                    </div>
                  </div>
                </div>
                {/*end::Scroll*/}
                {/*begin::Actions*/}
                <div className="card-footer d-flex justify-content-center">
                  <button
                    className="btn btn-light-primary font-weight-bold"
                    style={{marginRight: "1rem"}}
                    type="button"
                    onClick={() => {
                      navigate("/validation-management/view-validation")
                    }}
                  >
                    <span className="indicator-label">{getLabel("CANCEL")}</span>
                  </button>
                  <button
                    type="submit"
                    id="kt_password_reset_submit"
                    className="btn btn-lg btn-primary fw-bolder me-4"
                    disabled={formik.isSubmitting || !formik.isValid}
                  >
                    {loading ? (
                      <span className="indicator-progress" style={{display: "block"}}>
                        {getLabel("PLEASE_WAIT")}
                        <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                      </span>
                    ) : (
                      <span className="indicator-label">{getLabel("SUBMIT")}</span>
                    )}
                  </button>
                </div>
                {/*end::Actions*/}
              </Form>
            )
          }}
        </Formik>
      </div>
    </>
  )
}

export default CreateEditValidation
