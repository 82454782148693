/* eslint-disable react-hooks/exhaustive-deps */
import * as Yup from "yup"
import toast from "react-hot-toast"

import { Formik, Form } from "formik"
import { useLocation, useNavigate } from "react-router-dom"
import { useEffect, useState } from "react"
import { PageTitle } from "../../../_metronic/layout/core"
import AxiosMethod from "../../../_metronic/Api/AxiosMethod"
import FormikController from "../../../_metronic/Shared-Component-formik/FormikController"
import {
  getTempCountries,
  getTempDept,
  getTempOrg,
  getTempState,
} from "../../../_metronic/helpers/reuseable-fun/FunReuse"
import { TakeEditedVal } from "../../../_metronic/helpers/only-edit-value/TakeEditedVal"
import { getLabel } from "../../../_metronic/language-provider/LanProvider"
import { ValSchemaList } from "../../../_metronic/Shared-Component-formik/schema/ValSchemaList"

const CreateEditStaff = () => {
  const [loading, setLoading] = useState(false)
  const usersBreadcrumbs = [
    {
      title: `${getLabel("STAFF")} ${getLabel("LIST")}`,
      path: "/org/staff-management",
      isSeparator: false,
      isActive: false,
    },
    {
      title: "",
      path: "",
      isSeparator: true,
      isActive: false,
    },
  ]
  const navigate = useNavigate()
  const location = useLocation()
  const [userList, setUserList] = useState([])
  const [stateList, setStateList] = useState([])
  const [deptList, setDeptList] = useState([])
  const [countryList, setCountryList] = useState([])
  const [orgForDept, setOrgForDept] = useState(
    location?.state?.editObj?.organization_id ? location?.state?.editObj?.organization_id : null
  )
  const [countryIdForState, setCountryIdForState] = useState(
    location?.state?.editObj?.country_id ? location?.state?.editObj?.country_id : null
  )
  const [profileImg, setProfileImg] = useState("")
  const [defaultStaff, setdefaultStaff] = useState(
    location?.state?.editObj ? location?.state?.editObj?.is_default : 0
  )

  const initialValues = {
    organization_id: location?.state?.editObj?.organization_id
      ? location?.state?.editObj?.organization_id
      : "",
    department_id: location?.state?.editObj ? location?.state?.editObj?.department_id : "",
    full_name: location?.state?.editObj ? location?.state?.editObj?.full_name : "",
    email: location?.state?.editObj ? location?.state?.editObj?.email : "",
    contact_no: location?.state?.editObj ? location?.state?.editObj?.contact_no : "",
    address: location?.state?.editObj ? location?.state?.editObj?.address : "",
    city: location?.state?.editObj ? location?.state?.editObj?.city : "",
    state_id: location?.state?.editObj ? location?.state?.editObj?.state_id : "",
    country_id: location?.state?.editObj ? location?.state?.editObj?.country_id : "",
    // start_shift: location?.state?.editObj ? location?.state?.editObj?.start_shift : "",
    // end_shift: location?.state?.editObj ? location?.state?.editObj?.end_shift : "",
    password: location?.state?.editObj ? "" : "",
  }

  let validationSchema = Yup.object({
    organization_id: ValSchemaList(0, "ORG"),
    department_id: ValSchemaList(0, "SCHEDULE"),
    full_name: ValSchemaList(0, "FULL_NAME"),
    email: ValSchemaList(1, "EMAIL"),
    // contact_no: ValSchemaList(4, "CONTACT_NO"),
    // ...(location?.state?.add && {
    //   start_shift: ValSchemaList(6, "START_TIME"),
    // }),
    // ...(location?.state?.add && {
    //   end_shift: ValSchemaList(6, "END_TIME").when(
    //     "start_shift",
    //     (start_shift, schema) =>
    //       start_shift && schema.min(start_shift, "End Time is must be later than Start Time")
    //   ),
    // }),
    ...(location?.state?.add && {
      password: ValSchemaList(8, "PASSWORD"),
    }),
  })
  const getDept = async () => {
    setDeptList(await getTempDept(orgForDept))
  }
  const getOrg = async () => {
    setUserList(await getTempOrg())
  }
  const getCountries = async () => {
    setCountryList(await getTempCountries())
  }
  const getState = async () => {
    setStateList(await getTempState(countryIdForState))
  }
  const onAddEditUser = async (values) => {
    setLoading(true)
    let temp = TakeEditedVal(values, initialValues)
    var bodyFormData = new FormData()
    for (var key in temp) {
      bodyFormData.append(key, temp[key])
    }
    bodyFormData.append("role_id", 3)
    bodyFormData.append("is_default", defaultStaff)
    !location?.state?.add &&
      bodyFormData.append(
        "organization_id",
        values.organization_id || location?.state?.editObj?.organization_id
      )
    !location?.state?.add &&
      bodyFormData.append(
        "department_id",
        values.department_id || location?.state?.editObj?.department_id
      )
    !location?.state?.add &&
      values.state_id &&
      !temp.country_id &&
      bodyFormData.append("country_id", location?.state?.editObj?.country_id)

    let createUsersRes = await AxiosMethod(
      location?.state?.add ? `users/create` : `users/${location?.state?.editObj.user_id}`,
      location?.state?.add ? `post` : `patch`,
      bodyFormData
    )
    if (createUsersRes && createUsersRes.status === 200) {
      if (location?.state?.add) {
        let generateQrRes = await AxiosMethod(`qrcodes/generate`, `post`, {
          email: values.email,
        })
        if (generateQrRes && generateQrRes.status === 200) {
          setLoading(false)
          toast.success(getLabel("STAFF_CREATED"))
          toast.success(getLabel("QR_GENERATED"))
        }
        if (JSON.parse(generateQrRes.request.response).status === "error") {
          toast.error(JSON.parse(generateQrRes.request.response).message)
        }
      } else {
        setLoading(false)
        toast.success(getLabel("STAFF_UPDATED"))
      }
      navigate("/org/staff-management")
    }
    if (JSON.parse(createUsersRes.request.response).status === "error") {
      setLoading(false)
      toast.error(JSON.parse(createUsersRes.request.response).message)
    }
  }
  useEffect(() => {
    getOrg()
    getCountries()
  }, [])
  useEffect(() => {
    getState()
  }, [countryIdForState])
  useEffect(() => {
    getDept()
  }, [orgForDept])

  return (
    <>
      <PageTitle breadcrumbs={usersBreadcrumbs}>
        {`${getLabel(location?.state?.add ? "ADD" : "EDIT")} ${getLabel("STAFF")}`}
      </PageTitle>

      <div className="card card-custom">
        <div className="card-header">
          <div className="card-title">
            <h3 className="card-label">{`${getLabel(
              location?.state?.add ? "add" : "edit"
            )} ${getLabel("STAFF")}`}</h3>
          </div>
        </div>

        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onAddEditUser}
        >
          {(formik) => {
            formik.values.country_id && setCountryIdForState(formik.values.country_id)
            formik.values.organization_id && setOrgForDept(formik.values.organization_id)
            return (
              <Form onSubmit={formik.handleSubmit} className="form">
                {/*begin::Scroll*/}
                <div className="card-body py-4">
                  <div
                    className="d-flex row me-n7 pe-7"
                    // className="d-flex row scroll-y me-n7 pe-7"
                    id="kt_modal_add_user_scroll"
                    data-kt-scroll="true"
                    data-kt-scroll-activate="{default: false, lg: true}"
                    // data-kt-scroll-max-height="auto"
                    data-kt-scroll-dependencies="#kt_modal_add_user_header"
                    data-kt-scroll-wrappers="#kt_modal_add_user_scroll"
                  // data-kt-scroll-offset="300px"
                  >
                    <div className="col-6 mb-7">
                      <FormikController
                        control="react_select"
                        label={getLabel("ORG")}
                        labelClassName="required fw-bold fs-6 mb-2"
                        name="organization_id"
                        className="form-control-solid mb-lg-0"
                        formik={formik}
                        options={userList}
                        value={formik.values.organization_id}
                        onChange={formik.handleChange}
                        error={formik.errors.organization_id}
                        isDisabled={!location?.state?.add}
                      />
                    </div>
                    {/* dept */}
                    {/* <div className="col-6 mb-7">
                      <FormikController
                        control="react_select"
                        label={getLabel("SCHEDULE")}
                        labelClassName="required fw-bold fs-6 mb-2"
                        name="department_id"
                        className="form-control-solid mb-lg-0"
                        formik={formik}
                        options={deptList}
                        value={formik.values.department_id}
                        onChange={formik.handleChange}
                        error={formik.errors.department_id}
                      />
                    </div> */}
                    {/* simple react state */}
                    <div className="col-6 mb-7">
                      <FormikController
                        control="select"
                        label={getLabel("SCHEDULE")}
                        labelClassName="required fw-bold fs-6 mb-2"
                        name="department_id"
                        className="form-select form-select-solid"
                        formik={formik}
                        options={deptList}
                        value={formik.values.department_id}
                        onChange={formik.handleChange}
                        error={formik.errors.department_id}
                        disabled={!location?.state?.add}
                      />
                    </div>

                    {/* startTime */}
                    {/* <div className="col-6 mb-7">
                      <FormikController
                        control="datetime"
                        label={getLabel("START_TIME")}
                        placeholder={getLabel("SELECT") + " " + getLabel("START_TIME")}
                        labelClassName="required fw-bold fs-6 mb-2"
                        className="form-control form-control-solid mb-lg-0"
                        name="start_shift"
                        timeFormat="HH:mm"
                        dateFormat="MMMM d, yyyy h:mm aa"
                        timeCaption="Time"
                        formik={formik}
                        value={formik.values.start_shift}
                        onChange={formik.handleChange}
                        error={formik.errors.start_shift}
                      />
                    </div> */}

                    {/* End Time */}
                    {/* <div className="col-6 mb-7">
                      <FormikController
                        control="datetime"
                        label={getLabel("END_TIME")}
                        placeholder={getLabel("SELECT") + " " + getLabel("END_TIME")}
                        labelClassName="required fw-bold fs-6 mb-2"
                        className="form-control form-control-solid mb-lg-0"
                        name="end_shift"
                        formik={formik}
                        value={formik.values.end_shift}
                        onChange={formik.handleChange}
                        error={formik.errors.end_shift}
                      />
                    </div> */}

                    {/* is default checkbox */}
                    <div className="col-6 mb-7">
                      <label className="fw-bold fs-6 mb-2" htmlFor="active">
                        Default Staff
                      </label>
                      <label className="form-check form-switch form-check-custom form-check-solid">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          name="active"
                          style={{ width: "5rem" }}
                          value={defaultStaff}
                          checked={defaultStaff}
                          onChange={() => setdefaultStaff(defaultStaff || 1)}
                        />
                      </label>
                    </div>

                    {/* Full Name */}
                    <div className="col-6 mb-7">
                      <FormikController
                        control="input"
                        type="text"
                        label={getLabel("FULL_NAME")}
                        placeholder={`${getLabel("ENTER")} ${getLabel("FULL_NAME")}`}
                        labelClassName="required fw-bold fs-6 mb-2"
                        name="full_name"
                        className="form-control form-control-solid mb-lg-0"
                        maxLength="25"
                        formik={formik}
                        value={formik.values.full_name}
                        onChange={formik.handleChange}
                        error={formik.errors.full_name}
                      />
                    </div>
                    {/* email */}
                    <div className="col-6 mb-7">
                      <FormikController
                        control="input"
                        type="email"
                        disabled={!location?.state?.add}
                        style={location?.state?.edit && { color: "hsl(0, 0%, 60%)" }}
                        label={getLabel("EMAIL")}
                        placeholder={`${getLabel("ENTER")} ${getLabel("EMAIL")}`}
                        labelClassName="required fw-bold fs-6 mb-2"
                        name="email"
                        className="form-control form-control-solid mb-lg-0"
                        maxLength="50"
                        formik={formik}
                        value={formik.values.email}
                        onChange={formik.handleChange}
                        error={formik.errors.email}
                      />
                    </div>
                    {/* Contact Number */}
                    <div className="col-6 mb-7">
                      <FormikController
                        control="input"
                        type="text"
                        label={getLabel("CONTACT_NO")}
                        placeholder={`${getLabel("ENTER")} ${getLabel("CONTACT_NO")}`}
                        labelClassName="fw-bold fs-6 mb-2"
                        name="contact_no"
                        className="form-control form-control-solid mb-lg-0"
                        onKeyPress={(event) => {
                          if (!/[0-9]/.test(event.key)) {
                            event.preventDefault()
                          }
                        }}
                        maxLength="20"
                        formik={formik}
                        value={formik.values.contact_no}
                        onChange={formik.handleChange}
                        error={formik.errors.contact_no}
                      />
                    </div>
                    {/* address */}
                    <div className="col-6 mb-7">
                      <FormikController
                        control="input"
                        type="text"
                        label={getLabel("ADDRESS")}
                        placeholder={`${getLabel("ENTER")} ${getLabel("ADDRESS")}`}
                        labelClassName="fw-bold fs-6 mb-2"
                        name="address"
                        className="form-control form-control-solid mb-lg-0"
                        maxLength="100"
                        formik={formik}
                        value={formik.values.address}
                        onChange={formik.handleChange}
                        error={formik.errors.address}
                      />
                    </div>
                    {/* city */}
                    <div className="col-6 mb-7">
                      <FormikController
                        control="input"
                        type="text"
                        label={getLabel("CITY")}
                        placeholder={`${getLabel("ENTER")} ${getLabel("CITY")}`}
                        labelClassName="fw-bold fs-6 mb-2"
                        name="city"
                        className="form-control form-control-solid mb-lg-0"
                        maxLength="25"
                        formik={formik}
                        value={formik.values.city}
                        onChange={formik.handleChange}
                        error={formik.errors.city}
                      />
                    </div>

                    {/* simple react country */}
                    <div className="col-6 mb-7">
                      <FormikController
                        control="select"
                        label={getLabel("COUNTRY")}
                        labelClassName="fw-bold fs-6 mb-2"
                        name="country_id"
                        className="form-select form-select-solid"
                        options={countryList}
                        value={formik.values.country_id}
                        onChange={(e) => {
                          formik.resetForm({
                            values: { ...formik.values, state_id: null },
                          })
                          formik.handleChange(e)
                          getState(e.target.value)
                        }}
                        error={formik.errors.country_id}
                      />
                    </div>

                    {/* simple react state_id */}
                    <div className="col-6 mb-7">
                      <FormikController
                        control="select"
                        label={getLabel("STATE")}
                        labelClassName="fw-bold fs-6 mb-2"
                        name="state_id"
                        className="form-select form-select-solid"
                        options={stateList}
                        value={formik.values.state_id}
                        onChange={formik.handleChange}
                        error={formik.errors.state_id}
                      />
                    </div>

                    {/* password filed id adding user */}
                    {location?.state?.add && (
                      <div className="col-6 mb-7">
                        <FormikController
                          control="input"
                          type="password"
                          autoComplete="on"
                          label={getLabel("PASSWORD")}
                          placeholder={`${getLabel("ENTER")} ${getLabel("PASSWORD")}`}
                          labelClassName="required fw-bold fs-6 mb-2"
                          name="password"
                          className="form-control form-control-solid mb-lg-0 pe-13"
                          maxLength="30"
                          formik={formik}
                          value={formik.values.password}
                          onChange={formik.handleChange}
                          error={formik.errors.password}
                        />
                      </div>
                    )}

                    {/*Profile ImageShared */}
                    <div className="col-6 mb-7">
                      <FormikController
                        control="image"
                        label={getLabel("PROFILE_IMG")}
                        labelClassName="fw-bold fs-6 mb-2"
                        name="profile_pic"
                        // className="form-control form-control-solid mb-lg-0"
                        profileImg={profileImg}
                        setProfileImg={setProfileImg}
                        editFlag={location?.state?.edit}
                        editPath={location?.state?.editObj?.profile_pic}
                        formik={formik}
                        value={formik.values.profile_pic}
                        onChange={formik.handleChange}
                        error={formik.errors.profile_pic}
                      />
                    </div>
                  </div>
                </div>
                {/*end::Scroll*/}
                {/*begin::Actions*/}
                <div className="card-footer d-flex justify-content-center">
                  <button
                    className="btn btn-light-primary font-weight-bold"
                    style={{ marginRight: "1rem" }}
                    type="button"
                    onClick={() => {
                      navigate("/org/staff-management")
                    }}
                  >
                    <span className="indicator-label">{getLabel("CANCEL")}</span>
                  </button>
                  <button
                    type="submit"
                    id="kt_password_reset_submit"
                    className="btn btn-lg btn-primary fw-bolder me-4"
                    disabled={formik.isSubmitting || !formik.isValid}
                  >
                    {loading ? (
                      <span className="indicator-progress" style={{ display: "block" }}>
                        {getLabel("PLEASE_WAIT")}
                        <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                      </span>
                    ) : (
                      <span className="indicator-label">{getLabel("SUBMIT")}</span>
                    )}
                  </button>
                </div>
                {/*end::Actions*/}
              </Form>
            )
          }}
        </Formik>
      </div>
    </>
  )
}

export default CreateEditStaff
