import * as Yup from "yup"
import toast from "react-hot-toast"
import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { Formik, Form } from "formik"
import { ValSchemaList } from "../../../_metronic/Shared-Component-formik/schema/ValSchemaList"
import AxiosMethod from "../../../_metronic/Api/AxiosMethod"
import { PageTitle } from "../../../_metronic/layout/core"
import { getLabel } from "../../../_metronic/language-provider/LanProvider"
import FormikController from "../../../_metronic/Shared-Component-formik/FormikController"
import { TakeEditedVal } from "../../../_metronic/helpers/only-edit-value/TakeEditedVal"

const ManageTurnServer = () => {
  const [loading, setLoading] = useState(false)
  const [turnData, setturnData] = useState()
  const [sendGrid, setsendGrid] = useState()
  const [disabledTurn, setdisabledTurn] = useState(true)
  const [disabledEmail, setdisabledEmail] = useState(true)
  const [disableStripe, setdisableStripe] = useState(true)

  const navigate = useNavigate()

  // initialValues for formik
  const initialValues = {
    url: turnData ? turnData.url : "",
    username: turnData ? turnData.username : "",
    password: turnData ? turnData.password : "",
    twilio_account_sid: turnData ? turnData.twilio_account_sid : "",
    twilio_auth_token: turnData ? turnData.twilio_auth_token : "",
      servertype : turnData ? turnData.servertype : ""
  }
  // validationSchema for formik
  let validationSchema = Yup.object({
    url: ValSchemaList(0, "URL_IP"),
  })
  const getServerDetail = async () => {
    const apiRes = await AxiosMethod(`serverDetails`, `GET`)

    if (apiRes && apiRes.status === 200) {
      setturnData(apiRes.data.data)
    }
    if (JSON.parse(apiRes.request.response).status === "error") {
      toast.error(JSON.parse(apiRes.request.response).message)
    }
  }

  useEffect(() => {
    getServerDetail()
  }, [])

  // Submit Language
  const onSubmit = async (obj) => {
    setLoading(true)
    const apiRes = await AxiosMethod(
      turnData?.id ? `serverDetails/${turnData.id}` : `serverDetails/insert`,
      `patch`,
      obj
    )
    if (apiRes && apiRes.status === 200) {
      setLoading(false)
      toast.success(apiRes.data.message)
      navigate("/config/accounts-settings")
      setTimeout(() => {
        setdisabledTurn(true)
      }, 100);
    }
    if (JSON.parse(apiRes.request.response).status === "error") {
      setLoading(false)
      toast.error(JSON.parse(apiRes.request.response).message)
    }
  }


  // initialValues for formik
  const initialValues1 = {
    email: sendGrid ? sendGrid.email : "",
    user: sendGrid ? sendGrid.user : "",
    password: sendGrid ? sendGrid.password : "",
    mail_server: sendGrid ? sendGrid.mail_server : "",
    port: sendGrid ? sendGrid.port : "",
    stripe_key: ""
  }
  let validationSchema1 = Yup.object({
    email: ValSchemaList(0, "ACCOUNT"),
  })

  const getSendGridDetail = async () => {
    const apiRes = await AxiosMethod(`serverDetails/sendgrid`, `GET`)

    if (apiRes && apiRes.status === 200) {
      setsendGrid(apiRes.data.data)
    }
    if (JSON.parse(apiRes.request.response).status === "error") {
      toast.error(JSON.parse(apiRes.request.response).message)
    }
  }

  useEffect(() => {
    getSendGridDetail()
  }, [])

  const onSubmitSendgrid = async (obj, { setFieldValue }) =>
  {
    let temp = TakeEditedVal(obj, initialValues1)
    setLoading(true)
    const apiRes = await AxiosMethod(
      "serverDetails/sendgrid",
      `PATCH`,
      temp
    )
    if (apiRes && apiRes.status === 200) {
      setLoading(false)
      toast.success(apiRes.data.message)
      navigate("/config/accounts-settings")
      setTimeout(() => {
        setFieldValue("stripe_key", "")
        setdisabledEmail(true)
      }, 100);
    }
    if (JSON.parse(apiRes.request.response).status === "error") {
      setLoading(false)
      toast.error(JSON.parse(apiRes.request.response).message)
    }
  }

  return (
    <>
      <PageTitle>{getLabel("ACCOUNT_SETTING")}</PageTitle>

      <div className="card card-custom">
        <div className="card-header" style={{ alignItems: "center" }}>
          <span className="card-title text-gray-800 fs-2 fw-bolder me-1">
            {getLabel("TURN_SERVER")}
          </span>
          <button
            style={{ height: "30px" }}
            className="card-title btn btn-sm btn-primary"
            onClick={() => {
              setdisabledTurn(!disabledTurn);
            }}
          >
            {getLabel("EDIT")}
          </button>
        </div>

        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
          enableReinitialize
        >
          {(formik) => {
            return (
              <Form onSubmit={formik.handleSubmit} className="form">
                {/*begin::Scroll*/}
                <div className="card-body py-4" style={{ maxHeight: "35vh" }}>
                  <div
                    className="d-flex row me-n7 pe-7"
                    id="kt_modal_add_user_scroll"
                    data-kt-scroll="true"
                    data-kt-scroll-activate="{default: false, lg: true}"
                    data-kt-scroll-dependencies="#kt_modal_add_user_header"
                    data-kt-scroll-wrappers="#kt_modal_add_user_scroll"
                  >
                      {/* Radio options */}
                      <div className="col-12 mb-7">
                          <label className="fw-bold fs-6 mb-2">Server:</label>
                          <div className={"row"}>
                              <div className="col-6 mb-7">
                                  <div className="form-check form-check-solid">
                                      <label className="form-check-label"><input
                                          className="form-check-input"
                                          type="radio"
                                          value="myTurnsServer"
                                          disabled={disabledTurn}
                                          formik={formik}
                                          name={"servertype"}
                                          checked={formik.values.servertype === "myTurnsServer"}
                                          onChange={formik.handleChange}
                                          error={formik.errors.servertype}                                      />
                                      I have used my turn server</label>
                                  </div>
                              </div>
                              <div className="col-6 mb-7">
                                <div className="form-check form-check-solid">
                                    <label className="form-check-label">
                                        <input
                                            className="form-check-input"
                                            type="radio"
                                            value="twilioServer"
                                            name={"servertype"}
                                            disabled={disabledTurn}
                                            formik={formik}
                                            checked={formik.values.servertype === "twilioServer"}
                                            onChange={formik.handleChange}
                                            error={formik.errors.servertype}

                                        />
                                        I have used twilio server</label>
                              </div>
                              </div>
                          </div>
                      </div>

                      {/* URL, username, and password fields */}
                      {
                          formik.values.servertype == "myTurnsServer" && (
                          <>
                            {/* Filed name */}
                            <div className="col-6 mb-7">
                              <FormikController
                                control="input"
                                type="text"
                                name="url"
                                disabled={disabledTurn}
                                style={disabledTurn ? { color: "hsl(0, 0%, 60%)" } : {}}
                                label={getLabel("URL_IP")}
                                placeholder={`${getLabel("ENTER")} ${getLabel("URL_IP")}`}
                                labelClassName="required fw-bold fs-6 mb-2"
                                className="form-control form-control-solid mb-lg-0"
                                formik={formik}
                                value={formik.values.url}
                                onChange={formik.handleChange}
                                error={formik.errors.url}
                              />
                            </div>
                            <div className="col-6 mb-7">
                              <FormikController
                                control="input"
                                type="text"
                                name="username"
                                disabled={disabledTurn}
                                style={disabledTurn ? { color: "hsl(0, 0%, 60%)" } : {}}
                                label={getLabel("USER_NAME")}
                                placeholder={`${getLabel("ENTER")} ${getLabel("USER_NAME")}`}
                                labelClassName="required fw-bold fs-6 mb-2"
                                className="form-control form-control-solid mb-lg-0"
                                formik={formik}
                                value={formik.values.username}
                                onChange={formik.handleChange}
                                error={formik.errors.username}
                              />
                            </div>
                            <div className="col-6 mb-7">
                              <FormikController
                                control="input"
                                type="text"
                                name="password"
                                disabled={disabledTurn}
                                style={disabledTurn ? { color: "hsl(0, 0%, 60%)" } : {}}
                                label={getLabel("PASSWORD")}
                                placeholder={`${getLabel("ENTER")} ${getLabel("PASSWORD")}`}
                                labelClassName="required fw-bold fs-6 mb-2"
                                className="form-control form-control-solid mb-lg-0"
                                formik={formik}
                                value={formik.values.password}
                                onChange={formik.handleChange}
                                error={formik.errors.password}
                              />
                            </div>
                          </>)
                      }

                      {/* API key and hash key fields */}
                      {formik.values.servertype == "twilioServer" && (
                          <>
                              <div className="col-6 mb-7">
                                  <FormikController
                                      control="input"
                                      type="text"
                                      name="twilio_account_sid"
                                      disabled={disabledTurn}
                                      style={disabledTurn ? { color: "hsl(0, 0%, 60%)" } : {}}
                                      label={"Account Sid"}
                                      placeholder={`${getLabel("ENTER")} ${"Account Sid"}`}
                                      labelClassName="required fw-bold fs-6 mb-2"
                                      className="form-control form-control-solid mb-lg-0"
                                      formik={formik}
                                      value={formik.values.twilio_account_sid}
                                      onChange={formik.handleChange}
                                      error={formik.errors.twilio_account_sid}
                                  />
                              </div>
                              <div className="col-6 mb-7">
                                  <FormikController
                                      // Your hash key field
                                      control="input"
                                      type="text"
                                      name="twilio_auth_token"
                                      disabled={disabledTurn}
                                      style={disabledTurn ? { color: "hsl(0, 0%, 60%)" } : {}}
                                      label={"Auth Token"}
                                      placeholder={`${getLabel("ENTER")} ${"Auth Token"}`}
                                      labelClassName="required fw-bold fs-6 mb-2"
                                      className="form-control form-control-solid mb-lg-0"
                                      formik={formik}
                                      value={formik.values.twilio_auth_token}
                                      onChange={formik.handleChange}
                                      error={formik.errors.twilio_auth_token}
                                  />
                              </div>
                          </>
                      )}
                  </div>
                </div>
                {/*end::Scroll*/}
                {/*begin::Actions*/}
                <div className="card-footer d-flex justify-content-center">
                  <button
                    type="submit"
                    id="kt_password_reset_submit"
                    className="btn btn-lg btn-primary fw-bolder me-4"
                    disabled={formik.isSubmitting || !formik.isValid || disabledTurn}
                  >
                    {loading ? (
                      <span className="indicator-progress" style={{ display: "block" }}>
                        {getLabel("PLEASE_WAIT")}
                        <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                      </span>
                    ) : (
                      <span className="indicator-label">{getLabel("SUBMIT")}</span>
                    )}
                  </button>
                </div>
                {/*end::Actions*/}
              </Form>
            )
          }}
        </Formik>
      </div>

      <div className="card card-custom mt-2">
        <div className="card-header" style={{ alignItems: "center" }}>
          <span className="card-title text-gray-800 fs-2 fw-bolder me-1">
            {getLabel("MAIL_SERVER")}
          </span>
          <button
            style={{ height: "30px" }}
            className="card-title btn btn-sm btn-primary"
            onClick={() => {
              setdisabledEmail(!disabledEmail);
            }}
          >
            {getLabel("EDIT")}
          </button>
        </div>

        <Formik
          initialValues={initialValues1}
          validationSchema={validationSchema1}
          onSubmit={onSubmitSendgrid}
          enableReinitialize
        >
          {(formik) => {
            return (
              <Form onSubmit={formik.handleSubmit} className="form">
                {/*begin::Scroll*/}
                <div className="card-body py-4" style={{ height: "36vh" }}>
                  <div
                    className="d-flex row me-n7 pe-7"
                    id="kt_modal_add_user_scroll"
                    data-kt-scroll="true"
                    data-kt-scroll-activate="{default: false, lg: true}"
                    data-kt-scroll-dependencies="#kt_modal_add_user_header"
                    data-kt-scroll-wrappers="#kt_modal_add_user_scroll"
                  >
                    {/* Filed name */}
                    <div className="col-6 mb-7">
                      <FormikController
                        control="input"
                        type="text"
                        name="email"
                        disabled={disabledEmail}
                        style={disabledEmail ? { color: "hsl(0, 0%, 60%)" } : {}}
                        label={getLabel("ACCOUNT")}
                        placeholder={`${getLabel("ENTER")} ${getLabel("ACCOUNT")}`}
                        labelClassName="required fw-bold fs-6 mb-2"
                        className="form-control form-control-solid mb-lg-0"
                        formik={formik}
                        value={formik.values.email}
                        onChange={formik.handleChange}
                        error={formik.errors.email}
                      />
                    </div>
                    <div className="col-6 mb-7">
                      <FormikController
                        control="input"
                        type="text"
                        name="user"
                        disabled={disabledEmail}
                        style={disabledEmail ? { color: "hsl(0, 0%, 60%)" } : {}}
                        label={getLabel("USER")}
                        placeholder={`${getLabel("ENTER")} ${getLabel("USER")}`}
                        labelClassName="required fw-bold fs-6 mb-2"
                        className="form-control form-control-solid mb-lg-0"
                        formik={formik}
                        value={formik.values.user}
                        onChange={formik.handleChange}
                        error={formik.errors.user}
                      />
                    </div>
                    <div className="col-6 mb-7">
                      <FormikController
                        control="input"
                        type="text"
                        name="password"
                        disabled={disabledEmail}
                        style={disabledEmail ? { color: "hsl(0, 0%, 60%)" } : {}}
                        label={getLabel("PASSWORD")}
                        placeholder={`${getLabel("ENTER")} ${getLabel("PASSWORD")}`}
                        labelClassName="required fw-bold fs-6 mb-2"
                        className="form-control form-control-solid mb-lg-0"
                        formik={formik}
                        value={formik.values.password}
                        onChange={formik.handleChange}
                        error={formik.errors.password}
                      />
                    </div>
                    <div className="col-6 mb-7">
                      <FormikController
                        control="input"
                        type="text"
                        name="mail_server"
                        disabled={disabledEmail}
                        style={disabledEmail ? { color: "hsl(0, 0%, 60%)" } : {}}
                        label={getLabel("MAIL_SERVER")}
                        placeholder={`${getLabel("ENTER")} ${getLabel("MAIL_SERVER")}`}
                        labelClassName="required fw-bold fs-6 mb-2"
                        className="form-control form-control-solid mb-lg-0"
                        formik={formik}
                        value={formik.values.mail_server}
                        onChange={formik.handleChange}
                        error={formik.errors.mail_server}
                      />
                    </div>
                    <div className="col-6 mb-7">
                      <FormikController
                        control="input"
                        type="text"
                        name="port"
                        disabled={disabledEmail}
                        style={disabledEmail ? { color: "hsl(0, 0%, 60%)" } : {}}
                        label={getLabel("PORT")}
                        placeholder={`${getLabel("ENTER")} ${getLabel("PORT")}`}
                        labelClassName="required fw-bold fs-6 mb-2"
                        className="form-control form-control-solid mb-lg-0"
                        formik={formik}
                        onKeyPress={(event) => {
                          if (!/[0-9]/.test(event.key)) {
                            event.preventDefault()
                          }
                        }}
                        value={formik.values.port}
                        onChange={formik.handleChange}
                        error={formik.errors.port}
                      />
                    </div>
                  </div>
                </div>
                {/*end::Scroll*/}
                {/*begin::Actions*/}
                <div className="card-footer d-flex justify-content-center">
                  <button
                    type="submit"
                    id="kt_password_reset_submit"
                    className="btn btn-lg btn-primary fw-bolder me-4"
                    disabled={formik.isSubmitting || !formik.isValid || disabledEmail}
                  >
                    {loading ? (
                      <span className="indicator-progress" style={{ display: "block" }}>
                        {getLabel("PLEASE_WAIT")}
                        <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                      </span>
                    ) : (
                      <span className="indicator-label">{getLabel("SUBMIT")}</span>
                    )}
                  </button>
                </div>
                {/*end::Actions*/}
              </Form>
            )
          }}
        </Formik>
      </div>

      {/* Stripe */}
      <div className="card card-custom mt-2">
        <div className="card-header" style={{ alignItems: "center" }}>
          <span className="card-title text-gray-800 fs-2 fw-bolder me-1">
            {getLabel("STRIPE")}
          </span>
          <button
            style={{ height: "30px" }}
            className="card-title btn btn-sm btn-primary"
            onClick={() => {
              setdisableStripe(!disableStripe);
            }}
          >
            {getLabel("EDIT")}
          </button>
        </div>

        <Formik
          initialValues={initialValues1}
          validationSchema={validationSchema1}
          onSubmit={onSubmitSendgrid}
          enableReinitialize
        >
          {(formik) => {
            return (
              <Form onSubmit={formik.handleSubmit} className="form">
                {/*begin::Scroll*/}
                <div className="card-body py-4" >
                  <div
                    className="d-flex row me-n7 pe-7"
                    id="kt_modal_add_user_scroll"
                    data-kt-scroll="true"
                    data-kt-scroll-activate="{default: false, lg: true}"
                    data-kt-scroll-dependencies="#kt_modal_add_user_header"
                    data-kt-scroll-wrappers="#kt_modal_add_user_scroll"
                  >
                    <div className="col-6 mb-7">
                      <FormikController
                        control="input"
                        type="text"
                        name="stripe_key"
                        disabled={disableStripe}
                        style={disableStripe ? { color: "hsl(0, 0%, 60%)" } : {}}
                        label={getLabel("STRIPE_API_KEY")}
                        placeholder={`${getLabel("ENTER")} ${getLabel("STRIPE_API_KEY")}`}
                        labelClassName="fw-bold fs-6 mb-2"
                        className="form-control form-control-solid mb-lg-0"
                        formik={formik}
                        value={formik.values.stripe_key}
                        onChange={formik.handleChange}
                        error={formik.errors.stripe_key}
                      />
                    </div>
                  </div>
                </div>
                {/*end::Scroll*/}
                {/*begin::Actions*/}
                <div className="card-footer d-flex justify-content-center">
                  <button
                    type="submit"
                    id="kt_password_reset_submit"
                    className="btn btn-lg btn-primary fw-bolder me-4"
                    disabled={formik.isSubmitting || !formik.isValid || disableStripe}
                  >
                    {loading ? (
                      <span className="indicator-progress" style={{ display: "block" }}>
                        {getLabel("PLEASE_WAIT")}
                        <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                      </span>
                    ) : (
                      <span className="indicator-label">{getLabel("SUBMIT")}</span>
                    )}
                  </button>
                </div>
                {/*end::Actions*/}
              </Form>
            )
          }}
        </Formik>
      </div>
    </>
  )
}

export default ManageTurnServer
