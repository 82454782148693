import ReactDOM from "react-dom";
import { MetronicI18nProvider } from "./_metronic/language-provider/Metronici18n"
import "./_metronic/assets/sass/style.scss"
import "./_metronic/assets/sass/style.react.scss"
import { AppRoutes } from "./app/routing/AppRoutes"
import store, { persistor } from "./_metronic/redux/store"
import { Provider } from "react-redux"
import { PersistGate } from "redux-persist/es/integration/react"
import "moment-timezone";

ReactDOM.render(
  <Provider store={store}>
    <PersistGate persistor={persistor}>
      <MetronicI18nProvider>
        <AppRoutes />
      </MetronicI18nProvider>
    </PersistGate>
  </Provider>,
  document.getElementById("root")
)
