import moment from "moment"
import {useState} from "react"
import {toQrImgUrl} from "../../../_metronic/helpers"
import ConfirmModel from "../../../_metronic/helpers/model/ConfirmModel"
import {handleNull, userUtcOffset} from "../../../_metronic/helpers/reuseable-fun/FunReuse"
import {DeleteSvg} from "../../../_metronic/helpers/svg-helper/DeleteSvg"
import EditSvg from "../../../_metronic/helpers/svg-helper/EditSvg"
import ViewSvg from "../../../_metronic/helpers/svg-helper/ViewSvg"
import {getLabel} from "../../../_metronic/language-provider/LanProvider"
import {useSelector} from "react-redux"
import ModalImage from "./ModalImage"

const QrCodeRows = ({qrCode, deleteQr}) => {
  const loggedInUser = useSelector((state) => state?.AuthReducer?.userData)
  const [delId, setdelId] = useState("")

  const [modalOpen, setModalOpen] = useState(false)
  const [selectedImage, setSelectedImage] = useState(null)

  const openModal = (image) => {
    setSelectedImage(image);
    setModalOpen (true);
  };

  const closeModal = () => {
    setSelectedImage(null)
    setModalOpen(false)
  }

  return (
    <>
      <tr key={qrCode.user_id} className="odd">
        <td>
          {qrCode.qr_img ? (
            <div style={{height: "50px", width: "50px"}}>
              {qrCode.qr_img && (
                <img
                  src={toQrImgUrl(qrCode.qr_img)}
                  alt=""
                  className="w-100"
                  onClick={() => openModal (toQrImgUrl(qrCode.qr_img))}
                />
              )}
            </div>
          ) : (
            <div className="symbol symbol-circle symbol-50px overflow-hidden me-3">
              <a>
                <div className="symbol-label fs-3 bg-light-primary text-primary">QRCode</div>
              </a>
            </div>
          )}
        </td>
        <td>{handleNull(qrCode["qr_name"])}</td>
        {loggedInUser["plan.plan_type"] !== "individual" && (
          <>
            <td>{handleNull(qrCode["user.full_name"])}</td>
            <td>{handleNull(qrCode.default_staff_name || qrCode["default_staff.full_name"])}</td>
            <td>
              {qrCode?.deptArr?.length > 0
                ? qrCode.deptArr.map((e, index) => (
                    <span style={{}} key={e}>
                      {e}
                        {qrCode.deptArr.length - 1 !== index ? " | " : ""}
                    </span>
                  ))
                : "N/A"}
            </td>
          </>
        )}

        {/* <td>
          {qrCode?.staffArr?.length > 0
            ? qrCode.staffArr.map((e) => (
                <span style={{display: "block", whiteSpace: "nowrap"}} key={e}>
                  {e}
                </span>
              ))
            : "N/A"}
        </td> */}
        <td className="text-capitalize">{qrCode["type"]}</td>
        <td>
          {qrCode["qr_start_time"] && qrCode["qr_start_time"] !== "Invalid date"
            ? moment(qrCode["qr_start_time"]).utcOffset(userUtcOffset).format("DD/MM/yyyy HH:mm")
            : "Never"}
        </td>
        <td className="text-capitalize">
          {qrCode["qr_end_time"] && qrCode["qr_end_time"] !== "Invalid date"
            ? moment(qrCode["qr_end_time"]).utcOffset(userUtcOffset).format("DD/MM/yyyy HH:mm")
            : "Never"}
        </td>
        <td className="text-capitalize">{qrCode["status"]}</td>
        <td>
          <div className="d-flex justify-content-end flex-shrink-0">
            <ViewSvg path="/qr-code-management/view-qr-code" editObj={qrCode} />
            <EditSvg path="/qr-code-management/manage-qr-code" editObj={qrCode} />
            <DeleteSvg onClick={() => setdelId(qrCode.qrcode_id)} />

            <ConfirmModel
              delMsz={getLabel("QR_CODE")}
              show={delId}
              onHide={setdelId}
              onConfirmDel={deleteQr}
            />
          </div>
        </td>
      </tr>

      {selectedImage !== null ? (
        <ModalImage isOpen={modalOpen} onClose={closeModal} imageUrl={selectedImage} />
      ) : (
        ""
      )}
    </>
  )
}

export default QrCodeRows
