import {Container, Nav, Navbar, Offcanvas} from "react-bootstrap"
import {getLabel} from "../../../../_metronic/language-provider/LanProvider"
import "./Auth.scss"

function Menu() {
  return (
    <>
      <Navbar expand="md" id="menu-auth">
        <Container>
          <Navbar.Brand href="#/start" className="my-0 py-0">
            <img alt="Logo QR Phone" height="100vh" src="/media/logos/qr-logo-menu.png" />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="offcanvasNavbar-expand-md" className="my-0 mx-2 pr-5">
          </Navbar.Toggle>
          <Navbar.Offcanvas
            id="offcanvasNavbar-expand-md"
            aria-labelledby="offcanvasNavbarLabel-expand-md"
            placement="end"
          >
            <Offcanvas.Header closeButton>
              <Offcanvas.Title href="#/start" id="offcanvasNavbarLabel-expand-md">
                <img alt="Logo QR Phone" height="70" src="/media/logos/qr-logo-menu.png" />
              </Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
              <Nav className="justify-content-end flex-grow-1 pe-3">
                <Nav.Link href="#/auth" className="px-3 menu-link-auth">
                  {getLabel("menu_login").toUpperCase()}
                </Nav.Link>
                <Nav.Link href="#/select-plan" className="px-3 menu-link-auth">
                  {getLabel("menu_pricing").toUpperCase()}
                </Nav.Link>
                <Nav.Link href="#/contact" className="px-3 menu-link-auth">
                  {getLabel("menu_contact").toUpperCase()}
                </Nav.Link>
              </Nav>
            </Offcanvas.Body>
          </Navbar.Offcanvas>
        </Container>
      </Navbar>
    </>
  )
}

export default Menu
