import {useLocation, useNavigate} from "react-router-dom"
import {getLabel} from "../../../_metronic/language-provider/LanProvider"
import {PageTitle} from "../../../_metronic/layout/core"

const ViewStaffMap = () => {
  const usersBreadcrumbs = [
    {
      title: `${getLabel("STAFF")} ${getLabel("MAPPING")}`,
      path: "/org/staff-mapping",
      isSeparator: false,
      isActive: false,
    },
    {
      title: "",
      path: "",
      isSeparator: true,
      isActive: false,
    },
  ]
  const navigate = useNavigate()
  const location = useLocation()

  return (
    <>
      <PageTitle breadcrumbs={usersBreadcrumbs}>
        {`${getLabel("VIEW")} ${getLabel("STAFF")} ${getLabel("MAPPING")}`}
      </PageTitle>

      <div className="card py-2 py-sm-4">
        <div className="card-body">
          <div className="row">
            <div className="col-4 mb-5 mt-3">
              <label className="fs-5 fw-bolder form-label mb-2">
                <span>{getLabel("ORG")}</span>
              </label>
              <div className="text-capitalize">
                {location?.state?.editObj?.["organization.full_name"]}
              </div>
            </div>
            <div className="col-4 mb-5 mt-3">
              <label className="fs-5 fw-bolder form-label mb-2">
                <span>{getLabel("SCHEDULE")}</span>
              </label>
              <div className="text-capitalize">{location?.state?.editObj?.["department.name"]}</div>
            </div>
            <div className="col-4 mb-5 mt-3">
              <label className="fs-5 fw-bolder form-label mb-2">
                <span>{getLabel("STAFF")}</span>
              </label>
              <div className="text-capitalize">{location?.state?.editObj?.["staff.full_name"]}</div>
            </div>
          </div>
        </div>
        <div className="text-center mb-5">
          <button
            type="reset"
            style={{color: "black"}}
            className="btn btn-light me-3"
            onClick={() => navigate(-1)}
          >
            {getLabel("DISCARD")}
          </button>
        </div>
      </div>
    </>
  )
}

export default ViewStaffMap
