/*
     AUTHOR: QASIM ZUBAIR
    SUMMARY: THIS SERVICE IS USED FOR MAINTAINING NETWORK RELATED TASKS.
 */

// IMPORT THE REACT COMPONENTS FOR PERFORMING BASIC OPERATIONS.
import React from 'react';
//import {env} from "../env";
import store from "../../_metronic/redux/store"
import {globals} from "../utils/globals"
import {URLS} from "../../_metronic/Api/UrlList"

const getLanguageCode = () =>
{
	const ls = localStorage.getItem(process.env.REACT_APP_I18N_CONFIG_KEY || "i18nConfig");
	// @ts-ignore
	const selectedLang = JSON.parse(ls)?.selectedLang;
	if (selectedLang === "en") {
		return 1
	} else if (selectedLang === "fr") {
		return 2
	} else if (selectedLang === "es") {
		return 3
	} else {
		return 1
	}
}

// MAKE HTTP REQUEST TO THE NODE.JS SERVER TO GET DATA. FOR EXAMPLE TO PUT OR RETRIEVE DATA FROM DATABASE.
export const httpRequest = async (
	endPoint: string,
	method: string,
	requestData: any = {},
	headers?: any,
	transformRequest?: any,
	server?: string | null,
	returnType?: string,
) =>
{
	try
	{
		
		const token = await store.getState ().AuthReducer.token || "";
		const languageCode = getLanguageCode ()
		
		// PARAMETERS TO SENT TO SERVER WITH HTTP REQUEST.
		let params: any =
			{
				method: method,
				headers:
					{
						Authorization: `Bearer ${token}`,
						Accept: 'application/json',
						'Content-Type': 'application/json',
					},
			};
		
		// IF HEADERS ARE PROVIDED THEN USE THEM.
		if (headers)
		{
			params.headers = headers;
		}
		
		// IF HEADERS ARE PROVIDED THEN USE THEM.
		if (transformRequest)
		{
			params.transformRequest = () =>
			{
				return transformRequest
			};
		}
		
		//console.log ('step4');
		
		// IF REQUEST DATA WAS PROVIDED THEN ADD IT.
		if (requestData && method == "POST" || requestData && method == "PATCH")
		{
			requestData.appversion = globals.appVersion;
			requestData.request_from = globals.request_from;
			
			// IF LANGUAGE ID IS NOT SET THEN SET IT NOW 1
			if (typeof requestData.language === "undefined")
			{
				requestData.language = `${languageCode}`;
			}
			params.body = JSON.stringify (requestData);
			console.log ("params.body--", params.body);
		}
		
		// IF METHOD IS GET AND REQUEST DATA IS SENT THE ADD IT TO THE ENDPOINT.
		if (method == "GET" && requestData)
		{
			endPoint += '?' + Object.keys (requestData)
									.map ((k) => (k + '=' + encodeURIComponent (requestData[k])))
									.join ('&');
			endPoint += '&appversion=' + globals.appVersion;
			endPoint += '&request_from=' + globals.request_from;
			
			// IF LANGUAGE ID IS NOT SET THEN SET IT NOW.
			if (endPoint.indexOf ("language") === -1)
			{
				endPoint += '&language=' + (`${languageCode}` || "en");
			}
		}
		
		//console.log ('step5');
		// IF IT'S A GET REQUEST BUT THRE IS NO "?" IN THE URL THEN ADD IT.
		if (method === "GET" && !requestData && endPoint.indexOf ("?") === -1)
		{
			endPoint += '?appversion=' + globals.appVersion +
				'&request_from=' + globals.request_from +
				'&language=' + `${languageCode}`;
		}
		
		// TIMEOUT THE FETCH REQUEST AFTER X SECONDS.
		const timeout = (requestData && requestData?.timeout ? requestData.timeout: 15000);
		const controller = new AbortController ();
		const timeoutId = setTimeout (() =>
		{
			console.log (`Request timeout for endpoint: ${endPoint}`, 'error');
			controller.abort ();
		}, timeout);
		
		// SEND THE SIGNAL TO THE FETCH API. THIS SIGNAL WILL ABORT THE CONNECTION IF NEEDED.
		params.signal = controller.signal;
		let paramsSent = params.body;
		
		// IF ITS REPORT A BUG CALL THEN DON'T STORE THE PARAMS SENT WITH THIS LOG.
		if (endPoint === "bugreport/create")
		{
			paramsSent = {};
		}
		console.log ("\nRequest URL => ", URLS?.BASE_URL +"/"+ endPoint, method, paramsSent);
		
		// SEND DATA TO SERVER.
		let response = await fetch (URLS?.BASE_URL +"/"+ endPoint, params);
		console.log (`API ${endPoint} is resolved.`);
		clearTimeout (timeoutId);
		
		// TRY TO RETURN JSON DATA.
		try
		{
			return await response.json ();
		}
		catch (error)
		{
			console.error (`API ${endPoint} has an error in converting response to json. ${error}`, response);
			return {success: false, isNetworkError: true, error: "Error in network request. " + error, errorType: 'network',};
		}
	}
	catch (error)
	{
		console.log (`API ${endPoint} has an error. ${error}`, 'error');
		return {success: false, isNetworkError: true, error: "Error in network request. " + error, errorType: 'network',};
	}
};


// CHECK IF THE INTERNET IS CONNECTED OR NOT?
export const isConnected = () =>
{
	// NetInfo.fetch().then((state: { type: any; isConnected: any; }) =>
	// {
	// 	console.log ("Connection type", state.type);
	// 	console.log ("Is connected?", state.isConnected);
	// });
};
