import {useNavigate, useSearchParams} from "react-router-dom"
import toast from "react-hot-toast"

import {useEffect} from "react"
import jwt_decode from "jwt-decode"
import AxiosMethod from "../../../_metronic/Api/AxiosMethod"
import {Spinner} from "react-bootstrap"

const InviteExistingUser = () => {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()

  const token_decoded = searchParams.get("token") && jwt_decode(searchParams.get("token"))

  const exstingUser = async () => {
    let res = await AxiosMethod(`users/inviteExistingUser`, `post`, {
      role_id: token_decoded?.roleId,
      user_id: token_decoded?.userId,
      organization_id: token_decoded?.organizationId,
    })

    if (res?.data?.status === "success") {
      toast.success(res.data.message)
      navigate("/")
      localStorage.clear()
    } else {
      toast.error(JSON.parse(res.request.response).message)
      navigate("/")
      localStorage.clear()
    }
  }
  useEffect(() => {
    exstingUser()
  }, [])
  return (
    <div className="text-center" style={{margin: "auto"}}>
      <Spinner
        animation="grow"
        size="sm"
        style={{width: "2.5rem", height: "2.5rem"}}
        className="ms-1"
      />
      <Spinner
        animation="grow"
        size="sm"
        style={{width: "2.5rem", height: "2.5rem"}}
        className="ms-1"
      />
      <Spinner
        animation="grow"
        size="sm"
        style={{width: "2.5rem", height: "2.5rem"}}
        className="ms-1"
      />
    </div>
  )
}

export default InviteExistingUser
