import {useLocation, useNavigate} from "react-router-dom"
import {toAbsoluteUrl, toProfileImgUrl} from "../../../_metronic/helpers"
import {getLabel} from "../../../_metronic/language-provider/LanProvider"
import {PageTitle} from "../../../_metronic/layout/core"
import {useState, useEffect} from "react"
import {getTempOrg, handleNull} from "../../../_metronic/helpers/reuseable-fun/FunReuse"
import InviteEmailSvg from "../../../_metronic/helpers/svg-helper/InviteEmailSvg"
import {DeleteSvg} from "../../../_metronic/helpers/svg-helper/DeleteSvg"
import AxiosMethod from "../../../_metronic/Api/AxiosMethod"
import {toast} from "react-hot-toast"
import ConfirmModel from "../../../_metronic/helpers/model/ConfirmModel"

const ViewStaff = () => {
  const usersBreadcrumbs = [
    {
      title: `${getLabel("STAFF")} ${getLabel("LIST")}`,
      path: "/org/staff-management",
      isSeparator: false,
      isActive: false,
    },
    {
      title: "",
      path: "",
      isSeparator: true,
      isActive: false,
    },
  ]
  const navigate = useNavigate()
  const location = useLocation()

  const [userList, setUserList] = useState([])
  const [value, setValue] = useState("")

  const getOrg = async () => {
	  let res = await getTempOrg();
	  console.log("getTempOrg", res)
    setUserList(res)
  }

  useEffect(() => {
    getOrg()
  }, [])

  const [delId, setdelId] = useState({
    delStaffId: "",
    delOrgId: "",
  })
  const removeFromOrg = async (showData) => {
    let removeStaffRes = await AxiosMethod(`users/removeStaffOrganization`, `post`, {
      staffId: showData.delStaffId,
      organizationId: showData.delOrgId,
      roleId: 3,
    })

    if (removeStaffRes.status === 200) {
      toast.success(removeStaffRes.data.message)
      navigate("/org/staff-management")
    }
    if (JSON.parse(removeStaffRes.request.response).status === "error") {
      toast.error(JSON.parse(removeStaffRes.request.response).message)
    }
  }
console.log ("ocation?.state?.editObj")
  return (
    <>
      <PageTitle breadcrumbs={usersBreadcrumbs}>
        {`${getLabel("VIEW")} ${getLabel("STAFF")}`}
      </PageTitle>

      <div className="card py-2 py-sm-4">
        <div className="card-body">
          <div className="row">
            <div className="col-12 col-sm-2 d-flex justify-content-center align-items-center mt-3 mt-sm-7">
              <div className="me-7 mb-4">
                <div className="symbol symbol-100px symbol-lg-160px symbol-fixed position-relative">
                  {location?.state?.editObj?.profile_pic ? (
                    <img
                      src={toProfileImgUrl(location?.state?.editObj?.profile_pic)}
                      alt=""
                      className="w-100"
                    />
                  ) : (
                    <img
                      src={toAbsoluteUrl("/media/not-found/noimg.png")}
                      alt=""
                      className="w-100"
                    />
                  )}
                </div>
              </div>
            </div>

            <div className="col-12 col-sm-10">
              <div className="row">
                <div className="col-4 mb-5 mt-3">
                  <label className="fs-5 fw-bolder form-label mb-2">
                    <span className="text-capitalize">{getLabel("NAME")}</span>
                  </label>
                  <div>{handleNull(location?.state?.editObj?.full_name)}</div>
                </div>
                <div className="col-4 mb-5 mt-3">
                  <label className="fs-5 fw-bolder form-label mb-2">
                    <span className="text-capitalize">{getLabel("EMAIL")}</span>
                  </label>
                  <div>{handleNull(location?.state?.editObj?.email)}</div>
                </div>
                <div className="col-4 mb-5 mt-3">
                  <label className="fs-5 fw-bolder form-label mb-2">
                    <span className="text-capitalize">{getLabel("CONTACT_NO")}</span>
                  </label>
                  <div>{handleNull(location?.state?.editObj?.contact_no)}</div>
                </div>
                {/* <div className="col-4 mb-5 mt-3">
                  <label className="fs-5 fw-bolder form-label mb-2">
                    <span className="text-capitalize">Organization</span>
                  </label>
                  <div>{handleNull(location?.state?.editObj["user.full_name"])}</div>
                </div>
                <div className="col-4 mb-5 mt-3">
                  <label className="fs-5 fw-bolder form-label mb-2">
                    <span className="text-capitalize">Group</span>
                  </label>
                  <div>{handleNull(location?.state?.editObj["department.name"])}</div>
                </div>
                <div className="col-4 mb-5 mt-3">
                  <label className="fs-5 fw-bolder form-label mb-2">
                    <span className="text-capitalize">Default Staff</span>
                  </label>
                  <div>{location?.state?.editObj.is_default == 1 ? "True" : "False"}</div>
                </div> */}
                <div className="col-4 mb-5 mt-3">
                  <label className="fs-5 fw-bolder form-label mb-2">
                    <span className="text-capitalize">{getLabel("ADDRESS")}</span>
                  </label>
                  <div>{handleNull(location?.state?.editObj?.address)}</div>
                </div>
                <div className="col-4 mb-5 mt-3">
                  <label className="fs-5 fw-bolder form-label mb-2">
                    <span className="text-capitalize">{getLabel("CITY")}</span>
                  </label>
                  <div>{handleNull(location?.state?.editObj?.city)}</div>
                </div>
                <div className="col-4 mb-5 mt-3">
                  <label className="fs-5 fw-bolder form-label mb-2">
                    <span className="text-capitalize">{getLabel("STATE")}</span>
                  </label>
                  <div>{handleNull(location?.state?.editObj["state.name"])}</div>
                </div>
                <div className="col-4 mb-5 mt-3">
                  <label className="fs-5 fw-bolder form-label mb-2">
                    <span className="text-capitalize">{getLabel("COUNTRY")}</span>
                  </label>
                  <div>{handleNull(location?.state?.editObj["country.name"])}</div>
                </div>
	              <div className="col-4 mb-5 mt-3">
		              <label className="fs-5 fw-bolder form-label mb-2">
			              <span className="text-capitalize">{getLabel ("ORGANIZATIONS")}</span>
		              </label>
		              <div> {location.state.editObj.organizations_array
					              .map((x) => (
						              <span style={{ display: "block", whiteSpace: "nowrap" }} key={x}>
			                {handleNull(x)}
			              </span>
		              ))}
					  </div>
	              </div>
                {/* already invited organization manager */}
                {/* <div className="col-4 mb-5 mt-3">
                  <label className="fs-5 fw-bolder form-label mb-2">
                    <span className="text-capitalize">{getLabel("ALREADY_INVITED_ORG")}</span>
                  </label>
                  <ul>
                    {userList
                      .filter((x) => location.state.editObj.organizations_array.includes(x.user_id))
                      .map((x) => (
                        <li key={x?.user_id} style={{marginBottom: ".2rem"}}>
                          {x?.full_name}
                          <DeleteSvg
                            onClick={() => {
                              setdelId({
                                delOrgId: x.user_id,
                                delStaffId: location.state.editObj.user_id,
                              })
                            }}
                            style={{marginLeft: "0.5rem"}}
                          />
                        </li>
                      ))}
                  </ul>
                </div> */}

                {/* <div className="col-4 mb-5 mt-3">
                  <label className="fs-5 fw-bolder form-label mb-2">
                    <span className="text-capitalize">{getLabel("INVITE_ORG")}</span>
                  </label>
                  <br />
                  <select value={value} onChange={(e) => setValue(e.currentTarget.value)}>
                    <option value="">{getLabel("SELECT")}...</option>
                    {userList
                      .filter(
                        (x) => !location.state.editObj.organizations_array.includes(x.user_id)
                      )
                      .map((x) => (
                        <option key={x.user_id} value={x.user_id}>
                          {x.full_name}
                        </option>
                      ))}
                  </select>
                  <InviteEmailSvg
                    onClick={() => {
                      value &&
                        navigate("/org/staff-management/invite", {
                          state: {
                            editObj: {
                              email: location?.state?.editObj?.email,
                              organization_id: value,
                              inviteType: "user",
                            },
                          },
                        })
                    }}
                  />
                </div> */}
              </div>
            </div>
          </div>
        </div>
        <div className="text-center mb-5">
          <button
            type="reset"
            style={{color: "black"}}
            className="btn btn-light me-3"
            onClick={() => navigate(-1)}
          >
            {getLabel("DISCARD")}
          </button>
        </div>
      </div>

      <ConfirmModel
        delMsz="Are you sure you want to remove this staff from the organization?"
        show={delId}
        onHide={setdelId}
        onConfirmDel={removeFromOrg}
      />
    </>
  )
}

export default ViewStaff
