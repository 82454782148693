import "./style/userscan.scss"
import logo from "./images/logo.svg"
import MissedCalled from "./images/missed-out.svg"
import {useEffect, useState} from "react"
import {Form, Formik} from "formik"
import {ValSchemaList} from "../../../_metronic/Shared-Component-formik/schema/ValSchemaList"
import * as Yup from "yup"
import QrUserDetailsInput from "../../../_metronic/Shared-Component-formik/input/QrUserDetailsInput"
import {useLocation} from "react-router-dom"
import {updateCallRealTime} from "../../services/helper.service"

const StaffNotAvailableComponent = () =>
{
	// GET THE CURRENT LOCATION AND STATE FROM REACT ROUTER
	const location = useLocation();
	const {state}: any = location;
	const [showContactMethod, setShowContactMethod] = useState<any>("");
	const [contactMethod, setContactMethod] = useState<any>('');
	const [contactInfo, setContactInfo] = useState('');
	const [isSubmitted, setIsSubmitted] = useState(false);

	// HANDLE RADIO BUTTON CHANGE USER WANT TO CONTACT BACK OR NOT.
	// IF USER SELECT YES THEN SHOW THE CONTACT METHOD.
	const handleContactBack = (e: any) =>
	{
		setShowContactMethod (e.target.value);
	};

	// HANDLE FORM SUBMISSION CONTACT BACK REQUEST.
	const handleSubmit = async (values:any) =>
	{
		// HERE YOU CAN HANDLE THE SUBMISSION OF THE FORM
		console.log('Contact values:', values);

		// UPDATE THE CALL DETAIL BY CALL DETAIL ID.
		//await updateCallFirestore (state.call_id, { callbackcontact: values});
		await updateCallRealTime (state.call_id, { callbackcontact: values});

		setIsSubmitted (true);
	};

	const initialValues =
	{
		email: '',
		telephone: ''
	};
	let valSch: any = {};

    useEffect(() =>
    {
        window.history.pushState (null, null, null)
        window.onpopstate = function ()
        {
            window.history.go(1)
        }
    }, []);

	useEffect (() =>
	{
		// CONTACT METHOD SELECTED ANYONE THEN CREATE THE VALIDATION SCHEMA.
		if (contactMethod === "email")
		{
			valSch["email"] = ValSchemaList (1, "email", "", "", "email");
		}
		else if (contactMethod === "contact_no")
		{
			valSch["contact_no"] = ValSchemaList (4, "contact_no", "", "", "contact_no");
		}
	}, [contactMethod]);

	// CREATE THE VALIDATION SCHEMA FOR THE FORM.
	let validationSchema = Yup.object(valSch)

	return (
		<div className="main-wrap" style={{alignItems:"flex-start"}}>
			<div className="banner-experience py-2" style={{height:'60px'}}>
				<img src={logo} alt="" />
			</div>
			<div className="container" style={{marginTop:'60px'}}>
				<div className="row">
					<div className="col-xxl-12 m-auto text-center thankscall-wrap">
						<img src={MissedCalled} alt="" width={150} className="image_call" />
						<div className="text-center thanks_text">
							<h4 className="mt-5" style={{color:"white"}}>
								No staff available right now, they have sent a message to you, here it is:
							</h4>
						</div>
					</div>
				</div>
				<div className="row">
					<div className="col-xxl-12 m-auto text-center">
						<div className="text-center">
							<p className="my-5">
								Sorry, we missed your call. We are currently unavailable to take your call. Please leave your contact information and we will get back to you as soon as possible.
							</p>
						</div>
					</div>
					<div className="text-center my-5">

						<div className="form-check-inline">
							<label className="form-check-label">
								<input
									type="radio"
									className="form-check-input"
									name="optradio"
									value="Yes"
									disabled={showContactMethod !== ""}
									onChange={handleContactBack}
								/> Yes
							</label>
						</div>
						<div className="form-check-inline">
							<label className="form-check-label">
								<input
									type="radio"
									className="form-check-input"
									name="optradio"
									value="No"
									disabled={showContactMethod !== ""}
									onChange={handleContactBack}
								/> No
							</label>
						</div>
					</div>

						{
							// SHOW THE CONTACT METHOD FORM IF USER SELECT YES. OTHERWISE SHOW THE THANK YOU MESSAGE.
							showContactMethod === "Yes" && showContactMethod != ''
								&&
								(<div className="my-5 ml">
									<p className="text-center">Great! What's your preferred method of contact?</p>
									<div className={"mb-5 ms-3"}>
										<div className="form-check mb-3">
											<label className="form-check-label">
												<input
													type="radio"
													className="form-check-input"
													name="contactMethod"
													value="email"
													disabled={contactMethod !== ""}
													onChange={(e) => setContactMethod(e.target.value)}
												/> Email
											</label>
										</div>
										<div className="form-check">
											<label className="form-check-label">
												<input
													type="radio"
													className="form-check-input"
													name="contactMethod"
													value="contact_no"
													disabled={contactMethod !== ""}
													onChange={(e) => setContactMethod (e.target.value)}
												/> Mobile number
											</label>
										</div>
									</div>
								{
									contactMethod && contactMethod != '' &&
									<>
										<div className="mt-5 pt-5">
										<Formik
											initialValues={initialValues}
											validationSchema={validationSchema}
											onSubmit={handleSubmit}
										>
											{
												(formik) =>
												{
													return (

													!isSubmitted ?
													<Form onSubmit={formik.handleSubmit} autoComplete="off">
															{
														contactMethod === "email"
															?
															<QrUserDetailsInput
																key={"email"}
																type={"email"}
																label={"email"}
																labelClassName="input-text"
																name={"email"}
																placeholder=" "
															/>
															:
															<QrUserDetailsInput
																key={"contact_no"}
																type={"contact_no"}
																label={"Contact no"}
																labelClassName="input-text"
																name={"telephone"}
																placeholder=" "
															/>
															}
															<button
																type="submit"
																className="btn btn-primary w-100 color-primary mt-50"
															> Submit
															</button>
														</Form>
													:
														<>
															{contactMethod === "email" && <p>Email: {formik.values.email}</p>}
															{contactMethod === 'contact_no' && <p>Contact Number: {formik.values.telephone}</p>}
															<div className="text-center mt-5">
																<p> Great, Thank you for your information. We will get back to you as soon as possible.</p>
															</div>
														</>
												)
												}
											}
										</Formik>
									</div>

									</>

								}
							</div>
						)
				}
					{	showContactMethod === "No" && showContactMethod != '' &&
						<div className="my-5 ml">
							<p className="text-center">
								No problem, hope to see you again soon. You can exit this browser tab now.
							</p>
						</div>
					}
					</div>
			</div>
		</div>
	)
}

export default StaffNotAvailableComponent
